import FuseAnimate from '@fuse/core/FuseAnimate';
import { useForm } from '@fuse/hooks';
import { Button, MenuItem, Card, CardContent, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch, useSelector } from 'react-redux';
import * as commonTypes from 'app/main/constants/CommonTypes';
import { setAdministrator } from 'app/auth/store/registerSlice';
import { doLogin } from 'app/auth/store/loginSlice';
import { setAdminSetup } from 'app/auth/store/initialSlice';
import { getSysConfigure, setSysConfigure } from 'app/store/docbase/admin/settingSlice';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		background: `radial-gradient(${darken(theme.palette.primary.main, 0.5)} 0%, ${theme.palette.primary.main} 80%)`,
		color: theme.palette.primary.contrastText
	}
}));

function RegisterPage({ history }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation('docbase');

	const sysConfigure = useSelector(({ docbase }) => docbase.admin.setting.sysConfigure);
	const initialObj = useSelector(({ auth }) => auth.initial);
	const { form, handleChange, resetForm } = useForm({
		userid: '',
		name: '',
		password: '',
		passwordConfirm: '',
		email: ''
	});
	const [language, setLanguage] = React.useState(sysConfigure ? sysConfigure.language : 'korean');
	const [serverSideEncryption, setServerSideEncryption] = React.useState(
		sysConfigure ? String(sysConfigure.server_side_encryption) : 'true'
	);

	useEffect(() => {
		// dispatch(setSysConfigure({ name: 'server_side_encryption', value: 'true' })).then(() => {
		// 	dispatch(setSysConfigure({ name: 'language', value: 'korean' }));
		// });
	}, [dispatch]);

	useEffect(() => {
		if (initialObj.administrator_setup) {
			history.push('/admin');
		}
	}, [initialObj]);

	useEffect(() => {
		setLanguage(sysConfigure.language);
		setServerSideEncryption(String(sysConfigure.server_side_encryption));
	}, [sysConfigure]);

	function isFormValid() {
		return (
			form.userid.trim().length > 0 &&
			form.name.trim().length > 0 &&
			form.password.length > 0 &&
			form.password.length > 3 &&
			form.password === form.passwordConfirm
		);
	}

	function handleSubmit(ev) {
		ev.preventDefault();
		setAdministrator(form.userid, form.password, form.name, form.email)
			.then(data => {
				if (data.result) {
					dispatch(setAdminSetup(true));
					doLogin(form.userid, form.password, '')
						.then(loginData => {
							history.replace('/dashboard');
						})
						.catch(err => {
							console.error(err);
						});
				} else {
					let msg = t('ADMIN_ERROR_MESSAGE_1');
					if (data.error === commonTypes.SERVER_ERROR_EXISTS) {
						msg = t('ADMIN_ERROR_MESSAGE_2');
					}
					dispatch(setAdminSetup(false));
					dispatch(showMessage({ message: msg, variant: 'error' }));
				}
			})
			.catch(err => {});
		resetForm();
	}

	const handleSettingChange = event => {
		dispatch(setSysConfigure({ name: event.target.name, value: event.target.value })).then(() => {
			dispatch(getSysConfigure());
		});
	};

	return (
		<div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>
			<div className="flex flex-col items-center justify-center w-full">
				<FuseAnimate animation="transition.expandIn">
					<Card className="w-full max-w-384 rounded-8">
						<CardContent className="flex flex-col items-center justify-center p-32">
							<img className="w-128" src="assets/brand/logo.svg" alt="logo" />

							<Typography variant="h6" className="mt-10 mb-12 font-bold text-20 sm:text-24" color="primary">
								{t('CREATE_ADMIN')}
							</Typography>

							<form
								name="registerForm"
								noValidate
								className="flex flex-col justify-center w-full"
								onSubmit={handleSubmit}
							>
								{sysConfigure.menu_support_type === 2 ? (
									<></>
								) : (
									<>
										<TextField
											select
											className="mb-16"
											name="server_side_encryption"
											value={serverSideEncryption}
											label={t('SERVER_ENCRYPT')}
											fullWidth
											onChange={e => handleSettingChange(e)}
										>
											<MenuItem value="true">{t('USE')}</MenuItem>
											<MenuItem value="false">{t('UNUSED')}</MenuItem>
										</TextField>
										<TextField
											name="language"
											value={language}
											label={t('LANGUAGE')}
											fullWidth
											onChange={e => handleSettingChange(e)}
										>
											<MenuItem value="korean">한국어</MenuItem>
											<MenuItem value="english">English</MenuItem>
										</TextField>
									</>
								)}

								<TextField
									className="mb-16"
									label={t('ID')}
									type="text"
									name="userid"
									value={form.userid}
									onChange={handleChange}
									variant="outlined"
									required
									fullWidth
									size="small"
								/>

								<TextField
									className="mb-16"
									label={t('NAME')}
									autoFocus
									type="text"
									name="name"
									value={form.name}
									onChange={handleChange}
									variant="outlined"
									required
									fullWidth
									size="small"
								/>

								<TextField
									className="mb-16"
									label={t('EMAIL')}
									type="email"
									name="email"
									value={form.email}
									onChange={handleChange}
									variant="outlined"
									fullWidth
									size="small"
								/>

								<TextField
									className="mb-16"
									label={t('PASSWORD')}
									type="password"
									name="password"
									value={form.password}
									onChange={handleChange}
									variant="outlined"
									required
									fullWidth
									size="small"
								/>

								<TextField
									className="mb-16"
									label={t('PASSWORD_CONFIRM')}
									type="password"
									name="passwordConfirm"
									value={form.passwordConfirm}
									onChange={handleChange}
									variant="outlined"
									required
									fullWidth
									size="small"
								/>

								<Button
									variant="contained"
									color="primary"
									className="w-full mx-auto"
									aria-label="Register"
									disabled={!isFormValid()}
									type="submit"
								>
									{t('CREATE')}
								</Button>
							</form>
						</CardContent>
					</Card>
				</FuseAnimate>
			</div>
		</div>
	);
}

export default RegisterPage;
