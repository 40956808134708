import { TableCell, TableHead, TableRow, Icon, Paper, Input, InputAdornment, IconButton } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

function RansomTableHead(props) {
	const { t } = useTranslation('docbase');

	const headerCols = [
		{
			id: 'user_id',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('USER'),
			isSearch: true,
			sort: false
		},
		{
			id: 'client_address',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('CLIENT_ADDRESS'),
			isSearch: true,
			sort: false
		},
		{
			id: 'ransomware_path',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('RANSOMWARE_PATH'),
			isSearch: true,
			sort: false
		},
		{
			id: 'infected_files',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('INFECTED_FILES'),
			isSearch: true,
			sort: false
		},
		{
			id: 'detected_at',
			align: 'center',
			className: 'left',
			disablePadding: false,
			label: t('DETECTED_AT'),
			isSearch: false,
			sort: false
		}
	];

	const [searchObj, setSearchObj] = React.useState({
		ransomware_path: { show: props.searchObj.path !== '', value: props.searchObj.path },
		user_id: { show: props.searchObj.user !== '', value: props.searchObj.user },
		client_address: { show: props.searchObj.clientAddress !== '', value: props.searchObj.clientAddress },
		infected_files: { show: props.searchObj.infectedFilePath !== '', value: props.searchObj.infectedFilePath }
	});

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};
	return (
		<TableHead>
			<TableRow>
				{headerCols.map(row => {
					return (
						<TableCell
							className={row.className}
							key={row.id}
							align={row.align}
							padding={row.disablePadding ? 'none' : 'default'}
							sortDirection={false}
						>
							{searchObj[row.id] && searchObj[row.id].show ? (
								<Paper className="flex items-center px-8 py-4 rounded-8 shadow mx-5">
									<Icon color="action">search</Icon>

									<Input
										placeholder={row.label}
										className="flex flex-1 mx-8 w-0"
										disableUnderline
										value={searchObj[row.id].value}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													className="p-5"
													aria-label="toggle password visibility"
													onClick={e =>
														setSearchObj({ ...searchObj, [row.id]: { show: false, value: '' } })
													}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													<Icon color="action">close</Icon>
												</IconButton>
											</InputAdornment>
										}
										inputProps={{
											'aria-label': 'Search'
										}}
										onChange={e => {
											setSearchObj({ ...searchObj, [row.id]: { show: true, value: e.target.value } });
										}}
										onKeyPress={e => {
											if (e.charCode === 13) {
												e.preventDefault();
												props.fnSearch(
													searchObj.ransomware_path.value,
													searchObj.user_id.value,
													searchObj.client_address.value,
													searchObj.infected_files.value
												);
											}
										}}
									/>
								</Paper>
							) : (
								<>
									{row.id === 'ransomware_path' ? (
										<div className="inline-block align-middle">
											<span className="align-middle">{row.label}</span>
											<br />
											<span className="align-middle">[{t('RANSOMWARE_STATUS')}]</span>
										</div>
									) : (
										<span className="align-middle">{row.label}</span>
									)}

									{row.isSearch ? (
										<Icon
											className="align-middle ml-3 cursor-pointer"
											onClick={e => {
												setSearchObj({ ...searchObj, [row.id]: { show: true, value: '' } });
											}}
										>
											search
										</Icon>
									) : (
										<></>
									)}
								</>
							)}
						</TableCell>
					);
				}, this)}
			</TableRow>
		</TableHead>
	);
}

export default RansomTableHead;
