import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import httpClient from 'app/utils/HttpClient';
import securityUtils from 'app/utils/SecurityUtils';
import commonUtils from 'app/utils/CommonUtils';
import { secureRequest, secureAsyncRequest } from 'app/store/docbase/commonAction';
import * as commonTypes from 'app/main/constants/CommonTypes';
import { getStorageDashboard } from './systemSlice';

export const getEmergencyMode = createAsyncThunk('setting/getEmergencyMode', async () => {
	const respData = await httpClient.sendPost('/api/getEmergencyMode', {});
	if (!respData.result) {
		httpClient.printError(respData);
		return { mode: 0, back_hours: 0, date: '2021-02-05', process_type: 1, status: 0 };
	}
	return {
		mode: respData.mode,
		back_hours: respData.back_hours,
		date: respData.date,
		process_type: respData.process_type,
		status: respData.status
	};
	// return  { mode: 0, back_hours: 0, date: '2021-02-05', process_type: 1, status: 0 };
});

export const getRecoveryAvailableDays = createAsyncThunk('setting/recoveryAvailableDays', async () => {
	const respData = await httpClient.sendPost('/api/recoveryAvailableDays', {});
	if (!respData.result) {
		return [];
	}
	return respData.days;
});

const DEFAULT_CONFIGURE = {
	language: 'korean',
	weblink_service_support: true,
	weblink_service_address: '',
	weblink_service_port: '',
	strong_password: false,
	collector_support: true,
	collect_transfer_size: 1048576,
	collect_interval_transfer: 1000,
	collect_drive_cdrom: false,
	collect_drive_fixed: true,
	collect_drive_ramdisk: false,
	collect_drive_remote: false,
	collect_drive_removable: false,
	collect_drive_fixed_hotplug: false,
	collect_sync_remove_support: true,
	collect_inclusive_pattern_support: false,
	collect_exclusive_extension_support: false,
	collect_config: [],
	server_side_encryption: false,
	keep_days_backup_data: 0,
	default_volume_label: '',
	private_folder_size: 0,
	collect_folder_size: 0,
	organization_folder_size: 0,
	shared_folder_size: 0,
	two_factor_authentication: false,
	support_default_smb_port: false,
	restrict_file_size: 0,
	restrict_file_exts: '',
	pii_detect_enable: false,
	pii_ocr: false,
	async_ocr: false,
	dup_detect_enable: false,
	access_control: false,
	saving_control: false,
	dataleak_control: false,
	service_type: 'ENT',
	connect_server_address: '',
	force_map_mount_drive: 'Y',
	map_mount_drive: 'Y',
	verysilent_setup: false,
	silent_setup: true,
	disable_connect_button_webadmin: true,
	auto_start: false,
	desktop_web_support: true,
	mobile_web_support: true,
	integrated_with_AD: false,
	OSR_support: false,
	no_shell_menu: false,
	disable_two_factor_authentication: false,
	allow_admin_access_users_folders: false,
	force_setup_perform: false,
	lock_volume_label: false,
	restrict_file_exts_remove_at_batch: false,
	support_ransomware_detection: true,
	support_secure_document: true,
	min_smb_protocol: 'SMB3',
	smb_encryption: false,
	sync_solution_integrated: false,
	sync_solution: '',
	sync_solution_consumer_folder_accessible: false,
	no_tray_icon_menu: false,
	support_oplocks: true,
	private_folder_support: true,
	preserve_last_revision: true,
	preserve_last_revision_without_original: false,
	max_days_preserve_last_revision_without_original: 10,
	disable_save_password: false,
	menu_support_type: 1,
	support_macos_dlp: false,
	weblink_restrict_exts: '',
	weblink_restrict_file_size: 0,
	weblink_restrict_total_size: 0
};
export const getSysConfigure = createAsyncThunk('setting/getSysConfigure', async () => {
	const respData = await httpClient.sendPost('/api/getSysConfigure', {});

	if (!respData.result) {
		httpClient.printError(respData);
		return DEFAULT_CONFIGURE;
	}

	return respData;
});
export const setSysConfigure = createAsyncThunk('setting/setSysConfigure', async ({ setConf }, { dispatch, getState }) => {
	const secureObj = await secureAsyncRequest();

	if (!('collector_support' in setConf)) {
		setConf.collector_support = getState().docbase.admin.setting.sysConfigure.collector_support;
	}
	// if (setConf.dataleak_control !== undefined) {
	// 	setConf.access_control = setConf.dataleak_control;
	// 	setConf.saving_control = setConf.dataleak_control;
	// }
	const params = {
		...setConf,
		token: secureObj.token
	};
	await httpClient.sendPost('/api/setSysConfigure', params);
});

export const searchSecureDocs = createAsyncThunk('setting/searchSecureDocs', async ({ page }, { dispatch, getState }) => {
	const searchObj = await httpClient.sendPost('/api/secureDocuments', {
		rows_per_page: getState().docbase.admin.setting.secureDocObj.rowsPerPage
	});
	if (searchObj.result) {
		dispatch(getSecureDocs({ page }));
		return { total_rows: searchObj.total_rows, total_pages: searchObj.total_pages };
	}
	return { total_rows: 0, total_pages: 0 };
});
export const getSecureDocs = createAsyncThunk('setting/getSecureDocs', async ({ page }, { dispatch, getState }) => {
	const docPages = await httpClient.sendPost('/api/secureDocumentsPage', { page });
	if (docPages.result) {
		return { docs: docPages.docs, page };
	}
	return { docs: [], page: 1 };
});
export const getAlienConnection = createAsyncThunk('setting/getAlienConnection', async () => {
	const respData = await httpClient.sendPost('/api/getAlienConnection', {});
	if (!respData.result) {
		return { enabled: false, name: '' };
	}

	return { enabled: respData.enabled, name: respData.name };
});

export const getSeedKey = createAsyncThunk('setting/getSeedKey', async () => {
	const respData = await httpClient.sendPost('/api/getSeedKey', {});
	if (!respData.result) {
		return { seedKey: '' };
	}
	return { seedKey: respData.seed_key };
});

/**
 * 개인정보 검출 문서 목록 조회
 */
export const searchPiiDocs = createAsyncThunk(
	'setting/searchPiiDocs',
	async ({ page, startDate, endDate, searchName }, { dispatch, getState }) => {
		const searchObj = await httpClient.sendPost('/api/piiDocuments', {
			rows_per_page: getState().docbase.admin.setting.piiDocObj.rowsPerPage,
			start_date: startDate,
			end_date: endDate,
			search_name: searchName
		});
		if (searchObj.result) {
			dispatch(getPiiDocs({ page }));
			return { total_rows: searchObj.total_rows, total_pages: searchObj.total_pages };
		}
		return { total_rows: 0, total_pages: 0 };
	}
);
/**
 * 개인정보 검출 문서 조회 목록 가져오기
 */
export const getPiiDocs = createAsyncThunk('setting/piiDocs', async ({ page }, { dispatch, getState }) => {
	const secureObj = await secureAsyncRequest();
	const docPages = await httpClient.sendPost('/api/piiDocumentsPage', { page, token: secureObj.token });
	if (docPages.result) {
		return { docs: docPages.docs, page };
	}
	return { docs: [], page: 1 };
});
/**
 * 랜섬웨어 이벤트 목록 조회
 */
export const ransomHistory = createAsyncThunk(
	'setting/ransomHistory',
	async ({ page, status, day, user, path, clientAddress, infectedFilePath }, { dispatch, getState }) => {
		const searchObj = await httpClient.sendPost('/api/ransomHistory', {
			rows_per_page: getState().docbase.admin.setting.ransomObj.rowsPerPage,
			duration: day,
			duration_type: 1,
			status,
			user,
			path,
			client_address: clientAddress,
			infected_file_path: infectedFilePath
		});
		if (searchObj.result) {
			dispatch(getRansomHistory({ page }));
			return { total_rows: searchObj.total_rows, total_pages: searchObj.total_pages };
		}
		return { total_rows: 0, total_pages: 0 };
	}
);
/**
 * 랜섬웨어 이벤트 조회 목록 가져오기
 */
export const getRansomHistory = createAsyncThunk('setting/getRansomHistory', async ({ page }, { dispatch, getState }) => {
	const secureObj = await secureAsyncRequest();
	const docPages = await httpClient.sendPost('/api/ransomHistoryPage', { page, token: secureObj.token });
	if (docPages.result) {
		return { events: docPages.events, page };
	}
	return { event: [], page: 1 };
});
/**
 * 중복문서 목록 조회
 */
export const searchDupDocs = createAsyncThunk('setting/searchDupDocs', async ({ page, path }, { dispatch, getState }) => {
	const searchObj = await httpClient.sendPost('/api/dupDocuments', {
		rows_per_page: getState().docbase.admin.setting.dupDocObj.rowsPerPage,
		path
	});
	if (searchObj.result) {
		dispatch(getDupDocs({ page }));
		if (path === '') {
			await dispatch(getStorageDashboard());
			const dupSizeInfo = await getDupDocSize(searchObj.total_pages);
			return { total_rows: searchObj.total_rows, total_pages: searchObj.total_pages, ...dupSizeInfo };
		}
		return { total_rows: searchObj.total_rows, total_pages: searchObj.total_pages, totalSize: -1, oriSize: -1 };
	}
	return { total_rows: 0, total_pages: 0, totalSize: -1, oriSize: -1 };
});
/**
 * 중복문서 조회 목록 가져오기
 */
export const getDupDocs = createAsyncThunk('setting/dupDocs', async ({ page }, { dispatch, getState }) => {
	const docPages = await httpClient.sendPost('/api/dupDocumentsPage', { page });
	if (docPages.result) {
		return { docs: docPages.docs, page };
	}
	return { docs: [], page: 1 };
});

export const getDupDocSize = async totalPage => {
	let totalSize = 0;
	let oriSize = 0;
	let fileHash = '';
	let resp = {};
	console.log(totalPage);
	for (let i = 1; i <= totalPage; i++) {
		// eslint-disable-next-line no-await-in-loop
		resp = await httpClient.sendPost('/api/dupDocumentsPage', { page: i });
		if (resp.result) {
			console.log(`page length: ${resp.docs.length}`);
			for (let cnt = 0; cnt < resp.docs.length; cnt++) {
				totalSize += resp.docs[cnt].file_size;
				if (fileHash !== resp.docs[cnt].file_hash && resp.docs[cnt].file_size > 0) {
					console.log(fileHash);
					oriSize += resp.docs[cnt].file_size;
					fileHash = resp.docs[cnt].file_hash;
				}
			}
		}
	}
	return { totalSize, oriSize };
};

/**
 * emergency_mode로 복원.
 *
 * @param {number} mode
 * @param {number} back_hours mode 1의 경우에 필요
 * @param {string} date mode 3의 경우에 필요
 */
export const startEmergencyMode = (mode, backHours, date) => {
	return new Promise((resolve, reject) => {
		httpClient
			.sendPost('/api/startEmergencyMode', {
				mode,
				back_hours: backHours,
				date
			})
			.then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
	});
};

/**
 * emergency_mode를 끝낸다.
 * emergency_mode 실행 전으로 파일들이 돌아간다.
 */
export const finishEmergencyMode = () => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/api/finishEmergencyMode', {}).then(data => {
			if (data.result) {
				resolve(data);
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};

/**
 * 실제로 복원을 진행한다.
 * 복원을 하면 되돌릴수 없다.
 *
 * @param {number} mode
 * @param {number} back_hours mode 1의 경우에 필요
 * @param {string} date mode 3의 경우에 필요
 */
export const restoreBackup = (mode, backHours, date) => {
	return new Promise((resolve, reject) => {
		httpClient
			.sendPost('/api/restoreBackup', {
				mode,
				back_hours: backHours,
				date
			})
			.then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
	});
};

/**
 * 공장초기화
 *
 */
export const factoryReset = () => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/factoryReset', { token: tk }).then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};

/**
 * 보안문서 설정 변경.
 *
 * @param {object} docObj
 * @param {string} name
 * @param {object} value
 */
export const updSecureDoc = (docObj, name, value) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/updateSecureDocumentEx', { ...docObj, [name]: value, token: tk }).then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};
/**
 * 보안문서 해제. 일반문서로 변경
 *
 * @param {number} docID
 */
export const releaseSecureDoc = docID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/releaseSecureDocumentEx', { doc_id: docID, token: tk }).then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};

export const setAlienConnection = (enabled, name) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/setAlienConnection', { token: tk, enabled, name }).then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};

/**
 * server 및 client update api
 *
 * @param {file} file
 * @returns
 */
export const updateSystem = file => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPostMultipartForm('/api/updateSystem', { token: tk }, [file], ['update_file'], resp => {
				if (resp.data.result) {
					return resolve(resp.data);
				}
				httpClient.printError(resp.data);
				return reject(resp.data);
			});
		});
	});
};

/**
 * 기기 파워 종료
 * @returns
 */
export const shutdownServer = () => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/api/shutdown', {}).then(data => {
			if (data.result) {
				resolve(data);
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};

/**
 * 사용자의 수집 사용여부 확인
 * @param {string} userID
 * @returns
 */
export const getUserCollectPolicy = userID => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/api/getUserCollectPolicy', { user_id: userID }).then(data => {
			if (data.result) {
				resolve(data);
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};
/**
 * 사용자의 수집여부  설정
 * @param {*} userID
 * @param {*} enabled
 * @param {*} fullScan
 * @returns
 */
export const setUserCollectPolicy = (userID, enabled, fullScan, removeSource) => {
	return new Promise((resolve, reject) => {
		httpClient
			.sendPost('/api/setUserCollectPolicy', { user_id: userID, enabled, full_scan: fullScan, remove_source: removeSource })
			.then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
	});
};
/**
 * 모든 사용자 수집정보
 * @returns
 */
export const getUsersWithCollectInfo = () => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/getUsersWithCollectInfo', { token: tk }).then(data => {
				if (data.result) {
					const collectInfoObj = {};
					data.users.forEach(user => {
						user.user_id = securityUtils.decryptAES(aes, user.user_id, 'hex', 'utf8');
						user.name = securityUtils.decryptAES(aes, user.user_name, 'hex', 'utf8');

						collectInfoObj[user.user_id] = user;
					});

					resolve({ obj: collectInfoObj, list: data.users });
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};
/**
 * 여러사용자 한번에 사용여부 변경
 * @param {*} userID
 * @param {*} enabled
 * @param {*} fullScan
 * @returns
 */
export const setUsersWithCollectInfo = infos => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			const users = infos.map(info => {
				return {
					user_id: securityUtils.encryptAES(aes, info.userID, 'utf8', 'hex'),
					enabled: info.enabled,
					full_scan: info.fullScan,
					remove_source: info.removeSource,
					// eslint-disable-next-line no-prototype-builtins
					full_scan_restart: info.hasOwnProperty('full_scan_restart') ? info.full_scan_restart : false
				};
			});

			httpClient.sendPost('/api/setUsersWithCollectInfo', { users, token: tk }).then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};
/**
 * 모든 사용자 수집정보
 * @returns
 */
export const getAllFoldersPermissions = isOrg => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/foldersPermissions', { token: tk }).then(data => {
				if (data.result) {
					const foldersObj = {};
					data.folders.forEach(folder => {
						if (isOrg) {
							if (commonTypes.FOLDER_TYPE_ORGANIZE === folder.folder_type)
								foldersObj[folder.fid] = folder.permissions;
						} else {
							foldersObj[folder.fid] = folder.permissions;
						}
					});
					resolve(foldersObj);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};

/**
 * 화이트 프로세스 목록 조회
 */
export const getWhiteProcessList = createAsyncThunk('setting/getWhiteProcessList', async () => {
	const respData = await httpClient.sendPost('/api/whiteProcessList', {});
	if (!respData.result) {
		httpClient.printError(respData);
		return [];
	}
	return respData.processes;
	// return [{ id: '1234', name: '프로세스' }];
});
/**
 * 등록되어 있는 화이트 프로세스를 제거한다.
 * @param {string} name
 * @returns
 */
export const unregisterWhiteProcess = name => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/api/unregisterWhiteProcess', { name }).then(data => {
			if (data.result) {
				resolve(data);
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};

/**
 * 화이트 URL 목록 조회
 */
export const getWhiteURLList = createAsyncThunk('setting/getWhiteURLList', async () => {
	const secureObj = await secureAsyncRequest();
	const respData = await httpClient.sendPost('/api/getRegisteredURLs', { token: secureObj.token });
	if (!respData.result) {
		httpClient.printError(respData);
		return [];
	}
	return respData.urls.map(uInfo => {
		uInfo.url = securityUtils.decryptAES(secureObj.aesKey, uInfo.url, 'hex', 'utf8');
		return uInfo;
	});
	// return [{ id: '1234', name: '프로세스' }];
});
/**
 * 화이트 URL를 등록한다.
 * @param {string} name
 * @param {string} type
 * @param {string} url
 * @returns
 */
export const registerWhiteURL = (name, type, url) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/registerWhiteURL', {
					name,
					set_type: type,
					url: securityUtils.encryptAES(aes, url, 'utf8', 'hex'),
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};
/**
 * 등록되어 있는 화이트 URL를 제거한다.
 * @param {string} name
 * @returns
 */
export const unregisterWhiteURL = name => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/unregisterWhiteURL', { name, token: tk }).then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};
/**
 * 화이트 프로세스를 승인한다.
 * @param {string} name
 * @returns
 */
export const approveRegisterWhiteProcess = (id, approve) => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/api/approveRegisterWhiteProcess', { id, approve }).then(data => {
			if (data.result) {
				resolve(data);
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};

const settingSlice = createSlice({
	name: 'setting',
	initialState: {
		showBackdrop: false,
		restoreObj: {
			mode: 0,
			back_hours: 0,
			date: '2021-02-05',
			days: []
		},
		sysConfigure: DEFAULT_CONFIGURE,
		getSysConf: false,
		alienConObj: {
			enabled: false,
			name: ''
		},
		secureDocObj: {
			selectedDocObj: { doc_id: -1, users: [], path: '', level: 10000 },
			rowsPerPage: 100,
			currentPage: 1,
			totalRows: 0,
			total_pages: 0,
			docs: []
		},
		dashboardObj: {
			total: {},
			types: {
				others: { size: 0 },
				organizes: { size: 0 },
				privates: { size: 0 },
				shareds: { size: 0 },
				collects: { size: 0 }
			},
			organizes: [],
			shareds: [],
			privates: [],
			collects: []
		},
		piiDocObj: {
			selectedDocObj: { path: '', pii: [] },
			rowsPerPage: 100,
			currentPage: 1,
			totalRows: 0,
			total_pages: 0,
			docs: []
		},
		dupDocObj: {
			selectedDocObj: { path: '', pii: [] },
			rowsPerPage: 100,
			currentPage: 1,
			totalRows: 0,
			total_pages: 0,
			docs: [],
			totalSize: 0,
			oriSize: 0
		},
		ransomObj: {
			selectedEvent: {},
			rowsPerPage: 100,
			currentPage: 1,
			totalRows: 0,
			total_pages: 0,
			events: []
		},
		whiteProcessList: [],
		whiteURLList: [],
		seedKey: ''
	},
	reducers: {
		onClickDoc: (state, action) => {
			if (state.secureDocObj.selectedDocObj.doc_id === action.payload.docObj.doc_id) {
				state.secureDocObj.selectedDocObj = { doc_id: -1, users: [], path: '', level: 10000 };
			} else {
				state.secureDocObj.selectedDocObj = action.payload.docObj;
			}
		},
		onClickDocEx: (state, action) => {
			//	목록 갱신후 보안문서 레벨 변경을반여영하기위해 적용
			state.secureDocObj.selectedDocObj = action.payload.docObj;
		},
		changeRowsPerPage: (state, action) => {
			state.secureDocObj.rowsPerPage = action.payload.value;
		},
		changePiiRowsPerPage: (state, action) => {
			state.piiDocObj.rowsPerPage = action.payload.value;
		},
		changeDupRowsPerPage: (state, action) => {
			state.dupDocObj.rowsPerPage = action.payload.value;
		},
		setShowBackdrop: (state, action) => {
			state.showBackdrop = action.payload.show;
		}
	},
	extraReducers: {
		[getEmergencyMode.fulfilled]: (state, action) => {
			state.restoreObj.mode = action.payload.mode;
			state.restoreObj.back_hours = action.payload.back_hours ? action.payload.back_hours : '0';
			state.restoreObj.date = action.payload.date ? action.payload.date : '-';
			state.restoreObj.process_type = action.payload.process_type;
			state.restoreObj.status = action.payload.status ? action.payload.status : 0;
		},
		[getRecoveryAvailableDays.fulfilled]: (state, action) => {
			state.restoreObj.days = action.payload.reverse();
		},
		[getSysConfigure.fulfilled]: (state, action) => {
			//	action에서 받은것을 그대로 입력해도 됨.... 테스트 시 설정값을 임의로 바꾸려고 이렇게 시작해서.. 그대로 나둠
			state.sysConfigure.language = action.payload.language;
			state.sysConfigure.weblink_service_support = action.payload.weblink_service_support;
			state.sysConfigure.weblink_service_address = action.payload.weblink_service_address;
			state.sysConfigure.weblink_service_port = action.payload.weblink_service_port;
			state.sysConfigure.strong_password = action.payload.strong_password;
			state.sysConfigure.collector_support = action.payload.collector_support;
			state.sysConfigure.collect_transfer_size = action.payload.collect_transfer_size;
			state.sysConfigure.collect_interval_transfer = action.payload.collect_interval_transfer;
			state.sysConfigure.collect_drive_cdrom = action.payload.collect_drive_cdrom;
			state.sysConfigure.collect_drive_fixed = action.payload.collect_drive_fixed;
			state.sysConfigure.collect_drive_ramdisk = action.payload.collect_drive_ramdisk;
			state.sysConfigure.collect_drive_remote = action.payload.collect_drive_remote;
			state.sysConfigure.collect_drive_removable = action.payload.collect_drive_removable;
			state.sysConfigure.collect_drive_fixed_hotplug = action.payload.collect_drive_fixed_hotplug;
			state.sysConfigure.collect_sync_remove_support = action.payload.collect_sync_remove_support;
			state.sysConfigure.collect_inclusive_pattern_support = action.payload.collect_inclusive_pattern_support;
			state.sysConfigure.collect_exclusive_extension_support = action.payload.collect_exclusive_extension_support;
			state.sysConfigure.collect_config = action.payload.collect_config;
			state.sysConfigure.server_side_encryption = action.payload.server_side_encryption;
			state.sysConfigure.keep_days_backup_data = action.payload.keep_days_backup_data;
			state.sysConfigure.default_volume_label = action.payload.default_volume_label;
			state.sysConfigure.private_folder_size = action.payload.private_folder_size;
			state.sysConfigure.collect_folder_size = action.payload.collect_folder_size;
			state.sysConfigure.organization_folder_size = action.payload.organization_folder_size;
			state.sysConfigure.shared_folder_size = action.payload.shared_folder_size;
			state.sysConfigure.two_factor_authentication = action.payload.two_factor_authentication;
			state.sysConfigure.support_default_smb_port = action.payload.support_default_smb_port;
			state.sysConfigure.restrict_file_size = action.payload.restrict_file_size;
			state.sysConfigure.pii_detect_enable = action.payload.pii_detect_enable;
			state.sysConfigure.pii_ocr = action.payload.pii_ocr;
			state.sysConfigure.dup_detect_enable = action.payload.dup_detect_enable;
			state.sysConfigure.access_control = action.payload.access_control;
			state.sysConfigure.saving_control = action.payload.saving_control;
			state.sysConfigure.dataleak_control = action.payload.dataleak_control;
			state.sysConfigure.connect_server_address = action.payload.connect_server_address;
			state.sysConfigure.force_map_mount_drive = action.payload.force_map_mount_drive;
			state.sysConfigure.map_mount_drive = action.payload.map_mount_drive;
			state.sysConfigure.verysilent_setup = action.payload.verysilent_setup;
			state.sysConfigure.silent_setup = action.payload.silent_setup;
			state.sysConfigure.disable_connect_button_webadmin = action.payload.disable_connect_button_webadmin;
			state.sysConfigure.auto_start = action.payload.auto_start;
			state.sysConfigure.desktop_web_support = action.payload.desktop_web_support;
			state.sysConfigure.mobile_web_support = action.payload.mobile_web_support;
			state.sysConfigure.integrated_with_AD = action.payload.integrated_with_AD;
			state.sysConfigure.OSR_support = action.payload.OSR_support;
			state.sysConfigure.no_shell_menu = action.payload.no_shell_menu;
			state.sysConfigure.no_tray_icon_menu = action.payload.no_tray_icon_menu;
			state.sysConfigure.disable_two_factor_authentication = action.payload.disable_two_factor_authentication;
			state.sysConfigure.allow_admin_access_users_folders = action.payload.allow_admin_access_users_folder;
			state.sysConfigure.force_setup_perform = action.payload.force_setup_perform;
			state.sysConfigure.lock_volume_label = action.payload.lock_volume_label;
			state.sysConfigure.restrict_file_exts_remove_at_batch = action.payload.restrict_file_exts_remove_at_batch;
			state.sysConfigure.support_ransomware_detection = action.payload.support_ransomware_detection;
			state.sysConfigure.support_secure_document = action.payload.support_secure_document;
			state.sysConfigure.min_smb_protocol = action.payload.min_smb_protocol;
			state.sysConfigure.smb_encryption = action.payload.smb_encryption;
			state.sysConfigure.sync_solution_integrated = action.payload.sync_solution_integrated;
			state.sysConfigure.sync_solution = action.payload.sync_solution;
			state.sysConfigure.sync_solution_consumer_folder_accessible = action.payload.sync_solution_consumer_folder_accessible;
			state.sysConfigure.support_oplocks = action.payload.support_oplocks;
			state.sysConfigure.private_folder_support = action.payload.private_folder_support;
			state.sysConfigure.preserve_last_revision = action.payload.preserve_last_revision;
			state.sysConfigure.preserve_last_revision_without_original = action.payload.preserve_last_revision_without_original;
			state.sysConfigure.max_days_preserve_last_revision_without_original =
				action.payload.max_days_preserve_last_revision_without_original;
			state.sysConfigure.disable_save_password = action.payload.disable_save_password;
			state.sysConfigure.menu_support_type = action.payload.menu_support_type;
			state.sysConfigure.async_ocr = action.payload.async_ocr;
			state.sysConfigure.support_macos_dlp = action.payload.support_macos_dlp;

			state.sysConfigure.weblink_restrict_exts = action.payload.weblink_restrict_exts;
			state.sysConfigure.weblink_restrict_file_size = action.payload.weblink_restrict_file_size;
			state.sysConfigure.weblink_restrict_total_size = action.payload.weblink_restrict_total_size;

			let exts = '';
			action.payload.restrict_file_exts.forEach((ext, idx) => {
				exts += idx === 0 ? ext : `,${ext}`;
			});
			state.sysConfigure.restrict_file_exts = exts;
			state.sysConfigure.service_type = action.payload.service_type;

			state.getSysConf = true;
		},
		[searchSecureDocs.fulfilled]: (state, action) => {
			state.secureDocObj.total_pages = action.payload.total_pages;
			state.secureDocObj.totalRows = action.payload.total_rows;
		},
		[getSecureDocs.fulfilled]: (state, action) => {
			state.secureDocObj.docs = action.payload.docs.map(doc => ({
				...doc,
				path: commonUtils.changeAccessibleFolder(doc.path)
			}));
			state.secureDocObj.currentPage = action.payload.page;
		},
		[getAlienConnection.fulfilled]: (state, action) => {
			state.alienConObj.enabled = action.payload.enabled;
			state.alienConObj.name = action.payload.name;
		},
		[getSeedKey.fulfilled]: (state, action) => {
			state.seedKey = action.payload.seedKey;
		},
		[searchPiiDocs.fulfilled]: (state, action) => {
			state.piiDocObj.total_pages = action.payload.total_pages;
			state.piiDocObj.totalRows = action.payload.total_rows;
		},
		[getPiiDocs.fulfilled]: (state, action) => {
			state.piiDocObj.docs = action.payload.docs.map(doc => ({
				...doc,
				registered_at: commonUtils.getDateStringToLocalDate(doc.registered_at)
			}));
			state.piiDocObj.currentPage = action.payload.page;
		},
		[searchDupDocs.fulfilled]: (state, action) => {
			state.dupDocObj.total_pages = action.payload.total_pages;
			state.dupDocObj.totalRows = action.payload.total_rows;
			if (action.payload.totalSize > -1 && action.payload.oriSize > -1) {
				state.dupDocObj.totalSize = action.payload.totalSize;
				state.dupDocObj.oriSize = action.payload.oriSize;
			}
		},
		[getDupDocs.fulfilled]: (state, action) => {
			state.dupDocObj.docs = action.payload.docs;
			state.dupDocObj.currentPage = action.payload.page;
		},
		[getWhiteProcessList.fulfilled]: (state, action) => {
			state.whiteProcessList = action.payload;
		},
		[ransomHistory.fulfilled]: (state, action) => {
			state.ransomObj.total_pages = action.payload.total_pages;
			state.ransomObj.totalRows = action.payload.total_rows;
		},
		[getRansomHistory.fulfilled]: (state, action) => {
			state.ransomObj.events = action.payload.events;
			state.ransomObj.currentPage = action.payload.page;
		},
		[getWhiteURLList.fulfilled]: (state, action) => {
			state.whiteURLList = action.payload;
		}
	}
});

export const { onClickDoc, onClickDocEx, changeRowsPerPage, changePiiRowsPerPage, changeDupRowsPerPage, setShowBackdrop } =
	settingSlice.actions;

export default settingSlice.reducer;
