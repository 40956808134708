import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	TablePagination,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Input,
	Tooltip,
	IconButton,
	Paper,
	Button
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CustomIcon from 'app/main/icon/CustomIcon';
import commonUtils from 'app/utils/CommonUtils';
import { showMessage } from 'app/store/fuse/messageSlice';
import LogsTableHead from './LogsTableHead';
import ConditionsModal from './ConditionsModal';

const useStyles = makeStyles(theme => ({
	root: {
		'& .search-form-wrapper': {
			width: '100%',
			marginLeft: 10,
			'&>.MuiButton-root': {
				width: 'fit-content',
				marginRight: 5
			},
			'& .MuiFormControlLabel-root': {
				marginLeft: 0,
				marginRight: 0
			},
			'& .date-wrapper': {
				display: 'flex',
				flex: '1 1 auto',
				flexFlow: 'row-reverse'
			}
		}
	},
	datePicker: {
		'& .MuiOutlinedInput-notchedOutline': { display: 'none' },
		'& input': {
			border: '1px solid rgba(0, 0, 0, 0.23)',
			borderRadius: 6,
			width: 165,
			textAlign: 'center',
			padding: '9.5px 0'
		},
		'& .MuiOutlinedInput-root.Mui-focused input': {
			boxShadow: '0 0 0 2px #000 inset'
		},
		'& .MuiButtonBase-root': {
			background: '#eceff4',
			borderRadius: 6,
			border: '1px solid #d9dee5',
			padding: 7
		},
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: 0
		}
	},
	contentWrapper: {
		height: `${window.innerHeight - 314}px`,
		padding: '0 20px'
	},
	searchInfo: {
		padding: '0px 20px 5px',
		'& .col': {
			display: 'inline-block',
			marginRight: 10,
			'& span': {
				cursor: 'default'
			},
			'& .value': {
				display: 'inline-block',
				'& span': {
					color: '#777'
				}
			}
		},
		'& .col:last-child': { marginRight: 0 }
	}
}));

function LogsTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
	const { t } = useTranslation('docbase');

	const [openTypeModal, setOpenTypeModal] = React.useState(false);
	//	time, currentPage는 변경시 getCollectLogs를 요청하지 않기위하여 변수 제외,
	const [conditionsObj, setConditionsObj] = React.useState({
		file_types: ['engine', 'collector', 'agent', 'server'],
		log_types: ['trace', 'info', 'warn', 'err', 'critical'],
		includes: [],
		excludes: [],
		rows_per_page: 100
	});
	const [currentPage, setCurrentPage] = React.useState(1);

	const didMount = React.useRef(false);
	useEffect(() => {
		if (didMount.current) {
			props.fnDoSearch(conditionsObj);
		} else didMount.current = true;
	}, [conditionsObj]);

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 314}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {}, [dispatch]);

	if (props.loading) {
		return <FuseLoading />;
	}

	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<ConditionsModal
				open={openTypeModal}
				conditionsObj={conditionsObj}
				fnHandleClose={() => setOpenTypeModal(false)}
				fnHandleOK={condiObj => {
					const incl = condiObj.includes.length === 0 ? [] : condiObj.includes.split(',').map(txt => txt.trim());
					const excl = condiObj.excludes.length === 0 ? [] : condiObj.excludes.split(',').map(txt => txt.trim());
					setConditionsObj({
						...conditionsObj,
						file_types: condiObj.file_types,
						log_types: condiObj.log_types,
						includes: incl,
						excludes: excl
					});
					setOpenTypeModal(false);
				}}
			/>

			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className="table-search-wrapper">
					<div className="">
						<div className="input-item">
							<Input
								name="userID"
								placeholder={t('ID')}
								className="search-input"
								disableUnderline
								fullWidth
								value={props.searchObj.userID}
								inputProps={{
									'aria-label': 'Search'
								}}
								onChange={ev => props.fnSetSearchObj(ev.target.name, ev.target.value)}
							/>
						</div>
					</div>

					<div className="search-form-wrapper">
						<Button
							variant="contained"
							className="default w-fit"
							type="button"
							onClick={() => {
								if (props.searchObj.userID === '') {
									dispatch(showMessage({ message: t('USER_ERROR_MESSAGE_3') }));
									return false;
								}
								props.fnGetUserLogs();
								return true;
							}}
						>
							{t('SEARCH')}
						</Button>
						<Button
							variant="contained"
							className="default w-fit"
							type="button"
							onClick={() => {
								if (!props.totalInfo.isSearch) {
									dispatch(showMessage({ message: t('INSTANT_LOGS_ERROR_MESSAGE_1') }));
									return false;
								}
								setOpenTypeModal(true);
								return true;
							}}
						>
							{t('INSTANT_LOGS_TITLE')}
						</Button>

						<FormGroup row className="row">
							<FormControlLabel
								control={
									<Checkbox
										checked={!props.searchObj.omitServer} // true: 미수집, false: 수집
										onChange={e => {
											props.fnSetSearchObj('omitServer', !props.searchObj.omitServer);
										}}
										name="omitServer"
									/>
								}
								label={t('INSTANT_LOGS_OMIT_SERVER')}
							/>
						</FormGroup>
						<div className="date-wrapper">
							<div className="date-picker-wrapper">
								<span className="title">{t('RESTORE_DAY')}</span>
								<div className="">
									<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
										<KeyboardDateTimePicker
											className={classes.datePicker}
											fullWidth
											disableToolbar
											invalidDateMessage=""
											autoOk
											size="small"
											variant="inline"
											inputVariant="outlined"
											format="yyyy/MM/dd hh:mm a"
											id="start_date"
											maxDate={new Date('2999-12-31')}
											value={props.searchObj.startDate}
											onChange={d => {
												// eslint-disable-next-line no-restricted-globals
												if (!isNaN(d.getTime())) {
													if (props.searchObj.startDate.getTime() !== d.getTime())
														props.fnSetSearchObj('startDate', d);
												}
											}}
											onBlur={e => {
												const val = commonUtils.getStringToDate(e.target.value.replaceAll('/', '-'));
												if (
													// eslint-disable-next-line no-restricted-globals
													!isNaN(val.getTime()) &&
													props.searchObj.startDate.getTime() !== val.getTime()
												)
													props.fnSetSearchObj('startDate', val);
											}}
											KeyboardButtonProps={{
												'aria-label': 'change date start'
											}}
										/>
									</MuiPickersUtilsProvider>
								</div>
								<div className="tilde">~</div>
								<div className="">
									<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
										<KeyboardDateTimePicker
											className={classes.datePicker}
											fullWidth
											disableToolbar
											invalidDateMessage=""
											autoOk
											size="small"
											variant="inline"
											inputVariant="outlined"
											format="yyyy/MM/dd hh:mm a"
											id="end_date"
											maxDate={new Date('2999-12-31')}
											value={props.searchObj.endDate}
											onChange={d => {
												// eslint-disable-next-line no-restricted-globals
												if (!isNaN(d.getTime())) {
													if (props.searchObj.endDate.getTime() !== d.getTime())
														props.fnSetSearchObj('endDate', d);
												}
											}}
											onBlur={e => {
												const val = commonUtils.getStringToDate(e.target.value.replaceAll('/', '-'));
												// eslint-disable-next-line no-restricted-globals
												if (!isNaN(val.getTime()) && props.searchObj.endDate.getTime() !== val.getTime())
													props.fnSetSearchObj('endDate', val);
											}}
											KeyboardButtonProps={{
												'aria-label': 'change date end'
											}}
										/>
									</MuiPickersUtilsProvider>
								</div>
							</div>
							<div className="">
								<Tooltip title={t('REFRESH')} placement="bottom">
									<IconButton
										key="refresh"
										aria-label="refresh"
										color="secondary"
										onClick={() => props.fnDoSearch(conditionsObj)}
									>
										<CustomIcon className="small" type="refresh" fill="#333d4b" />
									</IconButton>
								</Tooltip>
							</div>
						</div>
					</div>
				</div>
				<div className={classes.searchInfo}>
					<div className="col">
						<span>{t('INSTANT_LOGS_FILE_TYPES')}: </span>
						<div className="value">
							{conditionsObj.file_types.map((txt, idx) => {
								if (idx === 0) {
									return <span>{txt}</span>;
								}
								return <span>, {txt}</span>;
							})}
						</div>
					</div>
					<div className="col">
						<span>{t('INSTANT_LOGS_LOG_TYPES')}: </span>
						<div className="value">
							{conditionsObj.log_types.map((txt, idx) => {
								if (idx === 0) {
									return <span>{txt}</span>;
								}
								return <span>, {txt}</span>;
							})}
						</div>
					</div>
					<div className="col">
						<span>{t('INSTANT_LOGS_INCLUDES')}: </span>
						<div className="value">
							{conditionsObj.includes.map((txt, idx) => {
								if (idx === 0) {
									return <span>{txt}</span>;
								}
								return <span>, {txt}</span>;
							})}
						</div>
					</div>
					<div className="col">
						<span>{t('INSTANT_LOGS_EXCLUDES')}: </span>
						<div className="value">
							{conditionsObj.excludes.map((txt, idx) => {
								if (idx === 0) {
									return <span>{txt}</span>;
								}
								return <span>, {txt}</span>;
							})}
						</div>
					</div>
				</div>
				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<LogsTableHead />
						<TableBody>
							{props.data.map((log, idx) => {
								let fileType = '';
								switch (log.file_type) {
									case 1:
										fileType = 'Engine';
										break;
									case 2:
										fileType = 'Collector';
										break;
									case 3:
										fileType = 'Agent';
										break;
									default:
										fileType = 'Server';
								}
								return (
									<TableRow className="cursor-default" role="checkbox" tabIndex={-1} key={log.id}>
										<TableCell className="left" align="left" component="td" scope="row">
											{log.log_type}
										</TableCell>
										<TableCell className="left" align="left" component="td" scope="row">
											{fileType}
										</TableCell>
										<TableCell className="left" align="left" component="td" scope="row">
											{commonUtils.getDateTimeString(log.logged_at)}
										</TableCell>
										<TableCell className="left" align="left" component="td" scope="row">
											{log.message}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>
			</Paper>

			<TablePagination
				className="flex-shrink-0"
				component="div"
				count={props.totalInfo.total_rows}
				rowsPerPage={conditionsObj.rows_per_page}
				rowsPerPageOptions={[100, 250, 500]}
				page={currentPage}
				labelRowsPerPage={t('ROW_PER_PAGE_LABEL')}
				labelDisplayedRows={({ from, to, count }) => `${count !== -1 ? count : to} 중 ${from}-${to}`}
				backIconButtonProps={{
					'aria-label': t('PAGE_PREV_BTN_LABEL')
				}}
				nextIconButtonProps={{
					'aria-label': t('PAGE_NEXT_BTN_LABEL')
				}}
				onChangePage={(event, value) => {
					props.fnGetLogsPage(value);
					setCurrentPage(value);
					contentTable.current.scrollTop = 0;
				}}
				onChangeRowsPerPage={event => {
					setCurrentPage(1);
					setConditionsObj({ ...conditionsObj, rows_per_page: Number(event.target.value) });
					contentTable.current.scrollTop = 0;
				}}
			/>
		</div>
	);
}

export default LogsTable;
