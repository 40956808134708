export const MENU_DASHBOARD = '0';
export const MENU_ACCOUNT = '1';
export const MENU_ORG = '2';
export const MENU_SHARED = '3';
export const MENU_SETTINGS = '4';
export const MENU_WEBLINKINFO = '5';

export const FILE_PERM_NONE = 0;
export const FILE_PERM_READ = 1;
export const FILE_PERM_READ_WRITE = 2;
export const TRASH_PERM_NONE = 0;
export const TRASH_PERM_PURGE = 1;

export const READABLE_OBJ_TYPE_USER = 1;
export const READABLE_OBJ_TYPE_ORG = 2;

export const FOLDER_TYPE_UNKNOWN = 0;
export const FOLDER_TYPE_ORGANIZE = 1;
export const FOLDER_TYPE_SHARED = 2;
export const FOLDER_TYPE_PRIVATE = 3;
export const FOLDER_TYPE_COLLECT = 4;
export const FOLDER_TYPE_TRASH = 5;
export const FOLDER_TYPE_ORGSHARED = 9;

export const WEBLINK_STATUS_INACTIVE = 0;
export const WEBLINK_STATUS_ACTIVE = 1;
export const WEBLINK_STATUS_CREATING = 2;
export const WEBLINK_STATUS_CREATED = 3;

export const COLLECT_TARGET_EXCLUSIVE_PATTERN = 1;
export const COLLECT_TARGET_INCLUDE_EXTENSION = 2;
export const COLLECT_TARGET_INCLUSIVE_PATTERN = 3;
export const COLLECT_TARGET_EXCLUSIVE_EXTENSION = 4;

export const COLLECT_TARGET_CLIENT_ALL = 0;
export const COLLECT_TARGET_CLIENT_WINDOWS = 1;
export const COLLECT_TARGET_CLIENT_MAC = 2;
export const COLLECT_TARGET_CLIENT_LINUX = 3;

export const SERVER_ERROR_INVALID_PARAMETERS = 1;
export const SERVER_ERROR_INVALID_FOLDERTYPE = 2;
export const SERVER_ERROR_TIMEOUT = 3;
export const SERVER_ERROR_SERVICETOKEN = 4;
export const SERVER_ERROR_NO_PERMISSION = 5;
export const SERVER_ERROR_NOT_EXISTS = 6;
export const SERVER_ERROR_EXISTS = 7;
export const SERVER_ERROR_IS_FILE = 8;
export const SERVER_ERROR_WRONG_ID_PASSWORD = 9;
export const SERVER_ERROR_INVALID_PRODUCTKEY = 10;
export const SERVER_ERROR_TOO_SHOUT = 11;
export const SERVER_ERROR_TOO_LONG = 12;
export const SERVER_ERROR_TOO_MANY = 13;
export const SERVER_ERROR_LOGIN_FIRST = 14;
export const SERVER_ERROR_ADMINISTRATOR_ONLY = 15;
export const SERVER_ERROR_NOT_SET_ADMINISTRATOR = 16;
export const SERVER_ERROR_SYSTEM = 17;
export const SERVER_ERROR_INVALID_UPDATE_IMAGE = 18;
export const SERVER_ERROR_NOT_ALLOWED_REMOTE_ADDRESS = 19;
export const SERVER_ERROR_INVALID_REQUEST = 20;
export const SERVER_ERROR_GENERAL = 21;
export const SERVER_ERROR_NOT_ENOUGH_USER_LICENSES = 22;
export const SERVER_ERROR_NOT_COMPATIBLE_CLIENT_SERVER = 23;
export const SERVER_ERROR_PASSWORD_NOT_STRONG_ENOUGH = 24;
export const SERVER_ERROR_PASSWORD_SAME = 25;
export const SERVER_ERROR_EMERGENCY_MODE = 26;
export const SERVER_ERROR_TRASH_FOLDER = 27;
export const SERVER_ERROR_SPECIAL_FOLDER = 28;

export const ACCESS_TYPE_CREATE = 1;
export const ACCESS_TYPE_READ = 2;
export const ACCESS_TYPE_MOVE = 3;
export const ACCESS_TYPE_UPDATE = 4;
export const ACCESS_TYPE_HOLD = 5;
export const ACCESS_TYPE_REMOVE = 6;
export const ACCESS_TYPE_REMOVE_TRASH = 7;
export const ACCESS_TYPE_RECOVERY_TRASH = 8;
export const ACCESS_TYPE_PURGE_TRASH = 9;
export const ACCESS_TYPE_VIEWING = 10;
export const ACCESS_TYPE_WEB_CREATE = 11;
export const ACCESS_TYPE_WEB_DOWNLOAD = 12;
export const ACCESS_TYPE_COLLECT_FILE = 13;

export const MANAGE_TYPE_NONE = 0;
export const MANAGE_TYPE_APPROVE_TAKEOUT = 1;

export const NATIVE_EVENT_SHOW_SERVER_OBJ = 1;
export const NATIVE_EVENT_SET_LOGIN_OBJ = 2;
export const NATIVE_EVENT_SHARE_WEBLINK = 3;
export const NATIVE_EVENT_IOS_DOWNLOAD_FILE = 4;
export const NATIVE_EVENT_BIOMETRICS_INIT = 5;
export const NATIVE_EVENT_BIOMETRICS_AUTH = 6;
export const NATIVE_EVENT_BIOMETRICS_RELEASE = 7;

export const NATIVE_EVENT_ON_BIOMETRICS_LOGIN = 1;
export const NATIVE_EVENT_ON_BIOMETRICS_INIT = 2;
export const NATIVE_EVENT_SUCCESS_SHARED = 3;

export const TAKEOUT_REQUEST_TYPE_DOC = 1;
export const TAKEOUT_REQUEST_TYPE_PRINT = 2;
export const TAKEOUT_REQUEST_TYPE_WEBLINK = 3;

export const TAKEOUT_REQUEST_STATUS_REQ = 1;
export const TAKEOUT_REQUEST_STATUS_APPROVED = 100;
export const TAKEOUT_REQUEST_STATUS_APPROVED_AUTO = 110;
export const TAKEOUT_REQUEST_STATUS_REJECTED = 200;
export const TAKEOUT_REQUEST_STATUS_WITHDRAWN = 300;

export const TAKEOUT_APPROVE_TYPE_MANUAL = 1;
export const TAKEOUT_APPROVE_TYPE_AUTO = 2;
export const TAKEOUT_APPROVE_TYPE_UNAVAILABLE = 3;

export const CLIENT_TYPE_WIN_86 = 1;
export const CLIENT_TYPE_WIN_64 = 2;
export const CLIENT_TYPE_MAC = 3;
export const CLIENT_TYPE_LINUX = 4;
export const CLIENT_TYPE_WEB = 5;
export const CLIENT_TYPE_MAC_AMD = 6;
export const CLIENT_TYPE_MAC_ARM = 7;
export const CLIENT_TYPE_MAC_DLP = 8;
export const CLIENT_TYPE_WIN_DLP = 9;
