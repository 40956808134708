import { makeStyles } from '@material-ui/core/styles';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	tr: {
		'& .delete': {
			width: 100
		}
	}
}));

function WhiteURLTableHead(props) {
	const classes = useStyles();
	const { t } = useTranslation('docbase');

	const headerCols = [
		{
			id: 'white_url_name',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('WHITE_URL_NAME'),
			sort: false
		},
		{
			id: 'set_type',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('WHITE_URL_SET_TYPE'),
			sort: false
		},
		{
			id: 'white_url_url',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('WHITE_URL_URL'),
			sort: false
		},
		{
			id: 'process_delete',
			align: 'center',
			className: 'delete',
			disablePadding: false,
			label: t('DELETE'),
			sort: false
		}
	];

	return (
		<TableHead>
			<TableRow className={classes.tr}>
				{headerCols.map(row => {
					return (
						<TableCell
							className={row.className}
							key={row.id}
							align={row.align}
							padding={row.disablePadding ? 'none' : 'default'}
							sortDirection={false}
						>
							{row.label}
						</TableCell>
					);
				}, this)}
			</TableRow>
		</TableHead>
	);
}

export default WhiteURLTableHead;
