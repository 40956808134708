import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { statisticValues } from 'app/store/docbase/admin/systemSlice';
import CommonUtils from 'app/utils/CommonUtils';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ContentHeader from '../component/ContentHeader';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const useStyles = makeStyles(theme => ({
	graphWrapper: {
		flex: '1 1 auto',
		marginTop: 10,
		'&.mr': {
			marginRight: 10
		},
		'&.short': {
			minWidth: 520
		},
		'&.middle': {
			minWidth: 780
		},
		'&>.content': {
			padding: '15px 15px'
		}
	}
}));

let timerAccessLog;
let timerAverage;
function Logs(props) {
	const dispatch = useDispatch();
	const { t } = useTranslation('docbase');

	const { averageInfo } = useSelector(({ docbase }) => docbase.admin.system);

	const classes = useStyles(props);
	const labels = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
	const contentTable = React.useRef();
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const initAvgApicall = {
		series: [{ name: 'web-api-call', data: [] }]
	};
	const initAvgConnected = {
		series: [{ name: 'connected', data: [] }]
	};

	const initAvgIO = {
		series: [
			{ name: 'read', data: [] },
			{ name: 'write', data: [] }
		]
	};

	const initAvgFolder = {
		series: [
			{ name: 'create', data: [] },
			{ name: 'remove', data: [] }
		]
	};

	const initAvgFile1 = {
		series: [
			{ name: 'create', data: [] },
			{ name: 'remove', data: [] }
		]
	};

	const initAvgFile2 = {
		series: [
			{ name: 'rename', data: [] },
			{ name: 'update', data: [] },
			{ name: 'copy', data: [] }
		]
	};

	const [averageIOData, setAverageIOData] = React.useState(initAvgIO);
	const [averageConnectedData, setAverageConnectedData] = React.useState(initAvgConnected);
	const [averageApiCall, setAverageApiCall] = React.useState(initAvgApicall);
	const [averageFolder, setAverageFolder] = React.useState(initAvgFolder);
	const [averageFile1, setAverageFile1] = React.useState(initAvgFile1);
	const [averageFile2, setAverageFile2] = React.useState(initAvgFile2);
	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 141}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		dispatch(statisticValues()).then(() => {
			if (!timerAverage) {
				timerAverage = setInterval(() => {
					dispatch(statisticValues());
				}, 3000);
			}
		});

		return () => {
			if (timerAverage) {
				clearInterval(timerAverage);
				timerAverage = undefined;
			}
			if (timerAccessLog) {
				clearInterval(timerAccessLog);
				timerAccessLog = undefined;
			}
		};
	}, [dispatch]);

	useEffect(() => {
		const newReadData = [];
		const newWriteData = [];
		const newApiCall = [];
		const newConnectedData = [];
		const newCreateFolder = [];
		const newRemoveFolder = [];
		const newCreateFile = [];
		const newRemoveFile = [];
		const newRenameFile = [];
		const newUpdateFile = [];
		const newCopyFile = [];

		for (let x = 1; x < 20; x++) {
			newReadData.push(averageIOData.series[0].data[x] ? averageIOData.series[0].data[x] : 0);
			newWriteData.push(averageIOData.series[1].data[x] ? averageIOData.series[1].data[x] : 0);
			newApiCall.push(averageApiCall.series[0].data[x] ? averageApiCall.series[0].data[x] : 0);
			newConnectedData.push(averageConnectedData.series[0].data[x] ? averageConnectedData.series[0].data[x] : 0);
			newCreateFolder.push(averageFolder.series[0].data[x] ? averageFolder.series[0].data[x] : 0);
			newRemoveFolder.push(averageFolder.series[1].data[x] ? averageFolder.series[1].data[x] : 0);
			newCreateFile.push(averageFile1.series[0].data[x] ? averageFile1.series[0].data[x] : 0);
			newRemoveFile.push(averageFile1.series[1].data[x] ? averageFile1.series[1].data[x] : 0);
			newRenameFile.push(averageFile2.series[0].data[x] ? averageFile2.series[0].data[x] : 0);
			newUpdateFile.push(averageFile2.series[1].data[x] ? averageFile2.series[1].data[x] : 0);
			newCopyFile.push(averageFile2.series[2].data[x] ? averageFile2.series[2].data[x] : 0);
		}
		newReadData.push(averageInfo.readBytes);
		newWriteData.push(averageInfo.writeBytes);
		newApiCall.push(averageInfo.average_webapi_calls);
		newConnectedData.push(averageInfo.connected_clients);
		newCreateFolder.push(averageInfo.average_created_directories);
		newRemoveFolder.push(averageInfo.average_removed_directories);
		newCreateFile.push(averageInfo.average_created_files);
		newRemoveFile.push(averageInfo.average_removed_files);
		newRenameFile.push(averageInfo.average_renamed_files);
		newUpdateFile.push(averageInfo.average_updated_files);
		newCopyFile.push(averageInfo.average_copied_files);

		setAverageIOData({
			...initAvgIO,
			series: [
				{ name: t('AVERAGE_READ'), data: newReadData },
				{ name: t('AVERAGE_WRITE'), data: newWriteData }
			]
		});

		setAverageApiCall({
			...initAvgApicall,
			series: [{ name: t('AVERAGE_CALL_API_TITLE'), data: newApiCall }]
		});

		setAverageConnectedData({
			...initAvgConnected,
			series: [{ name: t('AVERAGE_CONNECTED'), data: newConnectedData }]
		});

		setAverageFolder({
			...initAvgFolder,
			series: [
				{ name: t('AVERAGE_FOLDER_CREATE'), data: newCreateFolder },
				{ name: t('AVERAGE_FOLDER_REMOVE'), data: newRemoveFolder }
			]
		});

		setAverageFile1({
			...initAvgFile1,
			series: [
				{ name: t('AVERAGE_FILE_CREATE'), data: newCreateFile },
				{ name: t('AVERAGE_FILE_REMOVE'), data: newRemoveFile }
			]
		});
		setAverageFile2({
			...initAvgFile2,
			series: [
				{ name: t('AVERAGE_FILE_RENAME'), data: newRenameFile },
				{ name: t('AVERAGE_FILE_UPDATE'), data: newUpdateFile },
				{ name: t('AVERAGE_FILE_COPY'), data: newCopyFile }
			]
		});
	}, [averageInfo]);

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			title: {
				display: false
			},
			datalabels: {
				display: false
			},
			legend: {
				position: 'bottom',
				labels: {
					font: 10
				}
			},
			tooltip: {
				enabled: true,
				backgroundColor: 'rgba(0,0,0,0.8)',
				titleFont: {
					size: 12
				},
				bodyFont: {
					size: 12
				},
				callbacks: {
					label: context => {
						return `${context.dataset.label}: ${context.raw || 0}`;
					}
				}
			}
		},
		interaction: {
			mode: 'index',
			intersect: false
		},
		stacked: false,
		scales: {
			y: {
				display: true,
				position: 'left',
				min: 0, // y축의 최소값 설정
				ticks: {
					display: false // y축 값 숨기기
				}
			}
		},
		elements: {
			line: {
				tension: 0.4, // 추가된 tension
				borderWidth: 2, // 추가된 borderWidth
				cubicInterpolationMode: 'monotone' // 추가된 cubicInterpolationMode
			},
			point: {
				radius: 0.8, // 추가된 pointRadius
				hitRadius: 1.5, // 추가된 pointHitRadius
				borderWidth: 0.3 // 추가된 pointBorderWidth
			}
		}
	};

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			sidebarInner
			header={<ContentHeader />}
			content={
				<FuseScrollbars className={clsx(classes.contentWrapper, 'w-full flex-grow overflow-x-auto')} ref={contentTable}>
					<div className="w-full">
						<Paper className={classes.graphWrapper}>
							<div className="flex w-full content" style={{ width: '100%', height: '350px' }}>
								<Line
									options={{
										...options,
										scales: { y: { ...options.scales.y, display: false } },
										plugins: {
											...options.plugins,
											tooltip: {
												...options.plugins.tooltip,
												callbacks: {
													label: context => {
														return `${context.dataset.label}: ${CommonUtils.changeByteToString(
															context.raw || 0
														)}`;
													}
												}
											}
										}
									}}
									data={{
										labels,
										datasets: [
											{
												label: averageIOData.series[0].name,
												data: averageIOData.series[0].data,
												borderColor: 'rgb(1, 143, 251)',
												backgroundColor: 'rgba(1, 143, 251, 0.5)'
											},
											{
												label: averageIOData.series[1].name,
												data: averageIOData.series[1].data,
												borderColor: 'rgb(66, 227, 150)',
												backgroundColor: 'rgba(66, 227, 150, 0.5)'
											}
										]
									}}
								/>
							</div>
						</Paper>
						<div className="flex flex-wrap">
							<Paper className={clsx(classes.graphWrapper, 'mr', 'short')}>
								<div className="flex w-full content">
									<Line
										options={options}
										data={{
											labels,
											datasets: [
												{
													label: averageFolder.series[0].name,
													data: averageFolder.series[0].data,
													borderColor: 'rgb(1, 143, 251)',
													backgroundColor: 'rgba(1, 143, 251, 0.5)'
												},
												{
													label: averageFolder.series[1].name,
													data: averageFolder.series[1].data,
													borderColor: 'rgb(66, 227, 150)',
													backgroundColor: 'rgba(66, 227, 150, 0.5)'
												}
											]
										}}
									/>
								</div>
							</Paper>
							<Paper className={clsx(classes.graphWrapper, 'mr', 'short')}>
								<div className="flex w-full content">
									<Line
										options={options}
										data={{
											labels,
											datasets: [
												{
													label: averageFile1.series[0].name,
													data: averageFile1.series[0].data,
													borderColor: 'rgb(1, 143, 251)',
													backgroundColor: 'rgba(1, 143, 251, 0.5)'
												},
												{
													label: averageFile1.series[1].name,
													data: averageFile1.series[1].data,
													borderColor: 'rgb(66, 227, 150)',
													backgroundColor: 'rgba(66, 227, 150, 0.5)'
												}
											]
										}}
									/>
								</div>
							</Paper>
							<Paper className={clsx(classes.graphWrapper, 'short')}>
								<div className="flex w-full content">
									<Line
										options={options}
										data={{
											labels,
											datasets: [
												{
													label: averageFile2.series[0].name,
													data: averageFile2.series[0].data,
													borderColor: 'rgb(1, 143, 251)',
													backgroundColor: 'rgba(1, 143, 251, 0.5)'
												},
												{
													label: averageFile2.series[1].name,
													data: averageFile2.series[1].data,
													borderColor: 'rgb(66, 227, 150)',
													backgroundColor: 'rgba(66, 227, 150, 0.5)'
												}
											]
										}}
									/>
								</div>
							</Paper>
						</div>
						<div className="flex flex-wrap">
							<Paper className={clsx(classes.graphWrapper, 'mr', 'middle')}>
								<div className="flex w-full content">
									<Line
										options={options}
										data={{
											labels,
											datasets: [
												{
													fill: true,
													label: averageConnectedData.series[0].name,
													data: averageConnectedData.series[0].data,
													borderColor: 'rgb(1, 143, 251)',
													backgroundColor: 'rgba(1, 143, 251, 0.5)'
												}
											]
										}}
									/>
								</div>
							</Paper>
							<Paper className={clsx(classes.graphWrapper, 'middle')}>
								<div className="flex w-full content">
									<Line
										options={{
											...options,
											plugins: {
												...options.plugins,
												tooltip: {
													...options.plugins.tooltip,
													callbacks: {
														label: context => {
															return context.raw || 0;
														}
													}
												}
											}
										}}
										data={{
											labels,
											datasets: [
												{
													fill: true,
													label: averageApiCall.series[0].name,
													data: averageApiCall.series[0].data,
													borderColor: 'rgb(1, 143, 251)',
													backgroundColor: 'rgba(1, 143, 251, 0.5)'
												}
											]
										}}
									/>
								</div>
							</Paper>
						</div>
					</div>
				</FuseScrollbars>
			}
		/>
	);
}

export default React.memo(Logs);
