import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import React, { useRef } from 'react';
import FusePageSimpleHeader from './FusePageSimpleHeader';
import FusePageSimpleSidebar from './FusePageSimpleSidebar';

// const headerHeight = 120;
const headerHeight = '6.4rem';
const toolbarHeight = 64;
const drawerWidth = window.innerWidth < 774 ? '100%' : 774;

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100%',
		position: 'relative',
		flex: '1 0 auto',
		height: 'auto',
		backgroundColor: theme.palette.background.default
	},
	innerScroll: {
		flex: '1 1 auto',
		height: '100%'
	},
	wrapper: {
		display: 'flex',
		flexDirection: 'row',
		flex: '1 1 auto',
		zIndex: 2,
		maxWidth: '100%',
		minWidth: 0,
		height: '100%',
		backgroundColor: theme.palette.background.default,
		overflowX: 'auto',
		padding: '0 30px',
		'&.userpage': {
			padding: 0
		}
	},
	header: {
		display: 'flex',
		background: '#ffffff',
		color: '#222222',
		margin: '0px 0px 30px',
		borderRadius: '6px',
		'&.userpage-header-mobile': {
			margin: 0
		},
		'&.userpage-header': {
			margin: '40px 0px 30px'
		},
		'&>div': {
			height: 70
		}
	},
	topBg: {
		left: 0,
		right: 0,
		top: 0,
		height: headerHeight,
		pointerEvents: 'none'
	},
	/* contentCardWrapper            : {
         display : 'flex ',
         flex    : '1 1 auto',
         overflow: 'visible!important',
         minWidth: 0,
         '&.ps'  : {
             overflow: 'visible!important'
         }
     },
     contentCardWrapperInnerSidebar: {
         display                     : 'block',
         overflow                    : 'auto!important',
         '-webkit-overflow-scrolling': 'touch',
         '&.ps'                      : {
             overflow: 'hidden!important'
         },
         '& $contentCard'            : {
             borderRadius: 8
         }
     }, */
	contentWrapper: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1 1 auto',
		overflow: 'auto',
		'-webkit-overflow-scrolling': 'touch',
		zIndex: 9999,
		minWidth: drawerWidth
	},
	toolbar: {
		height: toolbarHeight,
		minHeight: toolbarHeight,
		display: 'flex',
		alignItems: 'center'
	},
	content: {
		flex: '1 0 auto',
		padding: '0 2px'
	},
	sidebarWrapper: {
		overflow: 'hidden',
		backgroundColor: 'transparent',
		position: 'absolute',
		'&.permanent': {
			[theme.breakpoints.up('lg')]: {
				position: 'relative'
			}
		}
	},
	sidebar: {
		position: 'absolute',
		'&.permanent': {
			[theme.breakpoints.up('lg')]: {
				backgroundColor: '#f7f7f7',
				color: '#fff',
				position: 'relative'
			}
		},
		width: drawerWidth,
		height: '100%',
		'&.user-leftmenu.permanent': {
			width: 300,
			background: '#272c36'
		}
	},
	leftSidebar: {
		[theme.breakpoints.up('lg')]: {
			borderRight: `1px solid ${theme.palette.divider}`,
			borderLeft: 0
		}
	},
	rightSidebar: {
		[theme.breakpoints.up('lg')]: {
			// borderLeft: `1px solid ${theme.palette.divider}`,
			borderRight: 0
		}
	},
	sidebarHeader: {
		height: 90,
		minHeight: 90,
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText
	},
	sidebarHeaderInnerSidebar: {
		backgroundColor: 'transparent',
		color: 'inherit',
		height: 'auto',
		minHeight: 'auto'
	},
	sidebarContent: { padding: '0 2px' },
	backdrop: {
		position: 'absolute'
	}
}));

const FusePageSimple = React.forwardRef((props, ref) => {
	// console.info("render::FusePageSimple");
	const leftSidebarRef = useRef(null);
	const rightSidebarRef = useRef(null);
	const rootRef = useRef(null);
	const classes = useStyles(props);

	React.useImperativeHandle(ref, () => ({
		rootRef,
		toggleLeftSidebar: () => {
			leftSidebarRef.current.toggleSidebar();
		},
		toggleRightSidebar: () => {
			rightSidebarRef.current.toggleSidebar();
		}
	}));

	return (
		<div className={clsx(classes.root, props.innerScroll && classes.innerScroll)} ref={rootRef}>
			{/* <div className={clsx(classes.header, classes.topBg)} /> */}

			<div className="flex flex-auto flex-col container z-10 h-full">
				{props.header && props.sidebarInner && <FusePageSimpleHeader header={props.header} classes={classes} />}

				<div className={clsx(classes.wrapper, props.className)}>
					{(props.leftSidebarHeader || props.leftSidebarContent) && (
						<FusePageSimpleSidebar
							position="left"
							header={props.leftSidebarHeader}
							content={props.leftSidebarContent}
							variant={props.leftSidebarVariant || 'permanent'}
							innerScroll={props.innerScroll}
							sidebarInner={props.sidebarInner}
							classes={classes}
							ref={leftSidebarRef}
							rootRef={rootRef}
						/>
					)}

					{/* <FuseScrollbars */}
					{/*    className={clsx(classes.contentCardWrapper, props.sidebarInner && classes.contentCardWrapperInnerSidebar)} */}
					{/*    enable={props.innerScroll && props.sidebarInner} */}
					{/* > */}
					<FuseScrollbars className={classes.contentWrapper} enable={props.innerScroll && !props.sidebarInner}>
						{props.header && !props.sidebarInner && <FusePageSimpleHeader header={props.header} classes={classes} />}

						{props.contentToolbar && <div className={classes.toolbar}>{props.contentToolbar}</div>}

						{props.content && <div className={classes.content}>{props.content}</div>}
					</FuseScrollbars>
					{/* </FuseScrollbars> */}

					{(props.rightSidebarHeader || props.rightSidebarContent) && (
						<FusePageSimpleSidebar
							position="right"
							header={props.rightSidebarHeader}
							content={props.rightSidebarContent}
							variant={props.rightSidebarVariant || 'permanent'}
							innerScroll={props.innerScroll}
							sidebarInner={props.sidebarInner}
							classes={classes}
							ref={rightSidebarRef}
							rootRef={rootRef}
						/>
					)}
				</div>
			</div>
		</div>
	);
});

FusePageSimple.propTypes = {
	leftSidebarHeader: PropTypes.node,
	leftSidebarContent: PropTypes.node,
	leftSidebarVariant: PropTypes.node,
	rightSidebarHeader: PropTypes.node,
	rightSidebarContent: PropTypes.node,
	rightSidebarVariant: PropTypes.node,
	header: PropTypes.node,
	content: PropTypes.node,
	contentToolbar: PropTypes.node,
	sidebarInner: PropTypes.bool,
	innerScroll: PropTypes.bool
};

FusePageSimple.defaultProps = {};

export default React.memo(FusePageSimple);
