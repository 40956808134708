import { makeStyles } from '@material-ui/core/styles';
import {
	Icon,
	TextField,
	Card,
	CardActions,
	CardContent,
	IconButton,
	Button,
	Tooltip,
	MenuItem,
	InputAdornment,
	Input,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	Select,
	Typography
} from '@material-ui/core';
import React from 'react';
import * as commonTypes from 'app/main/constants/CommonTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
	setFolderPermission,
	releaseFolderPermission,
	selectedOrgFolderID,
	renameOrgFolder,
	getOrgFolderList,
	setFolderMaxSize,
	updateOrganizationFolder
} from 'app/store/docbase/admin/folderSlice';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import { setShowBackdrop } from 'app/store/docbase/admin/settingSlice';
import { naturalizeFiles } from 'app/store/docbase/admin/systemSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import commonUtils from 'app/utils/CommonUtils';
import PermDialog from '../component/PermDialog';
import OpenDialogForm from '../component/OpenDialogForm';
import TransferDialog from './TransferDialog';
import TransferDataDialog from './TransferDataDialog';
import PermIcon from '../icon/PermIcon';

const useStyles = makeStyles(theme => ({
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	cardInfo: {
		padding: '40px 30px 20px',
		'& .MuiCardActions-root': {
			padding: 0
		},
		'& .MuiCardContent-root': {
			padding: 0
		}
	},
	cardDetailInfo: {
		'margin-left': '10px',
		height: '48px',
		'line-height': '48px'
	},
	paperPerm: { marginTop: 30, padding: '20px 30px 0' },
	paperPermHeader: {
		padding: 0,
		display: 'flex',
		width: '100%',
		'&>div:first-child': {
			width: '100%',
			maxWidth: 534,
			marginRight: 10
		},
		'&>div:first-child .MuiAutocomplete-inputRoot': {
			height: 40
		},
		'&>div:last-child>button': {
			width: 140
		}
	},
	paperPermContent: { padding: 0, marginTop: 20 },
	infoFiled: {
		'&>div': {
			display: 'flex',
			height: 45,
			paddingRight: 30,
			borderTop: '1px solid rgb(141 149 160 / 18%)'
		},
		'&>div:last-child': { borderBottom: '1px solid rgb(141 149 160 / 18%)' }
	},
	filedTitle: {
		background: '#f5f7fa',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		cursor: 'default',
		width: '100%',
		maxWidth: 277,
		minWidth: 277
	},
	filedInput: {
		width: '100%',
		padding: '0 0 0 30px',
		display: 'flex',
		alignItems: 'center',
		'&.folder-name': { paddingRight: 10 }
	},
	customInput: {
		width: '100%',
		border: '1px solid rgba(0, 0, 0, 0.23)',
		borderRadius: 4,
		'&>input': {
			padding: '10.5px 14px'
		},
		'&.Mui-focused': {
			border: '2px solid rgba(0, 0, 0, 1)'
		},
		'&:hover': {
			border: '1px solid rgba(0, 0, 0, 1)'
		}
	},
	manage_type: {
		display: 'inline-flex',
		flex: '0 0 auto'
	},
	manage_priority: {
		width: '24px',
		height: '24px',
		'& .MuiSelect-root': {
			'padding-right': '0',
			'padding-bottom': '4px'
		},
		'& svg': {
			display: 'none'
		}
	},
	select: {
		width: '100%'
	},
	hint_span: {
		'padding-left': '10px',
		'font-size': '1rem',
		color: '#9f9f9f'
	},
	inputRight: {
		'& input': {
			'text-align': 'right'
		}
	},
	buttonGroup: {
		marginTop: 30,
		'& button': { marginRight: 10 }
	},
	h_29: { height: '29px' }
}));

function DetailSidebarContent(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const permList = React.useRef();
	const { t } = useTranslation('docbase');
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const { sysConfigure } = useSelector(({ docbase }) => docbase.admin.setting);
	const { orgPerms, orgsObj, selectedOrgID, capacity, parentPerms } = useSelector(({ docbase }) => docbase.admin.folder);

	const [openTransferDialog, setOpenTransferDialog] = React.useState(false);
	const [showTransferDialog, setShowTransferDialog] = React.useState(false);
	const [showPermDialog, setShowPermDialog] = React.useState(false);
	const [permsObj, setPermsObj] = React.useState({});
	const [orgName, setOrgName] = React.useState('');
	const [maxSize, setMaxSize] = React.useState(0);
	const [capacityEx, setCapacityEx] = React.useState(capacity);
	const [folderSizeUnit, setFolderSizeUnit] = React.useState(true);
	const [allowTakeout, setAllowTakeout] = React.useState(true);

	React.useEffect(() => {
		if (innerHeight >= 754) {
			permList.current.style.height = `${innerHeight - 140}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	React.useEffect(() => {
		commonUtils.infoLog('detail selectedID start');
		if (selectedOrgID && selectedOrgID > 0) {
			setOrgName(orgsObj[selectedOrgID].name);

			if (capacity && capacity >= 1024) {
				setMaxSize(Math.floor(capacity / 1024));
				setFolderSizeUnit(false);
			} else {
				setMaxSize(capacity || 0);
				setFolderSizeUnit(true);
			}
			setCapacityEx(capacity);
			setAllowTakeout(orgsObj[selectedOrgID].allow_takeout);
		} else {
			setOrgName('');
			setMaxSize(0);
			setFolderSizeUnit(true);
			setAllowTakeout(true);
		}

		commonUtils.infoLog('detail selectedID end');
	}, [selectedOrgID]);

	React.useEffect(() => {
		commonUtils.infoLog('detail orgs Obj useeffect start');
	}, [orgsObj]);

	React.useEffect(() => {
		//	선택한 조직의 권한과 상위 조직의 권한을 추가한다.
		const obj = {};
		if (orgsObj[selectedOrgID]) {
			const parentFids = commonUtils.splitPreDelimiter(orgsObj[selectedOrgID].fid_path).split('/');
			parentFids.forEach(fid => {
				if (fid !== '' && parentPerms[fid]) {
					parentPerms[fid].forEach(pp => {
						obj[pp.user_id] = true;
					});
				}
			});
		}

		orgPerms.forEach(perm => {
			obj[perm.user_id] = true;
		});

		//	상위 조직과 선택한 조직의 권한을 보여깅기위한 목록에 추가
		setPermsObj(obj);
	}, [orgPerms]);

	React.useEffect(() => {}, [dispatch]);

	function changePerm(fid, userID, filePerm, trashPerm, manageType, managePriority) {
		dispatch(setShowBackdrop({ show: true }));
		setFolderPermission(fid, userID, filePerm, trashPerm, manageType, managePriority === -1 ? 0 : managePriority)
			.then(data => {
				dispatch(showMessage({ message: t('PERM_MESSAGE_1') }));
				dispatch(selectedOrgFolderID({ fid: selectedOrgID }));
			})
			.catch(err => {
				if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
					if (err.message === 'the manage priority is not unique') {
						dispatch(showErrorMessage({ message: t('PERM_ERROR_MESSAGE_4') }));
					} else {
						dispatch(showErrorMessage({ message: t('PERM_ERROR_MESSAGE_1') }));
					}
				}
			})
			.finally(() => {
				dispatch(setShowBackdrop({ show: false }));
			});
	}

	function releasePerm(fid, userID) {
		dispatch(setShowBackdrop({ show: true }));
		releaseFolderPermission(fid, userID)
			.then(data => {
				dispatch(showMessage({ message: t('PERM_MESSAGE_2') }));
				dispatch(selectedOrgFolderID({ fid: selectedOrgID }));
			})
			.catch(err => {
				if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
					dispatch(showErrorMessage({ message: t('PERM_ERROR_MESSAGE_2') }));
				}
			})
			.finally(() => {
				dispatch(setShowBackdrop({ show: false }));
			});
	}

	/**
	 * 상위 조직에 권한이 있는 사용자 표시.
	 */
	function drawParentPerm() {
		if (orgsObj[selectedOrgID]) {
			const parentFids = commonUtils.splitPreDelimiter(orgsObj[selectedOrgID].fid_path).split('/');

			const permObjs = {};
			parentFids.forEach(fid => {
				if (fid !== '' && parentPerms[fid]) {
					parentPerms[fid].forEach(p => {
						permObjs[p.user_id] = p;
					});
				}
			});

			//	상위 조직에 권한이 있지만 현재 조직에도 권한이 있는 경우 상위 조직 권한에서 제거.
			orgPerms.forEach(p => {
				delete permObjs[p.user_id];
			});

			return (
				<>
					{Object.keys(permObjs).map((key, idx) => {
						return (
							<TableRow
								className="cursor-pointer"
								hover
								role="checkbox"
								tabIndex={-1}
								// key={d.user_id}
								// onClick={event => handleCheck(event, d.user_id)}
							>
								<TableCell className="left" align="left" component="td" scope="row" colSpan={2}>
									<div>
										<PermIcon type="person" className="small" />
										<span className="folder-name">{permObjs[key].user_name}</span>
									</div>
								</TableCell>

								<TableCell className="" align="center" component="td" scope="row">
									<Tooltip
										arrow
										title={
											permObjs[key].file_permission === commonTypes.FILE_PERM_READ
												? t('PERM_READ')
												: t('PERM_READ_WRITE')
										}
									>
										<IconButton
											onClick={e =>
												changePerm(
													selectedOrgID,
													permObjs[key].user_id,
													permObjs[key].file_permission === commonTypes.FILE_PERM_READ
														? commonTypes.FILE_PERM_READ_WRITE
														: commonTypes.FILE_PERM_READ,
													permObjs[key].trash_permission,
													permObjs[key].manage_type,
													permObjs[key].manage_priority
												)
											}
										>
											{permObjs[key].file_permission === commonTypes.FILE_PERM_READ ? (
												<PermIcon type="readonly" className="" />
											) : (
												<PermIcon type="write" className="" />
											)}
										</IconButton>
									</Tooltip>
								</TableCell>

								<TableCell className="" component="td" scope="row" align="center">
									<Tooltip
										arrow
										title={
											permObjs[key].trash_permission === commonTypes.TRASH_PERM_NONE
												? t('PERM_TRASH_IMPOSSIBLE')
												: t('PERM_TRASH_POSSIBLE')
										}
									>
										<IconButton
											onClick={e =>
												changePerm(
													selectedOrgID,
													permObjs[key].user_id,
													permObjs[key].file_permission,
													permObjs[key].trash_permission === commonTypes.TRASH_PERM_NONE
														? commonTypes.TRASH_PERM_PURGE
														: commonTypes.TRASH_PERM_NONE,
													permObjs[key].manage_type,
													permObjs[key].manage_priority
												)
											}
										>
											{permObjs[key].trash_permission === commonTypes.TRASH_PERM_NONE ? (
												<PermIcon type="trash-delete" className="" />
											) : (
												<PermIcon type="trash" className="" />
											)}
										</IconButton>
									</Tooltip>
								</TableCell>
								<TableCell className="" component="td" scope="row" align="center">
									<Tooltip
										arrow
										title={
											permObjs[key].file_permission === commonTypes.FILE_PERM_NONE
												? t('PERM_NONE')
												: t('PERM_NOT_NONE')
										}
									>
										<IconButton
											onClick={e =>
												changePerm(
													selectedOrgID,
													permObjs[key].user_id,
													permObjs[key].file_permission === commonTypes.FILE_PERM_NONE
														? commonTypes.FILE_PERM_READ_WRITE
														: commonTypes.FILE_PERM_NONE,
													permObjs[key].trash_permission,
													permObjs[key].manage_type,
													permObjs[key].manage_priority
												)
											}
										>
											{permObjs[key].file_permission === commonTypes.FILE_PERM_NONE ? (
												<PermIcon type="visibility_off" className="" />
											) : (
												<PermIcon type="visibility" className="" />
											)}
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						);
					})}
				</>
			);
		}
		return <></>;
	}

	return (
		<div className="" ref={permList}>
			<PermDialog
				open={showPermDialog}
				type="0"
				permsObj={permsObj}
				fid={selectedOrgID}
				fnHandleClose={() => setShowPermDialog(false)}
				fnSetPermComplete={() => {
					dispatch(showMessage({ message: t('PERM_MESSAGE_1') }));
					dispatch(selectedOrgFolderID({ fid: selectedOrgID }));
					setShowPermDialog(false);
				}}
			/>
			<TransferDataDialog
				open={openTransferDialog}
				fnHandleClose={() => {
					setOpenTransferDialog(false);
				}}
			/>
			<TransferDialog
				open={showTransferDialog}
				targetPath={selectedOrgID <= 0 ? '' : orgsObj[selectedOrgID].path}
				fnResetList={() => {
					dispatch(getOrgFolderList({ parentFid: selectedOrgID }));
				}}
				fnHandleClose={() => setShowTransferDialog(false)}
			/>

			<Card className={classes.cardInfo}>
				<CardActions disableSpacing>
					<IconButton
						className="md:hidden"
						aria-controls="detail-back"
						onClick={e => {
							props.fnToggleRightSidebar();
						}}
					>
						<Icon>arrow_back</Icon>
					</IconButton>
					<Typography className={clsx(classes.cardDetailInfo, 'noto-bold')} variant="h6">
						<span>{t('DETAIL_INFO')}</span>
						<span>{selectedOrgID <= 0 ? '' : `[${orgsObj[selectedOrgID].name}]`}</span>
					</Typography>
				</CardActions>
				<CardContent className={classes.infoFiled}>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('FOLDER_NAME')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							<TextField
								className=""
								variant="outlined"
								type="text"
								name="name"
								fullWidth
								value={orgName}
								onChange={e => setOrgName(e.target.value)}
							/>
						</div>
						<div className={clsx('align-middle items-center flex')}>
							<Button
								variant="contained"
								color="secondary"
								className=""
								type="button"
								fullWidth
								onClick={() => {
									if (selectedOrgID <= 0) {
										dispatch(showErrorMessage({ message: t('ORG_ERROR_MESSAGE_2') }));
									} else if (orgsObj[selectedOrgID].name !== orgName) {
										dispatch(
											openDialog({
												children: (
													<OpenDialogForm
														title={t('NAME_CHANGE')}
														content={t('ORG_CHANGE_MESSAGE')}
														fnHandleOK={() => {
															dispatch(setShowBackdrop({ show: true }));
															renameOrgFolder(selectedOrgID, orgName)
																.then(data => {
																	dispatch(getOrgFolderList({ parentFid: selectedOrgID }));
																})
																.finally(() => {
																	dispatch(setShowBackdrop({ show: false }));
																});
														}}
													/>
												)
											})
										);
									} else {
										dispatch(showMessage({ message: t('EXTERNAL_ERROR_MESSAGE_6') }));
									}
								}}
							>
								{t('CHANGE')}
							</Button>
						</div>
					</div>
					{sysConfigure.dataleak_control ? (
						<>
							<div className="">
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('ALLOW_TAKEOUT_FOLDER')}</p>
								</div>
								<div className={clsx(classes.filedInput, '')}>
									<Select
										id="allow_takeout"
										variant="outlined"
										className={clsx(classes.select, '')}
										value={allowTakeout}
										onChange={e => {
											updateOrganizationFolder(selectedOrgID, e.target.value)
												.then(() => {
													setAllowTakeout(e.target.value);
													dispatch(getOrgFolderList({ parentFid: selectedOrgID }));
													dispatch(showMessage({ message: t('SAVED') }));
												})
												.catch(err => {
													dispatch(
														showErrorMessage({
															message: t('SETTING_UPDATE_ERROR_MESSAGE_3')
														})
													);
												});
										}}
									>
										<MenuItem value="true">{t('PERMIT')}</MenuItem>
										<MenuItem value="false">{t('UNAVAILABLE')}</MenuItem>
									</Select>
								</div>
							</div>
						</>
					) : (
						<></>
					)}
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('MAX_CAPACITY')}</p>
							<span className={classes.hint_span}>{t('FOLDER_SIZE_HELP_TEXT')}</span>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<Input
								type="number"
								name="capacity"
								disableUnderline
								className={classes.customInput}
								endAdornment={
									<InputAdornment position="end" className="">
										<Select
											id="folder_capacity"
											disableUnderline
											variant="standard"
											className={clsx(classes.selectTextAlignRight, '')}
											value={folderSizeUnit}
											onChange={e => {
												let tmpOrgSize = 0;
												if (!folderSizeUnit) {
													tmpOrgSize = maxSize < 1024 ? maxSize : Math.floor(maxSize / 1024);
												} else {
													tmpOrgSize = maxSize * 1024;
												}

												setFolderMaxSize(orgsObj[selectedOrgID].disp_path, tmpOrgSize)
													.then(() => {
														setFolderSizeUnit(!folderSizeUnit);
														dispatch(showMessage({ message: t('CHANGE_CAPACITY_MESSAGE_3') }));
													})
													.catch(err => {
														dispatch(
															showErrorMessage({
																message: t('CHANGE_CAPACITY_ERROR_MESSAGE_1')
															})
														);
													});
											}}
										>
											<MenuItem value="false">Gbyte</MenuItem>
											<MenuItem value="true">Mbyte</MenuItem>
										</Select>
									</InputAdornment>
								}
								value={maxSize}
								onChange={e => setMaxSize(e.target.value)}
								onBlur={e => {
									e.preventDefault();
									const size = folderSizeUnit ? maxSize : maxSize * 1024;
									if (size < 0) {
										dispatch(showErrorMessage({ message: t('NUMBER_ERROR_MESSAGE') }));
										if (capacityEx && capacityEx >= 1024) {
											setMaxSize(Math.floor(capacityEx / 1024));
											setFolderSizeUnit(false);
										} else {
											setMaxSize(capacityEx || 0);
											setFolderSizeUnit(true);
										}

										return false;
									}
									if (capacityEx !== size) {
										setFolderMaxSize(orgsObj[selectedOrgID].disp_path, size)
											.then(() => {
												setCapacityEx(size);
												dispatch(showMessage({ message: t('CHANGE_CAPACITY_MESSAGE_3') }));
											})
											.catch(err => {
												dispatch(showErrorMessage({ message: t('CHANGE_CAPACITY_ERROR_MESSAGE_1') }));
											});
									}
									return true;
								}}
							/>
						</div>
					</div>
				</CardContent>
				<CardActions className={clsx(classes.buttonGroup, 'block')}>
					<div className="w-full flex">
						<div className={clsx('inline-block align-middle')}>
							<Button
								variant="contained"
								className="default"
								type="button"
								fullWidth
								size="small"
								onClick={() => {
									if (selectedOrgID <= 0) {
										dispatch(showErrorMessage({ message: t('ORG_ERROR_MESSAGE_2') }));
									} else {
										setShowTransferDialog(true);
									}
								}}
							>
								{t('MOVE')}
							</Button>
							<Button
								variant="contained"
								className="default"
								type="button"
								fullWidth
								size="small"
								onClick={e => setOpenTransferDialog(true)}
							>
								{t('MOVE_ORG_DATA_TITLE')}
							</Button>
						</div>
						<div className={clsx(classes.h_29, 'inline-block align-middle ml-5 w-100')} style={{ display: 'none' }}>
							<Button
								variant="contained"
								className="default"
								type="button"
								fullWidth
								size="small"
								onClick={() => {
									if (selectedOrgID <= 0) {
										dispatch(showErrorMessage({ message: t('ORG_ERROR_MESSAGE_2') }));
									} else {
										naturalizeFiles(orgsObj[selectedOrgID].real_path).then(() => {
											dispatch(
												showMessage({
													message: t('SEARCH_INDEXING_MESSAGE_1')
												})
											);
										});
									}
								}}
							>
								{t('SEARCH_INDEXING')}
							</Button>
						</div>
					</div>
				</CardActions>
			</Card>

			<Card className={classes.paperPerm}>
				<CardContent className={classes.paperPermHeader}>
					<div>
						<Button
							variant="contained"
							color="secondary"
							className=""
							type="button"
							fullWidth
							// onClick={setPerm}
							onClick={() => {
								if (selectedOrgID <= 0) {
									dispatch(showErrorMessage({ message: t('PERM_ERROR_MESSAGE_3') }));
								} else {
									setShowPermDialog(true);
								}
							}}
						>
							{t('PERM_FOLDER_TITLE')}
						</Button>
					</div>
				</CardContent>

				<CardContent className={classes.paperPermContent}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<TableHead>
							<TableRow>
								<TableCell padding="none" align="left" className="left title">
									{t('USER')}
								</TableCell>
								<TableCell padding="none" align="center" className="perm-col-2">
									{t('MANAGE_PRIORITY')}
								</TableCell>
								<TableCell padding="none" align="center" className="perm-col-1">
									{t('PERM_READ_WRITE')}
								</TableCell>
								<TableCell padding="none" align="center" className="perm-col-2">
									{t('PERM_TRASH')}
								</TableCell>
								<TableCell padding="none" align="center" className="perm-col-1">
									{t('PERM_DELETE')}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{orgPerms && orgPerms.length > 0 ? (
								orgPerms.map((perm, idx) => {
									if (perm.file_permission === commonTypes.FILE_PERM_NONE) {
										return (
											<TableRow
												className="cursor-pointer"
												hover
												role="checkbox"
												tabIndex={-1}
												// key={d.user_id}
												// onClick={event => handleCheck(event, d.user_id)}
											>
												<TableCell className="left" align="left" component="td" scope="row">
													<div>
														<PermIcon type="person" className="small" />
														<span className="folder-name">{perm.user_name}</span>
													</div>
												</TableCell>

												<TableCell className="" align="center" component="td" scope="row" />
												<TableCell className="" component="td" scope="row" align="center" />
												<TableCell className="" component="td" scope="row" align="center">
													<Tooltip
														arrow
														title={
															perm.file_permission === commonTypes.FILE_PERM_NONE
																? t('PERM_NONE')
																: t('PERM_NOT_NONE')
														}
													>
														<IconButton onClick={e => releasePerm(selectedOrgID, perm.user_id)}>
															{perm.file_permission === commonTypes.FILE_PERM_NONE ? (
																<PermIcon type="visibility_off" className="" />
															) : (
																<PermIcon type="visibility" className="" />
															)}
														</IconButton>
													</Tooltip>
												</TableCell>
											</TableRow>
										);
									}
									return (
										<TableRow
											className="cursor-pointer"
											hover
											role="checkbox"
											tabIndex={-1}
											// key={d.user_id}
											// onClick={event => handleCheck(event, d.user_id)}
										>
											<TableCell className="left" align="left" component="td" scope="row">
												<div>
													<PermIcon type="person" className="" />
													<span className="folder-name">{perm.user_name}</span>
												</div>
											</TableCell>
											<TableCell className="cell-approve" align="center" component="td" scope="row">
												{perm.manage_type !== commonTypes.MANAGE_TYPE_APPROVE_TAKEOUT ? (
													<IconButton
														onClick={e => {
															let priority = 1;
															orgPerms.forEach(p => {
																if (p.manage_priority >= priority) {
																	priority = p.manage_priority + 1;
																}
															});
															changePerm(
																selectedOrgID,
																perm.user_id,
																perm.file_permission,
																perm.trash_permission,
																perm.manage_type !== commonTypes.MANAGE_TYPE_APPROVE_TAKEOUT
																	? commonTypes.MANAGE_TYPE_APPROVE_TAKEOUT
																	: commonTypes.MANAGE_TYPE_NONE,
																priority
															);
														}}
													>
														<span>{t('MANAGE_TYPE_NONE')}</span>
													</IconButton>
												) : (
													<div className={classes.manage_type}>
														<IconButton>
															<Tooltip arrow title={t('MANAGE_PRIORITY')}>
																<TextField
																	select
																	className={classes.manage_priority}
																	name="manage_type"
																	value={perm.manage_priority}
																	onChange={e =>
																		changePerm(
																			selectedOrgID,
																			perm.user_id,
																			perm.file_permission,
																			perm.trash_permission,
																			e.target.value === -1
																				? commonTypes.MANAGE_TYPE_NONE
																				: perm.manage_type,
																			e.target.value
																		)
																	}
																	fullWidth
																>
																	<MenuItem value={-1}>
																		<Icon>perm_identity</Icon>
																	</MenuItem>
																	<MenuItem className="justify-center" value={0}>
																		0
																	</MenuItem>
																	<MenuItem className="justify-center" value={1}>
																		1
																	</MenuItem>
																	<MenuItem className="justify-center" value={2}>
																		2
																	</MenuItem>
																	<MenuItem className="justify-center" value={3}>
																		3
																	</MenuItem>
																	<MenuItem className="justify-center" value={4}>
																		4
																	</MenuItem>
																	<MenuItem className="justify-center" value={5}>
																		5
																	</MenuItem>
																	<MenuItem className="justify-center" value={6}>
																		6
																	</MenuItem>
																	<MenuItem className="justify-center" value={7}>
																		7
																	</MenuItem>
																	<MenuItem className="justify-center" value={8}>
																		8
																	</MenuItem>
																	<MenuItem className="justify-center" value={9}>
																		9
																	</MenuItem>
																</TextField>
															</Tooltip>
														</IconButton>
													</div>
												)}
											</TableCell>
											<TableCell className="" align="center" component="td" scope="row">
												<Tooltip
													arrow
													title={
														perm.file_permission === commonTypes.FILE_PERM_READ
															? t('PERM_READ')
															: t('PERM_READ_WRITE')
													}
												>
													<IconButton
														onClick={e =>
															changePerm(
																selectedOrgID,
																perm.user_id,
																perm.file_permission === commonTypes.FILE_PERM_READ
																	? commonTypes.FILE_PERM_READ_WRITE
																	: commonTypes.FILE_PERM_READ,
																perm.trash_permission,
																perm.manage_type,
																perm.manage_priority
															)
														}
													>
														{perm.file_permission === commonTypes.FILE_PERM_READ ? (
															<PermIcon type="readonly" className="" />
														) : (
															<PermIcon type="write" className="" />
														)}
													</IconButton>
												</Tooltip>
											</TableCell>

											<TableCell className="" component="td" scope="row" align="center">
												<Tooltip
													arrow
													title={
														perm.trash_permission === commonTypes.TRASH_PERM_NONE
															? t('PERM_TRASH_IMPOSSIBLE')
															: t('PERM_TRASH_POSSIBLE')
													}
												>
													<IconButton
														onClick={e =>
															changePerm(
																selectedOrgID,
																perm.user_id,
																perm.file_permission,
																perm.trash_permission === commonTypes.TRASH_PERM_NONE
																	? commonTypes.TRASH_PERM_PURGE
																	: commonTypes.TRASH_PERM_NONE,
																perm.manage_type,
																perm.manage_priority
															)
														}
													>
														{perm.trash_permission === commonTypes.TRASH_PERM_NONE ? (
															<PermIcon type="trash-delete" className="" />
														) : (
															<PermIcon type="trash" className="" />
														)}
													</IconButton>
												</Tooltip>
											</TableCell>
											<TableCell className="" component="td" scope="row" align="center">
												<Tooltip title={t('PERM_DELETE')} arrow>
													<IconButton
														aria-label="releasePerm"
														onClick={e => releasePerm(selectedOrgID, perm.user_id)}
													>
														<PermIcon type="delete" className="" />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									);
								})
							) : (
								<></>
							)}
							{drawParentPerm()}
						</TableBody>
					</Table>
				</CardContent>
			</Card>
		</div>
	);
}

export default DetailSidebarContent;
