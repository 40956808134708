import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
	FormGroup,
	FormControlLabel,
	Checkbox,
	Table,
	TableBody,
	TableCell,
	TableRow,
	IconButton,
	Icon,
	Tooltip,
	Paper,
	Input,
	Button,
	TextField,
	TablePagination,
	MenuItem
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { inquiryClientAccessLogs, inquiryClientAccessLogsPage } from 'app/store/docbase/admin/dlpSlice';
import commonUtils from 'app/utils/CommonUtils';
import CustomIcon from 'app/main/icon/CustomIcon';
import excelUtils from 'app/utils/ExcelUtils';
import ClientMonitoringLogTableHead from './ClientMonitoringLogTableHead';
import ViewDialog from './ViewDialog';
import NonLoggingDialog from './NonLoggingDialog';
import LogTypeCheckBox from './LogTypeCheckBox';

const useStyles = makeStyles(theme => ({
	root: {
		marginRight: 30,
		borderRadius: 6,
		'& .search-form-wrapper': {
			display: 'block!important',
			flex: '1 1 auto',
			'& .content': { display: 'flex', marginBottom: 10 },
			'& .input-item': { width: 180 },
			'& .date-picker-wrapper': { marginLeft: '10px!important', marginRight: '0!important' }
		},
		'& .button-group': {
			display: 'flex',
			'& button:first-child': {
				marginRight: 5,
				width: 80
			}
		}
	},
	datePicker: {
		width: ' 190px!important'
	},
	deleteIcon: {
		color: '#ff6565'
	},
	contentWrapper: {
		height: `${window.innerHeight - 393}px`,
		padding: '0 20px'
	},
	icon: {
		width: 20,
		height: 20
	},
	btn01: {
		marginRight: 10
	},
	tr: {
		'& .path': {
			whiteSpace: 'pre-wrap!important',
			overflowWrap: 'break-word',
			textOverflow: 'ellipsis',
			width: '400px',
			maxWidth: '400px'
		}
	},
	text12: {
		'& .MuiTypography-root': {
			fontSize: '12px!important'
		}
	}
}));

let logTypes = [
	{ type: 1, cons: 'DLPClientLogGeneral', icon: 'application-outline' },
	{ type: 2, cons: 'DLPClientLogCreateFile', icon: 'file-document-plus-outline' },
	{ type: 3, cons: 'DLPClientLogWriteFile', icon: 'file-edit-outline' },
	{ type: 4, cons: 'DLPClientLogRenameFile', icon: 'rename-outline' },
	{ type: 5, cons: 'DLPClientLogCopyFile', icon: 'content-copy' },
	{ type: 6, cons: 'DLPClientLogCloneFile', icon: 'content-duplicate' },
	{ type: 7, cons: 'DLPClientLogLinkFile', icon: 'link-box-outline' },
	{ type: 8, cons: 'DLPClientLogDeleteFile', icon: 'file-document-remove-outline' },
	{ type: 9, cons: 'DLPClientLogTruncateFile', icon: 'tray-remove' },
	{ type: 10, cons: 'DLPClientLogExecute', icon: 'cog-play-outline' },
	{ type: 11, cons: 'DLPClientLogNetwork', icon: 'network-outline' },
	{ type: 12, cons: 'DLPClientLogFetchURL', icon: 'web' },
	{ type: 13, cons: 'DLPClientLogUploadURL', icon: 'file-upload-outline' },
	{ type: 14, cons: 'DLPClientLogMount', icon: 'content-save-move-outline' },
	{ type: 15, cons: 'DLPClientLogUnmount', icon: 'content-save-off-outline' },
	{ type: 16, cons: 'DLPClientLogSendWebMail', icon: 'email-outline' }
];
const logTypeObj = {
	1: { title: 'DLPClientLogGeneral', icon: 'application-outline' },
	2: { title: 'DLPClientLogCreateFile', icon: 'file-document-plus-outline' },
	3: { title: 'DLPClientLogWriteFile', icon: 'file-edit-outline' },
	4: { title: 'DLPClientLogRenameFile', icon: 'rename-outline' },
	5: { title: 'DLPClientLogCopyFile', icon: 'content-copy' },
	6: { title: 'DLPClientLogCloneFile', icon: 'content-duplicate' },
	7: { title: 'DLPClientLogLinkFile', icon: 'link-box-outline' },
	8: { title: 'DLPClientLogDeleteFile', icon: 'file-document-remove-outline' },
	9: { title: 'DLPClientLogTruncateFile', icon: 'tray-remove' },
	10: { title: 'DLPClientLogExecute', icon: 'cog-play-outline' },
	11: { title: 'DLPClientLogNetwork', icon: 'network-outline' },
	12: { title: 'DLPClientLogFetchURL', icon: 'web' },
	13: { title: 'DLPClientLogUploadURL', icon: 'file-upload-outline' },
	14: { title: 'DLPClientLogMount', icon: 'content-save-move-outline' },
	15: { title: 'DLPClientLogUnmount', icon: 'content-save-off-outline' },
	16: { title: 'DLPClientLogSendWebMail', icon: 'email-outline' }
};

let pageType = 0;
function ClientMonitoringLogTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const { t } = useTranslation('docbase');

	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const [loading, setLoading] = useState(true);

	const [dialogObj, setDialogObj] = React.useState({ open: false, logObj: {}, nonLogging: false });
	const [searchObj, setSearchObj] = React.useState({
		userID: '',
		clientID: '',
		userName: '',
		logGroup: 2,
		isMonitoring: false,
		isBlocking: true,
		orderAscending: false,
		startDate: commonUtils.getLastWeek(),
		endDate: new Date()
	});
	const [pageInfo, setPageInfo] = React.useState({ total_rows: 0, total_pages: 0, current_page: 0, rows_per_page: 100 });
	const [data, setData] = useState([]);
	const [types, setTypes] = React.useState(
		window.location.pathname === '/clientStorageLog'
			? { 2: true, 3: true, 4: true, 5: true, 6: true, 7: true, 8: true, 9: true }
			: { 1: true, 10: true, 11: true, 12: true, 13: true, 14: true, 15: true, 16: true }
	);
	const [allCheck, setAllCheck] = React.useState(true);

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 393}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		switch (window.location.pathname) {
			case '/clientStorageLog':
				pageType = 1;
				logTypes = [
					{ type: 2, cons: 'DLPClientLogCreateFile', icon: 'file-document-plus-outline' },
					{ type: 3, cons: 'DLPClientLogWriteFile', icon: 'file-edit-outline' },
					{ type: 4, cons: 'DLPClientLogRenameFile', icon: 'rename-outline' },
					{ type: 5, cons: 'DLPClientLogCopyFile', icon: 'content-copy' },
					{ type: 6, cons: 'DLPClientLogCloneFile', icon: 'content-duplicate' },
					{ type: 7, cons: 'DLPClientLogLinkFile', icon: 'link-box-outline' },
					{ type: 8, cons: 'DLPClientLogDeleteFile', icon: 'file-document-remove-outline' },
					{ type: 9, cons: 'DLPClientLogTruncateFile', icon: 'tray-remove' }
				];
				break;
			default:
				pageType = 0;
				logTypes = [
					{ type: 1, cons: 'DLPClientLogGeneral', icon: 'application-outline' },
					{ type: 10, cons: 'DLPClientLogExecute', icon: 'cog-play-outline' },
					{ type: 11, cons: 'DLPClientLogNetwork', icon: 'network-outline' },
					{ type: 12, cons: 'DLPClientLogFetchURL', icon: 'web' },
					{ type: 13, cons: 'DLPClientLogUploadURL', icon: 'file-upload-outline' },
					{ type: 14, cons: 'DLPClientLogMount', icon: 'content-save-move-outline' },
					{ type: 15, cons: 'DLPClientLogUnmount', icon: 'content-save-off-outline' },
					{ type: 16, cons: 'DLPClientLogSendWebMail', icon: 'email-outline' }
				];
		}

		setLoading(true);
		doSearch(pageInfo.rows_per_page);
		return () => {
			setSearchObj({
				userID: '',
				clientID: '',
				logGroup: 2,
				isMonitoring: false,
				isBlocking: true,
				startDate: new Date(),
				endDate: new Date()
			});
		};
	}, [dispatch]);
	if (loading) {
		return <FuseLoading />;
	}

	async function doSearch(rowsPerPage) {
		setLoading(true);
		let strType = '';
		let strGroup = '';
		Object.keys(types).forEach((key, idx) => {
			if (types[key]) {
				if (strType === '') strType += key;
				else strType += `, ${key}`;
			}
		});

		if (searchObj.isMonitoring) strGroup = '1, 3';
		else strGroup = '1';

		if (searchObj.isBlocking) strGroup += ', 2';

		const respData = await inquiryClientAccessLogs(
			searchObj.userID,
			searchObj.clientID,
			searchObj.userName,
			strGroup,
			commonUtils.getDateString(searchObj.startDate),
			commonUtils.getDateString(searchObj.endDate),
			searchObj.orderAscending,
			strType,
			rowsPerPage
		);

		if (respData.rows > 0) {
			await getPage(1);
		} else {
			setData([]);
		}
		setPageInfo({
			...pageInfo,
			total_rows: respData.rows,
			total_pages: respData.pages,
			rows_per_page: rowsPerPage,
			current_page: 1
		});
		setLoading(false);
	}

	async function getPage(page) {
		const logs = await inquiryClientAccessLogsPage(page);
		setData(logs);
		setPageInfo({ ...pageInfo, current_page: page });
	}

	async function downloadExcel() {
		// props.fnDoSearch();
		const titles = [
			t('DLP_CLIENT_LOG_LOGGED_AT'),
			t('DLP_CLIENT_LOG_USERNAME'),
			t('DLP_CLIENT_LOG_TYPE'),
			t('DLP_CLIENT_LOG_PATH'),
			t('DLP_CLIENT_LOG_SIGNING_ID'),
			t('DLP_CLIENT_LOG_HASH'),
			t('DLP_CLIENT_LOG_SOURCE_PATH'),
			t('DLP_CLIENT_LOG_DEST_PATH'),
			t('DLP_CLIENT_LOG_LOCAL_ENDPOINT'),
			t('DLP_CLIENT_LOG_REMOTE_ENDPOINT'),
			t('URL'),
			t('DLP_CLIENT_LOG_WEB_UPLOAD_FILE'),
			t('DLP_CLIENT_LOG_MAIL_CONTENTS')
		];
		const contents = [];
		const colSizes = [
			{ wpx: 80 },
			{ wpx: 80 },
			{ wpx: 80 },
			{ wpx: 300 },
			{ wpx: 150 },
			{ wpx: 200 },
			{ wpx: 250 },
			{ wpx: 250 },
			{ wpx: 150 },
			{ wpx: 150 },
			{ wpx: 150 },
			{ wpx: 150 },
			{ wpx: 350 }
		];

		for (let i = 1; i <= pageInfo.total_pages; i++) {
			// eslint-disable-next-line no-await-in-loop
			const l = await inquiryClientAccessLogsPage(i);
			l.forEach(obj => {
				let fileNames = '';
				if (obj.files && obj.files.length > 0) {
					obj.files.forEach((file, idx) => {
						if (idx > 0) fileNames += `, ${file}`;
						else fileNames += file;
					});
				}
				contents.push({
					logged_at: commonUtils.getDateStringToLocalDate(obj.logged_at),
					name: `${obj.user_name}(${obj.user_id}-${obj.client_id})`,
					log_type: t(logTypeObj[obj.log_type].title),
					path: obj.path,
					signing_id: obj.signing_id,
					hash: obj.hash,
					source_path: obj.source_path,
					dest_path: obj.dest_path,
					local_endpoint: obj.local_endpoint,
					remote_endpoint: obj.remote_endpoint,
					url: obj.url,
					files: fileNames,
					log: obj.log
				});
			});
		}

		let title = '차단/유출 로그';
		if (pageType === 1) {
			title = '매체 로그';
		}
		excelUtils.excelDownload(
			`${title}${commonUtils.getDateString(searchObj.startDate)}-${commonUtils.getDateString(searchObj.endDate)}`,
			titles,
			contents,
			colSizes
		);
	}

	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<ViewDialog
				open={dialogObj.open}
				logObj={dialogObj.logObj}
				fnHandleClose={() => setDialogObj({ ...dialogObj, open: false, logObj: {} })}
			/>
			<NonLoggingDialog
				open={dialogObj.nonLogging}
				fnHandleClose={() => setDialogObj({ ...dialogObj, nonLogging: false })}
			/>

			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className="table-search-wrapper">
					<div className="total-cnt-info" style={{ flex: '0 0 auto' }}>
						<span>
							{t('TOTAL')} <span className="count">{pageInfo.total_rows}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="search-form-wrapper">
						<div className="content">
							<div
								className={clsx(classes.text12, 'input-item')}
								style={{
									display: 'block',
									paddingTop: '5px'
								}}
							>
								<FormControlLabel
									control={
										<Checkbox
											checked={searchObj.isMonitoring}
											onChange={e => {
												setSearchObj({ ...searchObj, isMonitoring: !searchObj.isMonitoring });
											}}
											name="isMonitoring"
										/>
									}
									label={t('DLP_CLIENT_MONITORING_LOG')}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={searchObj.isBlocking}
											onChange={e => {
												setSearchObj({ ...searchObj, isBlocking: !searchObj.isBlocking });
											}}
											name="isBlocking"
										/>
									}
									label={t('DLP_CLIENT_BLOCKED_LOG')}
								/>
							</div>

							<div className="input-item">
								<span className="title">{t('DELETE_USER_ID')}</span>
								<Input
									className="search-input"
									disableUnderline
									fullWidth
									value={searchObj.userID}
									inputProps={{
										'aria-label': 'Search'
									}}
									size="small"
									onChange={ev => setSearchObj({ ...searchObj, userID: ev.target.value })}
								/>
							</div>
							<div className="input-item">
								<span className="title">{t('DELETE_USER_NAME')}</span>
								<Input
									className="search-input"
									disableUnderline
									fullWidth
									value={searchObj.userName}
									inputProps={{
										'aria-label': 'Search'
									}}
									size="small"
									onChange={ev => setSearchObj({ ...searchObj, userName: ev.target.value })}
								/>
							</div>
							<div className="input-item">
								<span className="title">{t('SORT_DATE')}</span>
								<TextField
									variant="outlined"
									select
									className=""
									label=""
									value={searchObj.orderAscending}
									fullWidth
									onChange={e => {
										setSearchObj({
											...searchObj,
											orderAscending: e.target.value
										});
									}}
								>
									<MenuItem value>{t('ASCENDING')}</MenuItem>
									<MenuItem value={false}>{t('DESCENDING')}</MenuItem>
								</TextField>
							</div>
							<div className="input-item">
								<span className="title">{t('RESTORE_DAY')}</span>
								<div className="date-picker-wrapper">
									<div className="">
										<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
											<KeyboardDatePicker
												className={classes.datePicker}
												disableToolbar
												invalidDateMessage=""
												autoOk
												size="small"
												variant="inline"
												inputVariant="outlined"
												format="yyyy/MM/dd"
												id="start_date"
												maxDate={new Date('2999-12-31')}
												value={searchObj.startDate}
												onChange={d => {
													// eslint-disable-next-line no-restricted-globals
													if (!isNaN(d.getTime())) {
														if (searchObj.startDate.getTime() !== d.getTime())
															setSearchObj({ ...searchObj, startDate: d });
													}
												}}
												// onBlur={e => {
												// 	const val = commonUtils.getStringToDate(e.target.value.replaceAll('/', '-'));
												// 	if (!isNaN(val.getTime()) && props.searchObj.startDate.getTime() !== val.getTime())
												// 		props.fnSetSearchObj('startDate', val);
												// }}
												KeyboardButtonProps={{
													'aria-label': 'change date start'
												}}
											/>
										</MuiPickersUtilsProvider>
									</div>
									<div className="tilde">~</div>
									<div className="">
										<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
											<KeyboardDatePicker
												className={classes.datePicker}
												disableToolbar
												invalidDateMessage=""
												autoOk
												size="small"
												variant="inline"
												inputVariant="outlined"
												format="yyyy/MM/dd"
												id="end_date"
												maxDate={new Date('2999-12-31')}
												value={searchObj.endDate}
												onChange={d => {
													// eslint-disable-next-line no-restricted-globals
													if (!isNaN(d.getTime())) {
														if (searchObj.endDate.getTime() !== d.getTime())
															setSearchObj({ ...searchObj, endDate: d });
													}
												}}
												// onBlur={e => {
												// 	const val = commonUtils.getStringToDate(e.target.value.replaceAll('/', '-'));
												// 	if (!isNaN(val.getTime()) && props.searchObj.endDate.getTime() !== val.getTime())
												// 		props.fnSetSearchObj('endDate', val);
												// }}
												KeyboardButtonProps={{
													'aria-label': 'change date end'
												}}
											/>
										</MuiPickersUtilsProvider>
									</div>
								</div>
							</div>
						</div>
						<div className="content">
							<FormGroup row>
								<FormControlLabel
									className={clsx(classes.text12)}
									control={
										<Checkbox
											checked={allCheck}
											onChange={e => {
												const tmp = {};
												if (allCheck) {
													Object.keys(types).forEach(key => {
														tmp[key] = false;
													});
												} else {
													Object.keys(types).forEach(key => {
														tmp[key] = true;
													});
												}
												setTypes(tmp);
												setAllCheck(!allCheck);
											}}
											name="allCheck"
										/>
									}
									label={t('전체선택')}
								/>
								{logTypes.map(at => {
									return (
										<LogTypeCheckBox
											type={at.type}
											cons={at.cons}
											icon={at.icon}
											types={types}
											setTypes={typeVal => {
												setTypes(typeVal);
											}}
										/>
									);
								})}
							</FormGroup>
						</div>
					</div>
					<div className="button-group">
						<Button
							size="small"
							variant="contained"
							className="default"
							type="button"
							onClick={() => {
								doSearch(pageInfo.rows_per_page);
							}}
						>
							{t('SEARCH')}
						</Button>

						<Tooltip title={t('EXCEL_DOWNLOAD')} placement="bottom">
							<IconButton
								key="download"
								aria-label="download"
								color="secondary"
								onClick={() => {
									downloadExcel();
								}}
							>
								<CustomIcon className="small" type="download" fill="#333d4b" />
							</IconButton>
						</Tooltip>

						<Button
							size="small"
							variant="contained"
							className="default"
							type="button"
							onClick={() => {
								setDialogObj({ ...dialogObj, nonLogging: true });
							}}
							style={{ width: 80 }}
						>
							{t('제외 앱')}
						</Button>
					</div>
				</div>

				{/* <FuseAnimate animation="transition.expandIn" delay={800}>
					<Fab
						color="default"
						aria-label="edit"
						className={clsx(
							'absolute right-60 mx-16 -mb-28 z-10 top-10 md:top-13 md:hidden w-45 h-45 md:w-56 md:h-56'
						)}
						onClick={e => props.fnToggleRightSidebar()}
					>
						<Icon>edit_icon</Icon>
					</Fab>
				</FuseAnimate> */}
				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<ClientMonitoringLogTableHead pageType={pageType} />

						<TableBody>
							{data.map(d => {
								let tagName = (
									<Tooltip title={t(logTypeObj[d.log_type].title)} placement="bottom" arrow>
										<IconButton onClick={e => {}} style={{ padding: 0 }}>
											<CustomIcon className="big" type={logTypeObj[d.log_type].icon} fill="#333d4b" />
										</IconButton>
									</Tooltip>
								);
								let groupName = '';
								let dest = d.dest_path;
								let { path } = d;

								if (d.log_group === 1) {
									groupName = t('DLP_CLIENT_RELEASE_BLOCK');
								} else if (d.log_group === 3) {
									groupName = t('DLP_CLIENT_MONITORING_LOG');
								} else {
									groupName = t('DLP_CLIENT_BLOCKED_LOG');
								}

								const pathName = commonUtils.splitLastDelimiterString(path);
								const parentPath = commonUtils.splitPreDelimiter(path);

								path = (
									<div>
										<p className="log-name">{pathName}</p>
										<p className="log-path">{parentPath}</p>
									</div>
								);

								switch (d.log_type) {
									case 1:
										break;
									case 2:
										break;
									case 3:
										break;
									case 4:
										path = d.source_path;
										break;
									case 5:
										break;
									case 6:
										break;
									case 7:
										break;
									case 8:
										break;
									case 9:
										break;
									case 10:
										break;
									case 11:
										dest = d.remote_endpoint;
										path = d.local_endpoint;
										break;
									case 12:
										dest = d.url;
										break;
									case 13:
										path = d.url;
										dest = d.files;
										break;
									case 14: {
										const tmp = JSON.parse(d.log);
										path = tmp.type === 'msdos' ? tmp.protocol : tmp.type;
										dest = d.path;
										break;
									}
									case 15: {
										const tmp = JSON.parse(d.log);
										path = tmp.type === 'msdos' ? tmp.protocol : tmp.type;
										path = d.path;
										dest = d.log;
										break;
									}
									case 16: {
										const logObj = JSON.parse(d.log);
										console.log(logObj);

										const strTo = '';
										path = d.url;
										dest = strTo;
										break;
									}
									default:
										tagName = '';
										break;
								}

								d.tagName = tagName;
								d.list_path = path;
								return (
									<TableRow
										className={clsx(classes.tr, 'course-pointer')}
										hover
										role="checkbox"
										tabIndex={-1}
										key={d.fid}
										selected={d.fid === props.selected}
										onClick={event => {}}
									>
										<TableCell className="cursor-pointer left" component="td" scope="row" align="left">
											{commonUtils.getDateStringToLocalDate(d.logged_at)}
										</TableCell>
										<TableCell className="cursor-pointer left" component="td" scope="row" align="left">
											{`${d.user_name}(${d.user_id}-${d.client_id})`}
										</TableCell>
										<TableCell
											className="cursor-pointer"
											component="td"
											scope="row"
											align="center"
											style={{ width: 110 }}
										>
											{`${groupName}`}
										</TableCell>
										<TableCell
											className="cursor-pointer"
											component="td"
											scope="row"
											align="center"
											style={{ width: 70 }}
										>
											{tagName}
										</TableCell>
										<TableCell className="cursor-pointer left path" component="td" scope="row" align="left">
											{path}
										</TableCell>
										<TableCell className="cursor-pointer left path" component="td" scope="row" align="left">
											{dest}
										</TableCell>
										<TableCell className="cursor-pointer left" component="td" scope="row" align="left">
											{d.signing_id}
										</TableCell>
										<TableCell className="cursor-pointer left" component="td" scope="row" align="left">
											<div>
												<Tooltip title={t('DLP_CLIENT_LOG_VIER')} arrow>
													<IconButton
														onClick={e => {
															setDialogObj({ open: true, logObj: d });
														}}
													>
														<Icon style={{ color: '#575757' }}>pageview</Icon>
													</IconButton>
												</Tooltip>
											</div>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>
			</Paper>
			<TablePagination
				className="flex-shrink-0"
				component="div"
				count={pageInfo.total_rows}
				rowsPerPage={pageInfo.rows_per_page}
				rowsPerPageOptions={[100, 250, 500]}
				page={pageInfo.current_page}
				labelRowsPerPage={t('ROW_PER_PAGE_LABEL')}
				labelDisplayedRows={({ from, to, count }) => `${count !== -1 ? count : to} 중 ${from}-${to}`}
				backIconButtonProps={{
					'aria-label': t('PAGE_PREV_BTN_LABEL')
				}}
				nextIconButtonProps={{
					'aria-label': t('PAGE_NEXT_BTN_LABEL')
				}}
				onChangePage={(e, value) => {
					getPage(value);
					contentTable.current.scrollTop = 0;
				}}
				onChangeRowsPerPage={e => {
					doSearch(Number(e.target.value));
					contentTable.current.scrollTop = 0;
				}}
			/>
		</div>
	);
}

export default ClientMonitoringLogTable;
