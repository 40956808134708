import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Table, TableBody, TableCell, TableRow, IconButton, Button, Tooltip, Paper, Input } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { openDialog } from 'app/store/fuse/dialogSlice';
import CustomIcon from 'app/main/icon/CustomIcon';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import GroupsTableHead from './GroupsTableHead';
import OpenDialogForm from '../../component/OpenDialogForm';
import CreateGroupDialog from './CreateGroupDialog';
import DLPPolicyDialog from './DLPPolicyDialog';

const useStyles = makeStyles(theme => ({
	root: { marginRight: 30, borderRadius: 6 },
	deleteIcon: {
		color: '#ff6565'
	},
	contentWrapper: {
		height: `${window.innerHeight - 228}px`,
		padding: '0 20px'
	},
	icon: {
		width: 20,
		height: 20
	},
	insertForm: {
		display: 'flex',
		'&>div': { display: 'flex', alignItems: 'center' },
		'&>div:first-child': { width: '100%' },
		'&>div:first-child input.MuiOutlinedInput-input': { padding: '5.7px 10px' },
		'&>div.button-group': {
			paddingRight: 20
		},
		'&>div.button-group>button': {
			width: 106,
			height: 28,
			marginLeft: 10
		}
	}
}));

function GroupsTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const { t } = useTranslation('docbase');

	const [innerHeight, setInnerHeight] = useState(window.innerHeight);

	const [searchText, setSearchText] = useState('');
	const [loading, setLoading] = useState(true);
	const [dialogObj, setDialogObj] = useState({ open: false, type: 0 });
	const [data, setData] = useState([]);

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 228}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		if (searchText.length > 0) {
			setData(props.groups.filter(group => group.name.toLowerCase().includes(searchText.toLowerCase())));
		} else {
			setData(props.groups);
		}
		setLoading(false);
	}, [searchText]);

	useEffect(() => {
		if (searchText.length > 0) {
			setData(props.groups.filter(group => group.name.toLowerCase().includes(searchText.toLowerCase())));
		} else {
			setData(props.groups);
		}
		setLoading(false);
	}, [props.groups]);

	useEffect(() => {
		setLoading(true);
		props.fnGetGroups();
		return () => {};
	}, [dispatch]);

	const handleOK = () => {
		props.fnGetGroups();
	};
	const handleClose = () => {
		setDialogObj({ open: false, type: 0 });
	};

	const onClickHandler = id => {
		if (props.selectedID !== id) {
			props.fnSetSelectedID(id);
		} else {
			props.fnSetSelectedID(0);
		}
	};

	if (loading) {
		return <FuseLoading />;
	}

	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<CreateGroupDialog
				open={dialogObj.open}
				type={dialogObj.type}
				selectedID={props.selectedID}
				fnHandleClose={handleClose}
				fnHandleOK={handleOK}
			/>

			<DLPPolicyDialog
				open={props.DLPPolicy.open}
				groupID={props.DLPPolicy.selectedID}
				fnHandleClose={() => props.fnCloseDLPPolicy()}
			/>
			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className={clsx('items-center w-full flex table-search-wrapper')}>
					<div className="">
						<div className="input-item">
							<Input
								placeholder={t('SEARCH')}
								className="search-input"
								disableUnderline
								fullWidth
								value={searchText}
								inputProps={{
									'aria-label': 'Search'
								}}
								onChange={ev => setSearchText(ev.target.value)}
							/>
						</div>
					</div>
					<div className="total-cnt-info">
						<span className="pipe">|</span>
						<span>
							{t('TOTAL')} <span className="count">{data.length}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="btn-group">
						<div className="">
							<Button
								variant="contained"
								color="default"
								type="button"
								style={{ marginRight: 5 }}
								onClick={e => {
									props.fnOpenDLPPolicy();
								}}
							>
								{t('DEFAULT_POLICY_SETTING')}
							</Button>
							<Button
								variant="contained"
								color="default"
								type="button"
								style={{ marginRight: 5 }}
								onClick={e => {
									setDialogObj({ open: true, type: 0 });
								}}
							>
								{t('NEW_REGISTRATION')}
							</Button>
							<Button
								variant="contained"
								color="default"
								type="button"
								onClick={e => {
									if (props.selectedID === 0) {
										dispatch(
											showErrorMessage({
												message: t('DLP_POLICY_GROUP_MESSAGE_3')
											})
										);
									} else {
										setDialogObj({ open: true, type: 1 });
									}
								}}
							>
								{t('COPY_POLICY')}
							</Button>
						</div>
					</div>
				</div>

				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<GroupsTableHead />

						<TableBody>
							{data.map(d => {
								return (
									<TableRow
										className="cursor-pointer"
										hover
										role="checkbox"
										tabIndex={-1}
										key={d.id}
										selected={d.id === props.selectedID}
										onClick={event => {
											onClickHandler(d.id);
										}}
									>
										<TableCell className="cursor-pointer left" component="td" scope="row">
											{d.name}
										</TableCell>
										<TableCell className="cursor-pointer left" component="td" scope="row">
											{d.desc}
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											<div className="">
												<Tooltip title={t('DELETE')} arrow>
													<IconButton
														onClick={e => {
															e.stopPropagation();
															dispatch(
																openDialog({
																	children: (
																		<OpenDialogForm
																			title={t('DELETE_FOLDER_TITLE')}
																			content={`'${d.name}'${t('DELETE_CONTENT')}`}
																			fnHandleOK={() => props.fnRemoveGroups(d.id)}
																		/>
																	)
																})
															);
														}}
													>
														<CustomIcon type="delete" className="" fill="#575757" />
													</IconButton>
												</Tooltip>
											</div>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>
			</Paper>
		</div>
	);
}

export default GroupsTable;
