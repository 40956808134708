import Ransome from './Ransom';

const RansomConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/ransom',
			component: Ransome
		}
	]
};

export default RansomConfig;
