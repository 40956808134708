import { combineReducers } from '@reduxjs/toolkit';
import admin from './admin';
import wc from './wc';

const docbaseReducers = combineReducers({
	admin,
	wc
});

export default docbaseReducers;
