import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Button, Slide, TextField } from '@material-ui/core';
import * as commonTypes from 'app/main/constants/CommonTypes';
import { useDispatch } from 'react-redux';
import { showErrorMessage } from 'app/store/fuse/messageSlice';
import { paperPasswordAuth } from 'app/auth/store/loginSlice';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 604
		}
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	body: {
		background: '#f2f3f5',
		padding: '24px 30px 30px',
		color: '#353b48',
		'&>.content': {
			background: '#ffffff',
			borderRadius: 6,
			padding: 40
		},
		'&>.content>.row': {
			display: 'flex',
			alignItems: 'center',
			height: 40,
			margin: '15px 0'
		},
		'&>.content>.row:first-child': {
			marginTop: 0
		},
		'&>.content>.MuiTypography-root': { color: '#404040' }
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 40,
		'& .MuiButton-root': {
			width: 172
		},
		'& .MuiButton-root:first-child': {
			marginRight: 16
		}
	}
});

export default function PaperPasswordDialog(props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation('docbase');
	const inputName = React.useRef(null);

	const [paperPassword, setPaperPassword] = React.useState('');

	React.useEffect(() => {
		setPaperPassword('');
	}, [props.open]);

	return (
		<div>
			<Dialog
				open={props.open}
				TransitionComponent={Transition}
				keepMounted
				fullWidth
				maxWidth="xs"
				onClose={props.fnHandleClose}
				aria-labelledby="docbase_search_form"
				aria-describedby="search-for-file-in-folder "
			>
				<DialogTitle className={classes.title} onClose={props.fnHandleClose}>
					<span className="align-middle">{t('PRINT_PASSWORD_AUTH')}</span>
				</DialogTitle>
				<DialogContent className={classes.body}>
					<div className="content">
						<DialogContentText id="alert-dialog-slide-description" className="">
							{t('PRINT_PASSWORD_CONTEXT').replace('%s', props.question)}
						</DialogContentText>
						<div className="row">
							<TextField
								className=""
								type="password"
								name="cur-password"
								placeholder={t('PRINT_PASSWORD')}
								value={paperPassword}
								onChange={e => setPaperPassword(e.target.value)}
								variant="outlined"
								size="small"
								required
								fullWidth
								inputRef={inputName}
							/>
						</div>
						<div className={classes.buttonWrapper}>
							<Button
								onClick={props.fnHandleClose}
								variant="contained"
								className="default"
								aria-label="btn-cancel"
								type="button"
							>
								{t('CANCEL')}
							</Button>
							<Button
								variant="contained"
								color="secondary"
								className=""
								aria-label="btn-send"
								type="button"
								onClick={e => {
									if (paperPassword && paperPassword.length > 0) {
										paperPasswordAuth(paperPassword)
											.then(data => {
												props.fnHandleComplete();
											})
											.catch(err => {
												let msg = '';
												if (err.error === commonTypes.SERVER_ERROR_NOT_EXISTS) {
													msg = t('PRINT_PASSWORD_ERROR_MESSAGE_1');
												} else if (err.error === commonTypes.SERVER_ERROR_WRONG_ID_PASSWORD) {
													msg = t('PRINT_PASSWORD_ERROR_MESSAGE_2');
												}
												dispatch(showErrorMessage({ message: msg }));
											});
									}
								}}
							>
								{t('CHANGE')}
							</Button>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
