const locale = {
	BROWSER_TITLE: 'COODOC',
	PRODUCT_NAME: 'COODOC',
	PRODUCT_NAME_ADMIN: 'COODOC ADMIN',
	PRODUCT_ADMIN: 'ADMIN',
	USER: '',
	WEBLINK_TITLE: 'COODOC Weblink Download'
};

export default locale;
