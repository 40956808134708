import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import PrintLogTable from './PrintLogTable';
// import DetailSidebarContent from './DetailSidebarContent';

import ContentHeader from '../../component/ContentHeader';

function PrintLogPage() {
	const pageLayout = React.useRef(null);
	const [selectedFid, setSelectedFid] = React.useState(-1);

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={
				<PrintLogTable
					selected={selectedFid}
					fnSetSelectedFid={fid => setSelectedFid(fid)}
					fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
				/>
			}
			// rightSidebarContent={
			// 	<DetailSidebarContent
			// 		selected={selectedFid}
			// 		path={path}
			// 		fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
			// 	/>
			// }
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default PrintLogPage;
