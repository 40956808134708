import ReleasePage from './ReleasePage';

const ReleaseConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/releaseBlockings',
			component: ReleasePage
		}
	]
};

export default ReleaseConfig;
