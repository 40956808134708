import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Table, TableBody, TableCell, TableRow, IconButton, Icon, Tooltip, Paper, Input, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { inquiryReleaseBlockingsPage, inquiryReleaseBlockings } from 'app/store/docbase/admin/dlpSlice';
import commonUtils from 'app/utils/CommonUtils';
import ReleaseTableHead from './ReleaseTableHead';
import ViewDialog from './ViewDialog';

const useStyles = makeStyles(theme => ({
	root: { marginRight: 30, borderRadius: 6 },
	deleteIcon: {
		color: '#ff6565'
	},
	contentWrapper: {
		height: `${window.innerHeight - 228}px`,
		padding: '0 20px'
	},
	icon: {
		width: 20,
		height: 20
	},
	btn01: {
		marginRight: 10
	}
}));

function ReleaseTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const { t } = useTranslation('docbase');

	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const [loading, setLoading] = useState(true);
	const [pageInfo, setPageInfo] = React.useState({ total_rows: 0, total_pages: 0, current_page: 0, rows_per_page: 100 });
	const [dialogObj, setDialogObj] = React.useState({ open: false, requestObj: {} });
	const [searchObj, setSearchObj] = React.useState({
		userID: '',
		status: 0,
		startDate: commonUtils.getLastWeek(),
		endDate: new Date()
	});

	const [data, setData] = useState([]);

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 228}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		setLoading(true);
		doSearch(pageInfo.rows_per_page);
		return () => {
			setSearchObj({
				userID: '',
				status: 0,
				startDate: commonUtils.getLastWeek(),
				endDate: new Date()
			});
		};
	}, [dispatch]);

	if (loading) {
		return <FuseLoading />;
	}

	async function doSearch(rowsPerPage, page) {
		setLoading(true);
		const respData = await inquiryReleaseBlockings(
			commonUtils.getDateString(searchObj.startDate),
			commonUtils.getDateString(searchObj.endDate),
			searchObj.status,
			rowsPerPage
		);

		const pageEx = page || 1;
		if (respData.rows > 0) {
			await getPage(pageEx);
		} else {
			setData([]);
		}
		setPageInfo({
			...pageInfo,
			total_rows: respData.rows,
			total_pages: respData.pages,
			rows_per_page: rowsPerPage,
			current_page: pageEx
		});
		setLoading(false);
	}

	async function getPage(page) {
		const logs = await inquiryReleaseBlockingsPage(page);
		setData(logs);
	}

	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<ViewDialog
				open={dialogObj.open}
				requestObj={dialogObj.requestObj}
				fnReset={() => {
					doSearch(pageInfo.rows_per_page, pageInfo.current_page);
					setDialogObj({ open: false, requestObj: {} });
				}}
				fnHandleClose={() => setDialogObj({ open: false, requestObj: {} })}
			/>

			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className={clsx('items-center w-full flex table-search-wrapper')}>
					<div className="total-cnt-info">
						<span className="pipe">|</span>
						<span>
							{t('TOTAL')} <span className="count">{data.length}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="search-form-wrapper">
						<div className="input-item">
							<Input
								placeholder={t('사용자 아이디')}
								className="search-input"
								disableUnderline
								fullWidth
								value={searchObj.userID}
								inputProps={{
									'aria-label': 'Search'
								}}
								onChange={ev => setSearchObj({ ...searchObj, userID: ev.target.value })}
							/>
						</div>
						<div className="input-item">
							<Input
								placeholder={t('클라이언트 아이디')}
								className="search-input"
								disableUnderline
								fullWidth
								value={searchObj.clientID}
								inputProps={{
									'aria-label': 'Search'
								}}
								onChange={ev => setSearchObj({ ...searchObj, clientID: ev.target.value })}
							/>
						</div>
						<div className="date-picker-wrapper">
							<span className="title">{t('RESTORE_DAY')}</span>
							<div className="">
								<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
									<KeyboardDatePicker
										className={classes.datePicker}
										fullWidth
										disableToolbar
										invalidDateMessage=""
										autoOk
										size="small"
										variant="inline"
										inputVariant="outlined"
										format="yyyy/MM/dd"
										id="start_date"
										maxDate={new Date('2999-12-31')}
										value={searchObj.startDate}
										onChange={d => {
											// eslint-disable-next-line no-restricted-globals
											if (!isNaN(d.getTime())) {
												if (searchObj.startDate.getTime() !== d.getTime())
													setSearchObj({ ...searchObj, startDate: d });
											}
										}}
										// onBlur={e => {
										// 	const val = commonUtils.getStringToDate(e.target.value.replaceAll('/', '-'));
										// 	if (!isNaN(val.getTime()) && props.searchObj.startDate.getTime() !== val.getTime())
										// 		props.fnSetSearchObj('startDate', val);
										// }}
										KeyboardButtonProps={{
											'aria-label': 'change date start'
										}}
									/>
								</MuiPickersUtilsProvider>
							</div>
							<div className="tilde">~</div>
							<div className="">
								<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
									<KeyboardDatePicker
										className={classes.datePicker}
										fullWidth
										disableToolbar
										invalidDateMessage=""
										autoOk
										size="small"
										variant="inline"
										inputVariant="outlined"
										format="yyyy/MM/dd"
										id="end_date"
										maxDate={new Date('2999-12-31')}
										value={searchObj.endDate}
										onChange={d => {
											// eslint-disable-next-line no-restricted-globals
											if (!isNaN(d.getTime())) {
												if (searchObj.endDate.getTime() !== d.getTime())
													setSearchObj({ ...searchObj, endDate: d });
											}
										}}
										// onBlur={e => {
										// 	const val = commonUtils.getStringToDate(e.target.value.replaceAll('/', '-'));
										// 	if (!isNaN(val.getTime()) && props.searchObj.endDate.getTime() !== val.getTime())
										// 		props.fnSetSearchObj('endDate', val);
										// }}
										KeyboardButtonProps={{
											'aria-label': 'change date end'
										}}
									/>
								</MuiPickersUtilsProvider>
							</div>
						</div>
						<div>
							<Button
								variant="contained"
								className="default"
								type="button"
								onClick={() => {
									doSearch(pageInfo.rows_per_page);
								}}
							>
								{t('SEARCH')}
							</Button>
						</div>
					</div>
				</div>

				{/* <FuseAnimate animation="transition.expandIn" delay={800}>
					<Fab
						color="default"
						aria-label="edit"
						className={clsx(
							'absolute right-60 mx-16 -mb-28 z-10 top-10 md:top-13 md:hidden w-45 h-45 md:w-56 md:h-56'
						)}
						onClick={e => props.fnToggleRightSidebar()}
					>
						<Icon>edit_icon</Icon>
					</Fab>
				</FuseAnimate> */}
				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<ReleaseTableHead />

						<TableBody>
							{data.map(d => {
								let status = '';
								let type = '';
								switch (d.status) {
									case 1:
										status = t('요청');
										break;
									case 100:
										status = t('승인');
										break;
									case 110:
										status = t('자동 승인');
										break;
									case 200:
										status = t('거절');
										break;
									case 300:
										status = t('요청 철회');
										break;
									default:
								}
								switch (d.release_type) {
									case 0:
										type = t('전체');
										break;
									case 1:
										type = t('매체 접근');
										break;
									case 2:
										type = t('네트워크');
										break;
									case 3:
										type = t('실행');
										break;
									case 4:
										type = t('브라우저 업로드');
										break;
									case 5:
										type = t('블루투스 파일전송');
										break;
									case 6:
										type = t('블루투스 장치 페어링');
										break;
									case 7:
										type = t('메신저 파일전송');
										break;
									case 100:
										type = t('설치모드');
										break;
									default:
								}

								return (
									<TableRow
										className={clsx(classes.tr, 'course-pointer')}
										hover
										role="checkbox"
										tabIndex={-1}
										key={d.fid}
										selected={d.fid === props.selected}
										onClick={event => {}}
									>
										<TableCell className="cursor-pointer left" component="td" scope="row" align="left">
											{commonUtils.getDateStringToLocalDate(d.requested_at)}
										</TableCell>
										<TableCell className="cursor-pointer left" component="td" scope="row" align="left">
											{`${d.requester_name}(${d.requester})`}
										</TableCell>
										<TableCell className="cursor-pointer left" component="td" scope="row" align="left">
											{`${d.approver_name}(${d.approver})`}
										</TableCell>
										<TableCell className="cursor-pointer" component="td" scope="row" align="center">
											{type}
										</TableCell>
										<TableCell className="cursor-pointer" component="td" scope="row" align="center">
											{d.duration}
										</TableCell>
										<TableCell className="cursor-pointer left path" component="td" scope="row" align="left">
											{d.reason_request}
										</TableCell>
										<TableCell className="cursor-pointer" component="td" scope="row" align="center">
											{status}
										</TableCell>
										<TableCell className="cursor-pointer left" component="td" scope="row" align="left">
											{commonUtils.getDateStringToLocalDate(d.approved_at)}
										</TableCell>
										<TableCell className="cursor-pointer left" component="td" scope="row" align="left">
											<div>
												<Tooltip title={t('DLP_CLIENT_LOG_VIER')} arrow>
													<IconButton
														onClick={e => {
															setDialogObj({ open: true, requestObj: d });
														}}
													>
														<Icon style={{ color: '#575757' }}>pageview</Icon>
													</IconButton>
												</Tooltip>
											</div>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>
			</Paper>
		</div>
	);
}

export default ReleaseTable;
