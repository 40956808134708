import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import ClientMonitoringLogTable from './ClientMonitoringLogTable';

import ContentHeader from '../../component/ContentHeader';

function ClientMonitoringLogPage() {
	const pageLayout = React.useRef(null);
	const [selectedFid, setSelectedFid] = React.useState(-1);
	const [path, setPath] = React.useState('');

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={
				<ClientMonitoringLogTable
					selected={selectedFid}
					fnSetSelectedFid={fid => setSelectedFid(fid)}
					fnSetPath={p => setPath(p)}
					fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
				/>
			}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default ClientMonitoringLogPage;
