import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { IconButton } from '@material-ui/core';
import { selectNavigation } from 'app/store/fuse/navigationSlice';
import { useSelector } from 'react-redux';
import { doLogout } from 'app/auth/store/loginSlice';
import { Link } from 'react-router-dom';
import commonUtils from 'app/utils/CommonUtils';
import LanguageSwitcher from 'app/fuse-layouts/shared-components/LanguageSwitcher';
import profileIcon from '../icon/header_profile.svg';
import homeIcon from '../icon/header_home.svg';
import logoutIcon from '../icon/header_logout.svg';

const useStyles = makeStyles(theme => ({
	root: {
		padding: '40px 32px',
		color: '#222222'
	},
	naviWrapper: { display: 'inline-block', '& span': { cursor: 'default', marginRight: 10 } },
	navigationPath: {
		display: 'inline-block',
		fontSize: 18,
		color: '#222222',
		'&>span': { cursor: 'default' },
		'&>button': { 'min-width': 'auto' }
	},
	icon: {
		width: 32,
		height: 32
	},
	adminInfo: {
		marginRight: 30,
		fontSize: 14,
		'&>span': {
			marginRight: 20,
			cursor: 'default'
		},
		'$>span:last-child': {
			marginRight: 0
		}
	},
	profileWrapper: {
		marginRight: 15
	},
	mr20: {
		marginRight: 20
	},
	item: props => ({
		height: 60,
		width: '100%',
		paddingLeft: 30,
		paddingRight: 20,
		borderTop: '1px solid #353b48',
		borderBottom: '1px solid #353b48',
		fontSize: 16,
		color: '#8b95a1',
		backgroundColor: '#fff',
		'& .arrow-icon': {
			color: '#8b95a1'
		}
	})
}));

function ContentHeader(props) {
	const classes = useStyles();
	const navigation = useSelector(selectNavigation);

	const [naviPath, setNaviPath] = React.useState('');

	React.useEffect(() => {
		if (navigation && navigation.length > 0) {
			for (let i = 0; i < navigation.length; i++) {
				if (navigation[i].children && navigation[i].children.length > 0) {
					for (let j = 0; j < navigation[i].children.length; j++) {
						if (navigation[i].children[j].url === window.location.pathname) {
							setNaviPath(`${navigation[i].title}>>${navigation[i].children[j].title}`);
							break;
						}
					}
				}
			}
		}
	}, [window.location.pathname]);

	const spNavi = naviPath.split('>>');

	return (
		<div className={clsx('sm:flex sm:flex-1 w-full items-center justify-between', classes.root)}>
			<div className="flex items-center">
				{spNavi.map((pathName, idx) => (
					<div className={classes.naviWrapper}>
						<div className={classes.navigationPath}>
							<span>{`${pathName}`}</span>
						</div>
						{spNavi.length > idx + 1 ? <span>&gt; </span> : ''}
					</div>
				))}
			</div>

			<div className="flex flex-1 items-center justify-end px-12">
				<div className={classes.profileWrapper}>
					<img className={classes.icon} src={profileIcon} alt="header-profile-icon" />
				</div>
				<div className={classes.adminInfo}>
					<span>{localStorage.getItem('doc_name')}</span>
					<span>|</span>
					<span>{commonUtils.getDateString(new Date())}</span>
				</div>
				<IconButton>
					<Link to="/dashboard">
						<img className={classes.icon} src={homeIcon} alt="header-home-icon" />
					</Link>
				</IconButton>
				<LanguageSwitcher style={{ minWidth: 'inherit', width: 56, height: 56, padding: 12 }} />
				<IconButton
					className="cursor-pointer"
					onClick={e => {
						doLogout().then(data => {
							window.location.href = '/admin';
						});
					}}
				>
					<img className={classes.icon} src={logoutIcon} alt="header-logout-icon" />
				</IconButton>
			</div>
		</div>
	);
}

export default ContentHeader;
