import FuseAnimate from '@fuse/core/FuseAnimate';
import { useForm } from '@fuse/hooks';
import {
	Button,
	Card,
	CardContent,
	Checkbox,
	FormControl,
	FormControlLabel,
	TextField,
	MenuItem,
	IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LanguageSwitcher from 'app/fuse-layouts/shared-components/LanguageSwitcher';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as commonTypes from 'app/main/constants/CommonTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
	doIntegratedLogin,
	doLogin,
	authTOTP,
	getTOTPSecretKey,
	registerTOTP,
	requestPaperPassword
} from 'app/auth/store/loginSlice';
import { getUsers } from 'app/store/docbase/admin/accountSlice';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';

import Cookies from 'js-cookie';
import CustomIcon from 'app/main/icon/CustomIcon';
import securityUtils from 'app/utils/SecurityUtils';
import ChangeDialog from 'app/main/component/ChangeDialog';
import Chart from 'react-apexcharts';
import CommonUtils from 'app/utils/CommonUtils';
import PaperPasswordDialog from './PaperPasswordDialog';

const isMobile = sessionStorage.getItem('device') === 'mobile' || window.ReactNativeWebView;

const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
		backgroundImage: 'url("assets/brand/login_bg.png")',
		backgroundRepeat: 'unset',
		width: '100%!important',
		height: '100%',
		'&>div': isMobile
			? {
					position: 'relative',
					width: '100%',
					maxWidth: 375,
					height: '100%',
					margin: '0 auto',
					objectFit: 'contain',
					'-webkit-backdrop-filter': 'blur(50px)',
					backdropFilter: 'blur(50px)',
					backgroundImage: 'url("assets/brand/login_mobile_bg.png")',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center center'
			  }
			: {
					position: 'absolute',
					left: 180,
					width: 480,
					height: '100%',
					objectFit: 'contain',
					'-webkit-backdrop-filter': 'blur(50px)',
					backdropFilter: 'blur(50px)',
					background: 'rgb(255 255 255 / 20%)'
			  },

		'& .login-logo': { margin: '0 auto' },
		'& .login-wrapper': {
			boxShadow: 'none',
			background: 'none',
			display: 'flex',
			justifyContent: 'center',
			height: '100%',
			position: 'relative'
		},
		'& .login-wrapper>.login-content': {
			height: '100%',
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -30%)'
		},
		'& .login-wrapper .sub-title': {
			fontFamily: 'ProximaNova-Bold',
			fontWeight: 'bold',
			fontSize: 32,
			color: '#00cfe2',
			textAlign: 'center',
			marginTop: 52,
			marginBottom: 80
		},
		'& .login-wrapper .login-form': {
			color: '#a2a2ad',
			width: 336,
			marginBottom: 20,
			'& .MuiInput-root': {
				color: '#a2a2ad',
				fontSize: 16
			},
			'& .MuiInput-underline:before': {
				borderColor: 'rgb(162 162 173 / 80%)'
			},
			'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
				borderColor: 'rgb(162 162 173 / 100%)'
			},
			'& .MuiInput-underline:after': {
				borderBottom: '2px solid rgb(162 162 173 / 100%)'
			},
			'& .MuiCheckbox-colorSecondary>.MuiIconButton-label': {
				background: 'initial'
			},
			'& .MuiCheckbox-root': {
				color: '#939393',
				padding: '0 12px'
			},
			'& .MuiTypography-root': {
				color: '#ffffff'
			},
			'& .user-id': { marginBottom: 26 },
			'& .passwd': { marginBottom: 10 },
			'& .totp': { marginTop: 25 },
			'& .submit-btn': {
				marginTop: 96,
				width: '100%',
				height: 50,
				fontFamily: 'S-CoreDream-Bold',
				fontSize: 16,
				fontWeight: 'bold'
			}
		},
		'& .client-download-wrapper': {
			display: 'flex',
			'&>div:first-child': {
				width: '100%',
				marginRight: 12,
				'& .MuiSelect-select': {
					background: '#ffffff',
					padding: '15.5px 14px',
					fontSize: 16,
					color: '#333d4b'
				},
				'& .MuiSelect-select:focus': {
					borderRadius: 'inherit'
				}
			},
			'&>div:last-child': {
				'&>.MuiIconButton-root': { background: '#eceff4', borderRadius: 6, width: 56, height: 50 }
			}
		},
		'& .info-wrapper': {
			boxShadow: 'none',
			background: 'none',
			display: 'flex',
			justifyContent: 'center',
			'&>content': {
				paddingTop: 197
			},
			'& .info-text': {
				fontSize: 23,
				color: '#ffffff',
				textAlign: 'center',
				marginTop: 52
			}
		},
		'& .btn-group': {
			marginTop: 96,
			width: '100%',
			display: 'flex',
			'&>button': {
				width: '100%',
				height: 50
			},
			'&>button:first-child': {
				marginRight: 10
			}
		}
	},
	mbLoginCardContent: { 'padding-bottom': '3.2rem!important' },
	imgQrcode: { margin: '0 auto' }
}));

function LoginPage({ history, location }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation(['docbase', 'brand']);

	const refRegTotp = React.useRef(null);
	const refInputTotp = React.useRef(null);

	const [verDownloadClient, setVerDownloadClient] = React.useState('-');
	const [formType, setFormType] = React.useState(0);
	const [totp, setTotp] = React.useState('');
	const [regTotp, setRegTotp] = React.useState('');
	const [regKey, setregKey] = React.useState('');
	const [qrcodeSrc, setQrcodeSrc] = React.useState('');

	const [openPasswordChange, setOpenPasswordChange] = React.useState(false);
	const [paperPasswordObj, setPaperPasswordObj] = React.useState({ open: false, question: '' });
	const initialObj = useSelector(({ auth }) => auth.initial);
	const isWC = location.pathname !== '/admin';

	// if (isMobile) document.getElementsByTagName('html')[0].style.fontSize = '150%';

	const series2 = [44, 55, 13, 43, 22, 11, 32, 44];
	const options2 = {
		chart: {
			width: 680,
			type: 'pie'
		},
		colors: ['#00cfe2', '#f6c142', '#e58950', '#95beff', '#272c36'],
		labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 400
					},
					legend: {
						position: 'bottom'
					}
				}
			}
		],
		stroke: {
			width: 0
		},
		dataLabels: {
			offsetX: 100,
			offsetY: 100,
			minAngleToShowLabel: 30,
			formatter(val, opts) {
				return `${val.toFixed(0)}%`;
			},
			style: {
				fontSize: '16px',
				fontFamily: 'S-CoreDream-Bold',
				fontWeight: 'bold'
			}
		},
		legend: {
			onItemClick: {
				toggleDataSeries: false
			},
			onItemHover: {
				highlightDataSeries: false
			},
			width: 120,
			formatter(seriesName, opts) {
				return `${seriesName} - ${opts.w.globals.series[opts.seriesIndex]}`;
			}
		},
		plotOptions: {
			pie: {
				expandOnClick: false,
				donut: {
					size: '10%',
					fontSize: '22px',
					fontFamily: 'S-CoreDream-Bold'
				}
			}
		},
		tooltip: {
			x: {
				show: true,
				format: 'dd MMM',
				formatter: undefined
			},
			y: {
				formatter(value, { seriesIndex, dataPointIndex, w }) {
					return value;
				}
			}
		}
	};
	const options3 = {
		chart: {
			width: 680,
			type: 'pie'
		},
		colors: ['#00cfe2', '#f6c142', '#e58950', '#95beff', '#272c36'],
		labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 400
					},
					legend: {
						position: 'bottom'
					}
				}
			}
		],
		stroke: {
			width: 0
		},
		dataLabels: {
			offsetX: 600,
			offsetY: 600,
			textAnchor: 'end',
			minAngleToShowLabel: 30,
			formatter(val, opts) {
				return `${val.toFixed(0)}%`;
			},
			style: {
				fontSize: '16px',
				fontFamily: 'S-CoreDream-Bold',
				fontWeight: 'bold'
			}
		},
		legend: {
			onItemClick: {
				toggleDataSeries: false
			},
			onItemHover: {
				highlightDataSeries: false
			},
			width: 120,
			formatter(seriesName, opts) {
				return `${seriesName} - ${opts.w.globals.series[opts.seriesIndex]}`;
			}
		},
		plotOptions: {
			pie: {
				expandOnClick: false,
				donut: {
					size: '0%',
					fontSize: '22px',
					fontFamily: 'S-CoreDream-Bold'
				}
			}
		},
		tooltip: {
			x: {
				show: true,
				format: 'dd MMM',
				formatter: undefined
			},
			y: {
				formatter(value, { seriesIndex, dataPointIndex, w }) {
					return value;
				}
			}
		}
	};

	const { sysConfigure } = useSelector(({ docbase }) => docbase.admin.setting);

	useEffect(() => {
		let rnOnMessage = null;
		if (window.ReactNativeWebView) {
			rnOnMessage = event => {
				const { eventID, eventData } = JSON.parse(event.data);
				switch (eventID) {
					case commonTypes.NATIVE_EVENT_ON_BIOMETRICS_LOGIN:
						if (eventData.result) {
							doLogin(
								securityUtils.decryptAESCTR(window.coodocWB.mbRemeID),
								securityUtils.decryptAESCTR(window.coodocWB.mbRemePW),
								''
							)
								.then(async data => {
									window.coodocWB.isFirst = false;
									setFormType(data.two_factor_authentication_flag);
									sessionStorage.setItem('user_id', window.coodocWB.mbRemeID);
									sessionStorage.setItem('auth', window.coodocWB.mbRemePW);
									//	WEB Client 로그인 처리
									if (data.need_password_change) {
										//	비밀번호 강제 변경
										setOpenPasswordChange(true);
									} else {
										drawTwoFactorAuth(data.two_factor_authentication_flag);
									}
								})
								.catch(err => {
									if (err.error === commonTypes.SERVER_ERROR_WRONG_ID_PASSWORD) {
										dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_1') }));
									} else {
										dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_2') }));
									}
								});
						} else {
							setInForm('userID', '');
							setInForm('remember', false);
							setInForm('password', '');
							setRememberPW(false);
						}
						break;
					default:
				}
			};
			document.addEventListener('message', rnOnMessage);
			window.addEventListener('message', rnOnMessage);
		}

		if (window.ReactNativeWebView && window.coodocWB.isFirst) {
			let existsUserID = false;
			let existsPassword = false;
			let userID = '';
			let passwd = '';

			if (window.coodocWB.mbRemeID && window.coodocWB.mbRemeID !== '') {
				userID = securityUtils.decryptAESCTR(window.coodocWB.mbRemeID);
				setInForm('userID', userID);
				setInForm('remember', true);
				existsUserID = true;
			}
			if (window.coodocWB.mbRemePW && window.coodocWB.mbRemePW !== '') {
				passwd = securityUtils.decryptAESCTR(window.coodocWB.mbRemePW);
				setInForm('password', passwd);
				setRememberPW(true);
				existsPassword = true;
			}

			if (existsUserID && existsPassword) {
				if (window.coodocWB.mbBiometrics) {
					window.ReactNativeWebView.postMessage(
						//	최초로그인으로 비밀번호를 변경해도 해당 값으로 저장하면된다.
						JSON.stringify({
							eventID: commonTypes.NATIVE_EVENT_BIOMETRICS_AUTH,
							eventData: {}
						})
					);
				} else {
					doLogin(userID, passwd, '')
						.then(async data => {
							window.coodocWB.isFirst = false;
							setFormType(data.two_factor_authentication_flag);
							//	WEB Client 로그인 처리
							if (data.need_password_change) {
								//	비밀번호 강제 변경
								setOpenPasswordChange(true);
							} else {
								drawTwoFactorAuth(data.two_factor_authentication_flag);
							}
						})
						.catch(err => {
							if (err.error === commonTypes.SERVER_ERROR_WRONG_ID_PASSWORD) {
								dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_1') }));
							} else {
								dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_2') }));
							}
						});
				}
			}
		}

		if (location.pathname === '/mv') {
			const urlParam = new URLSearchParams(location.search);
			doIntegratedLogin()
				.then(async data => {
					if (isWC) {
						setFormType(data.two_factor_authentication_flag);
						//	WEB Client 로그인 처리
						if (data.need_password_change) {
							//	비밀번호 강제 변경
							setOpenPasswordChange(true);
						} else {
							sessionStorage.setItem('auth', securityUtils.encryptAESCTR(form.password));
							drawTwoFactorAuth(data.two_factor_authentication_flag);
							if (window.ReactNativeWebView) saveMobileLoginInfo();
						}
					}
				})
				.catch(err => {
					console.log(err);
					if (err.error === commonTypes.SERVER_ERROR_WRONG_ID_PASSWORD) {
						dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_1') }));
					} else {
						dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_2') }));
					}
				});
		}

		return () => {
			document.removeEventListener('message', rnOnMessage);
			window.removeEventListener('message', rnOnMessage);
		};
	}, []);

	useEffect(() => {}, [dispatch]);

	useEffect(() => {
		if (!initialObj.product_registered) {
			history.push('/regProduct');
		} else if (!initialObj.administrator_setup) {
			history.push('/register');
		}
	}, [initialObj]);

	useEffect(() => {
		if (formType === 2) {
			refInputTotp.current.focus();
		} else if (formType === 1) {
			refRegTotp.current.focus();
		}
	}, [formType]);

	const { form, handleChange, resetForm, setInForm } = useForm({
		userID: isWC
			? Cookies.get('doc_id') && Cookies.get('doc_id') !== ''
				? Cookies.get('doc_id')
				: ''
			: Cookies.get('doc_admin_id') && Cookies.get('doc_admin_id') !== ''
			? Cookies.get('doc_admin_id')
			: '',
		password: '',
		remember: isWC
			? Cookies.get('doc_id') && Cookies.get('doc_id') !== ''
			: Cookies.get('doc_admin_id') && Cookies.get('doc_admin_id') !== ''
	});
	const [rememberPW, setRememberPW] = React.useState(false);

	function handleSubmit(ev) {
		ev.preventDefault();
		if (form.userID.trim() === '') {
			dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_3') }));
			return false;
		}
		if (form.password.trim() === '') {
			dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_5') }));
			return false;
		}
		doLogin(form.userID, form.password, '')
			.then(async data => {
				if (form.remember) {
					Cookies.set(isWC ? 'doc_id' : 'doc_admin_id', form.userID, { expires: 365 });
				} else {
					Cookies.remove(isWC ? 'doc_id' : 'doc_admin_id');
				}

				if (isWC) {
					setFormType(data.two_factor_authentication_flag);
					//	WEB Client 로그인 처리
					if (data.need_password_change) {
						//	비밀번호 강제 변경
						setOpenPasswordChange(true);
					} else {
						sessionStorage.setItem('auth', securityUtils.encryptAESCTR(form.password));
						drawTwoFactorAuth(data.two_factor_authentication_flag);
						if (window.ReactNativeWebView) {
							saveMobileLoginInfo();
							sessionStorage.setItem('user_id', securityUtils.encryptAESCTR(form.userID));
						}
					}
				} else {
					const users = await getUsers();
					const obj = users.find(u => u.user_id === form.userID);
					if (obj && obj.privilege === 1) {
						//	관리자는 dashboard로 프리패스
						history.push('/dashboard');
					} else {
						dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_6') }));
					}
				}
			})
			.catch(err => {
				console.log(err);
				if (err.error === commonTypes.SERVER_ERROR_WRONG_ID_PASSWORD) {
					dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_1') }));
				} else {
					dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_2') }));
				}
			});
		resetForm();
		return true;
	}

	/**
	 * 모바일 어플로 접근시 아이디 / 비밀번호 저장정보는 모바일에 저장한다.
	 */
	function saveMobileLoginInfo(userID, passwd) {
		window.ReactNativeWebView.postMessage(
			//	최초로그인으로 비밀번호를 변경해도 해당 값으로 저장하면된다.
			JSON.stringify({
				eventID: commonTypes.NATIVE_EVENT_SET_LOGIN_OBJ,
				eventData: {
					userID: form.remember ? securityUtils.encryptAESCTR(form.userID) : '',
					passwd: rememberPW ? sessionStorage.getItem('auth') : ''
				}
			})
		);
	}

	function drawTwoFactorAuth(flag) {
		if (flag === 0) {
			//	TOTP 사용 안 함
			history.push('/wc/main');
		} else if (flag === 1) {
			//	TOTP 사용 중
			//	TOTP정보 미인증 일 경우, QR코드를 가져온다.
			getTOTPSecretKey(form.userID)
				.then(dataTOTP => {
					setregKey(securityUtils.decryptAES(dataTOTP.aes, dataTOTP.secret_key, 'hex', 'utf8'));
					setQrcodeSrc(
						`data:image/bmp;base64,${btoa(
							String.fromCharCode.apply(
								null,
								securityUtils.hexToArray(securityUtils.decryptAES(dataTOTP.aes, dataTOTP.qrcode, 'hex', 'utf8'))
							)
						)}`
					);
				})
				.catch(err => console.error(err));
		}
	}

	let loginForm = <></>;

	if (formType === 0) {
		loginForm = (
			<form name="loginForm" noValidate className="login-form" onSubmit={handleSubmit}>
				<TextField
					className="user-id"
					placeholder={t('ID')}
					autoFocus
					type="text"
					name="userID"
					value={form.userID}
					onChange={handleChange}
					variant="standard"
					required
					fullWidth
					inputProps={{ autocapitalize: 'off', autoComplete: 'off' }}
					size="small"
				/>

				<TextField
					className="passwd"
					placeholder={t('PASSWORD')}
					type="password"
					name="password"
					value={form.password}
					onChange={handleChange}
					variant="standard"
					required
					fullWidth
					size="small"
				/>

				<div className="flex">
					<FormControl className="inline" style={{ lineHeight: '35px' }}>
						<FormControlLabel
							control={<Checkbox name="remember" checked={form.remember} onChange={handleChange} />}
							label={t('REMEMBER_ME')}
						/>
						{window.ReactNativeWebView ? (
							<FormControlLabel
								className="ml-0"
								control={
									<Checkbox
										name="remember"
										disabled={!form.remember}
										checked={rememberPW}
										onClick={e => {
											setRememberPW(!rememberPW);
										}}
									/>
								}
								label={t('REMEMBER_PW')}
							/>
						) : (
							<></>
						)}
					</FormControl>
					<LanguageSwitcher style={{ marginLeft: 'auto', minWidth: 'inherit', width: 56, padding: 0 }} />
				</div>

				<Button variant="contained" color="secondary" className="submit-btn" aria-label="LOG IN" fullWidth type="submit">
					{t('LOGIN')}
				</Button>
			</form>
		);
	} else if (formType === 1) {
		loginForm = (
			<form name="loginForm" noValidate className="login-form">
				<img className={clsx(classes.imgQrcode)} width="200" height="200" alt="qrcode" src={qrcodeSrc} />
				<TextField
					className="totp"
					placeholder={t('TOTP_VALUE')}
					autoFocus
					type="text"
					name="regTotp"
					value={regTotp}
					onChange={e => setRegTotp(e.target.value)}
					variant="standard"
					required
					fullWidth
					inputProps={{ autoComplete: 'off' }}
					inputRef={refRegTotp}
					size="small"
					onKeyDown={e => {
						if (e.keyCode === 13) {
							if (regTotp.trim().length > 0 && regTotp.trim().length === 6) {
								registerTOTP(regKey, regTotp)
									.then(data => {
										history.push('/wc/main');
									})
									.catch(err => {
										if (err.error === commonTypes.SERVER_ERROR_TIMEOUT) {
											dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_3') }));
										} else if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
											dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_4') }));
										} else {
											dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_5') }));
										}
									});
							}
						}
					}}
				/>

				<Button
					variant="contained"
					color="secondary"
					className="submit-btn"
					aria-label="TOTP Registere"
					type="button"
					onClick={e => {
						if (regTotp.trim().length > 0 && regTotp.trim().length === 6) {
							registerTOTP(regKey, regTotp)
								.then(data => {
									history.push('/wc/main');
								})
								.catch(err => {
									if (err.error === commonTypes.SERVER_ERROR_TIMEOUT) {
										dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_3') }));
									} else if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
										dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_4') }));
									} else {
										dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_5') }));
									}
								});
						}
					}}
				>
					{t('REGISTER')}
				</Button>
			</form>
		);
	} else if (formType === 2) {
		loginForm = (
			<div name="loginForm" noValidate className="login-form">
				<PaperPasswordDialog
					open={paperPasswordObj.open}
					question={paperPasswordObj.question}
					fnHandleClose={() => setPaperPasswordObj({ open: false, question: '' })}
					fnHandleComplete={() => {
						if (form.remember) {
							Cookies.set('doc_id', form.userID, { expires: 365 });
						} else {
							Cookies.remove('doc_id');
						}

						history.push('/wc/main');
					}}
				/>
				<TextField
					className=""
					placeholder={t('TOTP_VALUE')}
					autoFocus
					type="text"
					name="totp"
					value={totp}
					onChange={e => setTotp(e.target.value)}
					variant="standard"
					required
					size="small"
					fullWidth
					inputRef={refInputTotp}
					inputProps={{ autoComplete: 'off' }}
					onKeyDown={e => {
						if (e.keyCode === 13) {
							authTOTP(totp)
								.then(data => {
									if (form.remember) {
										Cookies.set('doc_id', form.userID, { expires: 365 });
									} else {
										Cookies.remove('doc_id');
									}

									history.push('/wc/main');
								})
								.catch(err => {
									if (err.error === commonTypes.SERVER_ERROR_TIMEOUT) {
										dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_3') }));
									} else if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
										dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_4') }));
									} else {
										dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_5') }));
									}
								});
						}
					}}
				/>
				<div className="btn-group">
					<Button
						variant="contained"
						className="defalut"
						aria-label="Paper OTP"
						type="button"
						onClick={e => {
							requestPaperPassword()
								.then(question => {
									setPaperPasswordObj({ open: true, question });
								})
								.catch(err => {
									if (err.error === commonTypes.SERVER_ERROR_NOT_EXISTS) {
										dispatch(showErrorMessage({ message: t('PRINT_PASSWORD_ERROR_MESSAGE_1') }));
									} else {
										dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_5') }));
										console.error(err);
									}
								});
						}}
					>
						{t('PRINT_PASSWORD')}
					</Button>
					<Button
						variant="contained"
						color="secondary"
						className=""
						aria-label="LOG IN"
						onClick={e => {
							authTOTP(totp)
								.then(data => {
									if (form.remember) {
										Cookies.set('doc_id', form.userID, { expires: 365 });
									} else {
										Cookies.remove('doc_id');
									}

									history.push('/wc/main');
								})
								.catch(err => {
									if (err.error === commonTypes.SERVER_ERROR_TIMEOUT) {
										dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_3') }));
									} else if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
										dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_4') }));
									} else {
										dispatch(showErrorMessage({ message: t('LOGIN_ERROR_MESSAGE_5') }));
									}
								});
						}}
						type="button"
					>
						{t('CONFIRM')}
					</Button>
				</div>
			</div>
		);
	}

	if (isMobile) {
		if (!sysConfigure.mobile_web_support) {
			return (
				<div className={classes.root}>
					<div className="">
						<FuseAnimate animation="transition.expandIn">
							<Card className="info-wrapper">
								<CardContent className="content">
									<img className="login-logo" src="assets/brand/logo_login.svg" alt="logo" />

									<p className="info-text">{t('DESKTOP_WEB_NOT_SUPPORT')}</p>
								</CardContent>
							</Card>
						</FuseAnimate>
					</div>
				</div>
			);
		}
	} else if (!sysConfigure.desktop_web_support && location.pathname !== '/admin') {
		return (
			<div className={classes.root}>
				<div className="">
					<FuseAnimate animation="transition.expandIn">
						<Card className="info-wrapper">
							<CardContent className="content">
								<img className="login-logo" src="assets/brand/logo_login.svg" alt="logo" />

								<p className="info-text">{t('DESKTOP_WEB_NOT_SUPPORT')}</p>
							</CardContent>
						</Card>
					</FuseAnimate>
				</div>
			</div>
		);
	}

	return (
		<div className={classes.root}>
			<div className="">
				<Card className="login-wrapper">
					<ChangeDialog
						open={openPasswordChange}
						curPassword={form.password}
						fnHandleClose={() => setOpenPasswordChange(false)}
						fnHandleComplete={() => {
							setOpenPasswordChange(false);
							drawTwoFactorAuth(formType);
							saveMobileLoginInfo();
						}}
					/>
					<CardContent className={clsx(isMobile ? classes.mbLoginCardContent : '', 'login-content')}>
						<img className="login-logo" src="assets/brand/logo_login.svg" alt="logo" />

						<p className="sub-title">{isWC ? '' : t('brand:PRODUCT_NAME_ADMIN')}</p>

						{loginForm}

						{!isMobile ? (
							<div className="client-download-wrapper">
								<div>
									<TextField
										select
										className=""
										value={verDownloadClient}
										variant="outlined"
										size="small"
										fullWidth
										onChange={e => setVerDownloadClient(e.target.value)}
									>
										<MenuItem value="-">{t('DOWNLOAD_CLIENT')}</MenuItem>
										<MenuItem value="online_win x86">Windows x86</MenuItem>
										<MenuItem value="online_win x64">Windows x64</MenuItem>
										{/* <MenuItem value="win x86">Windows x86</MenuItem>
										<MenuItem value="win x64">Windows x64</MenuItem> */}
										{/* <MenuItem value="mac">MAC(pkg)</MenuItem> */}
										<MenuItem value="online_mac_arm">MAC</MenuItem>
										<MenuItem value="online_mac_amd">MAC(Intel)</MenuItem>
									</TextField>
								</div>
								<div>
									<IconButton
										variant="contained"
										color="primary"
										className=""
										aria-label="Client downlaod"
										fullWidth
										type="button"
										onClick={e => {
											if (verDownloadClient === 'online_win x86') {
												window.open(
													'api/downloadClient?client_type=1&setup_type=2&setup_image_type=1',
													'_blank'
												);
											} else if (verDownloadClient === 'online_win x64') {
												window.open(
													'api/downloadClient?client_type=2&setup_type=2&setup_image_type=1',
													'_blank'
												);
											} else if (verDownloadClient === 'win x86') {
												window.open(
													'api/downloadClient?client_type=1&setup_type=1&setup_image_type=1',
													'_blank'
												);
											} else if (verDownloadClient === 'win x64') {
												window.open(
													'api/downloadClient?client_type=2&setup_type=1&setup_image_type=1',
													'_blank'
												);
											} else if (verDownloadClient === 'mac') {
												window.open(
													'api/downloadClient?client_type=3&setup_type=1&setup_image_type=1',
													'_blank'
												);
											} else if (verDownloadClient === 'online_mac_arm') {
												window.open(
													'api/downloadClient?client_type=7&setup_type=2&setup_image_type=1',
													'_blank'
												);
											} else if (verDownloadClient === 'online_mac_amd') {
												window.open(
													'api/downloadClient?client_type=6&setup_type=2&setup_image_type=1',
													'_blank'
												);
											} else {
												dispatch(showMessage({ message: t('DOWNLOAD_ERROR') }));
											}
										}}
									>
										<CustomIcon className="small" type="client-download" fill="#333d4b" />
									</IconButton>
								</div>
							</div>
						) : (
							<></>
						)}
					</CardContent>
				</Card>
			</div>
		</div>
	);
}

export default LoginPage;
