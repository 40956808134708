import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { DialogActions, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { closeDialog } from 'app/store/fuse/dialogSlice';

const useStyles = makeStyles({
	title: {
		color: '#000000',
		padding: 20,
		textAlign: 'center',
		'& .MuiTypography-h6': {
			fontSize: 16
		}
	},
	content: {
		color: '#404040',
		fontSize: 14,
		padding: '0px 20px',
		textAlign: 'center'
	},
	actions: {
		padding: 20,
		'& button': {
			width: '100%'
		}
	}
});

/**
 * openDialog로 팝업시 사용하기 위한 form. 요청하는 곳마다 ui를 통일하기위하여 분리시킴
 *
 * @param {*} props
 * @returns
 */
export default function OpenDialogForm(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation('docbase');

	return (
		<>
			<DialogTitle className={classes.title}>{props.title}</DialogTitle>
			<DialogContent className={classes.content}>{props.content}</DialogContent>
			<DialogActions className={classes.actions}>
				<Button className="default" onClick={() => dispatch(closeDialog())}>
					{t('NO')}
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						dispatch(closeDialog());
						props.fnHandleOK();
					}}
					color="secondary"
					autoFocus
				>
					{t('YES')}
				</Button>
			</DialogActions>
		</>
	);
}
