import FuseAnimate from '@fuse/core/FuseAnimate';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import securityUtils from 'app/utils/SecurityUtils';
import { showErrorMessage } from 'app/store/fuse/messageSlice';
import { getWeblinkInfo, secureRequest } from 'app/store/docbase/commonAction';

const useStyles = makeStyles(theme => ({
	root: {
		background: `radial-gradient(${darken(theme.palette.primary.dark, 0.5)} 0%, ${theme.palette.primary.dark} 80%)`,
		color: theme.palette.primary.contrastText
	}
}));

function WeblinkPage({ history, location }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation(['docbase', 'brand']);

	const urlParam = new URLSearchParams(location.search);
	const [password, setPassword] = React.useState('');
	const [isPassword, setIsPassword] = React.useState(true);
	const [errMessage, setErrMessage] = React.useState('');

	useEffect(() => {
		init(false);
	}, []);

	function init(reload) {
		getWeblinkInfo(urlParam.get('id')).then(respData => {
			setIsPassword(respData.password_protected);
			if (respData.limit_downloads > 0 && respData.remain_downloads <= 0) {
				setErrMessage(t('WEBLINK_ERROR_MESSAGE_1'));
				setIsPassword(false);
			} else if (respData.status !== 1) {
				if (respData.status === 6) {
					setErrMessage(t('WEBLINK_ERROR_MESSAGE_4'));
				} else {
					setErrMessage(t('WEBLINK_ERROR_MESSAGE_2'));
				}
				setIsPassword(false);
			} else if (new Date().getTime() > new Date(respData.expired_at).getTime() + 86400000) {
				//  86400000 하루의 getTime() 값
				setErrMessage(t('WEBLINK_ERROR_MESSAGE_3'));
				setIsPassword(false);
			} else if (!respData.password_protected && !reload) {
				doDownload();
			}
		});
	}
	function doDownload() {
		secureRequest((aes, tk) => {
			const a = document.createElement('a');
			let url = `${window.location.origin}/api/downloadWeblink?id=${urlParam.get('id')}&token=${tk}`;
			if (isPassword) {
				url += `&password=${securityUtils.encryptAES(aes, password, 'utf8', 'hex')}`;
			}
			a.href = url;
			// a.setAttribute('target', '_blank'); //or any other extension
			document.body.appendChild(a);
			a.click();

			setTimeout(_ => {
				init(true);
			}, 300);
		});
	}

	function submitHandler(e) {
		if (password.trim().length === 0) {
			dispatch(showErrorMessage({ message: t('USER_ERROR_MESSAGE_5') }));
		} else {
			doDownload();
		}

		e.stopPropagation();
		e.preventDefault();
	}
	return (
		<div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>
			<div className="flex flex-col items-center justify-center w-full">
				<FuseAnimate animation="transition.expandIn">
					<Card className="w-full max-w-384 rounded-8">
						<CardContent className="flex flex-col items-center justify-center p-32">
							<img className="w-128" src="assets/brand/logo.svg" alt="logo" />

							<Typography variant="h6" className="mt-16 mb-16 font-bold">
								{t('brand:WEBLINK_TITLE')}
							</Typography>

							{errMessage !== '' ? <p style={{ fontSize: 12, color: '#e91414' }}>{errMessage}</p> : <></>}
							{isPassword ? (
								<form
									name="loginForm"
									noValidate
									className="flex flex-col justify-center w-full"
									onSubmit={e => {
										submitHandler(e);
									}}
								>
									<TextField
										className="mb-16"
										label={t('PASSWORD')}
										type="password"
										name="password"
										variant="outlined"
										size="small"
										required
										value={password}
										onChange={e => setPassword(e.target.value)}
										fullWidth
									/>

									<Button
										variant="contained"
										color="primary"
										className="w-full mx-auto"
										aria-label="download"
										onClick={e => {
											submitHandler(e);
										}}
									>
										{t('DOWNLOAD')}
									</Button>
								</form>
							) : (
								<></>
							)}
						</CardContent>
					</Card>
				</FuseAnimate>
			</div>
		</div>
	);
}

export default WeblinkPage;
