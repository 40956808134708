import React from 'react';
import MobileDetect from 'mobile-detect';
import { Redirect } from 'react-router-dom';
import i18n from 'i18n';
import i18next from 'i18next';
import FuseUtils from '@fuse/utils';
import LoginConfig from 'app/main/login/LoginConfig';
import RegisterConfig from 'app/main/register/RegisterConfig';
import UsersConfig from 'app/main/users/UsersConfig';
import OrgsConfig from 'app/main/orgs/OrgsConfig';
import SharedsConfig from 'app/main/shareds/SharedsConfig';
import RestoreConfig from 'app/main/restore/RestoreConfig';
import SettingsConfig from 'app/main/settings/SettingsConfig';
import WeblinkConfig from 'app/main/weblink/WeblinkConfig';
import SecureDocsConfig from 'app/main/secureDocs/SecureDocsConfig';
import DashboardConfig from 'app/main/dashboard/DashboardConfig';
import RegProductConfig from 'app/main/regProduct/RegProductConfig';
import FileManagerAppConfig from 'app/main/file-manager/FileManagerAppConfig';
import LogsConfig from 'app/main/statisticLog/StatisticLogConfig';
import OpenContentSiteConfig from 'app/main/contentSite/OpenContentSiteConfig';
import CollectorConfig from 'app/main/collector/CollectorConfig';
import PiiDocsConfig from 'app/main/piiDocs/PiiDocsConfig';
import DupDocsConfig from 'app/main/dupDocs/DupDocsConfig';
import OsrProcessesConfig from 'app/main/osr/OsrProcessesConfig';
import WhiteProcessesConfig from 'app/main/whiteProcesses/WhiteProcessesConfig';
import AuditLogsConfig from 'app/main/auditLogs/LogsConfig';
import AccessLogsConfig from 'app/main/accessLogs/LogsConfig';
import LogoutConfig from 'app/main/logout/LogoutConfig';
import RansomConfig from 'app/main/ransom/RansomConfig';
import TakeoutsConfig from 'app/main/takeouts/TakeoutsConfig';
import HoldFilesConfig from 'app/main/holdFiles/HoldFilesConfig';
import SetupInfoPageConfig from 'app/main/setupInfo/SetupInfoPageConfig';
import ExportApprovalConfig from 'app/main/exportApproval/ExportApprovalConfig';
import InstantLogsConfig from 'app/main/instantLogs/LogsConfig';
import ClientRunningLogsConfig from 'app/main/clientRunningLogs/LogsConfig';
import DLPClientPageConfig from 'app/main/dlp/client/DLPClientPageConfig';
import PolicySetupPageConfig from 'app/main/dlp/policySetup/PolicySetupPageConfig';
import ReleaseBlockingsConfig from 'app/main/dlp/releaseBlocking/ReleaseConfig';
import PrintLogConfig from 'app/main/dlp/printLog/PrintLogConfig';
import ClientAccessLogConfig from 'app/main/dlp/clientAccessLog/ClientAccessLogConfig';
import ClientMonitoringLogConfig from 'app/main/dlp/clientMonitoringLog/ClientMonitoringLogConfig';
import GroupsConfig from 'app/main/dlp/groups/GroupsConfig';
import dlpClientAlertLog from 'app/main/dlp/clientAlertLog/ClientAlertLogConfig';
import OfflineDriveConfig from 'app/main/offlineDrive/OfflineDriveConfig';
import OfflineTakeoutsConfig from 'app/main/offlineTakeoutLogs/LogsConfig';

import ko from 'app/main/i18n/ko';
import en from 'app/main/i18n/en';
import brandKo from 'app/main/i18n/brand_ko';
import brandEn from 'app/main/i18n/brand_en';

i18next.addResourceBundle('ko', 'docbase', ko);
i18next.addResourceBundle('en', 'docbase', en);
i18next.addResourceBundle('ko', 'brand', brandKo);
i18next.addResourceBundle('en', 'brand', brandEn);

const routeConfigs = [
	LoginConfig,
	RegisterConfig,
	UsersConfig,
	OrgsConfig,
	SharedsConfig,
	RestoreConfig,
	SettingsConfig,
	WeblinkConfig,
	SecureDocsConfig,
	PiiDocsConfig,
	DupDocsConfig,
	WhiteProcessesConfig,
	DashboardConfig,
	RegProductConfig,
	FileManagerAppConfig,
	LogsConfig,
	AccessLogsConfig,
	AuditLogsConfig,
	OpenContentSiteConfig,
	CollectorConfig,
	OsrProcessesConfig,
	LogoutConfig,
	RansomConfig,
	TakeoutsConfig,
	HoldFilesConfig,
	SetupInfoPageConfig,
	ExportApprovalConfig,
	InstantLogsConfig,
	ClientRunningLogsConfig,
	DLPClientPageConfig,
	PolicySetupPageConfig,
	ReleaseBlockingsConfig,
	PrintLogConfig,
	ClientAccessLogConfig,
	GroupsConfig,
	ClientMonitoringLogConfig,
	dlpClientAlertLog,
	OfflineDriveConfig,
	OfflineTakeoutsConfig
];

let lang = sessionStorage.getItem('coodoc-language');
if (lang === null || lang.length === 0) {
	//	기본은 브라우저를 따라가고싶지만. 우선은 한글로 지원
	// lang = window.navigator.language;
	lang = 'ko';
}

console.log(lang);
const md = new MobileDetect(window.navigator.userAgent);

if (window.ReactNativeWebView || md.mobile()) {
	sessionStorage.setItem('device', 'mobile');
}

i18n.changeLanguage(lang).then(() => {});
document.title = brandKo.BROWSER_TITLE;
const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/',
		component: () => <Redirect to="/login" />
	},
	{
		path: '/admin',
		component: () => <Redirect to="/admin" />
	}
];

export default routes;
