import { makeStyles } from '@material-ui/core/styles';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	tr: {
		'& .delete': {
			width: 100
		},
		'& .approve': {
			width: 100
		},
		'& .process_type': {
			width: 140
		}
	}
}));

function WhiteProcessesTableHead(props) {
	const classes = useStyles();
	const { t } = useTranslation('docbase');

	const headerCols = [
		{
			id: 'whiteprocess_name',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('NAME'),
			sort: false
		},
		{
			id: 'program_rename',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('WHITE_PROCESS_PROGRAM_NAME'),
			sort: false
		},
		{
			id: 'verify_type',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('WHITE_PROCESS_VERIFY_TYPE'),
			sort: false
		},
		{
			id: 'process_type',
			align: 'left',
			className: 'left process_type',
			disablePadding: false,
			label: t('WHITE_PROCESS_TYPE'),
			sort: false
		},
		{
			id: 'approved',
			align: 'center',
			className: 'approve',
			disablePadding: false,
			label: t('TAKEOUT_APPROVED'),
			sort: false
		},
		{
			id: 'regdate',
			align: 'center',
			className: 'table_col_date',
			disablePadding: false,
			label: t('REGIST_DATE'),
			sort: false
		},
		{
			id: 'process_delete',
			align: 'center',
			className: 'delete',
			disablePadding: false,
			label: t('DELETE'),
			sort: false
		}
	];

	return (
		<TableHead>
			<TableRow className={classes.tr}>
				{headerCols.map(row => {
					return (
						<TableCell
							className={row.className}
							key={row.id}
							align={row.align}
							padding={row.disablePadding ? 'none' : 'default'}
							sortDirection={false}
						>
							{row.label}
						</TableCell>
					);
				}, this)}
			</TableRow>
		</TableHead>
	);
}

export default WhiteProcessesTableHead;
