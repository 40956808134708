const locale = {
	CONFIRM: 'OK',
	USE: 'Enable',
	UNUSED: 'Disable',
	APPLY: 'Apply',
	UNAPPLIED: 'Cancel',
	CANCEL: 'Cancel',
	ADD: 'Add',
	CHANGE: 'Modify',
	DELETE: 'Delete',
	REMOVE: 'Remove',
	MOVE: 'Move',
	YES: 'Yes',
	NO: 'No',
	CLOSE: 'Close',
	MODIFY: 'Modify',
	TIME: 'Time',
	PERMIT: 'Allow',
	BLOCK: 'Block',
	UNSELECTED: 'Unselected',
	MONTH: '월',
	DAY: '일',
	HOUR: '시',
	MINUTE: '분',
	SEC: 'Second',
	CHECK_ALL: 'Check all',
	NOTE: 'Ref.',
	IP_ADDRESS: 'IP',
	LOGIN_ERROR_MESSAGE_1: 'Wrong ID or password',
	LOGIN_ERROR_MESSAGE_2: 'Authentication has failed',
	LOGIN_ERROR_MESSAGE_3: 'Timeout the input period',
	LOGIN_ERROR_MESSAGE_4: 'Registered already',
	LOGIN_ERROR_MESSAGE_5: 'Wrong input',
	LOGIN_ERROR_MESSAGE_6: 'Only the administrator can connect to it',
	TOTP_SETTING: 'Setup TOTP',
	TOTP_AUTH: 'Enable TOTP authentication',
	TOTP_AUTH_USED: 'TOTP authentication has enabled',
	TOTP_SETTINGKEY: '설정키',
	TOTP_VALUE: 'The value of TOTP',
	TOTP_MESSAGE_1: 'Do you want to enable TOTP authentication as a 2-factor authentication?',
	TOTP_MESSAGE_2: 'Timeout the input period',
	LOGOUT: 'Log off',
	LOGOUT_MESSAGE_1: 'Do you want to log off now?',
	ID: 'ID',
	PASSWORD: 'Password',
	NAME: 'Name',
	EMAIL: 'Email',
	PASSWORD_CONFIRM: 'Confirmation password',
	PASSWORD_AUTO: '암호 자동생성',
	ACCESS_LEVEL: 'Access level',
	LEVEL: 'Level',
	CREATE: 'Creation',
	REMEMBER_ME: 'Save an ID',
	REMEMBER_PW: 'Save a password',
	LOGIN: 'Login',
	PRINT_PASSWORD: 'Paper OTP',
	PRINT_PASSWORD_AUTH: 'Paper OTP authentication',
	PRINT_PASSWORD_CONTEXT:
		"Input 3 values continually without a white space which are placed at '%s' on the paper OTP. The paper OTP can be used only for a day when you can't use TOTP and you can get it through the administrator",
	PRINT_PASSWORD_ERROR_MESSAGE_1: 'There is no paper OTP has been issued. Contact the administrator',
	PRINT_PASSWORD_ERROR_MESSAGE_2: 'Wrong paper OTP',
	CREATE_ADMIN: 'Create an administrator',
	SERVER_ENCRYPT: 'Encrypt files on the server side',
	LANGUAGE: 'Language',
	REGISTER_ERROR_MESSAGE_1: 'Registration has failed',
	REGISTER_PRODUCT: 'Product Registration',
	REGISTER: 'Register',
	ADMIN_ERROR_MESSAGE_1: 'Registration of the administrator has failed',
	ADMIN_ERROR_MESSAGE_2: 'There is no administrator has been registered',
	SHARED_FOLDER: 'The shared folder (Shared Folder)',
	ORG_FOLDER: 'The organization folder (Organization Folder)',
	PRIVATE_FOLDER: 'The private folder (Private Folder)',
	COLLECTOR_FOLDER: 'The collect folder (Collect Folder)',
	ORGSHARED_FOLDER: 'The shared folder in the organization (Shared in Organization Folder)',
	ORGSHARED: '',
	ETC: '',
	FOLDER_NAME: "Folder's name",
	USER: 'User',
	USER_TITLE: 'User',
	USER_ADD: 'Add an user (Add User)',
	USER_SELECT: 'Select an user (Select User)',
	ORG_TITLE: 'Organization',
	ORG_ADD: 'Add an organization (Add Organization)',
	ORG_NAME: "Organization's name",
	ORG_TOP: "The path of the organization (Organization's path)",
	SHARED_TITLE: 'Shared',
	SECUREDOC_TITLE: 'Secure Document',
	PERM_NO_FOLDER: 'There is no folder that can be added',
	PERM_NO_USER: 'There is no user that can be added',
	PERM_FOLDER_INPUT: 'Add the folder permission (Add Folder Permission)',
	PERM_USER_INPUT: 'Add the user permission',
	PERM_READ: 'Read only',
	PERM_READ_WRITE: 'Write only',
	PERM_TRASH: 'Purge the trash',
	PERM_TRASH_POSSIBLE: 'Allow purging of the trash',
	PERM_TRASH_IMPOSSIBLE: 'Disallow purging of the trash',
	PERM_NONE: 'Hidden',
	PERM_NOT_NONE: 'Visible',
	PERM_DELETE: 'Remove a permission',
	PERM_INPUT_TEXT: 'Search the users',
	PERM_FOLDER_TITLE: 'Add the folder permission',
	NO_USER: 'There is no user',
	USER_TOTP_TITLE: "Reset the user's TOTP",
	USER_TOTP_CONTENT: "Do you want to reset the user's TOTP?",
	DELETE_FOLDER_TITLE: 'Remove the folder',
	DELETE_CONTENT: 'Do you want to remove',
	MULTIPLE_LOGINS: '중복로그인',
	USER_DELETE_CONTENT_1: 'The private and collect folder of the user who will be removed might be moved to the specific user',
	USER_DELETE_CONTENT_2:
		"Select the user who will be received the private and collect folder of the user who will be removed. (The folders and the files will be removed totally if you don't select the user)",
	USER_MENU_1: 'Reset the password',
	USER_MENU_2: 'Reset the paper OTP',
	USER_MENU_3: 'Release the TOTP',
	USER_MESSAGE_1: 'The user has added',
	USER_MESSAGE_4: 'The name has changed',
	USER_MESSAGE_5: 'The email has changed',
	USER_MESSAGE_6: 'The access level has changed',
	USER_MESSAGE_7: 'The password has been reset',
	USER_MESSAGE_8: 'The TOTP has been reset',
	USER_MESSAGE_9: 'The user has removed',
	USER_MESSAGE_10: 'It has been ruled out from the HR Information system sync',
	USER_MESSAGE_11: 'It has been included in the HR Information system sync',
	USER_MESSAGE_12: 'The manual register user has been modified',
	USER_MESSAGE_13: '중복 로그인을 변경하였습니다',
	USER_MESSAGE_14: '개인폴더 반출 정책을 변경하였습니다',
	USER_MESSAGE_15: '조직폴더 반출 정책을 변경하였습니다',
	USER_MESSAGE_16: '계정 잠금을 변경하였습니다',
	USER_ERROR_MESSAGE_1: "The administrator can't be removed",
	USER_ERROR_MESSAGE_2: 'Removing has failed',
	USER_ERROR_MESSAGE_3: 'Input an ID',
	USER_ERROR_MESSAGE_4: 'Input the name',
	USER_ERROR_MESSAGE_5: 'Input the password',
	USER_ERROR_MESSAGE_6: 'Input the confirmation password',
	USER_ERROR_MESSAGE_7: 'The two passwords are mismatched',
	USER_ERROR_MESSAGE_8: 'The ID has owned by others',
	USER_ERROR_MESSAGE_9: 'The ID must have equal or less than 30 characters',
	USER_ERROR_MESSAGE_10: 'Not enough user licenses',
	USER_ERROR_MESSAGE_11: 'The name must have equal less than 30 characters',
	USER_ERROR_MESSAGE_12: 'Select the user',
	USER_ERROR_MESSAGE_13: 'The product has expired',
	USER_ERROR_MESSAGE_14: 'Select the folder',
	USER_ERROR_MESSAGE_16: 'Resetting the password has failed',
	USER_ERROR_MESSAGE_17: 'Issuing the paper OTP has failed',
	USER_ERROR_MESSAGE_18: 'Resetting the TOTP has failed',
	USER_ERROR_MESSAGE_19: "The user can't be removed if it's being used",
	USER_ERROR_MESSAGE_20: 'Select the manual register user',
	PATTERN_ERROR_MESSAGE: "\\', '\"', '/', ':', '?', '*', '<', '>', '|' are not allowed",
	RESET_PASSWORD_TITLE: 'Modifying the password of %s',
	RESET_PASSWORD_CONTENT:
		'Resetting the password. The user must log in with the password that has been reset and modify it to the new password',
	RESET_PASSWORD_ERROR_MESSAGE_1: 'Input the new password',
	RESET_PASSWORD_ERROR_MESSAGE_2: 'Input the new confirmation password',
	RESET_PASSWORD_ERROR_MESSAGE_3: 'The two passwords are mismatched',
	RESET_NEW_PASSWORD: 'New password',
	RESET_NEW_PASSWORD_CONFRIM: 'New confirmation password',
	PERM_MESSAGE_1: 'The permission has modified',
	PERM_MESSAGE_2: 'The permission has removed',
	PERM_ERROR_MESSAGE_1: 'Modifying the permission has failed',
	PERM_ERROR_MESSAGE_2: 'Removing the permission has failed',
	PERM_ERROR_MESSAGE_3: 'Select the folder',
	PERM_ERROR_MESSAGE_4: "There has duplicated administrator's priority already",
	SHARED_MESSAGE_1: 'The shared folder has removed',
	ORG_MESSAGE_1: 'The organization has removed',
	ORG_ERROR_MESSAGE_1: 'Removing the organization has failed',
	ORG_ERROR_MESSAGE_2: 'Select the organization',
	SHARED_ERROR_MESSAGE_1: 'Removing the shared folder has failed',
	SHARED_ERROR_MESSAGE_2: 'The shared folder has added',
	FOLDER_ERROR_MESSAGE_1: 'Input the name of the folder',
	FOLDER_ERROR_MESSAGE_2: 'There has a same folder name already',
	FOLDER_ERROR_MESSAGE_3: 'Input it has equal or less than 30 characters',
	FOLDER_ERROR_MESSAGE_4: "You can't set permission to the top-level folder",
	FOLDER_ERROR_MESSAGE_5: "The only user who registered the organization's shared folder can release",
	RESTORE_TEMP: 'Temporary restoring',
	RESTORE_TEMP_PROCESSING: 'Restoring as temporary',
	RESTORE_TEMP_FINISH: 'Finished temporary restore',
	RESTORE_TITLE: 'Do you want to start restoring?',
	RESTORE_MESSAGE_1: 'Modified things in today will be revoked',
	RESTORE_MESSAGE_2: 'Do you want to stop the temporary restoring?',
	RESTORE_MESSAGE_3: "Type 'Start Restoring' and push OK if you want to start restoring",
	RESTORE_MESSAGE_4: 'Mofiied things after the selected time will be revoked',
	RESTORE_MESSAGE_5: 'Do you want to revoke modified things after the selected time that you selected?',
	RESTORE_MESSAGE_6: 'Select one of the temporary restoring or restore',
	RESTORE_MESSAGE_7: "Can't modify while restoring",
	RESTORE_MESSAGE_8: 'The system is restoring as temporarily in progress',
	RESTORE_MESSAGE_9: 'The time to revoke',
	RESTORE_ERROR_MESSAGE_1: 'Input the confirmation text',
	RESTORE_ERROR_MESSAGE_2: 'Wrong confirmation text',
	RESTORE_ERROR_MESSAGE_3: 'The temporary restoring mode is ambiguous',
	RESTORE_ERROR_MESSAGE_4: 'Executing the temporary restoring has failed',
	RESTORE_ERROR_MESSAGE_5: 'Finishing the temporary restoring has failed',
	RESTORE_ERROR_MESSAGE_6: 'The restore mode is ambiguous',
	RESTORE: 'Restore',
	RESTORE_SUB_TITLE: 'The time of restoration',
	RESTORE_MODE: 'Restore mode',
	RESTORE_MODE_TODAY: 'Today',
	RESTORE_MODE_HOUR: 'Hour',
	RESTORE_MODE_DAY: 'Day',
	RESTORE_HOUR: 'Hours of restoration',
	RESTORE_NOW: 'Last recent',
	RESTORE_HOUR_BEFORE: 'hour(s) before',
	RESTORE_TODAY: 'Revoking what modified things at today',
	RESTORE_ONE_TIME: 'Revoking modified things after a specific time',
	RESTORE_DAY: 'Period',
	RESTORE_ONE_DAY: 'Date',
	RESTORE_TEMP_CHANGING: 'Processing a temporary restore file in progress',
	RESTORE_CHANGING: 'Processing',
	SECUREDOC: 'The secure document',
	SECUREDOC_READFILE: 'A document for reading',
	SECUREDOC_RELEASE_TITLE: 'Release a secure document',
	SECUREDOC_RELEASE_CONTENT: 'Do you want to release the secure document?',
	SECUREDOC_DOCID: 'Document No.',
	SECUREDOC_TYPE: 'Document type',
	SECUREDOC_PATH: 'Document',
	SECUREDOC_MESSAGE_1: 'The accessible users of the secure document have been modified',
	SECUREDOC_MESSAGE_2: 'The access level of the secure document has been modified',
	SECUREDOC_MESSAGE_3: 'The secure document has been released',
	SECUREDOC_ERROR_MESSAGE_1: 'Modifying information of the secure document has failed',
	SECUREDOC_ERROR_MESSAGE_2: 'Releasing the secure document has failed',
	SERVER_SETTING: 'Miscellaneus',
	SETTING: 'Setting',
	SAVED: 'Saved',
	FACTORY_RESET: 'Factory Reset',
	RESET: 'Resetting',
	FACTORY_RESET_CONFIRM: 'Do you want to perform the factory reset?',
	FACTORY_RESET_MESSAGE: 'We will move to the page of creating an administrator after performing the factory reset.',
	FACTORY_RESET_FAILED: 'Factory resetting has failed',
	DEFAULT_SETTING: 'Basic',
	WEBLINK_SETTING: 'Web-link',
	WEBLINK_USED: 'Enable the web-link service',
	WEBLINK_SERVICE_PORT: 'Web-link service port',
	WEBLINK_SERVICE_ADDR: 'Web-link service address',
	FOLDER_SIZE_SETTING: 'Storage size of each folder',
	PRIVATEFOLDER_SIZE: 'Storage size of the private folder',
	COLLECTFOLDER_SIZE: 'Storage size of the collect folder',
	SHAREDFOLDER_SIZE: 'Storage size of the shared folder',
	ORGFOLDER_SIZE: 'Storage size of the organization folder',
	FOLDER_SIZE_HELP_TEXT: "Unlimited if it's 0",
	COLLECT_GLOBAL_SETTING: 'Collector',
	COLLECT_PRIVATE_SETTING: 'Collection for each user',
	COLLECT_USE: 'Enable collecting',
	COLLECT_USED: 'Enabled collecting',
	COLLECT_NOT_USE: 'Disabled collecting',
	COLLECT_USE_FULL_SCAN: 'Collecting the legacy files',
	COLLECT_USE_FULL_SCAN_MANAGEMENT: 'Manage of collecting the legacy files',
	COLLECT_USED_FULL_SCAN: 'Enable collecting the legacy files',
	COLLECT_NOT_USED_FULL_SCAN: 'Disable collecting the legacy files',
	COLLECT_USE_REMOVE_SOURCE: 'Move the documents',
	COLLECT_USED_REMOVE_SOURCE: 'Enable move the documents',
	COLLECT_NOT_USED_REMOVE_SOURCE: 'Disable move the documents',
	COLLECT_SYNC_REMOVE: 'Enable the removed files sync',
	COLLECT_PATH_SETTING: 'Collecting path',
	INCLUSIVE: 'Method of including paths',
	EXCLUSIVE: 'Method of excluding paths',
	COLLECT_EXCLUSIVE_EXT_SETTING: '',
	COLLECT_EXCLUSIVE_EXT_SETTING_HELP_TEXT:
		'The extensions of collecting target files that are registered by the method of including the file extension will be used to detect ransomware. You have to set the file extensions up specially to detect ransomware when you use the method of excluding the file extension.',
	INCLUSIVE_EXT: 'Method of including the file extension',
	EXCLUSIVE_EXT: 'Method of excluding the file extension',
	COLLECT_EXCLUSIVE_EXT: 'File extensions to skip',
	COLLECT_EXT: 'File extensions to collect',
	RANSOMWARE_EXT: 'File extensions to detect ransomware',
	COLLECT_IGNORE: 'Paths to skip',
	COLLECT_INCLUSIVE: 'Paths to collect',
	COLLECT_MESSAGE_1: 'The setting has applied',
	COLLECTOR_TRANSFER_SIZE: 'Transfer size of collecting',
	COLLECTOR_INTERVAL_TRANSFER: 'Interval period per transfer',
	COLLECTOR_INTERVAL_TRANSFER_HELP_TEXT:
		"The collected file will be uploaded by the uploader. The uploader will be paused as long as the 'interval period' after uploading as much as the 'transfer size'",
	COLLECT_ALL_USE: 'Enable collecting for everybody',
	COLLECT_ALL_UNUSED: 'Disable collecting for everybody',
	COLLECT_ALL_APPLY: 'Apply to everybody',
	COLLECT_ALL_UNAPPLIED: '??',
	COLLECT_FULL_SCAN_STATUS: 'Status of collecting legacy files',
	COLLECT_FULL_SCAN_RESTART: 'Restart collecting legacy files',
	COLLECT_FULL_SCAN_PRESCANNED_FOLDERS: 'Prescanned folders',
	COLLECT_FULL_SCAN_PENDING_FOLDERS: 'Pending folders',
	COLLECT_FULL_SCAN_PENDING_FILES: 'Pending files',
	COLLECT_FULL_SCAN_REQUEST_AT: 'Requested date to restart collecting',
	COLLECT_FULL_PRESCAN_FINISHED_AT: 'Finished time to prescann',
	COLLECT_FULL_SCAN_FINISHED_AT: 'Finished time to scan',
	COLLECT_FULL_SCAN_STATUS_NONE: 'Idle',
	COLLECT_FULL_SCAN_STATUS_STARTED: 'Scanning',
	COLLECT_FULL_SCAN_STATUS_PRESCANED: 'Collecting',
	COLLECT_FULL_SCAN_STATUS_FINISHED: 'Finished',
	EXTERNAL_SETTING: 'Incoming Connection',
	EXTERNAL_USED: 'Enable incoming connection',
	EXTERNAL_SERVERNAME: 'The server name for the incoming connection',
	EXTERNAL_MESSAGE: 'The incoming connection has been set up',
	EXTERNAL_ERROR_MESSAGE_1: 'Only an english and numeric are available',
	EXTERNAL_ERROR_MESSAGE_2: 'Input the server name for the incoming connection',
	EXTERNAL_ERROR_MESSAGE_3: "The server name can't be modified when the incoming connection has been disabled",
	EXTERNAL_ERROR_MESSAGE_4: 'Setting the incoming connection has failed',
	EXTERNAL_ERROR_MESSAGE_5: 'The server name is being used already',
	EXTERNAL_ERROR_MESSAGE_6: 'Nothing has been changed',
	SETTING_BACKUP_KEEPDAY: 'Period to keep revision files',
	SETTING_DEFAULT_VOLUME_LABEL: 'Default volume label',
	SETTING_SAMBA_PORT_USE: 'Using the default SAMBA port',
	SETTING_SAMBA_PORT_USE_HINT:
		'Whether using 445 port or not. It should be enabled if there has a user to use sharing the local file.',
	SETTING_TWO_FACTOR_AUTH: 'Force to enable 2-factor authentication',
	SETTING_DISABLE_TWO_FACTOR_AUTH: 'Disable the 2-factor authentication',
	SETTING_NO_SHELL_MENU: 'Hide the context menu',
	SETTING_STRONG_PASSWORD: 'Enable the strong password',
	SETTING_STRONG_PASSWORD_HINT:
		"It let the user can't use a simple password. If it has been enabled then a password should have equal or more than 8 characters and also includes at least one capital letter, lowercase letter, numeric, and a special character each",
	SETTING_UPDATE: 'Update',
	SETTING_UPDATE_MESSAGE_1:
		'The update file has been registered. If the file is the server file, the server will be restarted, and after that, it will be applied.',
	SETTING_UPDATE_ERROR_MESSAGE_1: 'Invalid the update file',
	SETTING_UPDATE_ERROR_MESSAGE_2: 'This is the lower version than the current',
	SETTING_UPDATE_ERROR_MESSAGE_3: 'Updating has been failed',
	SETTING_PII_DETECT: 'Detecting PII(Personal Identifiable Information)',
	SETTING_PII_DETECT_ENABLE: 'Enable detecting PII',
	SETTING_PII_DETECT_OCR: 'Support OCR',
	SETTING_DUP_DETECT_ENABLE: 'Enable detecting the duplicated files',
	SETTING_RANSOMWARE_DETECT_USE: 'Enable detecting ransomware',
	SETTING_SECURE_DOCUMENT_USE: 'Support the secure document',
	SETTING_MIN_SMB_VERSION: 'Support minimum SMB protocol version',
	SETTING_SMB_ENCRYPTION: 'Enable SMB packet encryption',
	SETTING_SMB_ENCRYPTION_HELP_TEXT:
		'It makes the system use an encrypted SMB packet. It prevents leak data especially in a not secured environment outside, unlike a VPN but the transferring speed will be slowed slightly.',
	DOWNLOAD: 'Download',
	DOWNLOAD_CLIENT: 'Download a client',
	EXPIRE_AT: 'Expire date',
	LICENSES_TITLE: '라이센스 정보',
	USER_LICENSES: 'Users',
	MAINTENANCE_AT: '유지보수 기한',
	PRODUCTKEY_STATE: 'Status of the product key',
	INVALID: 'Abnormal',
	VALID: 'Normal',
	SEEDKEY: 'Seed-key info.',
	COPY: 'Copy',
	SEEDKEY_COPY: 'Copy the seed-key',
	WEBLINK_ERROR_MESSAGE_1: 'Exceed the limit count of download',
	WEBLINK_ERROR_MESSAGE_2: "The download link didn't activated",
	WEBLINK_ERROR_MESSAGE_3: 'The download has expired',
	WEBLINK_ERROR_MESSAGE_4: 'Invalid download link. Check the URL',
	WEBLINK_ERROR_MESSAGE_5: 'Input the password',
	WEBLINK_ERROR_MESSAGE_6: 'Input the confirmation password',
	WEBLINK_ERROR_MESSAGE_7: 'Two password are mismatched',
	WEBLINK_ERROR_MESSAGE_8: 'Input the limit count of download',
	WEBLINK_ERROR_MESSAGE_9: 'Input the limit count of download as a number',
	WEBLINK_ERROR_MESSAGE_10: '웹 링크를 만들 파일을 찰을 수 없습니다',
	NAME_CHANGE: 'Change the name',
	ORG_CHANGE_MESSAGE: 'Do you want to change the name of the organization?',
	SHARED_CHANGE_MESSAGE: 'Do you want to change the name of the shared folder?',
	TRANSFER: 'Moving documents',
	MOVE_ORG_DATA_TITLE: 'Moving data',
	SOURCE_ORG_NAME: 'Original organization folder',
	TARGET_ORG_NAME: 'Target organization folder',
	MOVE_ORG_DATA_MESSAGE_1: 'Do you want to move the data of the organization folder?',
	MOVE_ORG_DATA_MESSAGE_2: 'The data has been moved',
	MOVE_ORG_DATA_ERROR_MESSAGE_1: 'The original and target organization are same',
	MOVE_ORG_DATA_ERROR_MESSAGE_2: 'Moving data has been failed',
	RESTRICT_TITLE: 'The files prevented to saving',
	RESTRICT_SIZE_TITLE: 'Restrict size',
	RESTRICT_EXT_TITLE: 'Restrict extensions',
	RESTRICT_EXT_HELP_TEXT: 'You can set multiple extensions separated by ""',
	RESTRICT_EXT_REMOVE: 'Prevent saving by the extensions',
	RESTRICT_EXT_REMOVE_HELP_TEXT:
		"If it activated, the files that were restricted by its extensions occurred errors immediately when they tried to be saved. If it doesn't activate, the files are allowed to save but they will be removed by the batch process",
	CURRENT_PASSWORD: 'The current password',
	NEW_PASSWORD: 'The new password',
	CHANGE_PASSWORD: 'Change the password',
	SHUTDOWN: 'Exit',
	SYSTEM_SHUTDOWN: 'Shutdown the system',
	SHUTDOWN_MESSAGE_1: 'Do you want to shutdown the system?',
	SHUTDOWN_MESSAGE_2: 'The system has been shutdown',
	NO_USED: 'No activated',
	USAGE: 'Usage',
	GROUP_USAGE: 'Usage per group',
	REMAINING_CAPACITY: 'Storage remained',
	TOTAL_CAPACITY: 'Total storage',
	ALL: 'All',
	DETAIL: 'Details',
	ACCESS_TYPE: 'Access types',
	ACCESS_TYPE_CREATE: 'Created',
	ACCESS_TYPE_READ: 'Read',
	ACCESS_TYPE_MOVE: 'Moved',
	ACCESS_TYPE_UPDATE: 'Updated',
	ACCESS_TYPE_HOLD: 'Hold',
	ACCESS_TYPE_REMOVE: 'Removed',
	ACCESS_TYPE_REMOVE_TRASH: 'Trashing',
	ACCESS_TYPE_RECOVERY_TRASH: 'Recovery trash',
	ACCESS_TYPE_PURGE_TRASH: 'Purge trash',
	ACCESS_TYPE_VIEWING: 'Viewed',
	ACCESS_TYPE_WEB_CREATE: 'Upload',
	ACCESS_TYPE_WEB_DOWNLOAD: 'Download',
	ACCESS_TYPE_COLLECT_FILE: 'Collect',
	TODAY_AVERAGE_DATA: 'Average usage',
	FILE_LOCATION: 'File location',
	REGIST_DATE: 'Register date',
	REGIST_USER: 'User who registered',
	RELEASE: 'Release',
	SECUREDOC_DIALOG_CONTENTS_1: 'The minimum level to access the secure document',
	SECUREDOC_DIALOG_CONTENTS_2: 'The users who can access the secure document',
	SECUREDOC_DIALOG_CONTENTS_3: 'There is no specific user who can access the secure document',
	SECUREDOC_DIALOG_MESSAGE_1: 'You have no right to downgrade the access level',
	SECUREDOC_DIALOG_MESSAGE_2: 'Released',
	SECUREDOC_DIALOG_MESSAGE_3: 'Changed',
	SECUREDOC_DIALOG_MESSAGE_4: 'Registered',
	DELETE_PERMANENTLY: 'Removing permanently',
	NEW_FOLDER: 'New folder',
	UPLOAD: 'Upload',
	FILE_DOWNLOAD: 'Download',
	PASTE: 'Paste',
	SEARCH: 'Search',
	CUT: 'Cut',
	REVISION_HISTORY: 'Revision History',
	SEARCH_IN_FOLDER: 'Search in the folder',
	SEARCH_DELETED_FILE_IN_FOLDER: 'Search deleted files in the folder',
	RENAME: 'Rename',
	CONTEXT_MESSAGE_1: "You can't upload files into the top-level folder",
	CONTEXT_MESSAGE_2: 'The folder has the same name exists already',
	CONTEXT_MESSAGE_3: 'The file has the same name exists already',
	CONTEXT_MESSAGE_4: 'You have no permission',
	CONTEXT_MESSAGE_5: 'Creation has been failed',
	CONTEXT_MESSAGE_6: 'Select only one file',
	CHANGE_PASSWORD_MESSAGE_1: 'The password has been changed',
	CHANGE_PASSWORD_ERROR_MESSAGE_1: 'The new password is mismatched',
	CHANGE_PASSWORD_ERROR_MESSAGE_2: 'The new password is the same as the previous one',
	CHANGE_PASSWORD_ERROR_MESSAGE_3: 'The current password is mismatched',
	CHANGE_PASSWORD_ERROR_MESSAGE_4:
		'The password should have at least one capital, lowercase, numeric, and one special character each',
	FILE_NAME: "File's name",
	FILE_SIZE: "File's size",
	FILE_MODIFIED: 'Last modified',
	REVERT: '',
	REVISION_DELETE: 'Remove a revision',
	REVISION_EMPTY: 'There has no revision',
	REVISION_DIALOG_MESSAGE_1: 'Select a revision',
	REVISION_DIALOG_MESSAGE_2: 'The current file has been replaced with the selected revision',
	REVISION_DIALOG_MESSAGE_3: 'Do you want to restore the current file by the selected revision?',
	REVISION_DIALOG_MESSAGE_4: 'Do you want to remove a selected revision?',
	SEARCH_ALL: 'Search',
	SEARCH_BELOW: 'Search under',
	SEARCH_STRING: 'Search string',
	SEARCH_EXCLUSION_STRING: 'Exclude string',
	SEARCH_EXTS: 'Extensions (separated by comma)',
	OPTION: 'Options',
	PREFIX: 'Prefix',
	LIKE: 'Likes',
	DELETE_DATE: 'Deleted date',
	DELETE_USER: 'User',
	DELETE_USER_ID: 'User ID',
	DELETE_USER_NAME: 'User Name',
	SEARCH_DIALOG_MESSAGE_1: 'Select a file',
	RESTORE_COMPLETE: 'Restored',
	DELETE_COMPLETE: 'Deleted',
	MAX_CAPACITY: 'Restrict storage size',
	PRIVATE_MAX_CAPACITY: 'Restrict the private folder size',
	COLLECT_MAX_CAPACITY: 'Restrict the collect folder size',
	COLLECT_DRIVE_FIXED: 'Collecting from the fixed storage',
	COLLECT_DRIVE_REMOVABLE: 'Collecting from the removable storage',
	COLLECT_DRIVE_CDROM: 'Collecting from CD-ROM',
	COLLECT_DRIVE_RAMDISK: 'Collecting from RAMDISK',
	COLLECT_DRIVE_REMOTE: 'Collecting from the remote drive',
	COLLECT_DRIVE_FIXED_HOTPLUG: 'Collecting from the USB fixed storage',
	CHANGE_CAPACITY_MESSAGE_1: 'The limit size of the private folder has been changed',
	CHANGE_CAPACITY_MESSAGE_2: 'The limit size of the collect folder has been changed',
	CHANGE_CAPACITY_MESSAGE_3: 'The limit storage size has been changed',
	CHANGE_CAPACITY_MESSAGE_4: '변경 사항이 반영 중입니다.',
	CHANGE_CAPACITY_ERROR_MESSAGE_1: 'Chaning the limit storage size has been failed',
	PII_DOCUMENTS: 'Detect PII (Personal Identifiable Information)',
	PII_NAME: 'Target name to detect',
	PII_ELEMENT: 'Elements to detect',
	DUP_DOCUMENTS: 'Duplicated documents',
	DUP_FILE_HASH: 'File Hash',
	DUP_FILE_PATH: 'Location of the file',
	WHITE_PROCESS: 'White process',
	PROCESS: 'Process',
	URL: 'URL',
	URL_REGISTRATION: 'URL Registration',
	PROCESS_DELETE_TITLE: 'Remove the process',
	URL_DELETE_TITLE: 'Remove the URL',
	PROCESS_DELETE_CONTENT: 'Do you want to remove the process %s?',
	URL_DELETE_CONTENT: 'Do you want to remove the URL %s?',
	PROCESS_APPROVE_TITLE: '프로세스 승인',
	PROCESS_APPROVE_CONTENT: '프로세스를 %s 하시겠습니까?',
	WHITE_URL_MODAL_1: 'URL Registration',
	WHITE_URL_MODAL_2: 'Set the URL to be allowed uploading files through the chrome browser',
	WHITE_PROCESS_ID: 'ID',
	WHITE_PROCESS_VERIFY_TYPE: 'Verifying methods',
	WHITE_PROCESS_PROGRAM_NAME: 'Program Name',
	WHITE_PROCESS_VERIFY_HASH: 'File Hash',
	WHITE_PROCESS_VERIFY_DIGITAL: 'Verifying by the digital signature',
	WHITE_PROCESS_MESSAGE_1: 'The process has removed',
	WHITE_PROCESS_MESSAGE_2: 'URL has removed',
	WHITE_PROCESS_MESSAGE_3: 'URL has registered',
	WHITE_PROCESS_MESSAGE_4: "Input it with omitted 'http://' or 'https://'",
	WHITE_PROCESS_ERROR_MESSAGE_1: 'Removing the process has been failed',
	WHITE_PROCESS_ERROR_MESSAGE_2: 'Removing the URL has been failed',
	WHITE_PROCESS_ERROR_MESSAGE_3: 'Input the name',
	WHITE_PROCESS_ERROR_MESSAGE_4: 'Input the URL',
	WHITE_PROCESS_ERROR_MESSAGE_5: 'Registering the URL has been failed',
	SETTING_CENTRALIZED_DOC: 'Security',
	ACCESS_CONTROL_USED: 'Enable the access control',
	SAVING_CONTROL_USED: 'Enable the saving control',
	TRANSFER_ORG: 'Move the organization folder',
	TRANSFER_ORG_MESSAGE_1: 'Do you want to move the organization folder?',
	TRANSFER_ORG_MESSAGE_2: 'Moved',
	TRANSFER_ORG_ERROR_MESSAGE_1: 'Select the parent folder of the folder that will be moved',
	TRANSFER_ORG_ERROR_MESSAGE_2: 'Moving has been failed',
	REMOVE_FILE_ERROR_MESSAGE_1: 'Removing has been failed. Check the permission',
	FILE_LOG_TITLE: 'File Log',
	AUDIT_LOG_TITLE: 'Audit Log',
	DOCUMENT: 'Document',
	SETUP_SETTING: 'Client',
	CONNECT_SERVER_ADDRESS: 'Server address',
	CONNECT_SERVER_PORT: 'Server port',
	MAP_MOUNT_DRIVE: 'Default mount drive letter',
	FORCE_MAP_MOUNT_DRIVE: 'Force the mount drive letter',
	FORCE_MAP_MOUNT_DRIVE_HINT: "The user can't change the mount drive letter",
	SILENT_SETUP: 'Silent setup',
	SILENT_SETUP_HINT: 'Update the client without the user prompt',
	VERYSILENT_SETUP: 'Hide the setup',
	DISABLE_CONNECT_BUTTON_WEBADMIN: 'Hide the button to connect the admin web',
	AUTO_START: 'Auto start',
	DESKTOP_WEB_SUPPORT: 'Support the PC Web client',
	MOBILE_WEB_SUPPORT: 'Support the mobile Web client',
	INTEGRATED_WITH_AD: 'Integrated with AD',
	COLLECT_INCLUSIVE_NAME: 'Designated collect folder name',
	RANSOM_MANAGEMENT: 'Ransomware Detection',
	RANSOMWARE_PATH: 'Ransomware Process Path',
	RANSOMWARE_STATUS: 'Status of deal with ransomware',
	CLIENT_ADDRESS: 'Client IP',
	INFECTED_FILES: 'Infected files',
	STATUS: 'Status',
	DETECTED_AT: 'Detected date',
	START_DATE: 'Start date',
	END_DATE: 'End date',
	AUDIT_TYPE_ALL: 'All',
	AUDIT_TYPE_LOGGED_IN: 'Login',
	AUDIT_TYPE_LOGGED_OFF: 'Log out',
	AUDIT_TYPE_CREATE_USER: 'Create an user',
	AUDIT_TYPE_MODIFY_USER: 'Modify an user',
	AUDIT_TYPE_MODIFY_PASSWORD: 'Modify a password',
	AUDIT_TYPE_REMOVE_USER: 'Remove a user',
	AUDIT_TYPE_CREATE_PAPER_PASSWORD: 'Create the paper OTP',
	AUDIT_TYPE_CREATE_ORGANIZATION: 'Create an organization',
	AUDIT_TYPE_RENAME_ORGANIZATION: 'Rename an organization folder',
	AUDIT_TYPE_TRANSFER_ORGANIZATION: 'Move an organization folder',
	AUDIT_TYPE_MOVE_ORGANIZATION_DATA: 'Move an organization data',
	AUDIT_TYPE_REMOVE_ORGANIZATION: 'Remove an organization folder',
	AUDIT_TYPE_CREATE_SHARED_FOLDER: 'Create a shared folder',
	AUDIT_TYPE_RENAME_SHARED_FOLDER: 'Rename s shared folder',
	AUDIT_TYPE_REMOVE_SHARED_FOLDER: 'Remove a shared folder',
	AUDIT_TYPE_ADD_FOLDER_PERMISSION: 'Add a folder permission',
	AUDIT_TYPE_UPDATE_FOLDER_PERMISSION: 'Update a folder permission',
	AUDIT_TYPE_REMOVE_FOLDER_PERMISSION: 'Remove a folder permission',
	AUDIT_TYPE_CREATE_ORGSHARED_FOLDER: 'Create an organization shared folder',
	AUDIT_TYPE_REMOVE_ORGSHARED_FOLDER: 'Remove an organization shared folder',
	AUDIT_TYPE_RESET_FOLDER_PERMISSION: 'Reset an folder permission',
	AUDIT_TYPE_REGISTER_SECURED_PROCESS: 'Register a secured process',
	AUDIT_TYPE_UNREGISTER_SECURED_PROCESS: 'Unregister a secured process',
	AUDIT_TYPE_SYSCONFIG_CHANGED: 'Change a system configuration',
	AUDIT_TYPE_CREATE_SUB_USER: '사용자 생성(중복로그인)',
	DIVISION: 'Class',
	EXISTING_FILE: 'Existed file',
	NEW_FILE: 'New file(or executable program)',
	DATE: 'Date',
	MORE_INFO: 'Additional info.',
	PREVIOUS_INFO: 'Previous info.',
	CHANGED_INFO: 'Changed info.',
	AUDIT_TYPE: 'Audit type',
	NOW_ACCESSLOG: 'Access logs',
	DASHBOARD: 'Dashboard',
	NOW_FILE_ACCESSLOG: 'File access log',
	DESKTOP_WEB_NOT_SUPPORT: "Web service doesn't support PC",
	PII_SEARCH: 'File / Folder name',
	MANAGE_TYPE_NONE: 'General user',
	MANAGE_PRIORITY: 'Manage priority',
	OSR_ADD_ORG: 'The organization to be added',
	OSR_REMOVE_ORG: 'The organization to be removed',
	OSR_REMOVE_USER: 'The user to be removed',
	OSR_NO_CHANGE: 'No change',
	OSR: 'Synchronization with HR Information System',
	OSR_CONFIRM_MESSAGE_1: 'Do you want to apply to sync with HR Information System?',
	OSR_CONFIRM_MESSAGE_2: 'Sync with HR Information System has been applied',
	OSR_CONFIRM_MESSAGE_3: '조직코드를 등록하시겠습니까?',
	DATALEAK_CONTRL: 'Enable the data leak control',
	DATALEAK_MESSAGE_1: "You can't use the data leak control",
	DATALEAK_MESSAGE_2: "You can't use the access control",
	DATALEAK_MESSAGE_3: "You can't use the saving control",
	TAKEOUT_TITLE: 'Takeout Document',
	TAKEOUT_REQ_AT: 'Requested date',
	TAKEOUT_APP_AT: 'Processed date',
	TAKEOUT_REQUESTER: 'Requester',
	TAKEOUT_APPROVER: 'Approver',
	TAKEOUT_REQ_TYPE: 'Request type',
	TAKEOUT_STATUS: 'Status',
	TAKEOUT_REASON_REQ: 'The reason to request',
	TAKEOUT_REASON_REJ: 'The reason to reject',
	TAKEOUT_FILE_COUNT: 'Document count',
	TAKEOUT_REQUEST: 'Requestion',
	TAKEOUT_APPROVED: 'Approval',
	TAKEOUT_APPROVED_AUTO: 'Auto approval',
	TAKEOUT_REJECTED: 'Reject',
	TAKEOUT_WITHDRAWN: 'Withdraw the requestion',
	TAKEOUT_DOC: 'Takeout documents',
	TAKEOUT_PRINT: 'Takeout by printing',
	TAKEOUT_WEBLINK: 'Takeout by web-link',
	LOCAL_ACCOUNT: 'Manual Registered User',
	LOCAL_ACCOUNT_HELP:
		"You must select 'No' for the user who exists in the HR Information system, if don't then, the synchronization will be failed.",
	ALLOW_ADMIN_ACCESS_USERS_FOLDERS: "Allow the admin to access users' folders",
	ALLOW_ADMIN_ACCESS_USERS_FOLDERS_HINT: "The administrator can access all other users' folders through the COODOC client",
	FORCE_SETUP_PERFORM: 'Force update the incompatible client',
	FORCE_SETUP_PERFORM_HINT: 'If the client is incompatible with the server (version) then, it force update the client',
	HOLD_FILES: 'Hold files',
	SINCE_SECE: 'Elapsed',
	START_TIME: 'Start time',
	AUTO_DETER: 'Auto determine',
	USER_DETER: 'User determine',
	HIDDEN: 'Hidden',
	VISIBLE: 'Visible',
	AUTO_START_AFTER_BOOT: 'Auto start after boot up',
	NOT_USED: 'Not use',
	FORCED_UPDATE: 'Force update',
	USER_CHOICE: 'User selection',
	OSR_REMOVE_USER_LABEL: "Recipient (Keep steady if doesn't select)",
	RANSOMWARE_STATUS_NONE: 'All',
	RANSOMWARE_STATUS_COM: 'Not a ransomware',
	RANSOMWARE_STATUS_BLOCK: 'Blocking I/O',
	CHANGE_CAPACYTI_TITLE: 'Changing capacity of',
	SETTING_LOCK_VOLUME_LABEL: 'Lock the mounted drive letter',
	RANSOMWARE_FILE_STATUS_COM: 'Not a ransomware',
	RANSOMWARE_FILE_STATUS_RESTORE: 'Restored file',
	RANSOMWARE_FILE_STATUS_INFECTION: 'Infected file',
	CLEARANCE: 'Arrange',
	OSR_BTN_1: 'Set manually',
	OSR_BTN_2: 'Set as the same organization',
	OSR_BTN_3: 'Arrange all',
	OSR_BTN_4: 'Set as the same user',
	OSR_HINT_MESSAGE_1:
		"It is the organization list that has been appended to the HR Information System. You would choose 'The organization name before changed' if the organization path or the organization name has been changed. And you would choose 'Add' if in case the new organization has been created",
	OSR_HINT_MESSAGE_2:
		"It is the organization list that does not exist in the HR Information System. You would choose 'Keep' if you want to keep using it in the COODOC otherwise choose 'Arrange'. You could choose 'The organization that you want to move it' if you want to move data to the other organization",
	OSR_HINT_MESSAGE_3:
		"It is the user list that does not exist in the HR Information System. You would choose 'Keep' if you want to keep using it in the COODOC otherwise choose 'Arrange'. You could choose 'The user that you want to move it' if you want to move data to the other user",
	OSR_ERROR_MESSAGE_1: 'Set the organization to be appended first',
	OSR_ERROR_MESSAGE_2: "You can't keep the sub-organization folders if remove or move the superior folder",
	OSR_ERROR_MESSAGE_3: 'The error occurred while inquiring about the HR Information System. Contat the administrator',
	OSR_ERROR_MESSAGE_4: 'Synchronizing with the HR Information System in progress',
	OSR_ERROR_MESSAGE_5: 'The error occurred while synchronizing the HR Information System. Contact the administrator',
	OSR_ERROR_MESSAGE_6: '이미 등록된 조직코드입니다.',
	SYNC_SOLUTION_TITLE: 'Synchronization Solution',
	SYNC_SOLUTION_INTEGRATED: 'Enable synchronization solution',
	SYNC_SOLUTION_INTEGRATED_HINT_1:
		'The document collector collects documents into the synchronization folder rather than into the server',
	SYNC_SOLUTION_INTEGRATED_HINT_2:
		'All functionality of the collector will be performed as same except different that the final destination of collecting',
	SYNC_SOLUTION_NAME: 'Name of the synchronization solution',
	SYNC_SOLUTION_CONSUMER_FOLDER_ACCESSIBLE: 'Support for the personal synchronization solution',
	NO_SHELL_MENU: 'Hide the context menu',
	NO_TRAY_ICON_MENU: 'Disable the menu of tray icon',
	NO_TRAY_ICON_MENU_HINT: "The dashboard wouldn't pop up when double click COODOC tray icon either if disabled it",
	SETTING_SUPPORT_OPLOCKS: 'Use Opportunistic locks',
	SETTING_SUPPORT_OPLOCKS_HELP_TEXT:
		'You should disable this option if you have experienced some problems accessing the file with other users simultaneously',
	SEARCH_INDEXING: 'Indexing',
	SEARCH_PRIVATE_INDEXING: 'Indexing of the private folder',
	SEARCH_COLLECT_INDEXING: 'Indexing of the collect folder',
	SEARCH_INDEXING_MESSAGE_1: 'It has been appended to the indexing list',
	REFRESH: 'Refresh',
	EXCEL_DOWNLOAD: 'Download a excel',
	FILE_LOG_EXCEL: 'File log',
	AUDIT_LOG_EXCEL: 'Audit log',
	WEBLINK: 'Create the web-link',
	SYNC_OSR: 'Synchronizing with the HR Information System',
	NO_SYNC_OSR: 'Skip it from synchronizing',
	NO_SYNC_TARGET: 'Targets to be skipped',
	EXCLUSION: 'Skip',
	DOWNLOAD_LIMIT: 'Limit count to download',
	EXPIRE_DATE: 'Expire date',
	SEARCH_KEYWORD: 'Input a search string',
	ENTER_ID: 'Input an ID',
	ENTER_NAME: 'Input a name',
	ENTER_EMAIL: 'Input an email',
	ENTER_PASSWORD: 'Input a password',
	ENTER_PASSWORD_CONF: 'Input a password again',
	TOTAL: 'Total',
	CNT: '',
	NEW_REGISTRATION: 'New registration',
	DELETE_SELECTION: 'Remove the selected ones',
	ROW_PER_PAGE_LABEL: 'Rows per page',
	PAGE_PREV_BTN_LABEL: 'Previous page',
	PAGE_NEXT_BTN_LABEL: 'Next page',
	HOLD_FILES_CONF_TITLE: 'Release the documents that someone holds',
	HOLD_FILES_CONF_CONTENT: 'Terminate the session of',
	HOLD_FILES_MESSAGE_1: 'The session has been terminated',
	DETAIL_INFO: 'Details',
	AVERAGE_READ: 'Read',
	AVERAGE_WRITE: 'Write',
	AVERAGE_CONNECTED: 'Connections',
	AVERAGE_FILE_TITLE_1: 'Files creation / removing',
	AVERAGE_FILE_CREATE: 'File creation',
	AVERAGE_FILE_REMOVE: 'File removing',
	AVERAGE_FILE_TITLE_2: 'File updating',
	AVERAGE_FILE_RENAME: 'File renaming',
	AVERAGE_FILE_UPDATE: 'File updating',
	AVERAGE_FILE_COPY: 'File copying',
	AVERAGE_FOLDER_TITLE: 'Folder creation / removing',
	AVERAGE_FOLDER_CREATE: 'Folder creation',
	AVERAGE_FOLDER_REMOVE: 'Folder removing',
	AVERAGE_CALL_API_TITLE: 'Calls Web API',
	UPDATE_NEW_CLIENT_LOCK: 'Locks the version',
	UPDATE_NEW_CLIENT_UNLOCK: 'Release locks the version',
	UPDATE_NEW_CLIENT_ALL_LOCK: "Locks all clients' version",
	UPDATE_NEW_CLIENT_ALL_UNLOCK: "Release locks all clients' version",
	CLIENT_INFO_VERSION: 'Client version',
	CLIENT_INFO_INSTALL_AT: 'Install date',
	CLIENT_INFO_IP: 'Client IP',
	CLIENT_INFO_LAST_LOGIN_AT: 'Last logged in',
	UPDATE_NEW_CLIENT_MESSAGE_1: 'Do you want to lock the version of user(%d)?',
	UPDATE_NEW_CLIENT_MESSAGE_2: 'Do you want to release lock the version of the user(%d)?',
	UPDATE_NEW_CLIENT_MESSAGE_3: 'Do you want to lock the version of all users?',
	UPDATE_NEW_CLIENT_MESSAGE_4: 'Do you want to release lock the version of all users?',
	UPDATE_NEW_CLIENT_MESSAGE_5: 'Select the user or the organization',
	UPDATE_NEW_CLIENT_MESSAGE_6: 'Do you want to lock the version of the organization(%d)?',
	UPDATE_NEW_CLIENT_MESSAGE_7: 'Do you want to release lock the version of the organization(%d)?',
	LOCK: 'Lock',
	UNLOCK: 'Release',
	WHITE_URL_NAME: 'Name',
	WHITE_URL_NAME_PLACEHOLDER: 'Input a name',
	WHITE_URL_SET_TYPE: 'Comparison methods',
	WHITE_URL_URL: 'URL',
	WHITE_URL_URL_PLACEHOLDER: 'Input an URL',
	WHITE_URL_REGEX: 'Regular expression',
	WHITE_URL_PLAIN: 'Match the URL',
	WHITE_PROGRAM: 'White program',
	WHITE_SYSTEM_PROCESS: 'System program',
	WHITE_CHROME_BROWSER: 'Chrome browser',
	WHITE_RANSOMWARE: '랜섬웨어 아님',
	WHITE_PROCESS_TYPE: 'Type',
	TAKEOUT_APPROVE_TYPE_MANUAL: 'Manual approve',
	TAKEOUT_APPROVE_TYPE_AUTO: 'Auto approve',
	TAKEOUT_APPROVE_TYPE_UNAVAILABLE: 'Approval unavailable',
	TAKEOUT_APPROVE_MESSAGE_1: 'Modified',
	TAKEOUT_APPROVE_MANAGE_LOCK: 'Lock the modification by user',
	TAKEOUT_APPROVE_MANAGE_LOCK_ALL: 'Lock the all organization',
	TAKEOUT_APPROVE_MANAGE_UNLOCK_ALL: 'Release the lock on all organization',
	EXPORT_APPROVAL_MANAGEMENT: 'Managing the approver',
	MANUAL: 'Manual',
	AUTO: 'Auto',
	INSTANT_LOGS_TITLE: 'Set the search conditions',
	INSTANT_LOGS_FILE_TYPES: 'Type of the log file',
	INSTANT_LOGS_LOG_TYPES: 'Log type',
	INSTANT_LOGS_INCLUDES: 'Strings includes',
	INSTANT_LOGS_EXCLUDES: 'Strings excludes',
	INSTANT_LOGS_OMIT_SERVER: 'Collect the server log',
	INSTANT_LOGS_CONTENTS: 'Message',
	INSTANT_LOGS_ERROR_MESSAGE_1: 'Set up after searching',
	WEBLINK_COPY_MESSAGE: 'Copied',
	CLIENT_RUNNING_TYPE_START: 'Launch',
	CLIENT_RUNNING_TYPE_STOP: 'Terminate',
	CLIENT_RUNNING_TYPE_IGNORE: 'Hold files',
	CLIENT_RUNNING_TYPE_LOGOFF: 'PC logged off',
	CLIENT_RUNNING_TYPE_ABORTED: 'Aborted',
	DISABLE_SAVE_PASSWORD: '비밀번호 저장 비활성화',
	PRIVATE_FOLDER_SUPPORT: '개인폴더 사용',
	PRESERVE_LAST: '마지막 개정내역 보관',
	PRESERVE_LAST_WITHOUT_ORIGINAL: '휴지통 보관일 분리',
	PRESERVE_LAST_WITHOUT_ORIGINAL_HELP: "비활성화시 휴지통 보관일수는 '개정 내역 파일 보관 일수'과 동일",
	MAX_DAYS_PRESERVE_LAST_WITHOUT_ORIGINAL: '휴티동 보관일수',
	DOWNLOAD_ERROR: 'os를 선택해주세요.',
	NUMBER_ERROR_MESSAGE: '0보다 큰수를 입력해주세요.',
	DLP_CLIENT_INFO: 'dlp 클라이언트 정보',
	CONNECTED: '연결',
	NOT_CONNECTED: '미연결',
	DLP_POLICY_SETTING: 'dlp 정책 설정',
	DLPClientLogGeneral: '일반',
	DLPClientLogCreateFile: '생성',
	DLPClientLogWriteFile: '쓰기',
	DLPClientLogRenameFile: '이름변경',
	DLPClientLogCopyFile: '복사',
	DLPClientLogCloneFile: '복제',
	DLPClientLogLinkFile: '링크파일',
	DLPClientLogDeleteFile: '삭제',
	DLPClientLogTruncateFile: '내용삭제',
	DLPClientLogExecute: '프로그램 실행',
	DLPClientLogNetwork: '네트워크 연결',
	DLPClientLogFetchURL: '웹 접속',
	DLPClientLogUploadURL: '웹 업로드',
	DLPClientLogMount: '마운트',
	DLPClientLogUnmount: '마운트 해제',
	DLPClientLogSendWebMail: '웸 메일',
	DLP_CLIENT_LOG_VIER: '조회',
	DLP_CLIENT_LOG_VIEW_TITLE: '세부 내용 보기',
	DLP_CLIENT_LOG_LOGGED_AT: '일시',
	DLP_CLIENT_LOG_USERNAME: '사용자(클라이언트 번호)',
	DLP_CLIENT_LOG_TYPE: '로그 타입',
	DLP_CLIENT_LOG_PATH: '파일경로',
	DLP_CLIENT_LOG_SIGNING_ID: '프로그램 식별자',
	DLP_CLIENT_LOG_HASH: '프로그램 해시',
	DLP_CLIENT_LOG_SOURCE_PATH: '시작 경로',
	DLP_CLIENT_LOG_DEST_PATH: '목적지 경로',
	DLP_CLIENT_LOG_LOCAL_ENDPOINT: '출발지 주소',
	DLP_CLIENT_LOG_REMOTE_ENDPOINT: '목지지 주소',
	DLP_CLIENT_LOG_WEB_UPLOAD_FILE: '웹 업로드 파일',
	DLP_CLIENT_LOG_MAIL_RECIEVER: '받는사람',
	DLP_CLIENT_LOG_MAIL_CC: '참조',
	DLP_CLIENT_LOG_MAIL_TITLE: '제목',
	DLP_CLIENT_LOG_MAIL_CONTENTS: '내용',
	DLP_CLIENT_LOG_PRINT_DOC_FORMAT: '작업 포맷',
	DLP_CLIENT_LOG_PRINT_COPIES: '복사 수',
	DLP_CLIENT_LOG_PRINT_JOB_NAME: '작업 이름',
	DLP_CLIENT_LOG_PRINT_JOB_CREATED_AT: '생성일시',
	DLP_CLIENT_LOG_PRINT_JOB_PROCESSING_AT: '수행일시',
	DLP_CLIENT_LOG_PRINT_JOB_COMPLETED_AT: '완료일시',
	DLP_CLIENT_LOG_PRINT_PRINTER_URL: '프린트 URL',
	DLP_CLIENT_LOG_PRINT_JOB_STATE_MESSAGE: '프린트 상태메세지',
	DLP_CLIENT_LOG_PRINT_JOB_STATE_REASONS: '프린트 상태 사유',
	DLP_CLIENT_LOG_CONTROL_INFO: '프린트 작업 세부 정보',
	DLP_CLIENT_LOG_PRINT_FILE_DOWNLOAD_TITLE: '프린트 다운로드',
	DLP_CLIENT_LOG_PRINT_FILE_DOWNLOAD_CONTENT: '프린트된 파일을 다운로드하시겠습니까?',
	DLP_EXECUTE_PROGRAM: '실행제어',
	DLP_PROGRAM_HASH: '프로그램 해시',
	DLP_PROGRAM_SIGNING_ID: '프로그램 식별자',
	DLP_PROGRAM_MANAGE_NAME: '프로그램 관리명',
	DLP_PROGRAM_NAME: '프로그램명',
	DLP_PROCESS_TYPE: '프로세스 타입',
	DLP_EXECUTE_CONTROL_TITLE: '실제제어 설정',
	DLP_EXECUTE_CONTROL_CONTENT: '그룹의 실행제어를 설정하시겠습니까?',
	DLP_EXECUTE_CONTROL_DELETE_CONTENT: '그룹의 실행제어를 삭제하시겠습니까?',
	DLP_NETWORK: '네트워크제어',
	DLP_BLUETOOTH_PAIRING: '블루투스 페어링',
	DLP_BLUETOOTH_FILE: '블루투스 파일 전송',
	DLP_MESSENGER: '메신저 파일 전송',
	DLP_AIRDROP: '에어드랍',
	DLP_PROGRAM_INSTALL: '프로그램 설치',
	DLP_MOUNT: '마운트허용',
	DLP_PRINTING: '프린트',
	DLP_TAKEOUT_APPROVING: '반출방식',
	ASCENDING: '오름차순',
	DESCENDING: '내림차순',
	SORT_DATE: '날짜 정렬',
	TEMP_RELEASE: '임시해제',
	TYPE: '종류',
	INSTALL: '설치',
	INSTALL_LIST: '설치 목록',
	HAPPEND_AT: '발생일시',
	ALERT_TYPE: '경고 종류',
	ALERT_INFO: '경고 내용',
	DLP_CLIENT_BLOCKED_LOG: '차단 로그',
	DLP_CLIENT_MONITORING_LOG: '모니터링 로그',
	DLP_CLIENT_RELEASE_BLOCK: '해제 로그',
	NO_LOGIN: '미로그인',
	VERSION: '버전',
	LOGGED_IN_AT: '로그인 일시',
	LOGGED_OFF_AT: '로그오프 일시',
	CONNECTED_STATUS: '연결 상태',
	CHECKED_AT: '확인 시각',
	ACTIVE: '활성화',
	INACTIVE: '비활성',
	NOT_INSTALLED: '미설치',
	BLUETOOTH_LIST: '블루투스 목록',
	DLP_CLIENT_DELETE_TITLE: '클라이언트 제거',
	DLP_CLIENT_DELETE_CONTENT: '클라이언트를 제거 하시겠습니까?',
	DLP_CLIENT_DELETE_SUCCESS: '클라이언트를 제거하였습니다.',
	DLP_CLIENT_DELETE_FAIL: '클라이언트를 제거에 실패하였습니다',
	WEBLINK_COMMENT_1: '웹 링크 서비스 포트와 쿠독 웹 포트가 다른경우 관리자 재로그인이 필요합니다',
	WEBLINK_RESTRICT_EXTS: '웹 링크 제한 확장자',
	WEBLINK_RESTRICT_FILE_SIZE: '웹 링크 제한 파일 크기',
	WEBLINK_RESTRICT_TOTAL_SIZE: '웹 링크 제한 전체 크기',
	DLP_POLICY_WHITE_URL_MANAGEMENT: '업로드 URL 관리',
	DLP_POLICY_WHITE_URL_NAME: 'URL 관리명',
	DLP_POLICY_WHITE_URL_REGEX: 'URL 정규식',
	DLP_POLICY_WHITE_URL_TITLE: '업로드 URL 설정',
	DLP_POLICY_WHITE_URL_CONTENT: '그룹의 업로드 URL을 추가 하시겠습니까?',
	DLP_POLICY_WHITE_URL_DELETE_CONTENT: '그룹의 업로드 URL을 삭제하시겠습니까?',
	DLP_POLICY_WHITE_URL_REGEX_PLACEHOLDER: 'EX) *.coodoc.com',
	DLP_POLICY_WHITE_URL_MESSAGE_1: 'URL 관리명을 입해해 주세요',
	USER_LOCK: '계정 잠금',
	OSR_ORG_TITLE: '인사연동 조직 설정',
	OSR_ORG_CODE: '조직코드',
	ALLOW_TAKEOUT_PRIVATE_FOLDER: '개인폴더 반출',
	ALLOW_TAKEOUT_FOLDER: '조직폴더 반출',
	UNAVAILABLE: '불허',
	FOLLOWS_FOLDER_MASTER_POLICIES: '조직폴더정책 사용',
	BLACK_LIST: '블랙 리스트',
	WHITE_LIST: '화이트 리스트',
	APPROVED: '승인 되었습니다',
	REJECTED: '거절 되었습니다',
	FAILED: '수정에 실패하였습니다',
	WEBLINK_URL: '웹 링크 URL',
	WEBLINK_COPY_MESSAGE_2: '웹 링크 반출을 신청하였습니다. 승인 후 다운로드 가능합니다',
	CONTEXT_MESSAGE_7: '업로드 할수없는 위치입니다',
	CONTEXT_MESSAGE_8: '문서중앙화 사용중 다운로드를 할 수 없습니다',
	CONTEXT_MESSAGE_9: '다운로드를 할 수 없습니다',
	CONTEXT_MESSAGE_10: "'%s' 중복되는 파일이 있어 이동 할 수없습니다",
	FILE_DROP_TITLE: '파일을 드롭하세요',
	CHANGE_VIEW_LIST: '목록 보기로 전환',
	CHANGE_VIEW_GRID_1: '바둑판 보기로 전환',
	CONTEXT_CONFIRM_TITLE_1: '파일 삭제',
	CONTEXT_CONFIRM_CONTENT_1: '파일을 삭제 하시겠습니까?',
	CONTEXT_MESSAGE_11: '휴지통은 이동 할 수 없습니다',
	CONTEXT_MESSAGE_12: '특수 폴더의 파일 또는 폴더의 변경은 불가능합니다',
	CONTEXT_MESSAGE_13: '사용 가능한 메뉴가 없습니다',
	CONTEXT_MESSAGE_14: '다운로드 할 파일을 선택해 주세요',
	SHARE: '공유하기',
	DEFAULT_POLICY_SETTING: '기본 정책 설정',
	DEFAULT_POLICY: '기본 정책',
	COPY_POLICY: '정책 복사',
	POLICY_GROUP_TITLE: '정책 그룹 추가',
	GROUP_NAME: '그룹명',
	EXPLANATION: '설명',
	DLP_ALLOW_RELEASE_STORAGE: '매체 제어',
	DLP_ALLOW_RELEASE_NETWORK: '네트워크 제어',
	DLP_ALLOW_RELEASE_EXECUTE: '실행 제어',
	DLP_ALLOW_RELEASE_UPLOAD_URL: '브라우저 업로드 제어',
	DLP_ALLOW_RELEASE_BLUETOOTH: '블루투스 제어',
	DLP_ALLOW_RELEASE_MESSENGER: '메신저 제어',
	DLP_ALLOW_RELEASE_AIRDROP: '에어드랍 제어',
	DLP_POLICY_GROUP_SETTING: '보안 설정',
	DLP_POLICY_GROUP_NETWORK_TITLE: '네트워크제어 설정',
	DLP_POLICY_GROUP_NETWORK_CONTENT_1: '그룹의 네트워크제어를 수정하시겠습니까?',
	DLP_POLICY_GROUP_NETWORK_CONTENT_2: '그룹의 네트워크제어를 삭제하시겠습니까?',
	DLP_POLICY_GROUP_NETWORK_CONTENT_3: '그룹의 네트워크제어를 설정하시겠습니까?',
	DLP_POLICY_GROUP_GRANT: '차단 매체에 권한 부여',
	DLP_POLICY_GROUP_GRANT_TITLE: '권한제어 설정',
	DLP_POLICY_GROUP_GRANT_CONTENT_1: '그룹의 권한제어를 수정하시겠습니까?',
	DLP_POLICY_GROUP_GRANT_CONTENT_2: '그룹의 권한제어를 삭제하시겠습니까?',
	DLP_POLICY_GROUP_GRANT_CONTENT_3: '그룹의 권한제어를 설정하시겠습니까?',
	DLP_POLICY_GROUP_MESSAGE_1: '정책 그룹을 등록하였습니다',
	DLP_POLICY_GROUP_MESSAGE_2: '정책 그룹을 복사하였습니다',
	DLP_POLICY_GROUP_MESSAGE_3: '정책 그룹을 선택해 주세요',
	DLP_POLICY_GROUP_DEFAULT_TITLE: '기본 정책 설정',
	DLP_POLICY_GROUP_RELEASE: '해제 요청',
	DLP_POLICY_GROUP_DEFAULT_CONTENT_1: '그룹의 기본정책을 설정하시겠습니까?',
	DLP_POLICY_GROUP_DEFAULT_CONTENT_2: '그룹의 기본정책을 전체 정책으로 초기화하시겠습니까?',
	DLP_POLICY_GROUP_RELEASE_TITLE: '해제 요청 허용 여부 설정',
	DLP_POLICY_GROUP_RELEASE_CONTENT_1: '그룹의 해제 요청 허용 여부를 설정하시겠습니까?',
	DLP_POLICY_RELEASE_DURATION: '해제 허용 시간',
	DLP_POLICY_RELEASE_DURATION_TITLE: '해제 허용 시간 설정',
	DLP_POLICY_RELEASE_DURATION_CONTENT_1: '그룹의 해제 허용 시간을 설정하시겠습니까?',
	DLP_NETWORK_MANAGE_NAME: '네트워크 관리명',
	DLP_NETWORK_INBOUND_PORT: '인바운스 포트',
	DLP_NETWORK_OUTBOUND_DESTINATION: '아웃바운스 주소',
	ALL_PROGRAM: '모든 프로그램',
	NOT_SELECTED: '미지정',
	STORAGE_NAME: '매체명',
	ALLOW_READ: '읽기 허용',
	ALLOW_WRITE: '쓰기 허용',
	ALLOW_EXECUTE: '실행 허용',
	IOS_DOWNLOAD_CONTENT: '다운로드를 하시겠습니까?',
	VIEW: '보기',
	MB_SETTING_SERVER_INFO: '접속정보 변경',
	MB_SETTING_BIOMETRICS_USE: '생체인증 사용',
	MB_SETTING_BIOMETRICS_UNUSED: '생체인증 미사용',
	MB_SETTING_BIOMETRICS_COMPLETE: '생체인증 등록 완료',
	FOLDER_ERROR_MESSAGE_6: '이미 입력창이 있습니다',
	EXPAND_ALL: '모두 열기',
	COLLAPSE_ALL: '모두 접기',
	EXPIRE_AT_CHANGE: '만료일 변경',
	EXPIRE_AT_CHANGE_MESSAGE_1: '만료일을 변경하시겠습니까?',
	EXPIRE_AT_CHANGE_MESSAGE_2: '만료일을 변경하였습니다',
	EXPIRE_AT_RELEASE: '만료일 해제',
	EXPIRE_AT_RELEASE_MESSAGE_1: '만료일을 해제하시겠제습니까?',
	EXPIRE_AT_RELEASE_MESSAGE_2: '만료일을 해제하였습니다',
	EXPIRE_AT_ERROR_MESSAGE_1: '만료일을 선택해 주세요',
	DLP_CLIENT_MESSAGE_1: '전체 사용자의 설치를 허용하시겠습니까?',
	DLP_CLIENT_MESSAGE_2: '전체 사용자의 설치를 잠그시겠습니까?',
	DLP_CLIENT_MESSAGE_3: '사용자의 설치를 허용하시겠습니까?',
	DLP_CLIENT_MESSAGE_4: '사용자의 설치를 잠그시겠습니까?',
	DLP_CLIENT_MESSAGE_5: '설정에 실패하였습니다',
	DLP_CLIENT_MESSAGE_6: '설정이 반영되었습니다',
	DLP_CLIENT_MESSAGE_7: 'DLP 클라이언트를 강제 업데이트 하시겠습니까?',
	DLP_CLIENT_TITLE_1: '설치 허용 여부',
	DLP_CLIENT_TITLE_2: 'DLP 강제 업데이트',
	ALLOW_INSTALL: '설치 허용',
	LOCK_INSTALL: '설치 잠금',
	ALL_ALLOW_INSTALL: '전체 설치 허용',
	ALL_LOCK_INSTALL: '전체 설치 잠금',
	PATH: '경로',
	PATH_REG: '경로(정규식)',
	PATH_STORAGE: '가상매체',
	PATH_STORAGE_TITLE_1: '가상매체 삭제',
	PATH_STORAGE_TITLE_2: '가상매체 수정',
	PATH_STORAGE_TITLE_3: '가상매체 추가',
	PATH_STORAGE_CONTENT_1: '가상매체를 삭제 하시겠습니까?',
	PATH_STORAGE_MESSAGE_1: '가상매체가 삭제 되었습니다',
	PATH_STORAGE_MESSAGE_2: '가상매체가 삭제에 실패하였습니다',
	PATH_STORAGE_MESSAGE_3: '가상매체를 추가하였습니다',
	PATH_STORAGE_MESSAGE_4: '가상매체 추가에 실패하였습니다',
	PATH_STORAGE_MESSAGE_5: '가상매체를 수정하였습니다',
	PATH_STORAGE_MESSAGE_6: '가상매체 수정에 실패하였습니다',
	DLP_PROCESS_TYPE_UNKNOWN: '알 수 없는 프로그램',
	DLP_PROCESS_TYPE_NORMAL: '일반 프로그램',
	DLP_PROCESS_TYPE_SYSTEM: '시스템 프로그램',
	DLP_PROCESS_TYPE_CHROME: '크롬 브라우저',
	PROGRAM: '프로그램',
	PROGRAM_TITLE_1: '프램그램 삭제',
	PROGRAM_TITLE_2: '프램그램 수정',
	PROGRAM_TITLE_3: '프램그램 추가',
	PROGRAM_CONTENT_1: '프로그램을 삭제 하시겠습니까?',
	PROGRAM_MESSAGE_1: '프로그램이 삭제 되었습니다',
	PROGRAM_MESSAGE_2: '프로그램 삭제에 실패하였습니다',
	PROGRAM_MESSAGE_3: '프로그램을 추가하였습니다',
	PROGRAM_MESSAGE_4: '프로그램 추가에 실패하였습니다',
	PROGRAM_MESSAGE_5: '프로그램을 수정하였습니다',
	PROGRAM_MESSAGE_6: '프로그램 수정에 실패하였습니다',
	NETWORK: '네트워크',
	NETWORK_TITLE_1: '네트워크 삭제',
	NETWORK_TITLE_2: '네트워크 수정',
	NETWORK_TITLE_3: '네트워크 추가',
	NETWORK_CONTENT_1: '네워워크를 삭제 하시겠습니까?',
	NETWORK_MESSAGE_1: '네워워크가 삭제 되었습니다',
	NETWORK_MESSAGE_2: '네트워크 삭제에 실패하였습니다',
	NETWORK_MESSAGE_3: '네트워크를 추가하였습니다',
	NETWORK_MESSAGE_4: '네워워크 추가에 실패하였습니다',
	NETWORK_MESSAGE_5: '네트워크를 수정하였습니다',
	NETWORK_MESSAGE_6: '네트워크 수정에 실패하였습니다',
	DLP_POLICY_TITLE: '기본 설정',
	DLP_POLICY_STORAGE_TYPE: 'Storage 종류',
	DLP_HEALTH_MODULE_SECURE: 'dlp 클라이언트',
	DLP_HEALTH_MODULE_EPS: '제어 모듈',
	DLP_HEALTH_MODULE_NETWORK: '네트워크 모듈',
	DLP_HEALTH_MODULE_CHROME: '크롬 확장프로그램',
	MAC_ADDRESS: 'mac',
	ENTER_REASON: '사유를 입력해 주세요',
	SELECT_REQUEST: '요청을 선택해 주세요',
	OFFLINE_DRIVE: '오프라인 드라이브',
	OFFLINE_DRIVE_CONFIRM_1: '오프라인 드라이브를 거절하시겠습니까?',
	OFFLINE_DRIVE_CONFIRM_2: '오프라인 드라이브를 승인하시겠습니까?'
};
export default locale;
