import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getOtherSiteInfo } from 'app/store/docbase/admin/etcSlice';

function OpenContentSite({ props, location }) {
	const dispatch = useDispatch();
	const refForm = React.useRef();

	const [siteInfo, setSiteInfo] = useState({ id: '', password: '', formID: '', formPasswd: '', url: '' });

	useEffect(() => {
		const urlParam = new URLSearchParams(location.search);
		getOtherSiteInfo(urlParam.get('name')).then(data => {
			setSiteInfo({
				id: data.id,
				password: data.password,
				formID: data.form_id,
				formPasswd: data.form_password,
				url: data.login_url
			});
		});
	}, [dispatch]);

	useEffect(() => {
		if (siteInfo.url !== '') {
			submit();
		}
	}, [siteInfo]);

	async function submit() {
		refForm.current.action = siteInfo.url;

		refForm.current[siteInfo.formID].value = siteInfo.id;
		refForm.current[siteInfo.formPasswd].value = siteInfo.password;

		refForm.current.submit();
	}

	return (
		<div id="fuse-splash-screen">
			<div>
				<form
					name="loginForm"
					noValidate
					className="flex flex-col justify-center w-full"
					method="post"
					onSubmit={() => {
						console.log('onSubmit');
					}}
					ref={refForm}
				>
					<TextField
						className="mb-16"
						type="text"
						name={siteInfo.formID}
						value={siteInfo.id}
						onChange={e => setSiteInfo({ ...siteInfo, id: e.target.value })}
						variant="outlined"
						required
						fullWidth
					/>

					<TextField
						className="mb-16"
						type="text"
						name={siteInfo.formPasswd}
						value={siteInfo.password}
						onChange={e => setSiteInfo({ ...siteInfo, password: e.target.value })}
						variant="outlined"
						required
						fullWidth
					/>
				</form>
				<div className="center">
					<div className="logo">
						<img width="128" src="assets/brand/loading.svg" alt="logo" />
					</div>
					<div className="spinner-wrapper">
						<div className="spinner">
							<div className="inner">
								<div className="gap" />
								<div className="left">
									<div className="half-circle" />
								</div>
								<div className="right">
									<div className="half-circle" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OpenContentSite;
