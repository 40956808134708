import React, { Component } from 'react';
import { connect } from 'react-redux';
// import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { getSysConfigure } from 'app/store/docbase/admin/settingSlice';
import { doLogout } from 'app/auth/store/loginSlice';
import { initialSetup, getProductInfo } from './store/initialSlice';

// import WebSocketClient from '../utils/WebSocketClient';

// const docSoket = window.ReactNativeWebView ? undefined : new WebSocketClient(`${window.location.host}:000/wc`);
let lastTime = 0;
let sessionCheckTimer;
class Auth extends Component {
	// state = { waitAuthCheck: false && window.location.pathname !== '/openContentSite' };

	componentDidMount() {
		// if (docSoket) {
		// 	// this.state.socket.onMessage(e => {});
		// }
		this.props.initialSetup();
		this.props.getProductInfo();
		this.props.getSysConfigure();
		// this.setState({ waitAuthCheck: false });
		//	로그인 하지 않은 사용자 체크
		if (
			(localStorage.getItem('doc_user') === null || localStorage.getItem('doc_user') === '') &&
			window.location.pathname !== '/admin' &&
			window.location.pathname !== '/login' &&
			window.location.pathname !== '/mv' &&
			window.location.pathname !== '/openContentSite' &&
			window.location.pathname.indexOf('/weblink') === -1
		) {
			window.location.href = window.location.pathname.indexOf('/wc') === 0 ? '/login' : '/admin';
		}

		const d1 = new Date();
		lastTime = d1.getTime();

		window.addEventListener('click', e => {
			const d = new Date();
			lastTime = d.getTime();
		});

		if (!sessionCheckTimer) {
			sessionCheckTimer = setInterval(() => {
				const now = new Date();
				const dif = now.getTime() - lastTime;
				this.props.getSysConfigure();

				if (dif > 1000 * 60 * 60) {
					if (window.location.pathname !== '/admin' && window.location.pathname !== '/login') {
						doLogout().then(data => {
							window.alert('세션이 종료되었습니다. 다시 로그인 해주세요');
							window.location.href = window.location.pathname.indexOf('/wc') === 0 ? '/login' : '/admin';
						});
					}
				}
			}, 1000 * 60 * 10);
		}
	}

	render() {
		// return this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{this.props.children}</>;
		return (
			<>
				<Backdrop className="global-backdrop" open={this.props.showBackdrop}>
					<CircularProgress color="inherit" />
				</Backdrop>
				{this.props.children}
			</>
		);
	}
}

function mapStateToProps({ docbase }) {
	return { showBackdrop: docbase.admin.setting.showBackdrop };
}
export default connect(mapStateToProps, { initialSetup, getProductInfo, getSysConfigure })(Auth);
