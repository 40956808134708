import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogTitle, DialogContentText, Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 604
		}
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	body: {
		background: '#f2f3f5',
		padding: '24px 30px 30px',
		color: '#353b48',
		'&>.content': {
			background: '#ffffff',
			borderRadius: 6,
			padding: 20
		},
		'&>.content>.row': {
			display: 'flex',
			alignItems: 'center',
			margin: '15px 0'
		},
		'&>.content>.row:first-child': {
			marginTop: 0
		},
		'&>.content>.row:last-child': {
			marginBottom: 0
		},
		'&>.content>.row>.title': {
			width: 136,
			cursor: 'default',
			fontSize: 14
		},
		'&>.content>.row>.title>span:before': {
			color: '#00cfe2',
			content: '"*"',
			marginRight: '5px'
		},
		'&>.content>.row>.input-area': {
			width: '100%',
			maxWidth: 382,
			cursor: 'default',
			fontSize: 14
		}
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 20,
		'& .MuiButton-root': {},
		'& .MuiButton-root:first-child': {
			marginRight: 16
		}
	}
}));

export default function DeleteUserModal(props) {
	const { t } = useTranslation('docbase');
	const classes = useStyles();

	const [password, setPassword] = React.useState('');
	const [passwordConfirm, setPasswordConfirm] = React.useState('');
	const [passwordHelp, setPasswordHelp] = React.useState('');
	const [passwordConfirmHelp, setPasswordConfirmHelp] = React.useState('');

	React.useEffect(() => {
		setPassword('');
		setPasswordConfirm('');
		setPasswordHelp('');
		setPasswordConfirmHelp('');
	}, [props.open]);

	function validation() {
		let pwh = '';
		let pwch = '';
		if (password.length === 0) {
			pwh = t('RESET_PASSWORD_ERROR_MESSAGE_1');
		}
		if (passwordConfirm === 0) {
			pwch = t('RESET_PASSWORD_ERROR_MESSAGE_2');
		}
		if (passwordConfirm !== password) {
			pwh = t('RESET_PASSWORD_ERROR_MESSAGE_3');
			pwch = t('RESET_PASSWORD_ERROR_MESSAGE_3');
		}
		setPasswordHelp(pwh);
		setPasswordConfirmHelp(pwch);

		if (pwh === '' && pwch === '') {
			props.fnHandleOK(password);
			setPassword('');
			setPasswordConfirm('');
		}
	}

	return (
		<div>
			<Dialog className={classes.root} open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog-title">
				<DialogTitle className={classes.title} id="form-dialog-title">
					{t('USER_MENU_1')}
				</DialogTitle>
				<DialogContent className={classes.body}>
					<div className="content">
						<DialogContentText className="text-1" id="alert-dialog-slide-description">
							{`${props.userID}(${props.name})${t('RESET_PASSWORD_TITLE')}`}
						</DialogContentText>
						<div className="row">
							<div className="title">
								<span>{t('RESET_NEW_PASSWORD')}</span>
							</div>
							<div className="input-area">
								<TextField
									className=""
									type="password"
									placeholder={t('USER_ERROR_MESSAGE_5')}
									name="password"
									fullWidth
									value={password}
									variant="outlined"
									error={passwordHelp !== ''}
									helperText={passwordHelp}
									onChange={e => setPassword(e.target.value)}
									size="small"
								/>
							</div>
						</div>
						<div className="row">
							<div className="title">
								<span>{t('RESET_NEW_PASSWORD_CONFRIM')}</span>
							</div>
							<div className="input-area">
								<TextField
									className=""
									type="password"
									placeholder={t('USER_ERROR_MESSAGE_5')}
									name="passwordConfirm"
									fullWidth
									value={passwordConfirm}
									variant="outlined"
									error={passwordConfirmHelp !== ''}
									helperText={passwordConfirmHelp}
									onChange={e => setPasswordConfirm(e.target.value)}
									size="small"
								/>
							</div>
						</div>

						<DialogContentText className="text-2" id="alert-dialog-slide-description">
							{t('RESET_PASSWORD_CONTENT')}
						</DialogContentText>

						<div className={classes.buttonWrapper}>
							<Button
								onClick={props.fnHandleClose}
								variant="contained"
								className="default"
								aria-label="cancel"
								type="button"
							>
								{t('CANCEL')}
							</Button>
							<Button variant="contained" color="secondary" className="" type="button" onClick={e => validation()}>
								{t('CHANGE')}
							</Button>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
