import { makeStyles } from '@material-ui/core/styles';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	tr: {
		'& .user': {
			minWidth: '200px',
			width: '200px',
			maxWidth: '200px'
		},
		'& .type': {
			minWidth: '110px',
			width: '110px',
			maxWidth: '110px'
		},
		'& .path': {
			minWidth: '300px',
			width: '400px',
			maxWidth: '400px'
		},
		'& .identifier': {
			minWidth: '250px',
			width: '250px',
			maxWidth: '250px'
		}
	}
}));

function ExportApprovalTableHead(props) {
	const classes = useStyles(props);
	const { t } = useTranslation('docbase');

	const headerCols = [
		{
			id: 'logged_at',
			align: 'center',
			className: 'table_col_date',
			disablePadding: false,
			label: t('요청일'),
			sort: false
		},
		{
			id: 'requester',
			align: 'left',
			className: 'left user',
			disablePadding: false,
			label: t('요청자'),
			sort: false
		},
		{
			id: 'approver',
			align: 'left',
			className: 'left user',
			disablePadding: false,
			label: t('승인자'),
			sort: false
		},
		{
			id: 'release_type',
			align: 'center',
			className: 'type',
			disablePadding: false,
			label: t('해제 타입'),
			sort: false
		},
		{
			id: 'duration',
			align: 'center',
			className: '',
			disablePadding: false,
			label: t('해제 기간(분)'),
			sort: false
		},
		{
			id: 'reason_request',
			align: 'left',
			className: 'left path',
			disablePadding: false,
			label: t('요청 사유'),
			sort: false
		},
		{
			id: 'status',
			align: 'center',
			className: 'type',
			disablePadding: false,
			label: t('상태'),
			sort: false
		},
		{
			id: 'approved_at',
			align: 'center',
			className: 'table_col_date',
			disablePadding: false,
			label: t('숭인/거절일시'),
			sort: false
		},

		{
			id: 'view',
			align: 'center',
			className: '',
			disablePadding: false,
			label: t('DLP_CLIENT_LOG_VIER'),
			sort: false
		}
	];

	return (
		<TableHead>
			<TableRow>
				{/* <TableCell padding="none" className="w-40 md:w-64 text-center z-99">
					<Checkbox
						color="secondary"
						size="small"
						indeterminate={numSelected > 0 && numSelected < props.rowCount}
						checked={props.rowCount !== 0 && numSelected === props.rowCount}
						onChange={props.onSelectAllClick}
					/>
				</TableCell> */}

				{headerCols.map(row => {
					return (
						<TableCell
							className={row.className}
							key={row.id}
							align={row.align}
							padding={row.disablePadding ? 'none' : 'default'}
						>
							{row.label}
						</TableCell>
					);
				}, this)}
			</TableRow>
		</TableHead>
	);
}

export default ExportApprovalTableHead;
