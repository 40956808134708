import httpClient from '../../utils/HttpClient';
import securityUtils from '../../utils/SecurityUtils';
import { secureRequest } from '../../store/docbase/commonAction';

// eslint-disable-next-line import/prefer-default-export
export const setAdministrator = (userID, passwd, name, email) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/setAdministrator', {
					administrator_id: securityUtils.encryptAES(aes, userID, 'utf8', 'hex'),
					password: securityUtils.encryptAES(aes, passwd, 'utf8', 'hex'),
					name,
					email: !email ? '' : email,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};
