const locale = {
	BROWSER_TITLE: 'COODOC',
	PRODUCT_NAME: 'COODOC',
	PRODUCT_NAME_ADMIN: 'COODOC ADMIN',
	PRODUCT_ADMIN: '관리자',
	USER: '사용자',
	WEBLINK_TITLE: 'COODOC Weblink Download'
};

export default locale;
