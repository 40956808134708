import FuseAnimate from '@fuse/core/FuseAnimate';
import { useForm } from '@fuse/hooks';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { Typography, TextField, Card, CardContent, Button } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { registerPreorderedProduct } from 'app/auth/store/initialSlice';

const useStyles = makeStyles(theme => ({
	root: {
		background: `radial-gradient(${darken(theme.palette.primary.main, 0.5)} 0%, ${theme.palette.primary.main} 80%)`,
		color: theme.palette.primary.contrastText
	}
}));

function RegProduct({ history }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation('docbase');

	const initialObj = useSelector(({ auth }) => auth.initial);

	const { form, handleChange } = useForm({
		uuid: 0
	});

	useEffect(() => {
		if (initialObj.product_registered) {
			if (initialObj.administrator_setup) {
				history.push('/login');
			} else {
				history.push('/register');
			}
		}
	}, [initialObj]);

	function isFormValid() {
		return form.uuid !== '';
	}

	function handleSubmit(ev) {
		ev.preventDefault();

		registerPreorderedProduct(form.uuid)
			.then(data => {
				if (data.result) {
					history.replace('/register');
				} else {
					const msg = t('REGISTER_ERROR_MESSAGE_1');
					dispatch(showMessage({ message: msg, variant: 'error' }));
				}
			})
			.catch(err => {
				console.error(err);
			});
	}

	return (
		<div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>
			<div className="flex flex-col items-center justify-center w-full">
				<FuseAnimate animation="transition.expandIn">
					<Card className="w-full max-w-384 rounded-8">
						<CardContent className="flex flex-col items-center justify-center p-32">
							<img className="w-128" src="assets/brand/logo.svg" alt="logo" />

							<Typography variant="h6" className="mt-10 mb-12 font-bold text-20 sm:text-24" color="primary">
								{t('REGISTER_PRODUCT')}
							</Typography>

							<form
								name="registerForm"
								noValidate
								className="flex flex-col justify-center w-full"
								onSubmit={handleSubmit}
							>
								<TextField
									className="mb-16"
									label="선주문 UUID"
									type="text"
									name="uuid"
									value={form.uuid}
									onChange={handleChange}
									variant="outlined"
									required
									fullWidth
								/>

								<Button
									variant="contained"
									color="primary"
									className="w-full mx-auto"
									aria-label="Register"
									disabled={!isFormValid()}
									type="submit"
								>
									{t('REGISTER')}
								</Button>
							</form>
						</CardContent>
					</Card>
				</FuseAnimate>
			</div>
		</div>
	);
}

export default RegProduct;
