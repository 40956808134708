/* eslint-disable no-await-in-loop */
import React from 'react';
import clsx from 'clsx';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
	IconButton,
	Dialog,
	DialogContent,
	Typography,
	Button,
	Checkbox,
	TextField,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import { getNonLoggingApps, registerNonLoggingApp, unregisterNonLoggingApp } from 'app/store/docbase/admin/dlpSlice';
import { useTranslation } from 'react-i18next';
import commonUtils from 'app/utils/CommonUtils';

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});
const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 1208
		}
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	body: {
		background: '#f2f3f5',
		color: '#000000',
		fontSize: 14,
		height: 700,
		'&>div': {
			display: 'block',
			paddingRight: 30,
			borderTop: '1px solid rgb(141 149 160 / 18%)'
		},
		'&>div:last-child': { borderBottom: '1px solid rgb(141 149 160 / 18%)' },
		'&>.content': {
			background: '#ffffff',
			borderRadius: 6,
			padding: 40
		},
		'&>.content>.row': {
			display: 'flex',
			alignItems: 'center',
			margin: '15px 0'
		},
		'&>.content>.row:first-child': {
			marginTop: 0
		},
		'&>.content>.row:last-child': {
			marginBottom: 0
		},
		'&>.content>.row>.title': {
			width: 160,
			cursor: 'default',
			fontSize: 14
		},
		'&>.content>.row>.input-area': {
			width: '100%',
			maxWidth: 382,
			cursor: 'default',
			fontSize: 14
		},
		'&>.content>.row>.input-area>.hint': {
			color: '#9f9f9f',
			fontSize: '1.2rem'
		},
		'&>.content .text-2': {
			marginBottom: 0
		}
	},
	filedTitle: {
		background: '#f5f7fa',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		cursor: 'default',
		width: '100%',
		maxWidth: 277,
		minWidth: 277
	},
	filedInput: {
		width: '100%',
		padding: '0 0 0 30px',
		display: 'flex',
		alignItems: 'center',
		'&.folder-name': { paddingRight: 10 }
	},
	contentWrapper: {
		height: 500
	},
	tr: {
		'& .user': {
			minWidth: '200px',
			width: '200px',
			maxWidth: '200px'
		},
		'& .path': {
			minWidth: '300px',
			width: '300px',
			maxWidth: '300px'
		},
		'& .signing_id': {
			minWidth: '250px',
			width: '250px',
			maxWidth: '250px'
		},
		'& .view': {
			minWidth: '80px',
			width: '80px',
			maxWidth: '80px'
		}
	}
}));

export default function ViewDialog(props) {
	const classes = useStyles();
	const { t } = useTranslation('docbase');

	const [selectedIds, setSelectedIds] = React.useState({});
	const [data, setData] = React.useState([]);
	const [insertObj, setInsertObj] = React.useState({ signing_id: '', desc: '', word: '', regex: '' });

	React.useEffect(() => {
		if (props.open) {
			console.log(props);
			getList();
		} else {
			console.log(props);
			setInsertObj({ signing_id: '', desc: '', word: '', regex: '' });
		}
	}, [props.open]);

	async function getList() {
		const list = await getNonLoggingApps();
		const dataObjs = {};
		list.forEach(obj => {
			dataObjs[obj.id] = false;
		});
		setSelectedIds(dataObjs);
		setData(list);
	}

	return (
		<div>
			<Dialog className={classes.root} open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog-title">
				<DialogTitle className={classes.title} id="form-dialog-title" onClose={props.fnHandleClose}>
					{t('로깅 제외 앱 관리')}
				</DialogTitle>
				<DialogContent className={classes.body}>
					<div className="content">
						<div className="row">
							<div className="title">
								<span>{t('DLP_CLIENT_LOG_SIGNING_ID')}</span>
							</div>
							<div className="input-area">
								<TextField
									className=""
									type="text"
									name="signing_id"
									value={insertObj.signing_id}
									onChange={e => setInsertObj({ ...insertObj, signing_id: e.target.value })}
									variant="outlined"
									size="small"
									required
									fullWidth
									inputProps={{ autocapitalize: 'off' }}
								/>
							</div>
						</div>
						<div className="row">
							<div className="title">
								<span>{t('설명')}</span>
							</div>
							<div className="input-area">
								<TextField
									className=""
									type="text"
									name="desc"
									value={insertObj.desc}
									onChange={e => setInsertObj({ ...insertObj, desc: e.target.value })}
									variant="outlined"
									size="small"
									required
									fullWidth
									inputProps={{ autocapitalize: 'off' }}
								/>
							</div>
						</div>
						<div className="row">
							<div className="title">
								<span>{t('문자열')}</span>
							</div>
							<div className="input-area">
								<TextField
									className=""
									type="text"
									name="desc"
									value={insertObj.word}
									onChange={e => setInsertObj({ ...insertObj, word: e.target.value })}
									variant="outlined"
									size="small"
									required
									fullWidth
									inputProps={{ autocapitalize: 'off' }}
								/>
							</div>
						</div>
						<div className="row">
							<div className="title">
								<span>{t('정규식')}</span>
							</div>
							<div className="input-area">
								<TextField
									className=""
									type="text"
									name="desc"
									value={insertObj.regex}
									onChange={e => setInsertObj({ ...insertObj, regex: e.target.value })}
									variant="outlined"
									size="small"
									required
									fullWidth
									inputProps={{ autocapitalize: 'off' }}
								/>
							</div>
						</div>
						<div className="row" style={{ justifyContent: 'end' }}>
							<Button
								variant="contained"
								color="secondary"
								className="first-btn"
								type="button"
								onClick={e => {
									registerNonLoggingApp(
										insertObj.signing_id,
										insertObj.desc,
										insertObj.word,
										insertObj.regex
									).then(result => {
										getList();
										setInsertObj({ signing_id: '', desc: '', word: '', regex: '' });
									});
								}}
								style={{ marginRight: 10 }}
							>
								앱 등록
							</Button>

							<Button
								variant="contained"
								color="default"
								className=""
								type="button"
								onClick={e => {
									(async () => {
										let isFailed = false;
										const keys = Object.keys(selectedIds);
										for (let i = 0; i < keys.length; i++) {
											if (selectedIds[keys[i]]) {
												const result = await unregisterNonLoggingApp(keys[i]);
												if (!result) isFailed = true;
											}
										}
										if (!isFailed) {
											getList();
										}
									})();
								}}
							>
								삭제
							</Button>
						</div>
					</div>

					<div style={{ marginTop: 10 }}>
						<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')}>
							<Table stickyHeader className="" aria-labelledby="tableTitle">
								<TableHead>
									<TableRow className={classes.tr}>
										{/* <TableCell padding="none" className="w-40 md:w-64 text-center z-99">
										<Checkbox
											color="secondary"
											size="small"
											indeterminate={selectedIds  && numSelected < props.rowCount}
											checked={props.rowCount !== 0 && numSelected === props.rowCount}
											onChange={props.onSelectAllClick}
										/>
									</TableCell> */}
										<TableCell padding="none" className="w-40 md:w-64 text-center z-99" />
										<TableCell className="signing_id left" align="left" padding="default">
											{t('DLP_CLIENT_LOG_SIGNING_ID')}
										</TableCell>
										<TableCell className="desc left" align="left" padding="default">
											설명
										</TableCell>
										<TableCell className="desc left" align="left" padding="default">
											문자열
										</TableCell>
										<TableCell className="desc left" align="left" padding="default">
											정규식
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map((d, idx) => {
										return (
											<TableRow
												className={clsx(classes.tr, 'course-pointer')}
												hover
												role="checkbox"
												tabIndex={-1}
												key={d.idx}
												selected={selectedIds[d.id]}
												onClick={event => {
													console.log(d.id);
													console.log(selectedIds[d.id]);
													setSelectedIds({
														...selectedIds,
														[d.id]: !selectedIds[d.id]
													});
												}}
											>
												<TableCell className="text-center table-checkbox" padding="none">
													<Checkbox
														color="secondary"
														size="small"
														checked={selectedIds[d.id]}
														onClick={event => event.stopPropagation()}
														onChange={event => {
															console.log(d.id);
															console.log(selectedIds[d.id]);
															setSelectedIds({
																...selectedIds,
																[d.id]: !selectedIds[d.id]
															});
														}}
													/>
												</TableCell>
												<TableCell
													className="cursor-pointer left path"
													component="td"
													scope="row"
													align="left"
												>
													{d.signing_id}
												</TableCell>
												<TableCell
													className="cursor-pointer left path"
													component="td"
													scope="row"
													align="left"
												>
													{d.desc}
												</TableCell>
												<TableCell
													className="cursor-pointer left path"
													component="td"
													scope="row"
													align="left"
												>
													{d.word}
												</TableCell>
												<TableCell
													className="cursor-pointer left path"
													component="td"
													scope="row"
													align="left"
												>
													{d.regex}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</FuseScrollbars>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
