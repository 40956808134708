import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { Icon } from '@material-ui/core';

import CalendarTextIcon from 'mdi-react/CalendarTextIcon';
import ClipboardTextClockOutlineIcon from 'mdi-react/ClipboardTextClockOutlineIcon';
import AlertOctagonOutlineIcon from 'mdi-react/AlertOctagonOutlineIcon';
import FileCogOutlineIcon from 'mdi-react/FileCogOutlineIcon';
import ApplicationCogOutlineIcon from 'mdi-react/ApplicationCogOutlineIcon';

import ApplicationOutlineIcon from 'mdi-react/ApplicationOutlineIcon';
import FileDocumentPlusOutlineIcon from 'mdi-react/FileDocumentPlusOutlineIcon';
import FileEditOutlineIcon from 'mdi-react/FileEditOutlineIcon';
import RenameOutlineIcon from 'mdi-react/RenameOutlineIcon';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import ContentDuplicateIcon from 'mdi-react/ContentDuplicateIcon';
import LinkBoxOutlineIcon from 'mdi-react/LinkBoxOutlineIcon';
import FileDocumentRemoveOutlineIcon from 'mdi-react/FileDocumentRemoveOutlineIcon';
import TrayRemoveIcon from 'mdi-react/TrayRemoveIcon';
import CogPlayOutlineIcon from 'mdi-react/CogPlayOutlineIcon';
import NetworkOutlineIcon from 'mdi-react/NetworkOutlineIcon';
import WebIcon from 'mdi-react/WebIcon';
import FileUploadOutlineIcon from 'mdi-react/FileUploadOutlineIcon';
import ContentSaveMoveOutlineIcon from 'mdi-react/ContentSaveMoveOutlineIcon';
import ContentSaveOffOutlineIcon from 'mdi-react/ContentSaveOffOutlineIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import StamperIcon from 'mdi-react/StamperIcon';
import CloseOctagonOutlineIcon from 'mdi-react/CloseOctagonOutlineIcon';
import ViewGridOutlineIcon from 'mdi-react/ViewGridOutlineIcon';
import ViewListOutlineIcon from 'mdi-react/ViewListOutlineIcon';
import KeyboardBackspaceIcon from 'mdi-react/KeyboardBackspaceIcon';

import { ReactComponent as SharedIcon } from './icon_shared.svg';

import { ReactComponent as ClientDownloadIcon } from './icon_client_download.svg';
import { ReactComponent as DownloadIcon } from './icon_download.svg';
import { ReactComponent as RefreshIcon } from './icon_refresh.svg';
import { ReactComponent as OrgIcon } from './icon_org.svg';
import { ReactComponent as PersonIcon } from './icon_person.svg';
import { ReactComponent as DefaultDeleteIcon } from './default_delete.svg';
import { ReactComponent as CollectGlobalIcon } from './icon_collect_global.svg';
import { ReactComponent as CollectPrivateIcon } from './icon_collect_private.svg';
import { ReactComponent as RestoreIcon } from './icon_restore.svg';
import { ReactComponent as SettingsIcon } from './icon_settings.svg';
import { ReactComponent as DupIcon } from './icon_dup.svg';
import { ReactComponent as LogFileIcon } from './icon_log_file.svg';
import { ReactComponent as LogAuditIcon } from './icon_log_audit.svg';
import { ReactComponent as OsrIcon } from './icon_osr.svg';
import { ReactComponent as PiiIcon } from './icon_pii.svg';
import { ReactComponent as RansomIcon } from './icon_ransom.svg';
import { ReactComponent as RealtimeIcon } from './icon_realtime.svg';
import { ReactComponent as SecdocIcon } from './icon_secdoc.svg';
import { ReactComponent as UsedocIcon } from './icon_usedoc.svg';
import { ReactComponent as LockIcon } from './icon_lock.svg';
import { ReactComponent as LockShieldIcon } from './icon_lock_shield.svg';

const useStyles = makeStyles({
	root: {
		display: 'inline-block',
		verticalAlign: 'middle',
		'&.small': {
			width: '16px',
			height: '16px',
			fontSize: '16px'
		}
	},
	fileIcon: {
		width: '20px',
		height: '20px',
		fontSize: '20px',
		margin: '0 auto',
		'&.small': {
			width: '16px',
			height: '16px',
			fontSize: '16px'
		},
		'&.big': {
			width: '24px',
			height: '24px',
			fontSize: '24px'
		},
		'&.large': {
			width: '32px',
			height: '32px',
			fontSize: '32px'
		},
		'&.huge': {
			width: '64px',
			height: '64px',
			fontSize: '64px'
		}
	},
	fileListIcon: {
		width: '35px',
		height: '35px',
		margin: '0 auto'
	}
});

function CustomIcon(props) {
	const classes = useStyles();
	let img;
	switch (props.type) {
		case 'realtime':
			img = <RealtimeIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'hold-file':
			img = <UsedocIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'log-file':
			img = <LogFileIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'log-audit':
			img = <LogAuditIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'osr':
			img = <OsrIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'secdoc':
			img = <SecdocIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'pii':
			img = <PiiIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'dup':
			img = <DupIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'ransom':
			img = <RansomIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'delete':
			img = <DefaultDeleteIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'collect-global':
			img = <CollectGlobalIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'collect-private':
			img = <CollectPrivateIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'restore':
			img = <RestoreIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'settings':
			img = <SettingsIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'person':
			img = <PersonIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'org-folder':
			img = <OrgIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'shared-folder':
			img = <SharedIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'refresh':
			img = <RefreshIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'download':
			img = <DownloadIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'client-download':
			img = <ClientDownloadIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'lock':
			img = <LockIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'lock-shield':
			img = <LockShieldIcon className={clsx(classes.fileIcon, props.className)} fill={props.fill} />;
			break;
		case 'calendar-text':
			img = <CalendarTextIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'clipboard-text-clock-outline':
			img = <ClipboardTextClockOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'alert-octagon-outline':
			img = <AlertOctagonOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'file-cog-outline':
			img = <FileCogOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'application-cog-outline':
			img = <ApplicationCogOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'application-outline':
			img = <ApplicationOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'file-document-plus-outline':
			img = <FileDocumentPlusOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'file-edit-outline':
			img = <FileEditOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'rename-outline':
			img = <RenameOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'content-copy':
			img = <ContentCopyIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'content-duplicate':
			img = <ContentDuplicateIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'link-box-outline':
			img = <LinkBoxOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'file-document-remove-outline':
			img = <FileDocumentRemoveOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'tray-remove':
			img = <TrayRemoveIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'cog-play-outline':
			img = <CogPlayOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'network-outline':
			img = <NetworkOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'web':
			img = <WebIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'file-upload-outline':
			img = <FileUploadOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'content-save-move-outline':
			img = <ContentSaveMoveOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'content-save-off-outline':
			img = <ContentSaveOffOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'email-outline':
			img = <EmailOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'stamper':
			img = <StamperIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'reject':
			img = <CloseOctagonOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;

		case 'view-grid':
			img = <ViewGridOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'view-list':
			img = <ViewListOutlineIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		case 'backspace':
			img = <KeyboardBackspaceIcon className={clsx(classes.fileIcon, props.className)} color={props.fill} />;
			break;
		default:
			img = <Icon className={clsx(classes.fileIcon, props.className)}>{props.type}</Icon>;
	}

	return <div className={clsx(classes.root, props.className)}>{img}</div>;
}

export default CustomIcon;
