import Logs from './Logs';

const LogsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/clientRunningLog',
			component: Logs
		}
	]
};

export default LogsConfig;
