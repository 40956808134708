import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'contents',
		'& .logo-icon': {
			marginRight: 18,
			paddingLeft: 22
		},
		'& .logo-icon>img': {
			width: '100%',
			'max-width': '54px',
			margin: '0 auto'
		},
		'& .react-badge, & .logo-text': {
			'& > p': {
				color: '#fff'
			},
			'& > p:nth-child(1)': {
				fontFamily: 'S-CoreDream-Bold',
				fontSize: 21,
				paddingTop: 5,
				fontWeight: 'bold'
			},
			'& > p:nth-child(2)': {
				fontFamily: 'S-CoreDream-Medi',
				fontSize: 14
			}
		}
	},
	reactBadge: {
		backgroundColor: '#121212',
		color: '#61DAFB'
	}
}));

function Logo() {
	const classes = useStyles();
	const { t } = useTranslation('brand');

	return (
		<div className={clsx(classes.root, 'items-center')}>
			<div className="inline logo-icon">
				<img className="" src="assets/brand/logo_menu_top.svg" alt="logo" />
			</div>
			<div className="inline logo-text">
				<p>{t('PRODUCT_NAME')}</p>
				<p>{t('PRODUCT_ADMIN')}</p>
			</div>
		</div>
	);
}

export default Logo;
