import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import { debounce } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableSortLabel,
	Checkbox,
	TableRow,
	Tabs,
	Tab,
	Paper,
	Input,
	Button
} from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import FuseLoading from '@fuse/core/FuseLoading';

import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import { getUserList } from 'app/store/docbase/admin/accountSlice';
import { getAllFoldersPermissions } from 'app/store/docbase/admin/settingSlice';
import {
	installedClientsInfo,
	getRecommendUpdateNewClient,
	setRecommendUpdateNewClient
} from 'app/store/docbase/admin/systemSlice';
import { getDirectOrgList } from 'app/store/docbase/admin/folderSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import commonUtils from 'app/utils/CommonUtils';
import OpenDialogForm from '../component/OpenDialogForm';
import OrgTreeView from '../component/OrgTreeView';

const useStyles = makeStyles(theme => ({
	root: { borderRadius: 6 },
	deleteIcon: {
		color: '#ff6565'
	},
	contentWrapper: {
		height: `${window.innerHeight - 274}px`,
		padding: '0 20px'
	},
	table: {
		'& .lock': {
			width: 80
		},
		'& .datetime': {
			width: 100
		}
	},
	icon: {
		width: 20,
		height: 20
	},
	btn: {
		width: '155px!important'
	}
}));

const osComp = (clientType, version) => {
	let os = 'win64';
	if (clientType === 1) {
		os = 'win32';
	} else if (clientType === 3) {
		os = 'mac';
	}
	return <p>{`${version}(${os})`}</p>;
};
const DrawTabTable = React.memo(
	({
		t,
		tabValue,
		selectedUsers,
		data,
		setupClientData,
		order,
		setSelectedUsers,
		handleRequestSort,
		selectUser,
		expandedItems,
		orgChildren,
		handleExpandClick,
		setOrgChildren,
		selectOrgs
	}) => {
		let table = <Table />;
		if (tabValue === 0) {
			table = (
				<Table stickyHeader className="" aria-labelledby="tableTitle">
					<TableHead>
						<TableRow>
							<TableCell padding="none" className="w-40 md:w-64 text-center z-99">
								<Checkbox
									color="secondary"
									size="small"
									indeterminate={selectedUsers.length > 0 && selectedUsers.length < data.length}
									checked={data.length !== 0 && selectedUsers.length === data.length}
									onChange={e => {
										const newSelected = [];
										if (e.target.checked) {
											data.forEach(d => {
												newSelected.push(d.user_id);
											});
										}
										setSelectedUsers(newSelected);
									}}
								/>
							</TableCell>

							<TableCell className="left" align="left" padding="default" sortDirection={order.direction}>
								<TableSortLabel
									active={order.id === 'id'}
									direction={order.direction}
									onClick={event => handleRequestSort(event, 'id')}
								>
									{t('USER')}
								</TableSortLabel>
							</TableCell>
							<TableCell className="left" align="left" padding="default" sortDirection={order.direction}>
								<TableSortLabel
									active={order.id === 'org'}
									direction={order.direction}
									onClick={event => handleRequestSort(event, 'org')}
								>
									{t('ORG_NAME')}
								</TableSortLabel>
							</TableCell>
							<TableCell className="" align="center" padding="default" sortDirection={order.direction}>
								<TableSortLabel
									active={order.id === 'recommend_type'}
									direction={order.direction}
									onClick={event => handleRequestSort(event, 'recommend_type')}
								>
									{t('UPDATE_NEW_CLIENT_LOCK')
										.split(' ')
										.map((word, index, arr) => (
											<>
												{word}
												{index < arr.length - 1 && <br />}
											</>
										))}
								</TableSortLabel>
							</TableCell>
							<TableCell className="" align="center" padding="default" sortDirection={order.direction}>
								<TableSortLabel
									active={order.id === 'client_info'}
									direction={order.direction}
									onClick={event => handleRequestSort(event, 'client_info')}
								>
									{`${t('CLIENT_INFO_VERSION')}(OS)`}
								</TableSortLabel>
							</TableCell>
							<TableCell className="" align="center" padding="default" sortDirection={order.direction}>
								{t('MAC_ADDRESS')}
							</TableCell>
							<TableCell
								className="table_col_date"
								align="center"
								padding="default"
								sortDirection={order.direction}
							>
								{t('CLIENT_INFO_INSTALL_AT')}
							</TableCell>
							<TableCell className="" align="center" padding="default" sortDirection={order.direction}>
								{t('CLIENT_INFO_IP')}
							</TableCell>
							<TableCell
								className="table_col_date"
								align="center"
								padding="default"
								sortDirection={order.direction}
							>
								{t('CLIENT_INFO_LAST_LOGIN_AT')}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{_.orderBy(
							data,
							[
								d => {
									switch (order.id) {
										case 'id': {
											return d.user_id;
										}
										case 'org': {
											return d.orgs;
										}
										case 'recommend_type': {
											return d.recommend_type;
										}
										case 'client_info': {
											return d.client_info ? d.client_info.installed_version : '';
										}
										default: {
											return d[order.id];
										}
									}
								}
							],
							[order.direction]
						).map(d => {
							const isSelected = selectedUsers.indexOf(d.user_id) >= 0;

							return (
								<TableRow
									className="cursor-pointer"
									hover
									role="checkbox"
									aria-checked={isSelected}
									tabIndex={-1}
									key={d.user_id}
									selected={isSelected}
									onClick={event => selectUser(event, d.user_id)}
								>
									<TableCell className="text-center table-checkbox" padding="none">
										<Checkbox
											color="secondary"
											size="small"
											checked={isSelected}
											onClick={event => event.stopPropagation()}
											onChange={event => selectUser(event, d.user_id)}
										/>
									</TableCell>
									<TableCell className="left" component="td" scope="row">
										{`${d.user_id}(${d.name})`}
									</TableCell>
									<TableCell className="left" component="td" scope="row">
										{d.orgs}
									</TableCell>
									<TableCell className="" component="td" scope="row" align="center">
										{d.recommend_type === 0 ? t('LOCK') : t('UNLOCK')}
									</TableCell>
									<TableCell className="" component="td" scope="row" align="center">
										{d.client_info ? osComp(d.client_info.client_type, d.client_info.installed_version) : ''}
									</TableCell>
									<TableCell className="" component="td" scope="row" align="center">
										{d.client_info ? <p>{d.client_info.mac_address}</p> : ''}
									</TableCell>
									<TableCell className="" component="td" scope="row" align="center">
										{d.client_info ? (
											<p>{`${commonUtils.getDateStringToLocalDate(d.client_info.installed_at)}`}</p>
										) : (
											''
										)}
									</TableCell>
									<TableCell className="" component="td" scope="row" align="center">
										{d.client_info ? <p>{`${d.client_info.ip_address}`}</p> : ''}
									</TableCell>
									<TableCell className="" component="td" scope="row" align="center">
										{d.client_info ? (
											<p>{`${commonUtils.getDateStringToLocalDate(d.client_info.last_logged_at)}`}</p>
										) : (
											''
										)}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			);
		} else if (tabValue === 1) {
			table = (
				<div className="flex flex-col">
					<Button onClick={e => handleExpandClick()} style={{ alignSelf: 'flex-end' }}>
						{expandedItems.length === 0 ? t('EXPAND_ALL') : t('COLLAPSE_ALL')}
					</Button>
					<OrgTreeView
						className="full"
						childrenObj={orgChildren}
						expandedItems={expandedItems}
						checkbox
						fnOnChangeChecked={(fid, pid, checked) => {
							const targetArrays = orgChildren[pid].children.map(child => {
								const obj = { ...child };
								if (obj.fid === fid) {
									obj.checked = checked;
								}
								return obj;
							});
							setOrgChildren({ ...orgChildren, [pid]: { children: targetArrays } });
							selectOrgs(_, fid);
						}}
						fnOnClickHandler={(e, node) => {}}
					/>
				</div>
			);
		} else {
			table = (
				<Table stickyHeader className="" aria-labelledby="tableTitle">
					<TableHead>
						<TableRow>
							<TableCell
								className="table_col_medi"
								align="center"
								padding="default"
								sortDirection={order.direction}
							>
								<TableSortLabel
									active={order.id === 'client_info'}
									direction={order.direction}
									onClick={event => handleRequestSort(event, 'client_info')}
								>
									{t('CLIENT_INFO_VERSION')}
								</TableSortLabel>
							</TableCell>

							<TableCell
								className="table_col_short"
								align="center"
								padding="default"
								sortDirection={order.direction}
							>
								{t('OS')}
							</TableCell>
							<TableCell
								className="table_col_medi"
								align="center"
								padding="default"
								sortDirection={order.direction}
							>
								{t('MAC_ADDRESS')}
							</TableCell>
							<TableCell
								className="table_col_medi"
								align="center"
								padding="default"
								sortDirection={order.direction}
							>
								<TableSortLabel
									active={order.id === 'install_at'}
									direction={order.direction}
									onClick={event => handleRequestSort(event, 'install_at')}
								>
									{t('CLIENT_INFO_INSTALL_AT')}
								</TableSortLabel>
							</TableCell>
							<TableCell
								className="table_col_medi"
								align="center"
								padding="default"
								sortDirection={order.direction}
							>
								{t('CLIENT_INFO_IP')}
							</TableCell>
							<TableCell
								className="table_col_medi"
								align="center"
								padding="default"
								sortDirection={order.direction}
							>
								<TableSortLabel
									active={order.id === 'login_at'}
									direction={order.direction}
									onClick={event => handleRequestSort(event, 'login_at')}
								>
									{t('CLIENT_INFO_LAST_LOGIN_AT')}
								</TableSortLabel>
							</TableCell>
							<TableCell className="left" align="left" padding="default" sortDirection={order.direction}>
								<TableSortLabel
									active={order.id === 'id'}
									direction={order.direction}
									onClick={event => handleRequestSort(event, 'id')}
								>
									{t('USER')}
								</TableSortLabel>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{_.orderBy(
							setupClientData,
							[
								d => {
									switch (order.id) {
										case 'id': {
											return d.last_logged_in_user_id;
										}
										case 'client_info': {
											return d.installed_version;
										}
										case 'install_at': {
											return d.installed_at;
										}
										case 'login_at': {
											return d.last_logged_at;
										}
										default: {
											return d[order.id];
										}
									}
								}
							],
							[order.direction]
						).map(d => {
							let os = 'win64';
							if (d.client_type === 1) {
								os = 'win32';
							} else if (d.client_type === 3) {
								os = 'mac';
							}

							return (
								<TableRow
									className="cursor-pointer"
									hover
									role="checkbox"
									tabIndex={-1}
									key={d.mac}
									onClick={event => selectUser(event, d.user_id)}
								>
									<TableCell className="" component="td" scope="row" align="center">
										{d.installed_version}
									</TableCell>
									<TableCell className="" component="td" scope="row" align="center">
										{os}
									</TableCell>
									<TableCell className="" component="td" scope="row" align="center">
										{d.mac_address}
									</TableCell>
									<TableCell className="" component="td" scope="row" align="center">
										{commonUtils.getDateStringToLocalDate(d.installed_at)}
									</TableCell>
									<TableCell className="" component="td" scope="row" align="center">
										{d.ip_address}
									</TableCell>
									<TableCell className="" component="td" scope="row" align="center">
										{commonUtils.getDateStringToLocalDate(d.last_logged_at)}
									</TableCell>
									<TableCell className="left" component="td" scope="row">
										{`${d.last_logged_in_user_id}(${d.last_logged_in_user_name})`}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			);
		}
		return table;
	}
);

function SetupInfoTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const { t } = useTranslation('docbase');

	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const { usersObj } = useSelector(({ docbase }) => docbase.admin.account);

	const [tabValue, setTabValue] = React.useState(0);
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState([]);
	const [data, setData] = useState([]);
	const [orgChildren, setOrgChildren] = useState({});
	const [orgChildrenEx, setOrgChildrenEx] = useState({});
	const [orgs, setOrgs] = useState([]);
	const [expandedItems, setExpandedItems] = useState([]);
	const [order, setOrder] = useState({
		id: 'user_id',
		direction: 'asc'
	});
	const [searchText, setSearchText] = React.useState('');

	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selectedOrgs, setSelectedOrgs] = useState([]);
	const [setupClientData, setSetupClientData] = useState([]);
	const [setupClientCnt, setSetupClientCnt] = useState(0);
	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 274}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		if (Object.keys(usersObj).length > 0) {
			getUserData();
		} else {
			dispatch(getUserList()).then(() => {});
		}
	}, [usersObj, dispatch]);

	useEffect(() => {
		if (users.length > 0) filterSearch(searchText);

		return () => {
			filterSearch.cancel();
		};
	}, [searchText]);

	useEffect(() => {
		setData(users);
	}, [users]);

	const filterSearch = useCallback(
		debounce(val => {
			try {
				if (val.length > 0) {
					if (tabValue === 0) {
						const temps = [];
						for (let i = 0; i < users.length; i++) {
							if (
								users[i].user_id.toLowerCase().includes(val) ||
								users[i].name.toLowerCase().includes(val) ||
								users[i].orgs.toLowerCase().includes(val)
							) {
								temps.push(users[i]);
							}
						}
						setData(temps);
					} else {
						setOrgChildren({
							0: { children: orgs.filter(folder => folder.name.toLowerCase().includes(val.toLowerCase())) }
						});
						setExpandedItems([0]);
					}
				} else if (tabValue === 0) {
					setData(users);
				} else {
					setOrgChildren(orgChildrenEx);
					const expanded = [];
					Object.keys(orgChildrenEx).forEach(key => {
						expanded.push(Number(key));
					});
					setExpandedItems(expanded);
				}
			} catch (e) {
				console.error(e);
			} finally {
				setLoading(false);
			}
		}, 500)
	);

	async function getUserData() {
		const tmpUsersObj = {};
		const tmpUsers = await getRecommendUpdateNewClient('', 0);
		const userClientInfo = await installedClientsInfo('');
		const foldersPerm = await getAllFoldersPermissions(true);
		const respOrgObj = await getDirectOrgList();
		const permKeys = Object.keys(foldersPerm);

		const expanded = [];
		Object.keys(respOrgObj.orgChildren).forEach(key => {
			expanded.push(Number(key));
		});
		setOrgChildren(respOrgObj.orgChildren);
		setOrgChildrenEx(respOrgObj.orgChildren);
		setOrgs(respOrgObj.orgs);
		setExpandedItems(expanded);

		let cnt = 0;
		tmpUsers.forEach(u => {
			u.name = usersObj[u.user_id] ? usersObj[u.user_id].name : '';
			u.client_info = userClientInfo.reduce((latest, current) => {
				if (current.last_logged_in_user_id === u.user_id) {
					if (!latest || new Date(current.last_logged_at) > new Date(latest.last_logged_at)) {
						return current;
					}
				}
				return latest;
			}, null);

			if (u.client_info) {
				cnt++;
			}

			let orgsString = '';
			permKeys.forEach(key => {
				if (foldersPerm[key] && foldersPerm[key].length > 0) {
					foldersPerm[key].forEach(p => {
						if (p.user_id === u.user_id) {
							orgsString += `${commonUtils.splitLastDelimiterString(p.disp_path)} | `;
						}
					});
				}
			});
			u.orgs = orgsString.substring(0, orgsString.length - 2);
			tmpUsersObj[u.user_id] = u;
			orgsString = '';
		});

		setSetupClientCnt(cnt);
		setUsers(tmpUsers);
		setSetupClientData(userClientInfo);
		setLoading(false);
	}

	function handleRequestSort(event, property) {
		const id = property;
		let direction = 'desc';

		if (order.id === property && order.direction === 'desc') {
			direction = 'asc';
		}

		setOrder({
			direction,
			id
		});
	}

	function selectUser(event, id) {
		const selectedIndex = selectedUsers.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedUsers, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selectedUsers.slice(1));
		} else if (selectedIndex === selectedUsers.length - 1) {
			newSelected = newSelected.concat(selectedUsers.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selectedUsers.slice(0, selectedIndex), selectedUsers.slice(selectedIndex + 1));
		}

		setSelectedUsers(newSelected);
	}
	function selectOrgs(event, id) {
		const selectedIndex = selectedOrgs.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedOrgs, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selectedOrgs.slice(1));
		} else if (selectedIndex === selectedOrgs.length - 1) {
			newSelected = newSelected.concat(selectedOrgs.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selectedOrgs.slice(0, selectedIndex), selectedOrgs.slice(selectedIndex + 1));
		}

		setSelectedOrgs(newSelected);
	}

	function handleExpandClick() {
		if (expandedItems.length === 0) {
			const expanded = [];
			Object.keys(orgChildren).forEach(key => {
				expanded.push(Number(key));
			});
			setExpandedItems(expanded);
		} else {
			setExpandedItems([]);
		}
	}

	if (loading) {
		return <FuseLoading />;
	}

	function openConfirm(isAll, recommendType) {
		let content = '';
		if (!isAll && selectedUsers.length === 0 && selectedOrgs.length === 0) {
			dispatch(showErrorMessage({ message: t('UPDATE_NEW_CLIENT_MESSAGE_5') }));
			return false;
		}

		if (!isAll) {
			if (recommendType === 0) {
				content =
					tabValue === 0
						? t('UPDATE_NEW_CLIENT_MESSAGE_1').replace('%d', selectedUsers.length)
						: t('UPDATE_NEW_CLIENT_MESSAGE_6').replace('%d', selectedOrgs.length);
			} else {
				content =
					tabValue === 0
						? t('UPDATE_NEW_CLIENT_MESSAGE_2').replace('%d', selectedUsers.length)
						: t('UPDATE_NEW_CLIENT_MESSAGE_7').replace('%d', selectedOrgs.length);
			}
		} else if (recommendType === 0) {
			content = t('UPDATE_NEW_CLIENT_MESSAGE_3');
		} else {
			content = t('UPDATE_NEW_CLIENT_MESSAGE_4');
		}
		dispatch(
			openDialog({
				children: (
					<OpenDialogForm
						title={t('UPDATE_NEW_CLIENT_LOCK')}
						content={content}
						fnHandleOK={() => {
							setUpdateNewclient(isAll, recommendType);
						}}
					/>
				)
			})
		);
		return true;
	}

	/**
	 *
	 * @param {*} recommendType 0: 잠금, 1: 잠금해제
	 */
	async function setUpdateNewclient(isAll, recommendType) {
		setLoading(true);
		if (isAll) {
			for (let i = 0; i < users.length; i++) {
				// eslint-disable-next-line no-await-in-loop
				await setRecommendUpdateNewClient(users[i].user_id, 0, recommendType);
			}
		} else if (tabValue === 0) {
			for (let i = 0; i < selectedUsers.length; i++) {
				// eslint-disable-next-line no-await-in-loop
				await setRecommendUpdateNewClient(selectedUsers[i], 0, recommendType);
			}
		} else {
			for (let i = 0; i < selectedOrgs.length; i++) {
				// eslint-disable-next-line no-await-in-loop
				await setRecommendUpdateNewClient('', selectedOrgs[i], recommendType);
			}
		}
		getUserData();
	}

	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className="table-search-wrapper">
					<div className="">
						<div className="input-item">
							<Input
								placeholder={t('SEARCH')}
								className="search-input"
								disableUnderline
								fullWidth
								value={searchText}
								inputProps={{
									'aria-label': 'Search'
								}}
								onChange={ev => setSearchText(ev.target.value)}
							/>
						</div>
					</div>
					<div className="total-cnt-info">
						<span className="pipe">|</span>
						<span>
							{t('TOTAL')} <span className="count">{data.length}</span>
							{t('CNT')}
						</span>
						<span className="pipe">|</span>
						<span>
							{t('설치한 사용자')} <span className="count">{setupClientCnt}</span>
						</span>
					</div>
					{tabValue !== 2 ? (
						<div className="btn-group">
							<div>
								<Button
									variant="contained"
									className="default"
									type="button"
									onClick={() => openConfirm(false, 0)}
								>
									{t('UPDATE_NEW_CLIENT_LOCK')}
								</Button>
							</div>
							<div>
								<Button
									variant="contained"
									className="default"
									type="button"
									onClick={() => openConfirm(false, 1)}
								>
									{t('UPDATE_NEW_CLIENT_UNLOCK')}
								</Button>
							</div>
							<div>
								<Button
									variant="contained"
									className="default"
									type="button"
									onClick={() => openConfirm(true, 0)}
								>
									{t('UPDATE_NEW_CLIENT_ALL_LOCK')}
								</Button>
							</div>
							<div>
								<Button
									variant="contained"
									className={clsx(classes.btn, 'default')}
									type="button"
									onClick={() => openConfirm(true, 1)}
								>
									{t('UPDATE_NEW_CLIENT_ALL_UNLOCK')}
								</Button>
							</div>
						</div>
					) : (
						<></>
					)}
				</div>
				<div className="type-tabs">
					<Tabs
						value={tabValue}
						onChange={(ev, val) => {
							setTabValue(val);
							setSearchText('');
						}}
						indicatorColor="secondary"
						textColor="inherit"
						variant="scrollable"
						scrollButtons="off"
						classes={{ indicator: 'active_bar' }}
					>
						<Tab className="list-tab" disableRipple label={t('USER')} />
						<Tab className="list-tab" disableRipple label={t('ORG_TITLE')} />
						<Tab className="list-tab" disableRipple label={t('SETUP_SETTING')} />
					</Tabs>
				</div>
				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<DrawTabTable
						t={t}
						tabValue={tabValue}
						selectedUsers={selectedUsers}
						data={data}
						setupClientData={setupClientData}
						order={order}
						setSelectedUsers={obj => setSelectedUsers(obj)}
						handleRequestSort={(e, p) => handleRequestSort(e, p)}
						selectUser={(e, userID) => selectUser(e, userID)}
						expandedItems={expandedItems}
						orgChildren={orgChildren}
						handleExpandClick={() => handleExpandClick()}
						setOrgChildren={children => setOrgChildren(children)}
						selectOrgs={(e, fid) => selectOrgs(e, fid)}
					/>
				</FuseScrollbars>
			</Paper>
		</div>
	);
}

export default SetupInfoTable;
