import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import TakeoutsTable from './TakeoutsTable';
import DetailSidebarContent from './DetailSidebarContent';
import ContentHeader from '../component/ContentHeader';

function TakeoutsPage() {
	const [loading, setLoading] = React.useState(true);
	const [selectedObj, setSelectedObj] = React.useState({
		requester_name: '',
		requester: '',
		approver_name: '',
		approver: '',
		requested_at: '',
		approved_at: '',
		request_type: 0,
		status: 0,
		reason_requested: '',
		reason_rejected: ''
	});
	const pageLayout = React.useRef(null);

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={
				<TakeoutsTable
					loading={loading}
					setLoading={setLoading}
					selectedObj={selectedObj}
					fnSetSelectedObj={obj => setSelectedObj(obj)}
					fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
				/>
			}
			rightSidebarContent={
				<DetailSidebarContent
					loading={loading}
					setLoading={setLoading}
					selectedObj={selectedObj}
					fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
				/>
			}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default TakeoutsPage;
