import securityUtils from '../../utils/SecurityUtils';
import httpClient from '../../utils/HttpClient';

export const secureRequest = fnNextWork => {
	const keyObj = securityUtils.createECDH();
	httpClient
		.sendPost('/api/exchangePublicKey', { public_key: keyObj.getPublicKey('hex') })
		.then(data => {
			if (data.result) {
				const aesKey = securityUtils.computeSecretKey(keyObj, data.public_key);
				fnNextWork(
					aesKey,
					securityUtils.encryptAES(
						aesKey,
						securityUtils
							.decryptAES(securityUtils.computeSecretKey(keyObj, data.public_key), data.token, 'hex', 'utf8')
							.replace(':remove-this', ''),
						'utf8',
						'hex'
					)
				);
			} else httpClient.printError(data);
		})
		.catch(err => console.error(err));
};

export const secureAsyncRequest = async () => {
	const keyObj = securityUtils.createECDH();
	const respData = await httpClient.sendPost('/api/exchangePublicKey', { public_key: keyObj.getPublicKey('hex') });
	if (!respData.result) {
		httpClient.printError(respData);
		return {};
	}

	const aesKey = securityUtils.computeSecretKey(keyObj, respData.public_key);
	return {
		aesKey,
		token: securityUtils.encryptAES(
			aesKey,
			securityUtils.decryptAES(aesKey, respData.token, 'hex', 'utf8').replace(':remove-this', ''),
			'utf8',
			'hex'
		)
	};
};

export const getWeblinkInfo = async id => {
	const respData = await httpClient.sendPost('/api/getWeblinkInfo', { id });
	// const respData = { resuglt: true, status: 1, pasword_protected: true };
	return respData;
};
