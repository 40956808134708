import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, TablePagination, Paper, Tooltip, IconButton } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { inquiryTakeouts, inquiryTakeoutsPage, changeTakeoutsRowsPerPage } from 'app/store/docbase/admin/systemSlice';
import CustomIcon from 'app/main/icon/CustomIcon';
import commonUtils from 'app/utils/CommonUtils';
import * as commonTypes from 'app/main/constants/CommonTypes';
import TakeoutsTableHead from './TakeoutsTableHead';

const useStyles = makeStyles(theme => ({
	root: { marginRight: 30, borderRadius: 6 },
	contentWrapper: {
		height: `${window.innerHeight - 296}px`,
		padding: '0 20px'
	}
}));

function TakeoutsTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
	const { t } = useTranslation('docbase');

	const { takeouts, rowsPerPage, currentPage, totalRows } = useSelector(({ docbase }) => docbase.admin.system.takeoutsObj);

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 296}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		dispatch(inquiryTakeouts({ page: 1 })).then(() => {
			props.setLoading(false);
		});
	}, [dispatch]);

	function handleRowClick(takeoutObj) {
		props.fnSetSelectedObj(takeoutObj);
		props.fnToggleRightSidebar();
	}

	if (props.loading) {
		return <FuseLoading />;
	}

	function handleChangePage(event, value) {
		props.setLoading(true);
		dispatch(inquiryTakeoutsPage({ page: value + 1 })).then(() => props.setLoading(false));
	}
	function handleChangeRowsPerPage(event) {
		props.setLoading(true);
		dispatch(changeTakeoutsRowsPerPage({ value: event.target.value }));
		dispatch(inquiryTakeouts({ page: 1 })).then(() => props.setLoading(false));
	}
	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className="table-search-wrapper">
					<div className="total-cnt-info">
						<span>
							{t('TOTAL')} <span className="count">{totalRows}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="search-form-wrapper">
						<div className="">
							<Tooltip title={t('REFRESH')} placement="bottom">
								<IconButton
									key="refresh"
									aria-label="refresh"
									color="secondary"
									onClick={() => {
										dispatch(inquiryTakeouts({ page: 1 })).then(() => {
											props.setLoading(false);
										});
									}}
								>
									<CustomIcon className="small" type="refresh" fill="#333d4b" />
								</IconButton>
							</Tooltip>
						</div>
					</div>
				</div>
				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<TakeoutsTableHead />

						<TableBody>
							{takeouts.map(to => {
								let reqType = t('TAKEOUT_DOC');
								let status = t('TAKEOUT_REQUEST');
								switch (to.request_type) {
									case commonTypes.TAKEOUT_REQUEST_TYPE_PRINT:
										reqType = t('TAKEOUT_PRINT');
										break;
									case commonTypes.TAKEOUT_REQUEST_TYPE_WEBLINK:
										reqType = t('TAKEOUT_WEBLINK');
										break;
									default:
								}
								switch (to.status) {
									case commonTypes.TAKEOUT_REQUEST_STATUS_APPROVED:
										status = t('TAKEOUT_APPROVED');
										break;
									case commonTypes.TAKEOUT_REQUEST_STATUS_APPROVED_AUTO:
										status = t('TAKEOUT_APPROVED_AUTO');
										break;
									case commonTypes.TAKEOUT_REQUEST_STATUS_REJECTED:
										status = t('TAKEOUT_REJECTED');
										break;
									case commonTypes.TAKEOUT_REQUEST_STATUS_WITHDRAWN:
										status = t('TAKEOUT_WITHDRAWN');
										break;
									default:
								}
								return (
									<TableRow
										className={clsx(classes.tr, 'cursor-pointer')}
										hover
										role="checkbox"
										tabIndex={-1}
										key={to.toid}
										onClick={event => handleRowClick(to)}
										selected={props.selectedObj.toid === to.toid}
									>
										<TableCell className="left" align="left" component="td" scope="row">
											{commonUtils.getDateStringToLocalDate(to.requested_at)}
										</TableCell>
										<TableCell className="left" align="left" component="td" scope="row">
											{`${to.requester_name}(${to.requester})`}
										</TableCell>
										<TableCell className="left" component="td" scope="row">
											{`${to.approver_name}(${to.approver})`}
										</TableCell>
										<TableCell className="left" component="td" scope="row">
											{reqType}
										</TableCell>
										<TableCell className="left" component="td" scope="row">
											{status}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>

				<TablePagination
					className="flex-shrink-0 border-t-1 mx-20"
					component="div"
					count={totalRows}
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={[100, 250, 500]}
					page={currentPage - 1}
					labelRowsPerPage={t('ROW_PER_PAGE_LABEL')}
					labelDisplayedRows={({ from, to, count }) => `${count !== -1 ? count : to} 중 ${from}-${to}`}
					backIconButtonProps={{
						'aria-label': t('PAGE_PREV_BTN_LABEL')
					}}
					nextIconButtonProps={{
						'aria-label': t('PAGE_NEXT_BTN_LABEL')
					}}
					onChangePage={(e, val) => {
						handleChangePage(e, val);
						contentTable.current.scrollTop = 0;
					}}
					onChangeRowsPerPage={e => {
						handleChangeRowsPerPage(e);
						contentTable.current.scrollTop = 0;
					}}
				/>
			</Paper>
		</div>
	);
}

export default TakeoutsTable;
