import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { Icon } from '@material-ui/core';
import permSharedIcon from './icon_shared.svg';
import permDeleteIcon from './icon_delete.svg';
import permTrashIcon from './icon_trash.svg';
import permWriteIcon from './icon_write.svg';
import permOrgIcon from './icon_org.svg';
import permPersonIcon from './icon_person.svg';

const useStyles = makeStyles({
	root: {
		display: 'inline-block',
		verticalAlign: 'middle',
		maxWidth: 'inherit',
		width: '20px',
		height: '20px',
		'&.small': {
			width: '16px',
			height: '16px',
			fontSize: '16px'
		}
	},
	fileIcon: {
		width: '20px',
		height: '20px',
		fontSize: '20px',
		maxWidth: 'inherit',
		margin: '0 auto',
		'&.small': {
			width: '16px',
			height: '16px',
			fontSize: '16px'
		}
	},
	fileListIcon: {
		width: '35px',
		height: '35px',
		margin: '0 auto'
	}
});

function PermIcon(props) {
	const classes = useStyles();
	let img;

	switch (props.type) {
		case 'write':
			img = <img className={clsx(classes.fileIcon, props.className)} src={permWriteIcon} alt="file-icon" />;
			break;
		case 'delete':
			img = <img className={clsx(classes.fileIcon, props.className)} src={permDeleteIcon} alt="file-icon" />;
			break;
		case 'readonly':
			img = <Icon className={clsx(classes.fileIcon, props.className)}>article</Icon>;
			break;
		case 'trash':
			img = <Icon className={clsx(classes.fileIcon, props.className)}>delete_forever</Icon>;
			break;
		case 'trash-delete':
			img = <img className={clsx(classes.fileIcon, props.className)} src={permTrashIcon} alt="file-icon" />;
			break;
		case 'org':
			img = <img className={clsx(classes.fileIcon, props.className)} src={permOrgIcon} alt="file-icon" />;
			break;
		case 'shared':
			img = <img className={clsx(classes.fileIcon, props.className)} src={permSharedIcon} alt="file-icon" />;
			break;
		case 'person':
			img = <img className={clsx(classes.fileIcon, props.className)} src={permPersonIcon} alt="file-icon" />;
			break;
		case 'org-shared':
			img = <Icon className={clsx(classes.fileIcon, props.className)}>snippet_folder</Icon>;
			break;
		case 'visibility-off':
			img = <Icon className={clsx(classes.fileIcon, props.className)}>visibility_off</Icon>;
			break;
		case 'visibility':
			img = <Icon className={clsx(classes.fileIcon, props.className)}>visibility</Icon>;
			break;
		default:
			img = <Icon className={clsx(classes.fileIcon, props.className)}>snippet_folder</Icon>;
	}

	return <div className={clsx(classes.root, props.className)}>{img}</div>;
}

export default PermIcon;
