import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, FormGroup, FormControlLabel, Checkbox, DialogContent, DialogTitle, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 604
		}
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	body: {
		background: '#f2f3f5',
		padding: '24px 30px 30px',
		color: '#353b48',
		'&>.content': {
			background: '#ffffff',
			borderRadius: 6,
			padding: 40
		},
		'&>.content>.row': {
			display: 'flex',
			alignItems: 'center',
			margin: '15px 0'
		},
		'&>.content>.row:first-child': {
			marginTop: 0
		},
		'&>.content>.row:last-child': {
			marginBottom: 0
		},
		'&>.content>.row>.title': {
			width: 136,
			cursor: 'default',
			fontSize: 14
		},
		'&>.content>.row>.title>span:before': {
			color: '#00cfe2',
			content: '"*"',
			marginRight: '5px'
		},
		'&>.content>.row>.input-area': {
			width: '100%',
			maxWidth: 382,
			cursor: 'default',
			fontSize: 14
		}
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 40,
		'& .MuiButton-root': {
			width: 172,
			height: 54
		},
		'& .MuiButton-root:first-child': {
			marginRight: 16
		}
	}
}));

export default function DeleteUserModal(props) {
	const { t } = useTranslation('docbase');
	const classes = useStyles();

	const [types, setTypes] = React.useState([]);

	React.useEffect(() => {
		const splitType = props.selectedTypes === '' ? [] : props.selectedTypes.split(',');

		setTypes(
			splitType.length > 0
				? splitType.map(obj => {
						if (obj !== '') {
							return obj;
						}
						return '';
				  })
				: props.accessType.map(at => at.type.toString())
		);
	}, [props.open]);

	return (
		<div>
			<Dialog className={classes.root} open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog-title">
				<DialogTitle className={classes.title} id="form-dialog-title">
					{t('ACCESS_TYPE')}
				</DialogTitle>
				<DialogContent className={classes.body}>
					<div className="content">
						<FormGroup row>
							{props.accessType.map(at => {
								return (
									<FormControlLabel
										control={
											<Checkbox
												checked={types.indexOf(at.type.toString()) > -1}
												onChange={e => {
													if (at.type === 0) {
														const tex = [];
														if (types.indexOf('0') === -1) {
															for (let i = 0; i < props.accessType.length; i++) {
																tex.push(props.accessType[i].type.toString());
															}
														}
														setTypes(tex);
													} else {
														const tex = [...types];
														const idx = types.indexOf(at.type.toString());
														if (idx >= 0) {
															tex.splice(idx, 1);
														} else {
															tex.push(at.type.toString());
														}

														if (tex.length <= 10) {
															const d = tex.indexOf('0');
															if (d > -1) {
																tex.splice(d, 1);
															}
														}
														setTypes(tex);
													}
												}}
												name={`at_${at.cons}`}
											/>
										}
										label={t(at.cons)}
									/>
								);
							})}
						</FormGroup>
						<div className={classes.buttonWrapper}>
							<Button
								onClick={props.fnHandleClose}
								variant="contained"
								className="default"
								aria-label="cancel"
								type="button"
							>
								{t('CANCEL')}
							</Button>
							<Button
								variant="contained"
								color="secondary"
								className=""
								type="button"
								onClick={e => {
									props.fnHandleOK(types);
								}}
							>
								{t('APPLY')}
							</Button>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
