import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import ContentHeader from '../component/ContentHeader';
import SecureDocsTable from './SecureDocsTable';
import DetailSidebarContent from './DetailSidebarContent';

function SecureDocsPage() {
	const [loading, setLoading] = React.useState(true);
	const pageLayout = React.useRef(null);

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={
				<SecureDocsTable
					loading={loading}
					setLoading={setLoading}
					fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
				/>
			}
			rightSidebarContent={
				<DetailSidebarContent
					loading={loading}
					setLoading={setLoading}
					fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
				/>
			}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default SecureDocsPage;
