import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import FuseUtils from '@fuse/utils';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { navbarCloseMobile } from 'app/store/fuse/navbarSlice';
import CustomIcon from 'app/main/icon/CustomIcon';
import FuseNavBadge from '../FuseNavBadge';

const useStyles = makeStyles(theme => {
	return {
		item: props => ({
			height: 40,
			width: '100%',
			paddingRight: 40,
			paddingLeft: 50,
			'&.active': {
				color: `${theme.palette.secondary.main}!important`,
				pointerEvents: 'none',
				transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)'
			},
			'& .list-item-icon': {
				lineHeight: 1,
				width: 20,
				height: 20
			},
			'& .list-item-text': { marginLeft: 24, '&>span': { fontSize: 13 } },
			color: '#dadbdd',
			cursor: 'pointer',
			textDecoration: 'none!important'
		})
	};
});

function FuseNavVerticalItem(props) {
	const userRole = useSelector(({ auth }) => auth.user.role);
	const productInfo = useSelector(({ auth }) => auth.initial.productInfo);
	const dispatch = useDispatch();

	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down('md'));
	const { item, nestedLevel, location } = props;
	const classes = useStyles({
		itemPadding: nestedLevel > 0 ? 40 + nestedLevel * 16 : 24
	});

	const hasPermission = useMemo(() => FuseUtils.hasPermission(item.auth, userRole), [item.auth, userRole]);

	const { sysConfigure } = useSelector(({ docbase }) => docbase.admin.setting);

	if (!hasPermission) {
		return null;
	}

	if (!sysConfigure.OSR_support && item.id === 'osr_management') {
		return <></>;
	}

	if (
		!(sysConfigure.dataleak_control || sysConfigure.access_control || sysConfigure.saving_control) &&
		(item.id === 'takeout_management' ||
			item.id === 'export_approval_management' ||
			item.id === 'offline_drive_management' ||
			item.id === 'export_approval_management')
	) {
		return <></>;
	}

	if (
		!(productInfo.supportDataleakControl || productInfo.supportAccessControl || productInfo.supportSavingControl) &&
		item.id === 'white_process_management'
	) {
		// if (
		// 	!sysConfigure.support_macos_dlp &&
		// 	(item.id === 'dlp_client_info' ||
		// 		item.id === 'dlp_policy_setting' ||
		// 		item.id === 'dlp_group_setting' ||
		// 		item.id === 'releaseBlockings' ||
		// 		item.id === 'printLog' ||
		// 		item.id === 'clientAccessLog' ||
		// 		item.id === 'clientMonitoringLog')
		// ) {
		// 	return <></>;
		// }
		return <></>;
	}

	if (
		sysConfigure.menu_support_type === 2 &&
		(item.id === 'white_process_management' ||
			item.id === 'setup_info' ||
			item.id === 'holdFiles' ||
			item.id === 'instantLog' ||
			item.id === 'clientRunningLog')
	) {
		return <></>;
	}

	return (
		<ListItem
			button
			component={NavLinkAdapter}
			to={item.url}
			activeClassName="active"
			className={clsx(classes.item, 'list-item')}
			onClick={ev => mdDown && dispatch(navbarCloseMobile())}
			exact={item.exact}
		>
			{/* {item.icon && <Icon className="list-item-icon flex-shrink-0">{item.icon}</Icon>} */}
			<CustomIcon
				className="list-item-icon"
				type={item.icon}
				fill={location.pathname === item.url ? '#00cfe2' : '#dadbdd'}
			/>
			<ListItemText className="list-item-text" primary={item.title} />

			{item.badge && <FuseNavBadge badge={item.badge} />}
		</ListItem>
	);
}

FuseNavVerticalItem.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string,
		icon: PropTypes.string,
		url: PropTypes.string
	})
};

FuseNavVerticalItem.defaultProps = {};

const NavVerticalItem = withRouter(React.memo(FuseNavVerticalItem));

export default NavVerticalItem;
