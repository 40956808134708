/**
 * Authorization Roles
 */
const authRoles = {
	admin: ['admin'],
	noAdmin: ['no-admin'],
	noProductkey: ['no-productkey'],
	onlyGuest: []
};

export default authRoles;
