import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import { useDispatch } from 'react-redux';
import commonUtils from 'app/utils/CommonUtils';
import { searchPiiDocs } from 'app/store/docbase/admin/settingSlice';
import ContentHeader from '../component/ContentHeader';
import PiiDocsTable from './PiiDocsTable';

function PiiDocs() {
	const [loading, setLoading] = React.useState(true);
	const pageLayout = React.useRef(null);
	const dispatch = useDispatch();

	const [searchObj, setSearchObj] = React.useState({
		searchName: '',
		startDate: commonUtils.getLastWeek(),
		endDate: new Date()
	});

	React.useEffect(() => {
		doSearch();
	}, [searchObj]);

	function doSearch() {
		dispatch(
			searchPiiDocs({
				page: 1,
				startDate: commonUtils.getDateString(searchObj.startDate),
				endDate: commonUtils.getDateString(searchObj.endDate),
				searchName: searchObj.searchName
			})
		).then(() => {
			setLoading(false);
		});
	}

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={
				<PiiDocsTable
					loading={loading}
					searchObj={searchObj}
					setLoading={setLoading}
					fnSetSearchObj={(name, value) => setSearchObj({ ...searchObj, [name]: value })}
					fnDoSearch={() => doSearch()}
				/>
			}
			// rightSidebarContent={
			// 	<DetailSidebarContent
			// 		loading={loading}
			// 		setLoading={setLoading}
			// 		fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
			// 	/>
			// }
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default PiiDocs;
