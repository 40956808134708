import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, FormGroup, FormControlLabel, Checkbox, DialogContent, DialogTitle, Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 604
		}
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	body: {
		background: '#f2f3f5',
		padding: '24px 30px 30px',
		color: '#353b48',
		'&>.content': {
			background: '#ffffff',
			borderRadius: 6,
			padding: 20
		},
		'&>.content>.row': {
			display: 'flex',
			marginTop: 10
		},
		'&>.content>.row:first-child': {
			marginTop: 0
		},
		'&>.content>.row:last-child': {
			marginBottom: 0
		},
		'&>.content>.row>.title': {
			width: 100,
			cursor: 'default',
			fontSize: 14,
			lineHeight: '28px'
		},
		'&>.content>.row>.input-area': {
			width: '100%',
			maxWidth: 382,
			cursor: 'default',
			fontSize: 14
		},
		'&>.content>.row>.input-area>.MuiFormControlLabel-root': {
			marginLeft: 0,
			marginRight: 5
		}
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 20,
		'& .MuiButton-root': {
			// width: 172,
			// height: 54
		},
		'& .MuiButton-root:first-child': {
			marginRight: 16
		}
	}
}));

export default function DeleteUserModal(props) {
	const { t } = useTranslation('docbase');
	const classes = useStyles();

	const [selectedCond, setSelectedCond] = React.useState({ file_types: [], log_types: [], includes: '', excludes: '' });

	React.useEffect(() => {
		let inc = '';
		let exc = '';
		props.conditionsObj.includes.forEach((o, idx) => {
			if (idx > 0) {
				inc += ',';
			}
			inc += o;
		});
		props.conditionsObj.excludes.forEach((o, idx) => {
			if (idx > 0) {
				exc += ',';
			}
			exc += o;
		});

		setSelectedCond({
			file_types: props.conditionsObj.file_types,
			log_types: props.conditionsObj.log_types,
			includes: inc,
			excludes: exc
		});
	}, [props.open]);

	function drawCheckBox(group, id) {
		let label = '';
		switch (id) {
			case 'engine':
				label = t('Engine');
				break;
			case 'collector':
				label = t('Collector');
				break;
			case 'agent':
				label = t('Agent');
				break;
			case 'server':
				label = t('Server');
				break;
			case 'trace':
				label = t('Trace');
				break;
			case 'info':
				label = t('Information');
				break;
			case 'warn':
				label = t('Warning');
				break;
			case 'err':
				label = t('Error');
				break;
			case 'critical':
				label = t('Critical');
				break;
			default:
				label = t('');
		}
		return (
			<FormControlLabel
				control={
					<Checkbox
						checked={selectedCond[group].indexOf(id) >= 0}
						onChange={e => {
							const targets = selectedCond[group];
							const idx = targets.indexOf(id);
							if (idx === -1) {
								targets.push(id);
							} else {
								targets.splice(idx, 1);
							}
							setSelectedCond({ ...selectedCond, [group]: targets });
						}}
						name={id}
					/>
				}
				label={label}
			/>
		);
	}

	return (
		<div>
			<Dialog className={classes.root} open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog-title">
				<DialogTitle className={classes.title} id="form-dialog-title">
					{t('INSTANT_LOGS_TITLE')}
				</DialogTitle>
				<DialogContent className={classes.body}>
					<div className="content">
						<FormGroup row className="row">
							<div className="title">
								<span>{t('INSTANT_LOGS_FILE_TYPES')}</span>
							</div>
							<div className="input-area">
								{drawCheckBox('file_types', 'engine')}
								{drawCheckBox('file_types', 'collector')}
								{drawCheckBox('file_types', 'agent')}
								{drawCheckBox('file_types', 'server')}
							</div>
						</FormGroup>
						<FormGroup row className="row">
							<div className="title">
								<span>{t('INSTANT_LOGS_LOG_TYPES')}</span>
							</div>
							<div className="input-area">
								{drawCheckBox('log_types', 'trace')}
								{drawCheckBox('log_types', 'info')}
								{drawCheckBox('log_types', 'warn')}
								{drawCheckBox('log_types', 'err')}
								{drawCheckBox('log_types', 'critical')}
							</div>
						</FormGroup>
						<div className="row">
							<div className="title">
								<span>{t('INSTANT_LOGS_INCLUDES')}</span>
							</div>
							<div className="input-area">
								<TextField
									className=""
									type="text"
									placeholder={t('INSTANT_LOGS_INCLUDES')}
									name="includes"
									fullWidth
									value={selectedCond.includes}
									variant="outlined"
									onChange={e => setSelectedCond({ ...selectedCond, includes: e.target.value })}
									size="small"
								/>
							</div>
						</div>
						<div className="row">
							<div className="title">
								<span>{t('INSTANT_LOGS_EXCLUDES')}</span>
							</div>
							<div className="input-area">
								<TextField
									className=""
									type="text"
									placeholder={t('INSTANT_LOGS_EXCLUDES')}
									name="excludes"
									fullWidth
									value={selectedCond.excludes}
									variant="outlined"
									onChange={e => setSelectedCond({ ...selectedCond, excludes: e.target.value })}
									size="small"
								/>
							</div>
						</div>
						<div className={classes.buttonWrapper}>
							<Button
								onClick={props.fnHandleClose}
								variant="contained"
								className="default"
								aria-label="cancel"
								type="button"
							>
								{t('CANCEL')}
							</Button>
							<Button
								variant="contained"
								color="secondary"
								className=""
								type="button"
								onClick={e => {
									props.fnHandleOK(selectedCond);
								}}
							>
								{t('APPLY')}
							</Button>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
