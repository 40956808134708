import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, TablePagination, Paper, Tooltip, IconButton } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { activeOfflineDrives, getUsersObj } from 'app/store/docbase/admin/accountSlice';
import CustomIcon from 'app/main/icon/CustomIcon';
import commonUtils from 'app/utils/CommonUtils';
import * as commonTypes from 'app/main/constants/CommonTypes';
import securityUtils from 'app/utils/SecurityUtils';
import OfflineDriveTableHead from './OfflineDriveTableHead';

const useStyles = makeStyles(theme => ({
	root: { marginRight: 30, borderRadius: 6 },
	contentWrapper: {
		height: `${window.innerHeight - 296}px`,
		padding: '0 20px'
	}
}));

const isFirst = true;
function OfflineDriveTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const contentTable = React.useRef();
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
	const { t } = useTranslation('docbase');
	const [data, setData] = React.useState([]);
	const [totalRows, setTotalRows] = React.useState(0);

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 296}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		getRequestList();
	}, [dispatch]);

	const getRequestList = async () => {
		const usersObj = await getUsersObj();
		const respRequest = await activeOfflineDrives();

		if (respRequest.result) {
			setData(
				respRequest.requests.map(req => {
					let txt = '';
					switch (req.status) {
						case 0:
							txt = t('TAKEOUT_REQUEST');
							break;
						case 1:
							txt = t('TAKEOUT_APPROVED');
							break;
						case 2:
							txt = t('TAKEOUT_REJECTED');
							break;
						default:
					}
					req.user_id = securityUtils.decryptAES(respRequest.aes, req.user_id, 'hex', 'utf8');
					req.offline_map_path = securityUtils.decryptAES(respRequest.aes, req.offline_map_path, 'hex', 'utf8');
					req.reason = securityUtils.decryptAES(respRequest.aes, req.reason, 'hex', 'utf8');
					req.statusTxt = txt;
					req.userName = usersObj[req.user_id].name;
					return req;
				})
			);
			setTotalRows(respRequest.requests.length);
			props.setLoading(false);
		}
	};

	function handleRowClick(requestObj) {
		props.fnSetSelectedObj(requestObj);
		props.fnToggleRightSidebar();
	}

	if (props.loading) {
		return <FuseLoading />;
	}

	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className="table-search-wrapper">
					<div className="total-cnt-info">
						<span>
							{t('TOTAL')} <span className="count">{totalRows}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="search-form-wrapper">
						<div className="">
							<Tooltip title={t('REFRESH')} placement="bottom">
								<IconButton
									key="refresh"
									aria-label="refresh"
									color="secondary"
									onClick={() => {
										getRequestList();
									}}
								>
									<CustomIcon className="small" type="refresh" fill="#333d4b" />
								</IconButton>
							</Tooltip>
						</div>
					</div>
				</div>
				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<OfflineDriveTableHead />

						<TableBody>
							{data.map(to => {
								let status = t('TAKEOUT_REQUEST');
								switch (to.status) {
									case 0:
										status = t('TAKEOUT_REQUEST');
										break;
									case 1:
										status = t('TAKEOUT_APPROVED');
										break;
									default:
								}
								return (
									<TableRow
										className={clsx(classes.tr, 'cursor-pointer')}
										hover
										role="checkbox"
										tabIndex={-1}
										key={to.offline_drive_id}
										onClick={event => handleRowClick(to)}
										selected={props.selectedObj.offline_drive_id === to.offline_drive_id}
									>
										<TableCell className="left" align="left" component="td" scope="row">
											{`${to.userName}(${to.user_id})`}
										</TableCell>
										<TableCell className="left" align="left" component="td" scope="row">
											{commonUtils.getDateStringToLocalDate(to.requested_at)}
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											{status}
										</TableCell>
										<TableCell className="left" component="td" scope="row">
											{to.reason}
										</TableCell>
										<TableCell className="" align="center" component="td" scope="row">
											{commonUtils.getDateStringToLocalDate(to.expire_at)}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>
			</Paper>
		</div>
	);
}

export default OfflineDriveTable;
