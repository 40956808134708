import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function SharedsTableHead(props) {
	const { t } = useTranslation('docbase');

	return (
		<TableHead>
			<TableRow>
				<TableCell className="left">{t('FOLDER_NAME')}</TableCell>
				<TableCell className="delete-cell" align="center" padding="default" sortDirection={false}>
					{t('DELETE')}
				</TableCell>
			</TableRow>
		</TableHead>
	);
}

export default SharedsTableHead;
