import { Paper, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import commonUtils from 'app/utils/CommonUtils';
import * as commonTypes from 'app/main/constants/CommonTypes';
import { Close } from '@material-ui/icons';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, AfterDraw } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		maxWidth: 570,
		maxHeight: 570,
		position: 'relative',
		'& .header': {
			height: 40,
			color: '#353b48',
			borderBottom: '1px solid #e8ebf0',
			paddingLeft: 20,
			'&>p': {
				fontSize: 16,
				fontFamily: 'S-CoreDream-Bold'
			}
		},
		'& .content': {
			maxWidth: 510,
			padding: '0 10px'
		}
	}
}));

const prePrivateIndex = -1;
const prePrivateLabelVal = '';
function FolderDetailWidget(props) {
	const classes = useStyles(props);

	const data = {
		labels: ['남은용량', '사용량', '휴지통'],
		datasets: [
			{
				data: props.obj.series,
				hoverOffset: 4,
				cotout: '86%',
				backgroundColor: ['#272c36', '#00cfe2', '#f6c142']
			}
		]
	};

	const options = {
		plugins: {
			legend: {
				position: 'bottom',
				labels: {
					font: 10
				}
			},
			tooltip: {
				enabled: true,
				backgroundColor: 'rgba(0,0,0,0.8)',
				titleFont: {
					size: 12
				},
				bodyFont: {
					size: 12
				},
				padding: 10,
				callbacks: {
					label: context => {
						return commonUtils.changeByteToString(context.raw || 0);
					}
				}
			},
			datalabels: {
				formatter: (value, context) => {
					const total = context.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
					const percentage = `${((value / total) * 100).toFixed(2)}%`;
					return percentage;
				},
				color: '#fff',
				font: {
					weight: 'bold'
				}
			}
		}
	};

	return (
		<Paper className={classes.root}>
			<div className="flex items-center justify-between header">
				<Typography className="">
					{props.obj.folderType === commonTypes.FOLDER_TYPE_ORGANIZE
						? commonUtils.splitLastDelimiterString(props.obj.title)
						: props.obj.title}
				</Typography>
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={() => props.fnDeleteFolderInfo(props.obj.fid)}
				>
					<Close />
				</IconButton>
			</div>
			<div className="content">
				<Doughnut data={data} options={options} />
			</div>
		</Paper>
	);
}
export default React.memo(FolderDetailWidget);
