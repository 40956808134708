import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import { useDispatch } from 'react-redux';
import commonUtils from 'app/utils/CommonUtils';
import { getAuditLog } from 'app/store/docbase/admin/systemSlice';
import ContentHeader from '../component/ContentHeader';
import LogsTable from './LogsTable';

function Logs() {
	const dispatch = useDispatch();
	const [loading, setLoading] = React.useState(true);
	const pageLayout = React.useRef(null);

	const [searchObj, setSearchObj] = React.useState({
		startDate: new Date(),
		endDate: new Date(),
		auditType: 0,
		object: '',
		user: ''
	});

	React.useEffect(() => {
		doSearch();
	}, [searchObj]);

	function doSearch() {
		setLoading(true);
		dispatch(
			getAuditLog({
				page: 1,
				auditType: searchObj.auditType,
				startDate: commonUtils.getDateString(searchObj.startDate),
				endDate: commonUtils.getDateString(searchObj.endDate),
				object: searchObj.object,
				user: searchObj.user
			})
		).then(() => {
			setLoading(false);
		});
	}

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={
				<LogsTable
					loading={loading}
					searchObj={searchObj}
					setLoading={setLoading}
					fnSetSearchObj={(name, value) => setSearchObj({ ...searchObj, [name]: value })}
					fnDoSearch={() => doSearch()}
				/>
			}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default Logs;
