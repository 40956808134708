import { combineReducers } from '@reduxjs/toolkit';
import account from './accountSlice';
import folder from './folderSlice';
import setting from './settingSlice';
import system from './systemSlice';
import etc from './etcSlice';

const docbaseReducers = combineReducers({
	account,
	folder,
	setting,
	system,
	etc
});

export default docbaseReducers;
