import { combineReducers } from '@reduxjs/toolkit';
import login from './loginSlice';
import user from './userSlice';
import initial from './initialSlice';

const authReducers = combineReducers({
	user,
	login,
	initial
});

export default authReducers;
