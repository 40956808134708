/* eslint-disable camelcase */
import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import { searchDupDocs } from 'app/store/docbase/admin/settingSlice';
import ContentHeader from '../component/ContentHeader';
import DupDocsTable from './DupDocsTable';
// import DetailSidebarContent from './DetailSidebarContent';

function DupDocs() {
	const pageLayout = React.useRef(null);

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={
				<DupDocsTable
				// fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
				/>
			}
			// rightSidebarContent={
			// 	<DetailSidebarContent
			// 		loading={loading}
			// 		setLoading={setLoading}
			// 		fnToggleRightSidebar={ev => pageLayout.current.toggleRightSidebar()}
			// 	/>
			// }
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default DupDocs;
