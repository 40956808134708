import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableSortLabel,
	Checkbox,
	TableRow,
	Tabs,
	Tab,
	Paper,
	Input,
	Button,
	Tooltip,
	IconButton,
	TextField,
	MenuItem
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import FuseLoading from '@fuse/core/FuseLoading';

import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import { getUserList } from 'app/store/docbase/admin/accountSlice';
import {
	getStorageAccess,
	setStorageAccess,
	getPathStorageAccess,
	addPathStorageAccess,
	updatePathStorageAccess,
	removePathStorageAccess,
	getPrograms,
	registerProgram,
	updateProgram,
	removeProgram,
	getNetworks,
	registerNetwork,
	updateNetwork,
	removeNetwork
} from 'app/store/docbase/admin/dlpSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import CustomIcon from 'app/main/icon/CustomIcon';
import PathStorageDialog from './PathStorageDialog';
import ProgramDialog from './ProgramDialog';
import NetworkDialog from './NetworkDialog';
import OpenDialogForm from '../../component/OpenDialogForm';

const useStyles = makeStyles(theme => ({
	root: { borderRadius: 6 },
	deleteIcon: {
		color: '#ff6565'
	},
	contentWrapper: {
		height: `${window.innerHeight - 492}px`,
		padding: '0 20px'
	},
	icon: {
		width: 20,
		height: 20
	},
	btn: {
		width: '155px!important'
	},
	storageAccessTitle: {
		color: '#fff',
		background: '#353b48',
		alignItems: 'center',
		height: 50,
		'&>div': {
			flex: '1 0 auto'
		}
	}
}));

function PolicySetupTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const { t } = useTranslation('docbase');

	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const { usersObj } = useSelector(({ docbase }) => docbase.admin.account);

	const [tabValue, setTabValue] = React.useState(0);
	//	mode: 0 create, 1: modify
	const [dialogObj, setDialogObj] = React.useState({ pathStorage: false, program: false, network: false, mode: 0 });
	const [loading, setLoading] = useState(true);
	const [storageAccess, setStorageAccessList] = React.useState([]);
	const [setupObj, setSetupObj] = React.useState({ programs: [], networks: [], storages: [] });

	const [tableCnt, setTableCnt] = React.useState(0);
	const [order, setOrder] = useState({
		id: 'user_id',
		direction: 'asc'
	});
	const [searchText, setSearchText] = React.useState('');

	const [selectedObj, setSelectedObj] = useState({});

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 492}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		if (Object.keys(usersObj).length === 0) {
			dispatch(getUserList()).then(() => {
				init();
			});
		} else {
			init();
		}
	}, [dispatch]);

	useEffect(() => {
		switch (tabValue) {
			case 0:
				setTableCnt(setupObj.storages.length);
				break;
			case 1:
				setTableCnt(setupObj.programs.length);
				break;
			case 2:
				setTableCnt(setupObj.networks.length);
				break;
			default:
				setTableCnt(0);
		}
		console.log(setupObj);
		setSelectedObj({});
	}, [tabValue, setupObj, searchText]);

	// function filterData(list) {
	// 	if (searchText === '') {
	// 		return list;
	// 	}
	// }

	async function init() {
		const storages = await getStorageAccess();
		setStorageAccessList(storages);
		const pathStorages = await getPathStorageAccess();
		const programs = await getPrograms();
		const networks = await getNetworks();

		setSetupObj({ storages: pathStorages, programs, networks });
		setLoading(false);
	}

	function reqStorageAccess() {
		getStorageAccess().then(storages => {
			setStorageAccessList(storages);
		});
	}
	function handleRequestSort(event, property) {
		const id = property;
		let direction = 'desc';

		if (order.id === property && order.direction === 'desc') {
			direction = 'asc';
		}

		setOrder({
			direction,
			id
		});
	}

	if (loading) {
		return <FuseLoading />;
	}

	function drawTable() {
		let tableObj = <></>;
		switch (tabValue) {
			case 0:
				tableObj = (
					<Table stickyHeader className="">
						<TableHead>
							<TableRow>
								<TableCell padding="none" className="w-40 md:w-64 text-center z-99">
									<Checkbox
										color="secondary"
										size="small"
										indeterminate={Object.keys(selectedObj).length > 0}
										// checked={data.length !== 0 && selectedUsers.length === data.length}
										onChange={e => {
											e.stopPropagation();
										}}
									/>
								</TableCell>

								<TableCell className="left" align="left" padding="default" sortDirection={order.direction}>
									<TableSortLabel
										active={order.id === 'path'}
										direction={order.direction}
										onClick={event => handleRequestSort(event, 'path')}
									>
										{t('PATH')}
									</TableSortLabel>
								</TableCell>
								<TableCell className="left" align="left" padding="default" sortDirection={order.direction}>
									<TableSortLabel
										active={order.id === 'path_regex'}
										direction={order.direction}
										onClick={event => handleRequestSort(event, 'path_regex')}
									>
										{t('PATH_REG')}
									</TableSortLabel>
								</TableCell>
								<TableCell className="" align="center" padding="default" sortDirection={order.direction}>
									{t('AVERAGE_READ')}
								</TableCell>
								<TableCell className="" align="center" padding="default" sortDirection={order.direction}>
									{t('AVERAGE_WRITE')}
								</TableCell>
								<TableCell className="" align="center" padding="default" sortDirection={order.direction}>
									{t('CLIENT_RUNNING_TYPE_START')}
								</TableCell>
								<TableCell className="" align="center" padding="default" sortDirection={order.direction} />
							</TableRow>
						</TableHead>
						<TableBody>
							{_.orderBy(
								setupObj.storages,
								[
									d => {
										switch (order.id) {
											case 'path': {
												return d.path;
											}
											case 'path_regex': {
												return d.path_regex;
											}

											default: {
												return d[order.id];
											}
										}
									}
								],
								[order.direction]
							).map(d => {
								const isSelected = selectedObj.storage_id === d.storage_id;

								return (
									<TableRow
										className="cursor-pointer"
										hover
										role="checkbox"
										aria-checked={isSelected}
										tabIndex={-1}
										key={d.storage_id}
										selected={isSelected}
										onClick={event => setSelectedObj(isSelected ? {} : d)}
									>
										<TableCell className="text-center table-checkbox" padding="none">
											<Checkbox
												color="secondary"
												size="small"
												checked={isSelected}
												onClick={event => event.stopPropagation()}
												onChange={event => setSelectedObj(isSelected ? {} : d)}
											/>
										</TableCell>
										<TableCell className="left" component="td" scope="row">
											{d.path}
										</TableCell>
										<TableCell className="left" component="td" scope="row">
											{d.path_regex}
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											{d.read ? t('PERMIT') : t('BLOCK')}
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											{d.write ? t('PERMIT') : t('BLOCK')}
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											{d.execute ? t('PERMIT') : t('BLOCK')}
										</TableCell>

										<TableCell className="" component="td" scope="row" align="center">
											<div>
												<Tooltip title={t('DELETE')} arrow>
													<IconButton
														onClick={e => {
															dispatch(
																openDialog({
																	children: (
																		<OpenDialogForm
																			title={t('PATH_STORAGE_TITLE_1')}
																			content={t('PATH_STORAGE_CONTENT_1')}
																			fnHandleOK={() => {
																				removePathStorageAccess(d.storage_id)
																					.then(result => {
																						if (result) {
																							dispatch(
																								showMessage({
																									message:
																										t(
																											'PATH_STORAGE_MESSAGE_1'
																										)
																								})
																							);

																							getPathStorageAccess().then(
																								storages => {
																									setSetupObj({
																										...setupObj,
																										storages
																									});
																								}
																							);
																						}
																					})
																					.catch(err => {
																						console.log(err);
																						dispatch(
																							showErrorMessage({
																								message:
																									t('PATH_STORAGE_MESSAGE_2')
																							})
																						);
																					});
																			}}
																		/>
																	)
																})
															);
														}}
													>
														<CustomIcon type="delete" className="" fill="#575757" />
													</IconButton>
												</Tooltip>
											</div>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				);
				break;
			case 1:
				tableObj = (
					<Table stickyHeader className="">
						<TableHead>
							<TableRow>
								<TableCell padding="none" className="w-40 md:w-64 text-center z-99">
									<Checkbox
										color="secondary"
										size="small"
										indeterminate={Object.keys(selectedObj).length > 0}
										// checked={data.length !== 0 && selectedUsers.length === data.length}
										onChange={e => {
											e.stopPropagation();
										}}
									/>
								</TableCell>

								<TableCell className="left" align="left" padding="default" sortDirection={order.direction}>
									<TableSortLabel
										active={order.id === 'name'}
										direction={order.direction}
										onClick={event => handleRequestSort(event, 'name')}
									>
										{t('DLP_PROGRAM_MANAGE_NAME')}
									</TableSortLabel>
								</TableCell>
								<TableCell className="left" align="left" padding="default" sortDirection={order.direction}>
									{t('DLP_PROGRAM_SIGNING_ID')}
								</TableCell>
								<TableCell className="" align="center" padding="default" sortDirection={order.direction}>
									{t('DLP_PROGRAM_HASH')}
								</TableCell>
								<TableCell className="" align="center" padding="default" sortDirection={order.direction}>
									<TableSortLabel
										active={order.id === 'program_name'}
										direction={order.direction}
										onClick={event => handleRequestSort(event, 'program_name')}
									>
										{t('DLP_PROGRAM_NAME')}
									</TableSortLabel>
								</TableCell>
								<TableCell className="" align="center" padding="default" sortDirection={order.direction}>
									{t('DLP_PROCESS_TYPE')}
								</TableCell>
								<TableCell className="" align="center" padding="default" sortDirection={order.direction} />
							</TableRow>
						</TableHead>
						<TableBody>
							{_.orderBy(
								setupObj.programs,
								[
									d => {
										switch (order.id) {
											case 'name': {
												return d.name;
											}
											case 'program_name': {
												return d.program_name;
											}

											default: {
												return d[order.id];
											}
										}
									}
								],
								[order.direction]
							).map(d => {
								const isSelected = selectedObj.id === d.id;
								let processType = t('DLP_PROCESS_TYPE_UNKNOWN');
								if (d.process_type === 1) {
									processType = t('DLP_PROCESS_TYPE_NORMAL');
								} else if (d.process_type === 2) {
									processType = t('DLP_PROCESS_TYPE_SYSTEM');
								} else if (d.process_type === 3) {
									processType = t('DLP_PROCESS_TYPE_CHROME');
								}
								return (
									<TableRow
										className="cursor-pointer"
										hover
										role="checkbox"
										aria-checked={isSelected}
										tabIndex={-1}
										key={d.user_id}
										selected={isSelected}
										onClick={event => setSelectedObj(isSelected ? {} : d)}
									>
										<TableCell className="text-center table-checkbox" padding="none">
											<Checkbox
												color="secondary"
												size="small"
												checked={isSelected}
												onClick={event => event.stopPropagation()}
												onChange={event => setSelectedObj(isSelected ? {} : d)}
											/>
										</TableCell>
										<TableCell className="left" component="td" scope="row">
											{d.name}
										</TableCell>
										<TableCell className="left" component="td" scope="row">
											{d.signing_id}
										</TableCell>
										<TableCell className="left" component="td" scope="row" align="">
											{d.hash}
										</TableCell>
										<TableCell className="left" component="td" scope="row" align="">
											{d.program_name}
										</TableCell>
										<TableCell className="left" component="td" scope="row" align="">
											{processType}
										</TableCell>

										<TableCell className="" component="td" scope="row" align="center">
											<div>
												<Tooltip title={t('DELETE')} arrow>
													<IconButton
														onClick={e => {
															dispatch(
																openDialog({
																	children: (
																		<OpenDialogForm
																			title={t('PROGRAM_TITLE_1')}
																			content={t('PROGRAM_CONTENT_1')}
																			fnHandleOK={() => {
																				removeProgram(d.id, true)
																					.then(result => {
																						if (result) {
																							dispatch(
																								showMessage({
																									message:
																										t('PROGRAM_MESSAGE_1')
																								})
																							);

																							getPrograms().then(programs => {
																								setSetupObj({
																									...setupObj,
																									programs
																								});
																							});
																						}
																					})
																					.catch(err => {
																						console.log(err);
																						dispatch(
																							showErrorMessage({
																								message: t('PROGRAM_MESSAGE_2')
																							})
																						);
																					});
																			}}
																		/>
																	)
																})
															);
														}}
													>
														<CustomIcon type="delete" className="" fill="#575757" />
													</IconButton>
												</Tooltip>
											</div>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				);
				break;
			case 2:
				tableObj = (
					<Table stickyHeader className="">
						<TableHead>
							<TableRow>
								<TableCell padding="none" className="w-40 md:w-64 text-center z-99">
									<Checkbox
										color="secondary"
										size="small"
										indeterminate={Object.keys(selectedObj).length > 0}
										// checked={data.length !== 0 && selectedUsers.length === data.length}
										onChange={e => {
											e.stopPropagation();
										}}
									/>
								</TableCell>

								<TableCell className="left" align="left" padding="default" sortDirection={order.direction}>
									<TableSortLabel
										active={order.id === 'name'}
										direction={order.direction}
										onClick={event => handleRequestSort(event, 'name')}
									>
										{t('DLP_NETWORK_MANAGE_NAME')}
									</TableSortLabel>
								</TableCell>
								<TableCell className="left" align="left" padding="default" sortDirection={order.direction}>
									{t('DLP_NETWORK_INBOUND_PORT')}
								</TableCell>
								<TableCell className="" align="center" padding="default" sortDirection={order.direction}>
									{t('DLP_NETWORK_OUTBOUND_DESTINATION')}
								</TableCell>
								<TableCell className="" align="center" padding="default" sortDirection={order.direction} />
							</TableRow>
						</TableHead>
						<TableBody>
							{_.orderBy(
								setupObj.networks,
								[
									d => {
										switch (order.id) {
											case 'name': {
												return d.name;
											}

											default: {
												return d[order.id];
											}
										}
									}
								],
								[order.direction]
							).map(d => {
								const isSelected = selectedObj.id === d.id;

								return (
									<TableRow
										className="cursor-pointer"
										hover
										role="checkbox"
										aria-checked={isSelected}
										tabIndex={-1}
										key={d.user_id}
										selected={isSelected}
										onClick={event => setSelectedObj(isSelected ? {} : d)}
									>
										<TableCell className="text-center table-checkbox" padding="none">
											<Checkbox
												color="secondary"
												size="small"
												checked={isSelected}
												onClick={event => event.stopPropagation()}
												onChange={event => setSelectedObj(isSelected ? {} : d)}
											/>
										</TableCell>
										<TableCell className="left" component="td" scope="row">
											{d.name}
										</TableCell>
										<TableCell className="left" component="td" scope="row">
											{d.inbound_ports}
										</TableCell>
										<TableCell className="left" component="td" scope="row" align="">
											{d.outbound_destinations}
										</TableCell>

										<TableCell className="" component="td" scope="row" align="center">
											<div>
												<Tooltip title={t('DELETE')} arrow>
													<IconButton
														onClick={e => {
															dispatch(
																openDialog({
																	children: (
																		<OpenDialogForm
																			title={t('NETWORK_TITLE_1')}
																			content={t('NETWORK_CONTENT_1')}
																			fnHandleOK={() => {
																				removeNetwork(d.id)
																					.then(result => {
																						if (result) {
																							dispatch(
																								showMessage({
																									message:
																										t('NETWORK_MESSAGE_1')
																								})
																							);

																							getNetworks().then(networks => {
																								setSetupObj({
																									...setupObj,
																									networks
																								});
																							});
																						}
																					})
																					.catch(err => {
																						console.log(err);
																						dispatch(
																							showErrorMessage({
																								message: t('NETWORK_MESSAGE_2')
																							})
																						);
																					});
																			}}
																		/>
																	)
																})
															);
														}}
													>
														<CustomIcon type="delete" className="" fill="#575757" />
													</IconButton>
												</Tooltip>
											</div>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				);
				break;
			default:
		}
		return tableObj;
	}

	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<PathStorageDialog
				open={dialogObj.pathStorage}
				selectedObj={selectedObj}
				mode={dialogObj.mode}
				fnHandleClose={() => setDialogObj({ ...dialogObj, pathStorage: false })}
				fnHandleOk={(path, pathRegex, read, write, execute) => {
					addPathStorageAccess(path, pathRegex, read, write, execute)
						.then(result => {
							if (result) {
								dispatch(
									showMessage({
										message: t('PATH_STORAGE_MESSAGE_3')
									})
								);
								getPathStorageAccess().then(storages => {
									setSetupObj({ ...setupObj, storages });
									setTabValue(0);
									setDialogObj({ ...dialogObj, pathStorage: false });
								});
							}
						})
						.catch(err => {
							console.log(err);
							dispatch(
								showErrorMessage({
									message: t('PATH_STORAGE_MESSAGE_4')
								})
							);
						});
				}}
				fnHandleUpdate={(storageId, path, pathRegex, read, write, execute) => {
					updatePathStorageAccess(storageId, path, pathRegex, read, write, execute)
						.then(result => {
							if (result) {
								dispatch(
									showMessage({
										message: t('PATH_STORAGE_MESSAGE_5')
									})
								);
								getPathStorageAccess().then(storages => {
									setSetupObj({ ...setupObj, storages });
									setTabValue(0);
									setDialogObj({ ...dialogObj, pathStorage: false });
								});
							}
						})
						.catch(err => {
							console.log(err);
							dispatch(
								showErrorMessage({
									message: t('PATH_STORAGE_MESSAGE_6')
								})
							);
						});
				}}
			/>
			<ProgramDialog
				open={dialogObj.program}
				selectedObj={selectedObj}
				mode={dialogObj.mode}
				fnHandleClose={() => setDialogObj({ ...dialogObj, program: false })}
				fnHandleOk={(name, signingId, hash, programName, processType) => {
					registerProgram(name, signingId, hash, programName, processType)
						.then(result => {
							if (result) {
								dispatch(
									showMessage({
										message: t('PROGRAM_MESSAGE_3')
									})
								);
								getPrograms().then(programs => {
									setSetupObj({ ...setupObj, programs });
									setTabValue(1);
									setDialogObj({ ...dialogObj, program: false });
								});
							}
						})
						.catch(err => {
							console.log(err);
							dispatch(
								showErrorMessage({
									message: t('PROGRAM_MESSAGE_4')
								})
							);
						});
				}}
				fnHandleUpdate={(programId, name, signingId, hash, programName, processType) => {
					updateProgram(programId, name, signingId, hash, programName, processType)
						.then(result => {
							if (result) {
								dispatch(
									showMessage({
										message: t('PROGRAM_MESSAGE_5')
									})
								);
								getPrograms().then(programs => {
									setSetupObj({ ...setupObj, programs });
									setTabValue(1);
									setDialogObj({ ...dialogObj, program: false });
								});
							}
						})
						.catch(err => {
							console.log(err);
							dispatch(
								showErrorMessage({
									message: t('PROGRAM_MESSAGE_6')
								})
							);
						});
				}}
			/>
			<NetworkDialog
				open={dialogObj.network}
				selectedObj={selectedObj}
				mode={dialogObj.mode}
				fnHandleClose={() => setDialogObj({ ...dialogObj, network: false })}
				fnHandleOk={(name, inboundPorts, outboundDestinations) => {
					registerNetwork(name, inboundPorts, outboundDestinations)
						.then(result => {
							if (result) {
								dispatch(
									showMessage({
										message: t('NETWORK_MESSAGE_3')
									})
								);
								getNetworks().then(networks => {
									setSetupObj({ ...setupObj, networks });
									setTabValue(2);

									setDialogObj({ ...dialogObj, network: false });
								});
							}
						})
						.catch(err => {
							console.log(err);
							dispatch(
								showErrorMessage({
									message: t('NETWORK_MESSAGE_4')
								})
							);
						});
				}}
				fnHandleUpdate={(networkId, name, inboundPorts, outboundDestinations) => {
					updateNetwork(networkId, name, inboundPorts, outboundDestinations)
						.then(result => {
							if (result) {
								dispatch(
									showMessage({
										message: t('NETWORK_MESSAGE_5')
									})
								);
								getPrograms().then(networks => {
									setSetupObj({ ...setupObj, networks });
									setTabValue(2);
									setDialogObj({ ...dialogObj, network: false });
								});
							}
						})
						.catch(err => {
							console.log(err);
							dispatch(
								showErrorMessage({
									message: t('NETWORK_MESSAGE_6')
								})
							);
						});
				}}
			/>
			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className="dlp-storage-content">
					<div>{t('DLP_POLICY_TITLE')}</div>
					<div>
						<div className="row title">
							<div>{t('DLP_POLICY_STORAGE_TYPE')}</div>
							<div>{t('AVERAGE_READ')}</div>
							<div>{t('AVERAGE_WRITE')}</div>
							<div>{t('CLIENT_RUNNING_TYPE_START')}</div>
						</div>
						{storageAccess.map(obj => {
							let type = '';
							if (obj.storage_type === 1) {
								type = 'COODOC Drive';
							} else if (obj.storage_type === 2) {
								type = 'USB';
							} else if (obj.storage_type === 3) {
								type = 'Network Drive';
							}
							return (
								<div className="row">
									<div>{type}</div>
									<div>
										<TextField
											select
											className=""
											label=""
											value={obj.read}
											fullWidth
											// onChange={e => {
											// 	setStorageAccess(obj.storage_type, !obj.read, obj.write, obj.execute)
											// 		.then(result => {
											// 			if (result) {
											// 				dispatch(
											// 					showMessage({
											// 						message: t('설정이 반영되었습니다.')
											// 					})
											// 				);
											// 			}
											// 		})
											// 		.catch(err => {
											// 			console.log(err);
											// 			dispatch(
											// 				showErrorMessage({
											// 					message: t('설정에 실패하였습니다.')
											// 				})
											// 			);
											// 		})
											// 		.finally(() => {
											// 			reqStorageAccess();
											// 		});
											// }}
										>
											<MenuItem value>{t('PERMIT')}</MenuItem>
											{/* <MenuItem value={false}>{t('BLOCK')}</MenuItem> */}
										</TextField>
									</div>
									<div>
										<TextField
											select
											className=""
											label=""
											value={obj.write}
											fullWidth
											onChange={e => {
												setStorageAccess(obj.storage_type, obj.read, !obj.write, obj.execute)
													.then(result => {
														if (result) {
															dispatch(
																showMessage({
																	message: t('DLP_CLIENT_MESSAGE_6')
																})
															);
														}
													})
													.catch(err => {
														console.log(err);
														dispatch(
															showErrorMessage({
																message: t('DLP_CLIENT_MESSAGE_5')
															})
														);
													})
													.finally(() => {
														reqStorageAccess();
													});
											}}
										>
											<MenuItem value>{t('PERMIT')}</MenuItem>
											<MenuItem value={false}>{t('BLOCK')}</MenuItem>
										</TextField>
									</div>
									<div>
										<TextField
											select
											className=""
											label=""
											value={obj.execute}
											fullWidth
											onChange={e => {
												setStorageAccess(obj.storage_type, obj.read, obj.write, obj.execute)
													.then(result => {
														if (result) {
															dispatch(
																showMessage({
																	message: t('DLP_CLIENT_MESSAGE_6')
																})
															);
														}
													})
													.catch(err => {
														console.log(err);
														dispatch(
															showErrorMessage({
																message: t('DLP_CLIENT_MESSAGE_5')
															})
														);
													})
													.finally(() => {
														reqStorageAccess();
													});
											}}
										>
											<MenuItem value>{t('PERMIT')}</MenuItem>
											<MenuItem value={false}>{t('BLOCK')}</MenuItem>
										</TextField>
									</div>
								</div>
							);
						})}
					</div>
				</div>

				<div className="table-search-wrapper">
					<div className="">
						<div className="input-item">
							<Input
								placeholder={t('SEARCH')}
								className="search-input"
								disableUnderline
								fullWidth
								value={searchText}
								inputProps={{
									'aria-label': 'Search'
								}}
								onChange={ev => setSearchText(ev.target.value)}
							/>
						</div>
					</div>
					<div className="total-cnt-info">
						<span className="pipe">|</span>
						<span>
							{t('TOTAL')} <span className="count">{tableCnt}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="btn-group">
						<div>
							<Button
								variant="contained"
								className="default"
								type="button"
								onClick={() =>
									setDialogObj({
										...dialogObj,
										pathStorage: true,
										mode: Object.keys(selectedObj).length > 0 ? 1 : 0
									})
								}
							>
								{Object.keys(selectedObj).length > 0 && tabValue === 0
									? t('PATH_STORAGE_TITLE_2')
									: t('PATH_STORAGE_TITLE_3')}
							</Button>
						</div>
						<div>
							<Button
								variant="contained"
								className="default"
								type="button"
								onClick={() =>
									setDialogObj({
										...dialogObj,
										program: true,
										mode: Object.keys(selectedObj).length > 0 ? 1 : 0
									})
								}
							>
								{Object.keys(selectedObj).length > 0 && tabValue === 1
									? t('PROGRAM_TITLE_2')
									: t('PROGRAM_TITLE_3')}
							</Button>
						</div>
						<div>
							<Button
								variant="contained"
								className="default"
								type="button"
								onClick={() =>
									setDialogObj({
										...dialogObj,
										network: true,
										mode: Object.keys(selectedObj).length > 0 ? 1 : 0
									})
								}
							>
								{Object.keys(selectedObj).length > 0 && tabValue === 2
									? t('NETWORK_TITLE_2')
									: t('NETWORK_TITLE_3')}
							</Button>
						</div>
					</div>
				</div>
				<div className="type-tabs">
					<Tabs
						value={tabValue}
						onChange={(ev, val) => {
							setTabValue(val);
						}}
						indicatorColor="secondary"
						textColor="inherit"
						variant="scrollable"
						scrollButtons="off"
						classes={{ indicator: 'active_bar' }}
					>
						<Tab className="list-tab" disableRipple label={t('PATH_STORAGE')} />
						<Tab className="list-tab" disableRipple label={t('PROGRAM')} />
						<Tab className="list-tab" disableRipple label={t('NETWORK')} />
					</Tabs>
				</div>
				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					{drawTable()}
				</FuseScrollbars>
			</Paper>
		</div>
	);
}

export default PolicySetupTable;
