import FusePageSimple from '@fuse/core/FusePageSimple';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import commonUtils from 'app/utils/CommonUtils';
import { getClientRunningLogs, getClientRunningLogsPage } from 'app/store/docbase/admin/systemSlice';
import * as commonTypes from 'app/main/constants/CommonTypes';

import ContentHeader from '../component/ContentHeader';
import LogsTable from './LogsTable';

function Logs() {
	const dispatch = useDispatch();
	const [loading, setLoading] = React.useState(false);
	const pageLayout = React.useRef(null);
	const { t } = useTranslation('docbase');

	const today = new Date();
	const [searchObj, setSearchObj] = React.useState({
		startDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
		endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59),
		userID: '',
		rowsPerPage: 100
	});

	const [totalInfo, setTotalInfo] = React.useState({ total_rows: 0, total_pages: 0 });
	const [data, setData] = React.useState([]);

	function getRunningLogs() {
		setLoading(true);
		(async () => {
			const resp = await getClientRunningLogs(
				searchObj.userID,
				commonUtils.getDateTimeString(searchObj.startDate),
				commonUtils.getDateTimeString(searchObj.endDate),
				searchObj.rowsPerPage
			);
			console.log(resp);
			if (resp.result) {
				setTotalInfo({ total_rows: resp.total_rows, total_pages: resp.total_pages });
				getLogsPage(1);
			} else {
				setData([]);
				setLoading(false);
			}
		})();
	}

	function getLogsPage(page) {
		getClientRunningLogsPage(page)
			.then(logs => {
				setData(logs);
				setLoading(false);
			})
			.catch(err => {
				setLoading(false);
			});
	}

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={
				<LogsTable
					loading={loading}
					searchObj={searchObj}
					totalInfo={totalInfo}
					data={data}
					setLoading={setLoading}
					fnGetLogsPage={page => getLogsPage(page)}
					fnSetSearchObj={(name, value) => setSearchObj({ ...searchObj, [name]: value })}
					fnDoSearch={() => {
						setLoading(true);
						getRunningLogs();
					}}
				/>
			}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default Logs;
