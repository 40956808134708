import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import httpClient from 'app/utils/HttpClient';
import securityUtils from 'app/utils/SecurityUtils';
import { secureRequest, secureAsyncRequest } from 'app/store/docbase/commonAction';
import * as commonTypes from 'app/main/constants/CommonTypes';

export const getUserList = createAsyncThunk('account/getUserList', async () => {
	const secureObj = await secureAsyncRequest();
	const respData = await httpClient.sendPost('/api/userList', { token: secureObj.token });

	if (!respData.result) {
		httpClient.printError(respData);
		return [];
	}
	return { users: respData.users, aes: secureObj.aesKey };
	// return { users: [{ user_id: 'admin', name: '관리', access_level: 1, regdate: '2020-10-11' }] };
});

export const selectedUserID = createAsyncThunk('account/selectedUserID', async ({ userID }, { dispatch, getState }) => {
	if (userID === '') {
		return { permissions: [], selectedID: userID };
	}
	const secureObj = await secureAsyncRequest();
	const respData = await httpClient.sendPost('/api/userPermissionList', { user_id: userID, token: secureObj.token });

	// if (getState().docbase.admin.account.selectedID !== userID) dispatch(getAllFolderList());

	const capacityObj = { privateSize: 0, privatePath: '', collectorSize: 0, collectorPath: '' };
	if (respData.permissions.length > 0) {
		for (let i = 0; i < respData.permissions.length; i++) {
			if (respData.permissions[i].folder_type === commonTypes.FOLDER_TYPE_PRIVATE) {
				// eslint-disable-next-line no-await-in-loop
				const resp = await httpClient.sendPost('/api/getFolderMaxSize', { fid: respData.permissions[i].fid });
				capacityObj.privateSize = resp.result ? resp.size : 0;
				capacityObj.privatePath = respData.permissions[i].path;
			}
			if (respData.permissions[i].folder_type === commonTypes.FOLDER_TYPE_COLLECT) {
				// eslint-disable-next-line no-await-in-loop
				const resp = await httpClient.sendPost('/api/getFolderMaxSize', { fid: respData.permissions[i].fid });
				capacityObj.collectorSize = resp.result ? resp.size : 0;
				capacityObj.collectorPath = respData.permissions[i].path;
			}
		}
	}

	return {
		permissions: respData.permissions,
		selectedID: userID,
		privateSize: capacityObj.privateSize,
		privatePath: capacityObj.privatePath,
		collectorSize: capacityObj.collectorSize,
		collectorPath: capacityObj.collectorPath
	};
	// return { permissions: [], selectedID: '1' };
});

/**
 * 사용자 추가
 * @param {string} userID
 * @param {string} password
 * @param {string} name
 * @param {number} privilege
 * @param {number} accessLevel
 * @param {string} email
 * @param {boolean} localAccount
 * @param {number} multipleLogins
 * @param {string} expireAt
 */
export const addUser = (userID, password, name, privilege, accessLevel, email, localAccount, multipleLogins, expireAt) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/addUser', {
					user_id: securityUtils.encryptAES(aes, userID, 'utf8', 'hex'),
					password: securityUtils.encryptAES(aes, password, 'utf8', 'hex'),
					name,
					privilege,
					access_level: accessLevel,
					email: !email ? '' : email,
					local_account: JSON.parse(localAccount),
					no_sync_osr: false,
					allow_multiple_logins: multipleLogins,
					expire_at: expireAt,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};

/**
 * 사용자 삭제
 * @param {string} userID
 * @param {string} handoverUserID 삭제 될 사용자의 폴더 및  수집폴더를 인수받을 사용자의 ID
 */
export const removeUser = (userID, handoverUserID) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/removeUser', {
					user_id: securityUtils.encryptAES(aes, userID, 'utf8', 'hex'),
					handover_user_id: handoverUserID ? securityUtils.encryptAES(aes, handoverUserID, 'utf8', 'hex') : '',
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};

/**
 * 사용자 정보 수정
 *
 * @param {String} userID
 * @param {String} name
 * @param {number} privilege
 * @param {number} accessLevel
 * @param {String} email
 * @param {boolean} locked
 * @param {boolean} local_account
 * @param {boolean} no_sync_osr
 * @param {number}  allow_multiple_logins
 * @param {String}  expire_at
 */
export const updateUser = (targetUser, changeName, value) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			const params = { ...targetUser, [changeName]: value, token: tk };
			params.user_id = securityUtils.encryptAES(aes, targetUser.user_id, 'utf8', 'hex');
			httpClient.sendPost('/api/updateUser', params).then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};

/**
 * 사용자의 비밀번호를 재설정한다.
 *
 * @param {String} userID
 * @param {String} password
 */
export const resetPassword = (userID, password) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/resetPassword', {
					user_id: securityUtils.encryptAES(aes, userID, 'utf8', 'hex'),
					password: securityUtils.encryptAES(aes, password, 'utf8', 'hex'),
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};

/**
 * 종이암호 생성
 *
 * @param {string} userID
 */
export const createPaperPassword = userID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/createPaperPassword', {
					user_id: securityUtils.encryptAES(aes, userID, 'utf8', 'hex'),
					token: tk
				})
				.then(data => {
					if (data.result) {
						data.passwords = securityUtils.decryptAES(aes, data.passwords, 'hex', 'utf8');
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};

/**
 * TOTP 해제
 *
 * @param {string} userID
 */
export const resetTOTP = userID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/resetTOTP', { user_id: securityUtils.encryptAES(aes, userID, 'utf8', 'hex'), token: tk })
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};

/**
 * 비밀번호 변경
 * @param {string} curPassword
 * @param {string} newPassword
 * @returns
 */
export const changePassword = (curPassword, newPassword) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/changePassword', {
					current_password: securityUtils.encryptAES(aes, curPassword, 'utf8', 'hex'),
					new_password: securityUtils.encryptAES(aes, newPassword, 'utf8', 'hex'),
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};

export const getUsers = () => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/userList', { token: tk }).then(data => {
				if (data.result) {
					resolve(
						data.users.map(u => {
							u.user_id = securityUtils.decryptAES(aes, u.user_id, 'hex', 'utf8');
							return u;
						})
					);
				} else {
					reject(data);
				}
			});
		});
	});
};

export const getUsersObj = () => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/userList', { token: tk }).then(data => {
				if (data.result) {
					const usersObj = {};
					data.users.forEach(u => {
						const userID = securityUtils.decryptAES(aes, u.user_id, 'hex', 'utf8');

						usersObj[userID] = {
							...u,
							user_id: userID,
							id: userID,
							name: securityUtils.decryptAES(aes, u.name, 'hex', 'utf8')
						};
					});
					resolve(usersObj);
				} else {
					reject(data);
				}
			});
		});
	});
};

/**
 * 오프라인 드라이브 요청 목록(요청 및 승인 목록)
 *
 * @returns
 */
export const activeOfflineDrives = () => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/activeOfflineDrives', { token: tk }).then(data => {
				if (data.result) {
					resolve({
						...data,
						aes
					});
				} else {
					reject(data);
				}
			});
		});
	});
};

/**
 * 오프라인 드라이브 요청 허가.
 *
 * @returns
 */
export const approveDriveFolder = (driveID, approve, reason) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/approveOfflineDrive', {
					token: tk,
					offline_drive_id: driveID,
					approve,
					reason: approve === 2 && reason !== '' ? securityUtils.encryptAES(aes, reason, 'utf8', 'hex') : ''
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						reject(data);
					}
				});
		});
	});
};

const usersSlice = createSlice({
	name: 'account',
	initialState: {
		users: [],
		usersObj: {},
		userPerms: [],
		selectedID: '',
		privateSize: 0,
		privatePath: '',
		collectorSize: 0,
		collectorPath: '',
		licences: 0
	},
	reducers: {},
	extraReducers: {
		[getUserList.fulfilled]: (state, action) => {
			const usersObj = {};
			if (action.payload.users) {
				let cnt = 0;
				const users = action.payload.users.map((user, idx) => {
					user.user_id = securityUtils.decryptAES(action.payload.aes, user.user_id, 'hex', 'utf8');
					user.name = securityUtils.decryptAES(action.payload.aes, user.name, 'hex', 'utf8');
					user.email = securityUtils.decryptAES(action.payload.aes, user.email, 'hex', 'utf8');
					user.id = user.user_id;
					usersObj[user.user_id] = user;
					cnt += user.allow_multiple_logins === 0 ? 1 : user.allow_multiple_logins;
					return user;
				});
				state.users = users;
				state.usersObj = usersObj;
				state.licences = cnt - 1; // except admin
				// state.users = action.payload.users;
				// eslint-disable-next-line prefer-destructuring
				// state.usersObj = { [action.payload.users[0].user_id]: action.payload.users[0] };
			} else {
				state.licences = 0;
			}
		},
		[selectedUserID.fulfilled]: (state, action) => {
			state.userPerms = action.payload.permissions;
			state.selectedID = action.payload.selectedID;
			state.privateSize = action.payload.privateSize;
			state.privatePath = action.payload.privatePath;
			state.collectorSize = action.payload.collectorSize;
			state.collectorPath = action.payload.collectorPath;
		}
	}
});

// export const {} = usersSlice.actions;

export default usersSlice.reducer;
