import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { IconButton, Dialog, DialogContent, Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import commonUtils from 'app/utils/CommonUtils';

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});
const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 1208
		}
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	infoFiled: {
		color: '#000000',
		'&>div': {
			display: 'flex',
			height: 45,
			paddingRight: 30,
			borderTop: '1px solid rgb(141 149 160 / 18%)'
		},
		'&>div:last-child': { borderBottom: '1px solid rgb(141 149 160 / 18%)' }
	},
	filedTitle: {
		background: '#f5f7fa',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		cursor: 'default',
		width: '100%',
		maxWidth: 277,
		minWidth: 277
	},
	filedInput: {
		width: '100%',
		padding: '0 0 0 30px',
		display: 'flex',
		alignItems: 'center',
		'&.folder-name': { paddingRight: 10 }
	}
}));

export default function ViewDialog(props) {
	const classes = useStyles();
	const { t } = useTranslation('docbase');

	return (
		<div>
			<Dialog className={classes.root} open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog-title">
				<Backdrop className={classes.backdrop} open={false}>
					<CircularProgress color="inherit" />
				</Backdrop>
				<DialogTitle className={classes.title} id="form-dialog-title" onClose={props.fnHandleClose}>
					{t('DLP_CLIENT_LOG_VIEW_TITLE')}
				</DialogTitle>
				<DialogContent className={classes.infoFiled}>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('일시')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.logged_at ? commonUtils.getDateStringToLocalDate(props.logObj.logged_at) : ''}
						</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_USERNAME')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.user_name
								? `${props.logObj.user_name}(${props.logObj.user_id}-${props.logObj.client_id})`
								: ''}
						</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_TYPE')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.tagName || ''}</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_PATH')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.path || ''}</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_SIGNING_ID')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.signing_id || ''}</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_HASH')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.hash || ''}</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_SOURCE_PATH')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.source_path || ''}</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_DEST_PATH')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.dest_path || ''}</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
