import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { Button, CircularProgress, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import commonUtils from 'app/utils/CommonUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
	getEmergencyMode,
	startEmergencyMode,
	finishEmergencyMode,
	restoreBackup,
	getRecoveryAvailableDays
} from 'app/store/docbase/admin/settingSlice';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import ConfirmDialog from 'app/main/restore/ConfirmDialog';
import ConfirmRestore from './ConfirmRestore';
import OpenDialogForm from '../component/OpenDialogForm';

const useStyles = makeStyles(theme => ({
	circle: {
		display: 'inline-block',
		marginRight: 10,
		width: 8,
		height: 8,
		borderRadius: '50%',
		backgroundColor: '#ed6b5f',
		cursor: 'pointer',
		'&:hover': { width: 10, height: 10 }
	},
	contentWrapper: {
		height: `${window.innerHeight - 200}px`
	},
	mainPaper: { borderRadius: 6, padding: 0 },
	tr: {
		'&>.MuiTableCell-root span.folder-name': {
			marginLeft: 12
		},
		'& .date': {
			width: 194
		},
		'& .action-btn': {
			width: 480,
			'&>div': {
				display: 'flex',
				padding: '0 40px'
			}
		},
		'& .MuiButton-root': {
			width: '100%',
			height: 28,
			fontSize: 12,
			'&:first-child': {
				marginRight: 10
			}
		}
	},
	circleProgress: {
		width: 30,
		height: 30,
		marginLeft: 10
	}
}));

const MODE_NONE = 0;
const MODE_LASTRECENT = 1;
const MODE_TODAY = 2;
const MODE_DATE = 3;

const MODE_LASTRECENT_CODE = 'last_recent';
const MODE_TODAY_CODE = 'today';
const MODE_DATE_CODE = 'date';
let timer;

function RestoreContent() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation('docbase');
	const contentTable = React.useRef();

	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const restoreObj = useSelector(({ docbase }) => docbase.admin.setting.restoreObj);
	const [selectedDate, setSelectedDate] = React.useState(commonUtils.getDateString(new Date()));
	const [backHours, setBackHours] = React.useState(0);
	const [mode, setMode] = React.useState(MODE_TODAY);

	const [openModeConfirm, setOpenModeConfirm] = React.useState(false);
	const [openRestoreConfirm, setOpenRestoreConfirm] = React.useState(false);
	// 0: 비활성화, 1~ 각 버튼 상황에 맞는 버튼.
	const [restoreStatus, setRestoreStatus] = React.useState({ emergencyBtn: 0, restoreBtn: 0, isChange: false });
	const [isRestore, setIsRestore] = React.useState(false);

	React.useEffect(() => {
		dispatch(getEmergencyMode());
		dispatch(getRecoveryAvailableDays());
	}, [dispatch]);

	React.useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 200}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	React.useEffect(() => {
		if (restoreObj.mode !== MODE_NONE) {
			setMode(restoreObj.mode);
			if (restoreObj.mode === MODE_LASTRECENT) {
				setBackHours(restoreObj.back_hours);
			} else if (restoreObj.mode === MODE_DATE) {
				setSelectedDate(restoreObj.date);
			}
		}

		//	1: emergency mode, 2: restore mode
		if (restoreObj.process_type === 1) {
			if (restoreObj.status === 0) {
				clearInterval(timer);
				timer = undefined;
				setIsRestore(false);
				//	진행중이 아닌 때
				if (restoreObj.mode === MODE_NONE) {
					//	모드가 설정이 안되어 있으면 임시복원/복원 가능
					setRestoreStatus({ emergencyBtn: 1, restoreBtn: 1, isChange: true });
				} else {
					// 모드가 설정이 되어있으면 임시복원모드로 동작 중이다. 바로 복원이 가능하다.
					setRestoreStatus({ emergencyBtn: 3, restoreBtn: 1, isChange: false });
				}
			} else if (restoreObj.status === 1) {
				if (!timer) {
					timer = setInterval(() => {
						dispatch(getEmergencyMode());
					}, 2000);
				}
				if (isRestore) {
					//	임시모드에서 복원을 진행하면 process_type이 1로 온다....
					setRestoreStatus({ emergencyBtn: 0, restoreBtn: 2, isChange: false });
				} else {
					//	임시모드로 전환중
					setRestoreStatus({ emergencyBtn: 2, restoreBtn: 0, isChange: false });
				}
			}
		} else if (restoreObj.process_type === 2) {
			if (restoreObj.status === 0) {
				clearInterval(timer);
				timer = undefined;
				setIsRestore(false);
				//	진행중이 아닌 때
				setRestoreStatus({ emergencyBtn: 1, restoreBtn: 1, isChange: true });
			} else if (restoreObj.status === 1) {
				if (!timer) {
					timer = setInterval(() => {
						dispatch(getEmergencyMode());
					}, 2000);
				}
				//	복원 진행중
				setRestoreStatus({ emergencyBtn: 0, restoreBtn: 2, isChange: false });
			}
		} else if (restoreObj.process_type === 0) {
			if (timer) clearInterval(timer);
			setRestoreStatus({ emergencyBtn: 1, restoreBtn: 1, isChange: true });
		} else {
			setRestoreStatus({ emergencyBtn: 0, restoreBtn: 0, isChange: false });
		}
	}, [restoreObj]);

	//	임시복원 처리
	const handleTempResore = () => {
		let sMode = 'last_recent';
		if (mode === MODE_LASTRECENT) {
			sMode = MODE_LASTRECENT_CODE;
		} else if (mode === MODE_DATE) {
			sMode = MODE_DATE_CODE;
		} else if (mode === MODE_TODAY) {
			sMode = MODE_TODAY_CODE;
		} else {
			dispatch(showErrorMessage({ message: t('RESTORE_ERROR_MESSAGE_3') }));
			setOpenModeConfirm(false);
			return;
		}

		startEmergencyMode(sMode, backHours, commonUtils.getDateString(selectedDate))
			.then(data => {
				dispatch(getEmergencyMode());
				setOpenModeConfirm(false);
			})
			.catch(err => dispatch(showErrorMessage({ message: t('RESTORE_ERROR_MESSAGE_4') })));
	};

	const drawSubTitle = day => {
		if (restoreObj.date === day || (restoreObj.date === '-' && day === 'today')) {
			if (restoreObj.mode !== MODE_NONE && restoreObj.process_type === 1) {
				if (restoreStatus.emergencyBtn === 2 || restoreStatus.restoreBtn === 2) {
					return <p>{/* <CircularProgress className="align-middle" color="secondary" /> */}</p>;
				}

				if (restoreObj.mode === MODE_LASTRECENT) {
					if (Number(restoreObj.back_hours) === 0) {
						return (
							<p>
								<span className={classes.circle} />
								{`${t('RESTORE_TEMP_PROCESSING')} [${t('RESTORE_NOW')}]`}
							</p>
						);
					}
					return (
						<p>
							<span className={classes.circle} />
							{`${t('RESTORE_TEMP_PROCESSING')} [${t('RESTORE_NOW')} ${restoreObj.back_hours} ${t(
								'RESTORE_HOUR_BEFORE'
							)}]`}
						</p>
					);
				}
				if (restoreObj.mode === MODE_TODAY) {
					return (
						<p>
							<span className={classes.circle} />
							{`${t('RESTORE_TEMP_PROCESSING')} [${t('RESTORE_TODAY')}]`}
						</p>
					);
				}
				if (restoreObj.mode === MODE_DATE) {
					return (
						<p>
							<span className={classes.circle} />
							{`${t('RESTORE_TEMP_PROCESSING')} [${t('RESTORE_ONE_DAY')}: ${restoreObj.date}]`}
						</p>
					);
				}
			}
		}

		return <></>;
	};

	const drawBtnGroup = day => {
		if (restoreObj.date === day || (restoreObj.date === '-' && day === 'today')) {
			if (restoreStatus.emergencyBtn === 2 || restoreStatus.restoreBtn === 2) {
				return (
					<div className="">
						<p>
							<span className="">{t('RESTORE_CHANGING')}</span>
							<CircularProgress
								className={clsx(classes.circleProgress, 'align-middle')}
								color="secondary"
								size={30}
							/>
						</p>
					</div>
				);
			}

			if (restoreStatus.emergencyBtn === 3) {
				return (
					<div className="">
						<Button
							className="default"
							variant="contained"
							color=""
							aria-label="restore-mode"
							onClick={e => {
								if (restoreStatus.restoreBtn === 1) {
									setIsRestore(true);
									setOpenRestoreConfirm(true);
								}
							}}
							disabled={restoreStatus.restoreBtn === 0}
						>
							{t('RESTORE')}
						</Button>
						<Button
							className=""
							variant="contained"
							color="secondary"
							aria-label="emergency-mode"
							onClick={e => {
								if (restoreStatus.emergencyBtn === 3) {
									dispatch(
										openDialog({
											children: (
												<OpenDialogForm
													title={t('RESTORE_TEMP')}
													content={t('RESTORE_MESSAGE_2')}
													fnHandleOK={() => {
														finishEmergencyMode()
															.then(data => {
																dispatch(getEmergencyMode());
															})
															.catch(err =>
																dispatch(
																	showErrorMessage({
																		message: t('RESTORE_ERROR_MESSAGE_5')
																	})
																)
															)
															.finally(() => {
																dispatch(closeDialog());
															});
													}}
												/>
											)
										})
									);
								}
							}}
						>
							{t('RESTORE_TEMP_FINISH')}
						</Button>
					</div>
				);
			}
		}

		if (day === 'today') {
			return (
				<div>
					<Button
						variant="contained"
						className="default"
						aria-label="ignore-add"
						size="small"
						type="button"
						disabled={restoreStatus.emergencyBtn === 3}
						onClick={() => {
							if (restoreObj.status === 0) {
								setMode(MODE_TODAY);
								setOpenModeConfirm(true);
							} else {
								dispatch(showMessage({ message: t('RESTORE_MESSAGE_8') }));
							}
						}}
					>
						{t('RESTORE_TODAY')}
					</Button>
					<Button
						variant="contained"
						className="default"
						aria-label="ignore-add"
						size="small"
						type="button"
						disabled={restoreStatus.emergencyBtn === 3}
						onClick={() => {
							if (restoreObj.status === 0) {
								setMode(MODE_LASTRECENT);
								setOpenModeConfirm(true);
							} else {
								dispatch(showMessage({ message: t('RESTORE_MESSAGE_8') }));
							}
						}}
					>
						{t('RESTORE_ONE_TIME')}
					</Button>
				</div>
			);
		}
		return (
			<div>
				<Button
					variant="contained"
					className="default"
					aria-label="ignore-add"
					size="small"
					type="button"
					disabled={restoreStatus.emergencyBtn === 3}
					onClick={() => {
						if (restoreObj.status === 0 && restoreObj.mode === MODE_NONE) {
							setMode(MODE_DATE);
							setSelectedDate(day);
							setOpenModeConfirm(true);
						} else {
							dispatch(showMessage({ message: t('RESTORE_MESSAGE_8') }));
						}
					}}
				>
					{t('RESTORE')}
				</Button>
			</div>
		);
	};

	let contentText = '';
	if (mode === MODE_TODAY) {
		contentText = t('RESTORE_MESSAGE_1');
	} else if (mode === MODE_LASTRECENT) {
		contentText = t('RESTORE_MESSAGE_4');
	} else if (mode === MODE_DATE) {
		contentText = `${commonUtils.getDateString(selectedDate)} ${t('RESTORE_MESSAGE_5')}`;
	}
	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<FuseScrollbars className={clsx(classes.contentWrapper, 'w-full flex-grow overflow-x-auto')} ref={contentTable}>
				<Paper className={classes.mainPaper}>
					<ConfirmDialog
						open={openModeConfirm}
						mode={mode}
						title={t('RESTORE')}
						content_1={t('RESTORE_MESSAGE_6')}
						content_2={contentText}
						cancelTitle={t('RESTORE_TEMP')}
						okTitle={t('RESTORE')}
						backHours={backHours}
						fnHandleClose={() => setOpenModeConfirm(false)}
						fnSetBackHours={hour => setBackHours(hour)}
						fnHandleCancel={() => handleTempResore()}
						fnHandleOK={() => {
							setOpenRestoreConfirm(true);
							setOpenModeConfirm(false);
						}}
					/>

					<ConfirmRestore
						open={openRestoreConfirm}
						fnHandleClose={() => setOpenRestoreConfirm(false)}
						fnHandleOK={() => {
							let sMode = 'last_recent';
							if (mode === MODE_LASTRECENT) {
								sMode = MODE_LASTRECENT_CODE;
							} else if (mode === MODE_DATE) {
								sMode = MODE_DATE_CODE;
							} else if (mode === MODE_TODAY) {
								sMode = MODE_TODAY_CODE;
							} else {
								dispatch(showErrorMessage({ message: t('RESTORE_ERROR_MESSAGE_6') }));
								setOpenRestoreConfirm(false);
								return;
							}

							restoreBackup(sMode, backHours, commonUtils.getDateString(selectedDate)).then(data => {
								dispatch(getEmergencyMode());
								setOpenRestoreConfirm(false);
							});
						}}
					/>

					<div>
						<Table stickyHeader className="" aria-labelledby="tableTitle">
							<TableHead>
								<TableRow>
									<TableCell padding="none" align="left" className="left">
										{t('RESTORE_SUB_TITLE')}
									</TableCell>
									<TableCell padding="none" align="left" className="left">
										{t('STATUS')}
									</TableCell>
									<TableCell padding="none" align="center" className="" />
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow
									className={clsx(classes.tr, 'cursor-pointer')}
									hover
									role="checkbox"
									tabIndex={-1}
									// key={d.user_id}
									// onClick={event => handleCheck(event, d.user_id)}
								>
									<TableCell className="left date" align="left" component="td" scope="row">
										{commonUtils.getDateString(new Date())}
									</TableCell>

									<TableCell className="left" align="left" component="td" scope="row">
										{drawSubTitle('today')}
									</TableCell>

									<TableCell className="action-btn" component="td" scope="row" align="center">
										{drawBtnGroup('today')}
									</TableCell>
								</TableRow>
								{restoreObj.days.map((day, idx) => (
									<TableRow
										key={idx}
										className={clsx(classes.tr, 'cursor-pointer')}
										hover
										role="checkbox"
										tabIndex={-1}
									>
										<TableCell className="left date" align="left" component="td" scope="row">
											{`${day.substring(0, 4)}-${day.substring(4, 6)}-${day.substring(6)}`}
										</TableCell>

										<TableCell className="left" align="left" component="td" scope="row">
											{drawSubTitle(`${day.substring(0, 4)}-${day.substring(4, 6)}-${day.substring(6)}`)}
										</TableCell>

										<TableCell className="action-btn" component="td" scope="row" align="center">
											{drawBtnGroup(`${day.substring(0, 4)}-${day.substring(4, 6)}-${day.substring(6)}`)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</div>
				</Paper>
			</FuseScrollbars>
		</div>
	);
}

export default RestoreContent;
