import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { IconButton, Dialog, DialogContent, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import commonUtils from 'app/utils/CommonUtils';

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});
const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 1208
		}
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	infoFiled: {
		color: '#000000',
		'&>div': {
			display: 'flex',
			height: 45,
			paddingRight: 30,
			borderTop: '1px solid rgb(141 149 160 / 18%)'
		},
		'&>div:last-child': { borderBottom: '1px solid rgb(141 149 160 / 18%)' }
	},
	filedTitle: {
		background: '#f5f7fa',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		cursor: 'default',
		width: '100%',
		maxWidth: 277,
		minWidth: 277
	},
	filedInput: {
		width: '100%',
		padding: '0 0 0 30px',
		display: 'flex',
		alignItems: 'center',
		'&.folder-name': { paddingRight: 10 }
	}
}));

export default function ViewDialog(props) {
	const classes = useStyles();
	const { t } = useTranslation('docbase');
	console.log(props.logObj);
	return (
		<div>
			<Dialog className={classes.root} open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog-title">
				<DialogTitle className={classes.title} id="form-dialog-title" onClose={props.fnHandleClose}>
					{t('DLP_CLIENT_LOG_VIEW_TITLE')}
				</DialogTitle>
				<DialogContent className={classes.infoFiled}>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_CLIENT_LOG_USERNAME')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.user_name
								? `${props.logObj.user_name}(${props.logObj.user_id}-${props.logObj.client_id})`
								: ''}
						</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('mac')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.mac_address || ''}</div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('VERSION')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>{props.logObj.version || ''}</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('LOGIN')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.logged_in ? t('LOGIN') : t('NO_LOGIN')}
						</div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('CONNECTED')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.connected ? t('CONNECTED') : t('NOT_CONNECTED')}
						</div>
					</div>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('LOGGED_IN_AT')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.last_logged_in_at
								? commonUtils.getDateStringToLocalDate(props.logObj.last_logged_in_at)
								: ''}
						</div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('LOGGED_OFF_AT')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.last_logged_off_at
								? commonUtils.getDateStringToLocalDate(props.logObj.last_logged_off_at)
								: ''}
						</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('CHECKED_AT')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.health_last_checked_at
								? commonUtils.getDateStringToLocalDate(props.logObj.health_last_checked_at)
								: ''}
						</div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_HEALTH_MODULE_SECURE')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.health_module_secure ? 'ACTIVE' : 'INACTIVE'}
						</div>
					</div>

					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('SIP')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.sip_enable ? t('ACTIVE') : t('INACTIVE')}
						</div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('EPS')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'DLP_HEALTH_MODULE_EPS-name')}>
							{props.logObj.health_module_eps ? t('ACTIVE') : t('INACTIVE')}
						</div>
					</div>
					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_HEALTH_MODULE_NETWORK')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.health_module_network ? t('ACTIVE') : t('INACTIVE')}
						</div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('DLP_HEALTH_MODULE_CHROME')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{props.logObj.health_module_chrome < 0
								? t('NOT_INSTALLED')
								: props.logObj.health_module_chrome === 1
								? t('ACTIVE')
								: t('INACTIVE')}
						</div>
					</div>

					<div>
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('BLUETOOTH_LIST')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							{console.log(props)}
							{props.bluetoothDevices.map(bluetooth => {
								return (
									<p>
										<span>{bluetooth.device_name}</span> ||
										<span>{bluetooth.device_type}</span> ||
										<span>{bluetooth.device_address}</span>
									</p>
								);
							})}
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
