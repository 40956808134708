import { makeStyles } from '@material-ui/core/styles';
import { Icon, TextField, Card, CardActions, CardContent, IconButton, Button } from '@material-ui/core';
import React from 'react';
import commonUtils from 'app/utils/CommonUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import * as commonTypes from 'app/main/constants/CommonTypes';
import { openDialog } from 'app/store/fuse/dialogSlice';

import { approveDriveFolder } from 'app/store/docbase/admin/accountSlice';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import OpenDialogForm from '../component/OpenDialogForm';

const useStyles = makeStyles(theme => ({
	cardInfo: {
		padding: '40px 30px 20px',
		'& .MuiCardActions-root': {
			padding: 0
		},
		'& .MuiCardContent-root': {
			padding: 0
		}
	},
	infoFiled: {
		color: '#000000',
		'&>div': {
			display: 'flex',
			height: 45,
			borderTop: '1px solid rgb(141 149 160 / 18%)'
		},
		'&>div:last-child': { borderBottom: '1px solid rgb(141 149 160 / 18%)' }
	},
	filedTitle: {
		background: '#f5f7fa',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		cursor: 'default',
		width: '100%',
		maxWidth: 277
	},
	filedInput: { width: '100%', padding: '0 30px', display: 'flex', alignItems: 'center' },
	icon: {
		marginRight: 10
	},
	buttonGroup: {
		marginTop: 30,
		'& button': { marginRight: 10 }
	}
}));

function DetailSidebarContent(props) {
	const classes = useStyles();
	const permList = React.useRef();
	const dispatch = useDispatch();
	const { usersObj } = useSelector(({ docbase }) => docbase.admin.account);
	const { t } = useTranslation('docbase');

	const [rejectReason, setRejectReason] = React.useState('');
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	React.useEffect(() => {
		if (innerHeight >= 754) {
			permList.current.style.height = `${innerHeight - 140}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	React.useEffect(() => {}, []);

	let reqType = '';
	switch (props.selectedObj.request_type) {
		case commonTypes.TAKEOUT_REQUEST_TYPE_DOC:
			reqType = t('TAKEOUT_DOC');
			break;
		case commonTypes.TAKEOUT_REQUEST_TYPE_PRINT:
			reqType = t('TAKEOUT_PRINT');
			break;
		case commonTypes.TAKEOUT_REQUEST_TYPE_WEBLINK:
			reqType = t('TAKEOUT_WEBLINK');
			break;
		default:
	}

	const doApprove = async (driveID, approve, reason) => {
		if (approve === 2 && reason === '') {
			dispatch(showErrorMessage({ message: t('ENTER_REASON') }));
			return false;
		}
		const resp = await approveDriveFolder(driveID, approve, reason);
		if (resp.result) {
			dispatch(showMessage({ message: approve === 1 ? t('APPROVED') : t('REJECTED') }));
		}
		return true;
	};

	return (
		<div className="" ref={permList}>
			<Card className={classes.cardInfo}>
				<CardActions className={clsx(classes.card_button_group, 'md:')} disableSpacing>
					<IconButton
						className="md:hidden"
						aria-controls="detail-back"
						onClick={e => {
							props.fnToggleRightSidebar();
						}}
					>
						<Icon>arrow_back</Icon>
					</IconButton>
				</CardActions>

				<CardContent className={classes.infoFiled}>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('TAKEOUT_REQUESTER')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<TextField
								className=""
								variant="outlined"
								type="text"
								name="requester"
								fullWidth
								value={
									props.selectedObj.user_id ? `${props.selectedObj.userName}(${props.selectedObj.user_id})` : ''
								}
								inputProps={{ readOnly: true }}
							/>
						</div>
					</div>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('TAKEOUT_REQ_AT')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<TextField
								className=""
								variant="outlined"
								type="text"
								name="requested_at"
								fullWidth
								value={commonUtils.getDateStringToLocalDate(props.selectedObj.requested_at)}
								inputProps={{ readOnly: true }}
							/>
						</div>
					</div>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('EXPIRE_DATE')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<TextField
								className=""
								variant="outlined"
								type="text"
								name="expire_at"
								fullWidth
								value={commonUtils.getDateStringToLocalDate(props.selectedObj.expire_at)}
								inputProps={{ readOnly: true }}
							/>
						</div>
					</div>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('TAKEOUT_STATUS')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<TextField
								className=""
								variant="outlined"
								value={props.selectedObj.statusTxt}
								name="status"
								fullWidth
								inputProps={{ readOnly: true }}
							/>
						</div>
					</div>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('TAKEOUT_REASON_REQ')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<TextField
								className=""
								variant="outlined"
								type="text"
								name="reason_req"
								fullWidth
								value={props.selectedObj.reason}
								inputProps={{ readOnly: true }}
							/>
						</div>
					</div>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('TAKEOUT_REASON_REJ')}</p>
						</div>
						<div className={clsx(classes.filedInput, '')}>
							<TextField
								className=""
								variant="outlined"
								type="text"
								name="reason_rej"
								fullWidth
								value={rejectReason}
								onChange={e => setRejectReason(e.target.value)}
							/>
						</div>
					</div>
				</CardContent>
				{props.selectedObj.offline_drive_id > 0 &&
				props.selectedObj.status === 0 &&
				commonUtils.isAfter(new Date(`${props.selectedObj.expire_at} 00:00:00`), new Date()) ? (
					<CardActions className={clsx(classes.buttonGroup, 'block')}>
						<div className="w-full flex">
							<div className={clsx('inline-block align-middle')}>
								<Button
									variant="contained"
									className="default"
									type="button"
									fullWidth
									size="small"
									onClick={e => {
										if (props.selectedObj.offline_drive_id <= 0) {
											dispatch(showErrorMessage({ message: t('SELECT_REQUEST') }));
										} else {
											dispatch(
												openDialog({
													children: (
														<OpenDialogForm
															title={t('OFFLINE_DRIVE')}
															content={t('OFFLINE_DRIVE_CONFIRM_1')}
															fnHandleOK={() => {
																doApprove(props.selectedObj.offline_drive_id, 2, rejectReason);
															}}
														/>
													)
												})
											);
										}
									}}
								>
									{t('TAKEOUT_REJECTED')}
								</Button>
								<Button
									variant="contained"
									color="secondary"
									className=""
									type="button"
									fullWidth
									size="small"
									onClick={() => {
										if (props.selectedObj.offline_drive_id <= 0) {
											dispatch(showErrorMessage({ message: t('SELECT_REQUEST') }));
										} else {
											dispatch(
												openDialog({
													children: (
														<OpenDialogForm
															title={t('OFFLINE_DRIVE')}
															content={t('OFFLINE_DRIVE_CONFIRM_2')}
															fnHandleOK={() => {
																doApprove(props.selectedObj.offline_drive_id, 1, '');
															}}
														/>
													)
												})
											);
										}
									}}
								>
									{t('TAKEOUT_APPROVED')}
								</Button>
							</div>
						</div>
					</CardActions>
				) : (
					<></>
				)}
			</Card>
		</div>
	);
}

export default DetailSidebarContent;
