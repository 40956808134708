/**
 * 암복호화 기능을 사용하기 위한 유틸 모음.
 *
 * 개발에 필요한 부분만 가져다가 함수로 작성.
 * 없는 부분은 소스 확인 후 사용하기 편하게 함수를 추가하도록.
 *
 * AES-ECD 암복호화만 구현되어있음. 다른 암호화는 아래 lib사이트가서 확인 후 구현.
 *
 * 사용 lib
 * aes-js
 * npm install --save aes-js
 *
 * Homepage : https://github.com/ricmoo/aes-js
 *
 */
import crypto from 'crypto';
import aesjs from 'aes-js';

class SecurityUtils {
	/**
	 *
	 * @param aesKey            aes 키
	 * @param source            암호화 대상
	 * @param sourceEncoding    암호화 대상 인코딩
	 * @param outputEncoding    결과 인코딩
	 */
	encryptAES(aesKey, source, sourceEncoding, outputEncoding) {
		// An example 128-bit key
		let key = aesKey;
		if (!Buffer.isBuffer(aesKey)) key = aesjs.utils.hex.toBytes(aesKey);

		// Convert text to bytes
		// source = "12345678901234567890123456789012"
		let sourceBytes = [];
		if (sourceEncoding === 'hex') sourceBytes = aesjs.utils.hex.toBytes(source);
		else if (sourceEncoding === 'arrayBuffer') sourceBytes = new Uint8Array(source);
		else sourceBytes = aesjs.utils.utf8.toBytes(source);

		const aesEcb = new aesjs.ModeOfOperation.ecb(key);
		let encryptedBytes;
		const blockSize = 16;
		if (sourceBytes.length % blockSize === 0) encryptedBytes = aesEcb.encrypt(sourceBytes);
		else {
			const blockCnt =
				sourceBytes.length % blockSize > 0
					? Math.floor(sourceBytes.length / blockSize + 1)
					: sourceBytes.length / blockSize;
			const sBuf = Buffer.alloc(blockCnt * blockSize);
			Buffer.from(sourceBytes).copy(sBuf, 0, 0, sourceBytes.length);
			encryptedBytes = aesEcb.encrypt(sBuf);
		}
		// To print or store the binary data, you may convert it to hex
		let resultVal = '';
		if (outputEncoding === 'utf8') resultVal = aesjs.utils.utf8.fromBytes(encryptedBytes);
		else resultVal = aesjs.utils.hex.fromBytes(encryptedBytes);

		return resultVal;
	}

	/**
	 * aes 복호화
	 *
	 * @param aesKey            aes키
	 * @param source            복호화 대상
	 * @param sourceEncoding    복호화 인코딩
	 * @param outputEncoding    결과 인코딩
	 */
	decryptAES(aesKey, source, sourceEncoding, outputEncoding, originSize) {
		let key = aesKey;
		if (!Buffer.isBuffer(aesKey)) key = aesjs.utils.hex.toBytes(aesKey);

		const aesEcb = new aesjs.ModeOfOperation.ecb(key);
		// "a7d93b35368519fac347498dec18b458"

		// When ready to decrypt the hex string, convert it back to bytes
		let encryptedBytes = [];
		if (sourceEncoding === 'hex') encryptedBytes = aesjs.utils.hex.toBytes(source);
		else encryptedBytes = aesjs.utils.utf8.toBytes(source);

		// Since electronic codebook does not store state, we can
		// reuse the same instance.
		// var aesEcb = new aesjs.ModeOfOperation.ecb(key);
		const decryptedBytes = aesEcb.decrypt(encryptedBytes);

		//	arrayBuffer는 string으로 변경하지않고 돌려준다.
		if (outputEncoding === 'arrayBuffer') {
			if (originSize) {
				return decryptedBytes.slice(0, originSize);
			}
			return decryptedBytes;
		}

		// Convert our bytes back into text
		let resultVal = '';
		if (outputEncoding === 'utf8') resultVal = aesjs.utils.utf8.fromBytes(decryptedBytes);
		else resultVal = aesjs.utils.hex.fromBytes(decryptedBytes);

		//  array에 값이 0 인경우의 공백이 사라지지않아서 제거하는 부분을 추가
		let result = '';
		for (let i = 0; i < resultVal.length; i++) {
			if (resultVal.charCodeAt(i) !== 0) {
				result += resultVal[i];
			}
		}
		return result;
	}

	base64Encode(input, inputEncoding) {
		if (inputEncoding === undefined || inputEncoding === '') inputEncoding = 'utf8';

		return Buffer.from(input, inputEncoding).toString('base64');
	}

	base64Decode(input, outputEncoding) {
		if (outputEncoding === undefined || outputEncoding === '') outputEncoding = 'utf8';
		return Buffer.from(input, 'base64').toString(outputEncoding);
	}

	createECDH() {
		const alice = crypto.createECDH('prime256v1');
		alice.generateKeys();
		return alice;
	}

	computeSecretKey(myObject, publicKey) {
		return myObject.computeSecret(publicKey, 'hex');
	}

	hashSHA256(str) {
		return crypto.createHash('sha256').update(str).digest('hex');
	}

	decodeUTF8(str) {
		if (str) {
			return new TextDecoder('utf8').decode(this.strToUint8Array(str));
		}
		return '';
	}

	strToUint8Array(str) {
		const bytes = new Uint8Array(new ArrayBuffer(str.length));
		for (let i = 0; i < str.length; i++) {
			bytes[i] = str.charCodeAt(i);
		}
		return bytes;
	}

	hexToArray(hexString) {
		return aesjs.utils.hex.toBytes(hexString);
	}

	keyCheck(key) {
		let k = key;
		if (key[0] === 0) {
			k = key.slice(1, key.length);
		}
		switch (k.length) {
			case 16:
				return k;
			case 24:
				return k;
			case 32:
				return k;
			default:
				return this.normalizeKeySize(k);
		}
	}

	normalizeKeySize(key) {
		const keyLen = key.length;
		let keySize = ((keyLen - 1) / 8) * 8 + 8;

		if (keySize < 16) {
			keySize = 16;
		}

		const l = keySize + key.length;
		const retKey = new ArrayBuffer(l);
		retKey.splice(0, keyLen, ...key);

		let keylen = key.length;
		while (keylen < keySize) {
			retKey.splice(key.length, 0, ...key);
			keylen += key.length;
		}
		return retKey.slice(0, keySize);
	}

	encryptAESCTR(str) {
		const passByte = aesjs.utils.utf8.toBytes(str);
		const key = aesjs.utils.utf8.toBytes('SSY94120332ACBDDEY32985348TTY334');
		const aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
		return aesjs.utils.hex.fromBytes(aesCtr.encrypt(passByte));
	}

	decryptAESCTR(str) {
		const passByte = aesjs.utils.hex.toBytes(str);
		const key = aesjs.utils.utf8.toBytes('SSY94120332ACBDDEY32985348TTY334');
		const aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
		return aesjs.utils.utf8.fromBytes(aesCtr.decrypt(passByte));
	}
}
export default new SecurityUtils();
