import Users from './Users';

const UsersConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/users',
			component: Users
		}
	]
};

export default UsersConfig;

/**
 * Lazy load Example
 */
/*
import React from 'react';

const UsersConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
};

export default UsersConfig;

*/
