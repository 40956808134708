import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import httpClient from 'app/utils/HttpClient';
import commonUtils from 'app/utils/CommonUtils';
import * as commonTypes from 'app/main/constants/CommonTypes';
import { secureRequest, secureAsyncRequest } from 'app/store/docbase/commonAction';
import { setShowBackdrop } from './settingSlice';
import { getUserList } from './accountSlice';

/**
 * 조직, 공유폴더 모두 요청
 */
export const getAllFolderList = createAsyncThunk('folder/getAllFolderList', async () => {
	const secureObj1 = await secureAsyncRequest();
	const respData1 = await httpClient.sendPost('/api/organizationFolders', { token: secureObj1.token });
	const secureObj2 = await secureAsyncRequest();
	const respData2 = await httpClient.sendPost('/api/sharedFolderList', { token: secureObj2.token });
	const secureObj3 = await secureAsyncRequest();
	const respData3 = await httpClient.sendPost('/api/orgSharedFolderList', { token: secureObj3.token });

	const folderObj = { orgs: respData1.folders, shareds: respData2.folders, orgshareds: respData3.folders };
	return { folderObj };
	// return { users: [{ user_id: 'admin', name: '관리', access_level: 1, regdate: '2020-10-11' }] };
});
/**
 * 전제 조직폴더 조회
 */
export const getAllOrgFolderList = createAsyncThunk('folder/getAllOrgFolderList', async () => {
	const secureObj = await secureAsyncRequest();
	const respData = await httpClient.sendPost('/api/organizationFolders', { token: secureObj.token });
	if (!respData.result) {
		return { folders: [] };
	}
	return { folders: respData.folders };
});
/**
 * 조직폴더 조회
 *
 * @param {number} {parentFid: number}
 *
 */
export const getOrgFolderList = createAsyncThunk('folder/getOrgFolderList', async ({ parentFid }, { dispatch, getState }) => {
	const secureObj = await secureAsyncRequest();
	const respData = await httpClient.sendPost('/api/organizationFolderList', { parent_fid: parentFid, token: secureObj.token });

	return { folders: respData.folders };

	// return { folders: [{ fid: '1', name: '더더스', path: '/더덕스' }] };
});
/**
 * 조직폴더 조회 - orgsObj만 갱신하는 버전
 *
 * @param {number} {parentFid: number}
 *
 */
export const updOrgsObj = createAsyncThunk('folder/updOrgsObj', async ({ parentFid }) => {
	const secureObj = await secureAsyncRequest();
	const respData = await httpClient.sendPost('/api/organizationFolders', { parent_fid: parentFid, token: secureObj.token });
	return { folders: respData.folders };

	// return { folders: [{ fid: '1', name: '더더스', path: '/더덕스' }] };
});
/**
 * 조직폴더 클릭
 *
 * @param {number} {fid: number}
 */
export const selectedOrgFolderID = createAsyncThunk('folder/selectedOrgFolderID', async ({ fid }, { dispatch, getState }) => {
	if (fid <= 0) {
		return { permissions: [], selectedOrgID: 0, capacity: 0, parentPerms: {} };
	}

	const secureObj = await secureAsyncRequest();
	const respData = await httpClient.sendPost('/api/folderPermissionList', { fid, token: secureObj.token });
	const respCapacity = await httpClient.sendPost('/api/getFolderMaxSize', { fid });

	const parentFids = commonUtils.splitPreDelimiter(getState().docbase.admin.folder.orgsObj[fid].fid_path).split('/');
	const parentPerms = {};

	commonUtils.infoLog('parent start');
	for (let i = 0; i < parentFids.length; i++) {
		if (parentFids[i] !== '' && !getState().docbase.admin.folder.parentPerms[parentFids[i]]) {
			// eslint-disable-next-line no-await-in-loop
			const secureObj1 = await secureAsyncRequest();
			// eslint-disable-next-line no-await-in-loop
			const respData1 = await httpClient.sendPost('/api/folderPermissionList', {
				fid: parentFids[i],
				token: secureObj1.token
			});
			parentPerms[parentFids[i]] = respData1.permissions;
		}
	}
	commonUtils.infoLog('parent end');

	return { permissions: respData.permissions, selectedOrgID: fid, capacity: respCapacity.size, parentPerms };

	// return { permissions: [], selectedID: '1' };
});
/**
 * 조직폴더 최초 진입
 *
 * @param {number} {fid: number}
 */
export const initOrgPage = createAsyncThunk('folder/initOrgPage', async (_, { dispatch, getState }) => {
	await dispatch(getUserList());
	// await dispatch(moveOrgFolderID({ fid: 0 }));
	await dispatch(getAllOrgFolderList());

	return {};
});
/**
 * 공유폴더 조회
 *
 */
export const getSharedFolderList = createAsyncThunk('folder/getSharedFolderList', async () => {
	const secureObj = await secureAsyncRequest();
	const respData = await httpClient.sendPost('/api/sharedFolderList', { token: secureObj.token });
	const secureObj1 = await secureAsyncRequest();
	const respData1 = await httpClient.sendPost('/api/orgSharedFolderList', { token: secureObj1.token });
	return { folderObj: { shareds: respData.folders, orgshareds: respData1.folders } };

	// return { folders: [{ fid: '1', name: '더더스', path: '/더덕스' }] };
});
/**
 * 공유폴더 권한에 사용 할 목록 조회
 */
export const readableFolderPermissionObjects = createAsyncThunk('folder/readableFolderPermissionObjects', async () => {
	const respData = await httpClient.sendPost('/api/readableFolderPermissionObjects', {});
	return respData.objects;
});
/**
 * 공유폴더 클릭
 *
 * @param {number} {fid: number}
 */
export const selectedSharedFolderID = createAsyncThunk(
	'folder/selectedSharedFolderID',
	async ({ fid }, { dispatch, getState }) => {
		commonUtils.infoLog('selected share folder start');
		if (fid <= 0) {
			return { permissions: [], selectedSharedID: 0, capacity: 0 };
		}
		const secureObj = await secureAsyncRequest();
		const respData = await httpClient.sendPost('/api/getReadableFolderPermissions', { fid, token: secureObj.token });
		const respCapacity = await httpClient.sendPost('/api/getFolderMaxSize', { fid });

		commonUtils.infoLog('selected share folder end');
		return { permissions: respData.permissions, selectedSharedID: fid, capacity: respCapacity.size };
	}
);

/**
 * 조직폴더 추가
 * @param {string} name
 * @param {number} parentFid
 */
export const createOrgFolder = (name, parentFid, dispatch) => {
	dispatch(setShowBackdrop({ show: true }));
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/createOrganizationFolder', { name, parent_fid: !parentFid ? '0' : parentFid, token: tk })
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {
					dispatch(setShowBackdrop({ show: false }));
				});
		});
	});
};
/**
 * 조직폴더 삭제
 * @param {number} fid
 */
export const removeOrgFolder = (fid, dispatch) => {
	return new Promise((resolve, reject) => {
		if (!fid || fid <= 0) {
			reject(new Error('삭제할 조직을 선택해주세요.'));
		}
		dispatch(setShowBackdrop({ show: true }));
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/removeOrganizationFolder', { fid, token: tk })
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {
					dispatch(setShowBackdrop({ show: false }));
				});
		});
	});
};
/**
 * 조직폴더명 변경
 * @param {number} fid
 * @param {string} name
 * @returns
 */
export const renameOrgFolder = (fid, name) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/renameOrganizationFolder', { fid, name, token: tk }).then(data => {
				if (data.result) {
					resolve(data.result);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};
/**
 * 공유폴더 생성
 * @param {string} name
 */
export const createSharedFolder = (name, dispatch, expireAt) => {
	dispatch(setShowBackdrop({ show: true }));
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			const params = { name, token: tk };
			if (expireAt && expireAt !== '') {
				params.expire_at = expireAt;
			}
			httpClient
				.sendPost('/api/createSharedFolder', params)
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {
					dispatch(setShowBackdrop({ show: false }));
				});
		});
	});
};
/**
 * 공유폴더 만일일 수정.
 * @param {number} fid
 * @param {string} expireAt
 * @returns
 */
export const updateSharedFolder = (fid, expireAt) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/updateSharedFolder', { fid, expire_at: expireAt, token: tk }).then(data => {
				if (data.result) {
					resolve(data.result);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};
/**
 * 공유폴더 삭제
 * @param {number} fid
 */
export const removeSharedFolder = (fid, dispatch) => {
	return new Promise((resolve, reject) => {
		if (!fid) {
			reject(new Error('삭제할 폴더을 선택해주세요.'));
		}
		dispatch(setShowBackdrop({ show: true }));
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/removeSharedFolder', { fid, token: tk })
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {
					dispatch(setShowBackdrop({ show: false }));
				});
		});
	});
};
/**
 * 공유폴더명 변경
 * @param {number} fid
 * @param {string} name
 * @returns
 */
export const renameSharedFolder = (fid, name) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/renameSharedFolder', { fid, name, token: tk }).then(data => {
				if (data.result) {
					resolve(data.result);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};
/**
 * 폴더권한 추가
 * @param {number} fid
 * @param {string} userID
 * @param {number} filePerm
 * @param {number} trashPerm
 */
export const setFolderPermission = (fid, userID, filePerm, trashPerm, manageType, managePriority) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/setFolderPermission', {
					fid,
					user_id: userID,
					file_permission: filePerm,
					trash_permission: trashPerm,
					manage_type: manageType,
					manage_priority: managePriority,
					token: tk
				})
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};
/**
 * 폴더권한 제거
 * @param {number} fid
 * @param {string} userID
 */
export const releaseFolderPermission = (fid, userID) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/releaseFolderPermission', { fid, user_id: userID, token: tk }).then(data => {
				if (data.result) {
					resolve(data.result);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};
export const setReadableFolderPermissions = (fid, perms) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/setReadableFolderPermissions', {
					fid,
					permissions: perms,
					token: tk
				})
				.then(data => {
					if (data && data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data || new Error('error'));
					}
				})
				.catch(e => {
					console.log(e);
				});
		});
	});
};
export const removeOrgSharedFolder = (fid, dispatch) => {
	dispatch(setShowBackdrop({ show: true }));
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/removeOrgSharedFolder', { fid, token: tk })
				.then(data => {
					if (data.result) {
						resolve(data.result);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				})
				.finally(() => {
					dispatch(setShowBackdrop({ show: false }));
				});
		});
	});
};

/**
 * 전체 조직목록 orgsObj를 이외에 사용할 경우 가져온다
 */
export const getDirectOrgList = () => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/organizationFolders', { token: tk }).then(data => {
				if (data.result) {
					const orgChildren = { 0: { children: [] } };
					const orgs = data.folders
						.sort((a, b) => {
							if (a.disp_path > b.disp_path) {
								//	b가 a보다 앞으로 오도록 설정
								return 1;
							}
							if (a.disp_path === b.disp_path) {
								//	변경 없음
								return 0;
							}
							//	a 가 b보다 앞으로 오도록 설정
							return -1;
						})
						.map(f => {
							f.path = f.disp_path;
							f.name = f.name.replace('[_ORG_].', '');

							//	tree 생성용 데이터
							if (orgChildren[f.parent_fid] === undefined) {
								orgChildren[f.parent_fid] = { children: [f] };
							} else {
								orgChildren[f.parent_fid].children.push(f);
							}
							return f;
						});
					resolve({ orgs, orgChildren });
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};
/**
 * 조직폴더 이관 API
 *
 * @param {number} sourceID 원본 조직폴더 아이디
 * @param {number} targetID 대상 조직폴더 아이디
 * @returns
 */
export const moveOrgFolderData = (sourceID, targetID) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/moveOrganizationData', { source_fid: sourceID, target_fid: targetID, token: tk })
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};
/**
 * 조직폴더 이동 API
 *
 * @param {string} sourcePath 원본 조직폴더 경로
 * @param {string} targetPath 대상 조직폴더 경로
 * @returns
 */
export const transferOrgFolder = (sourcePath, targetPath) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/transferOrganizationFolder', { source_path: sourcePath, dest_path: targetPath, token: tk })
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};

/**
 * fid에 권한이 있는 사용자목록을 준다.
 * @param {*} fid
 */
export const getFolderPermList = fid => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/folderPermissionList', { fid, token: tk }).then(data => {
				if (data.result) {
					resolve(data.permissions);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};

/**
 * 조직공유폴더 권한정보 조회
 * @param {*} fid
 * @returns
 */
export const getReadableFolderPermissions = fid => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/getReadableFolderPermissions', { fid, token: tk }).then(data => {
				if (data.result) {
					resolve(data.permissions);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};

/**
 * 특정 폴더의 최대 크기를 조회한다.
 * @param {number} fid
 * @returns
 */
export const getFolderMaxSize = fid => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/api/getFolderMaxSize', { fid }).then(data => {
			if (data.result) {
				resolve({ path: data.path, size: data.size });
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};
/**
 * 특정 폴더의 최대 크기를 지정한다.
 * @param {string} path
 * @param {number} size (MByte)
 * @returns
 */
export const setFolderMaxSize = (path, size) => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/api/setFolderMaxSize', { path, size }).then(data => {
			if (data.result) {
				resolve();
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};
/**
 * 특정 폴더의 승인 여부 설정
 * @param {number} fid
 * @param {boolean} allowTakeout
 * @returns
 */
export const updateOrganizationFolder = (fid, allowTakeout) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/updateOrganizationFolder', { fid, allow_takeout: allowTakeout, token: tk }).then(data => {
				if (data.result) {
					resolve();
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};
const foldersSlice = createSlice({
	name: 'folder',
	initialState: {
		folders: [],
		foldersObj: {},
		orgFolders: [],
		orgsObj: {},
		orgPerms: [],
		parentPerms: {},
		selectedOrgID: -1,
		orgsChildren: { 0: [] },
		sharedFolders: [],
		sharedsObj: {},
		sharedPerms: [],
		readablePermObjs: [],
		selectedSharedID: 0,
		searchTextShared: '',
		capacity: 0,
		sharedCapacity: 0
	},
	reducers: {
		initOrgSearchText: {
			reducer: (state, action) => {
				state.searchTextOrg = '';
				state.selectedOrgID = 0;
			}
		},
		setSharedSearchText: {
			reducer: (state, action) => {
				state.searchTextShared = action.payload;
			},
			prepare: event => ({ payload: event.target.value || '' })
		}
	},
	extraReducers: {
		[getAllFolderList.fulfilled]: (state, action) => {
			const foldersObj = {};
			const folders = [];
			if (action.payload.folderObj) {
				action.payload.folderObj.orgs.forEach((folder, idx) => {
					//	disp_path를 path로 사용하면된다. [_ORG_].  는 물리경로
					folder.real_path = folder.path;
					folder.path = folder.disp_path ? folder.disp_path : folder.path;
					folder.name = folder.name.replace('[_ORG_].', '');
					folder.folder_type = commonTypes.FOLDER_TYPE_ORGANIZE;
					foldersObj[folder.fid] = folder;
					folders.push(folder);
				});

				action.payload.folderObj.shareds.forEach(folder => {
					folder.folder_type = commonTypes.FOLDER_TYPE_SHARED;
					foldersObj[folder.fid] = folder;
					folders.push(folder);
				});

				action.payload.folderObj.orgshareds.forEach(folder => {
					folder.path = folder.disp_path ? folder.disp_path : folder.path;
					folder.folder_type = commonTypes.FOLDER_TYPE_ORGSHARED;
					foldersObj[folder.fid] = folder;
					folders.push(folder);
				});

				state.folders = folders;
				state.foldersObj = foldersObj;
				// state.folders = action.payload.folders;
				// eslint-disable-next-line prefer-destructuring
				// state.foldersObj = action.payload.users[0];
			}
		},
		[getAllOrgFolderList.fulfilled]: (state, action) => {
			const foldersObj = {};
			const orgChildren = { 0: { children: [] } };
			if (action.payload.folders) {
				state.orgFolders = action.payload.folders
					.sort((a, b) => {
						if (a.disp_path > b.disp_path) {
							//	b가 a보다 앞으로 오도록 설정
							return 1;
						}
						if (a.disp_path === b.disp_path) {
							//	변경 없음
							return 0;
						}
						//	a 가 b보다 앞으로 오도록 설정
						return -1;
					})
					.map(folder => {
						folder.real_path = folder.path;
						folder.path = folder.disp_path.substring(folder.disp_path.substring(1).indexOf('/') + 1);
						folder.name = folder.name.replace('[_ORG_].', '');
						foldersObj[folder.fid] = folder;

						//	tree 생성용 데이터
						if (orgChildren[folder.parent_fid] === undefined) {
							orgChildren[folder.parent_fid] = { children: [folder] };
						} else {
							orgChildren[folder.parent_fid].children.push(folder);
						}
						return folder;
					});

				state.orgsObj = { ...state.orgsObj, ...foldersObj };
				state.orgsChildren = orgChildren;
			}
		},
		[getOrgFolderList.fulfilled]: (state, action) => {
			const foldersObj = {};
			if (action.payload.folders) {
				action.payload.folders.forEach(folder => {
					folder.real_path = folder.path;
					folder.path = folder.disp_path.substring(folder.disp_path.substring(1).indexOf('/') + 1);
					folder.name = folder.name.replace('[_ORG_].', '');
					foldersObj[folder.fid] = folder;
				});
				state.orgsObj = { ...state.orgsObj, ...foldersObj };
				state.orgFolders = action.payload.folders.sort((a, b) => {
					if (a.name > b.name) {
						//	b가 a보다 앞으로 오도록 설정
						return 1;
					}
					if (a.name === b.name) {
						//	변경 없음
						return 0;
					}
					//	a 가 b보다 앞으로 오도록 설정
					return -1;
				});
			}
		},
		[updOrgsObj.fulfilled]: (state, action) => {
			const foldersObj = {};
			action.payload.folders.forEach(folder => {
				folder.real_path = folder.path;
				folder.path = folder.disp_path.substring(folder.disp_path.substring(1).indexOf('/') + 1);
				folder.name = folder.name.replace('[_ORG_].', '');
				foldersObj[folder.fid] = folder;
			});
			state.orgsObj = { ...state.orgsObj, ...foldersObj };
		},
		[selectedOrgFolderID.fulfilled]: (state, action) => {
			commonUtils.infoLog('permission check start');
			state.orgPerms = action.payload.permissions
				? action.payload.permissions.sort((a, b) => {
						if (a.user_name > b.user_name) {
							//	b가 a보다 앞으로 오도록 설정
							return 1;
						}
						if (a.user_name === b.user_name) {
							//	변경 없음
							return 0;
						}
						//	a 가 b보다 앞으로 오도록 설정
						return -1;
				  })
				: [];
			state.selectedOrgID = action.payload.selectedOrgID;
			state.capacity = action.payload.capacity;

			state.parentPerms = { ...state.parentPerms, ...action.payload.parentPerms };
			commonUtils.infoLog('permission check end');
		},
		[getSharedFolderList.fulfilled]: (state, action) => {
			const foldersObj = {};
			const folders = [];
			action.payload.folderObj.shareds.forEach(folder => {
				folder.real_path = folder.path;
				folder.path = folder.path.substring(folder.path.substring(1).indexOf('/') + 1);
				foldersObj[folder.fid] = folder;
				folder.folder_type = commonTypes.FOLDER_TYPE_SHARED;

				folders.push(folder);
			});
			action.payload.folderObj.orgshareds.forEach(folder => {
				folder.real_path = folder.path;
				folder.path = folder.path.substring(folder.path.substring(1).indexOf('/') + 1);
				foldersObj[folder.fid] = folder;
				folder.folder_type = commonTypes.FOLDER_TYPE_ORGSHARED;

				folders.push(folder);
			});

			state.sharedsObj = foldersObj;
			state.sharedFolders = folders.sort((a, b) => {
				if (a.name > b.name) {
					//	b가 a보다 앞으로 오도록 설정
					return 1;
				}
				if (a.name === b.name) {
					//	변경 없음
					return 0;
				}
				//	a 가 b보다 앞으로 오도록 설정
				return -1;
			});
		},
		[selectedSharedFolderID.fulfilled]: (state, action) => {
			commonUtils.infoLog('selected share folder set start');
			state.sharedPerms = action.payload.permissions.sort((a, b) => {
				if (a.obj_type === b.obj_type) {
					if (a.name > b.name) {
						return 1;
					}
					if (a.user_name === b.user_name) {
						return 0;
					}
					return -1;
				}
				if (a.obj_type < b.obj_type) {
					return 1;
				}
				if (a.obj_type > b.obj_type) {
					return -1;
				}
				return 0;
			});
			state.selectedSharedID = action.payload.selectedSharedID;
			state.sharedCapacity = action.payload.capacity;
		},
		[readableFolderPermissionObjects.fulfilled]: (state, action) => {
			state.readablePermObjs = action.payload.sort((a, b) => {
				if (a.obj_type === b.obj_type) {
					if (a.name > b.name) {
						return 1;
					}
					if (a.name === b.name) {
						return 0;
					}
					return -1;
				}
				if (a.obj_type < b.obj_type) {
					return 1;
				}
				if (a.obj_type > b.obj_type) {
					return -1;
				}
				return 0;
			});
		}
	}
});

export const { setSharedSearchText, initOrgSearchText } = foldersSlice.actions;

export default foldersSlice.reducer;
