import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import GlobalContent from './GlobalContent';
import ContentHeader from '../component/ContentHeader';

function GlobalSetting() {
	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={<GlobalContent />}
		/>
	);
}

export default GlobalSetting;
