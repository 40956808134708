import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import { Paper, AppBar, Box, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAllFoldersPermissions, getUsersWithCollectInfo, setUsersWithCollectInfo } from 'app/store/docbase/admin/settingSlice';
import { getUserList } from 'app/store/docbase/admin/accountSlice';
import { getAllOrgFolderList } from 'app/store/docbase/admin/folderSlice';
import { showMessage } from 'app/store/fuse/messageSlice';

import TransferGrid from './TransferGrid';
import FullScanComponent from './FullScanComponent';

const useStyles = makeStyles(theme => ({
	root: {},
	contentWrapper: {
		// height: `${window.innerHeight - 140}px`
	},
	mainPaper: { borderRadius: 6, padding: 0, background: 'none', border: 0, boxShadow: 'none' },
	tabsBar: {
		height: 50,
		color: '#192141',
		background: 'none',
		boxShadow: 'none',
		'& .MuiTab-root': {
			padding: 0,
			minWidth: 136,
			fontSize: 16
		},
		'& .MuiTab-textColorInherit': { opacity: 1 },
		'& .MuiTab-textColorInherit.Mui-selected': {
			color: '#00cfe2',
			background: '#fff',
			borderRadius: '6px 6px 0 0'
		}
	},
	tabPanel: {
		height: 'calc(100% - 48px)'
	}
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={2} className="h-full p-0" style={{ 'margin-top': '-4px' }}>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}
const isMobile = sessionStorage.getItem('device') === 'mobile';
function PrivateContent() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();

	const { t } = useTranslation('docbase');

	const { usersObj } = useSelector(({ docbase }) => docbase.admin.account);
	const { orgFolders } = useSelector(({ docbase }) => docbase.admin.folder);

	const [tabType, setTabType] = React.useState(0);
	const [leftList, setLeftList] = React.useState([]);
	const [rightList, setRightList] = React.useState([]);
	const [leftObj, setLeftObj] = React.useState({});
	const [rightObj, setRightObj] = React.useState({});

	const [isLoading, setLoading] = React.useState(true);

	const [foldersPerm, setFoldersPerm] = React.useState({});
	const [userPolicyInfoObj, setUserPolicyInfoObj] = React.useState({});
	const [userPolicyInfos, setUserPolicyInfos] = React.useState([]);

	// const [tempPolicyInfoObj, setTempPolicyInfoObj] = React.useState({});

	// React.useEffect(() => {
	// 	if (contentTable.current) {
	// 		contentTable.current.style.height = `${innerHeight - 140}px`;
	// 	}
	// 	window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	// }, [innerHeight]);

	React.useEffect(() => {
		dispatch(getUserList()).then(() => {
			dispatch(getAllOrgFolderList()).then(() => {
				getAllFoldersPermissions().then(permObj => {
					setFoldersPerm(permObj);
					getUsersWithCollectInfo().then(collectInfo => {
						setUserPolicyInfoObj(collectInfo.obj);
						setUserPolicyInfos(collectInfo.list);
					});
				});
			});
		});
	}, [dispatch]);

	React.useEffect(() => {
		if (Object.keys(userPolicyInfoObj).length > 0) {
			assembleRightList();
		}
	}, [userPolicyInfoObj, tabType]);

	function assembleRightList() {
		setLoading(true);
		const tempUsersObj = { ...usersObj }; //	조직에 소속되지 않은 사용자를 찾기위한 사용자 목록, 조직에 소속된 사용자는 map에서 제거하여 무소속만 남긴다.

		let perms;
		let policy;
		const tempLeftList = [];
		const tempRightList = [];

		const groupLeftObj = {};
		const groupRightObj = {};

		let obj = {};
		let insertLeftOrg = false;
		let insertRightOrg = false;
		const sortOrg = [...orgFolders].sort((a, b) => {
			if (a.disp_path > b.disp_path) {
				//	b가 a보다 앞으로 오도록 설정
				return 1;
			}
			if (a.disp_path === b.disp_path) {
				//	변경 없음
				return 0;
			}
			//	a 가 b보다 앞으로 오도록 설정
			return -1;
		});
		for (let i = 0; i < sortOrg.length; i++) {
			// 조직폴더에 권한이 있는 사용자 목록을 가지고 작업
			perms = foldersPerm[sortOrg[i].fid];

			if (perms && perms.length > 0) {
				for (let j = 0; j < perms.length; j++) {
					policy = userPolicyInfoObj[perms[j].user_id];

					//	조직원이 없는 조직은 목록서서아안보이게 처리.
					if (tabType === 0) {
						if (policy.enabled && !insertLeftOrg) {
							obj = makeOrgObj(sortOrg[i], true);
							tempLeftList.push(obj);
							groupLeftObj[sortOrg[i].fid] = [obj];
							insertLeftOrg = true;
						}
						if (!policy.enabled && !insertRightOrg) {
							obj = makeOrgObj(sortOrg[i], false);
							tempRightList.push(obj);
							groupRightObj[sortOrg[i].fid] = [obj];
							insertRightOrg = true;
						}
					} else if (tabType === 2) {
						if (policy.full_scan && !insertLeftOrg) {
							obj = makeOrgObj(sortOrg[i], true);
							tempLeftList.push(obj);
							groupLeftObj[sortOrg[i].fid] = [obj];
							insertLeftOrg = true;
						}
						if (!policy.full_scan && !insertRightOrg) {
							obj = makeOrgObj(sortOrg[i], false);
							tempRightList.push(obj);
							groupRightObj[sortOrg[i].fid] = [obj];
							insertRightOrg = true;
						}
					} else {
						if (policy.remove_source && !insertLeftOrg) {
							obj = makeOrgObj(sortOrg[i], true);
							tempLeftList.push(obj);
							groupLeftObj[sortOrg[i].fid] = [obj];
							insertLeftOrg = true;
						}
						if (!policy.remove_source && !insertRightOrg) {
							obj = makeOrgObj(sortOrg[i], false);
							tempRightList.push(obj);
							groupRightObj[sortOrg[i].fid] = [obj];
							insertRightOrg = true;
						}
					}

					if (checkListType(policy)) {
						obj = {
							...userPolicyInfoObj[perms[j].user_id],
							name: userPolicyInfoObj[perms[j].user_id].name,
							fid: sortOrg[i].fid,
							isOrg: false,
							isPerm: true
						};
						tempLeftList.push(obj);
						groupLeftObj[sortOrg[i].fid] = [...groupLeftObj[sortOrg[i].fid], obj];
					} else {
						obj = {
							...userPolicyInfoObj[perms[j].user_id],
							name: userPolicyInfoObj[perms[j].user_id].name,
							fid: sortOrg[i].fid,
							isOrg: false,
							isPerm: true
						};
						tempRightList.push(obj);
						groupRightObj[sortOrg[i].fid] = [...groupRightObj[sortOrg[i].fid], obj];
					}

					delete tempUsersObj[perms[j].user_id];

					if (perms.length === j + 1) {
						insertRightOrg = false;
						insertLeftOrg = false;
						obj = {};
					}
				}
			}
		}
		const keys = Object.keys(tempUsersObj);
		if (keys.length > 0) {
			groupLeftObj.none = [];
			groupRightObj.none = [];
			for (let k = 0; k < keys.length; k++) {
				try {
					policy = userPolicyInfoObj[tempUsersObj[keys[k]].user_id];

					if (checkListType(policy)) {
						tempLeftList.push({
							...userPolicyInfoObj[tempUsersObj[keys[k]].user_id],
							name: userPolicyInfoObj[tempUsersObj[keys[k]].user_id].name,
							fid: -1,
							isOrg: false,
							isPerm: false
						});
						groupLeftObj.none = [
							...groupLeftObj.none,
							{
								...userPolicyInfoObj[tempUsersObj[keys[k]].user_id],
								name: userPolicyInfoObj[tempUsersObj[keys[k]].user_id].name,
								fid: -1,
								isOrg: false,
								isPerm: false
							}
						];
					} else {
						tempRightList.push({
							...userPolicyInfoObj[tempUsersObj[keys[k]].user_id],
							name: userPolicyInfoObj[tempUsersObj[keys[k]].user_id].name,
							fid: -1,
							isOrg: false,
							isPerm: false
						});
						groupRightObj.none = [
							...groupRightObj.none,
							{
								...userPolicyInfoObj[tempUsersObj[keys[k]].user_id],
								name: userPolicyInfoObj[tempUsersObj[keys[k]].user_id].name,
								fid: -1,
								isOrg: false,
								isPerm: false
							}
						];
					}
				} catch (err) {
					console.error(err);
				}
			}
		}

		setLeftList(tempLeftList);
		setRightList(tempRightList);
		setLeftObj(groupLeftObj);
		setRightObj(groupRightObj);
		setLoading(false);
	}

	/**
	 * 활성화 화활성화 상태에 라라 사용자 왼쪽 | 오른쪽 구분
	 * @param {Object} policy
	 * @returns
	 */
	const checkListType = policy => {
		if (tabType === 0) {
			return policy.enabled;
		}
		if (tabType === 2) {
			return policy.full_scan;
		}
		return policy.remove_source;
	};
	/**
	 * 리스트 출력용 조직폴더 정보 설정
	 * tabType이 달라도 이값은 같아야하므로 함수로 뺌
	 * @param {*} orgFolderInfo
	 * @param {*} isLeft
	 * @returns
	 */
	const makeOrgObj = (orgFolderInfo, isLeft) => {
		if (isLeft) {
			return {
				fid: orgFolderInfo.fid,
				name: orgFolderInfo.disp_path,
				user_id: '',
				isOrg: true,
				position: 'left'
			};
		}
		return {
			fid: orgFolderInfo.fid,
			name: orgFolderInfo.disp_path,
			user_id: '',
			isOrg: true,
			position: 'right'
		};
	};

	const doCollectPolicy = async (list, value) => {
		const policyInfos = [];
		for (let i = 0; i < list.length; i++) {
			if (list[i] !== '') {
				if (tabType === 0) {
					policyInfos.push({
						userID: list[i],
						enabled: value,
						fullScan: userPolicyInfoObj[list[i]].full_scan,
						removeSource: userPolicyInfoObj[list[i]].remove_source
					});
				} else if (tabType === 2) {
					policyInfos.push({
						userID: list[i],
						enabled: userPolicyInfoObj[list[i]].enabled,
						fullScan: value,
						removeSource: userPolicyInfoObj[list[i]].remove_source
					});
				} else {
					policyInfos.push({
						userID: list[i],
						enabled: userPolicyInfoObj[list[i]].enabled,
						fullScan: userPolicyInfoObj[list[i]].full_scan,
						removeSource: value
					});
				}
			}
		}

		setUsersWithCollectInfo(policyInfos).then(() => {
			dispatch(showMessage({ message: t('COLLECT_MESSAGE_1') }));
			getUsersWithCollectInfo().then(collectInfo => {
				setUserPolicyInfoObj(collectInfo.obj);
				setUserPolicyInfos(collectInfo.list);
			});
		});
	};

	const restartFullScan = info => {
		setUsersWithCollectInfo([
			{
				userID: info.user_id,
				enabled: info.enabled,
				fullScan: info.full_scan,
				removeSource: info.remove_source,
				full_scan_restart: true
			}
		]).then(() => {
			dispatch(showMessage({ message: t('COLLECT_MESSAGE_1') }));
			getUsersWithCollectInfo().then(collectInfo => {
				setUserPolicyInfoObj(collectInfo.obj);
				setUserPolicyInfos(collectInfo.list);
			});
		});
	};

	if (isLoading) {
		return <FuseLoading />;
	}
	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<FuseScrollbars className={clsx(classes.contentWrapper, 'w-full flex-grow overflow-x-auto')} ref={contentTable}>
				<Paper className={classes.mainPaper}>
					<AppBar position="static" className={classes.tabsBar}>
						<Tabs
							value={tabType}
							indicatorColor=""
							onChange={(e, newValue) => {
								if (tabType !== newValue) {
									setLoading(true);
									setTabType(newValue);
								}
							}}
						>
							<Tab label={t('COLLECT_USE')} {...a11yProps(0)} />
							<Tab label={t('COLLECT_USE_REMOVE_SOURCE')} {...a11yProps(1)} />
							<Tab label={t('COLLECT_USE_FULL_SCAN')} {...a11yProps(2)} />
							<Tab label={t('COLLECT_USE_FULL_SCAN_MANAGEMENT')} {...a11yProps(3)} />
						</Tabs>
					</AppBar>
					<TabPanel className={!isMobile ? classes.tabPanel : ''} value={tabType} index={0}>
						<TransferGrid
							tabType={tabType}
							leftList={leftList}
							rightList={rightList}
							leftObj={leftObj}
							rightObj={rightObj}
							fnDoCollectPolicy={doCollectPolicy}
						/>
					</TabPanel>

					<TabPanel className={!isMobile ? classes.tabPanel : ''} value={tabType} index={1}>
						<TransferGrid
							tabType={tabType}
							leftList={leftList}
							rightList={rightList}
							leftObj={leftObj}
							rightObj={rightObj}
							fnDoCollectPolicy={doCollectPolicy}
						/>
					</TabPanel>

					<TabPanel className={!isMobile ? classes.tabPanel : ''} value={tabType} index={2}>
						<TransferGrid
							tabType={tabType}
							leftList={leftList}
							rightList={rightList}
							leftObj={leftObj}
							rightObj={rightObj}
							fnDoCollectPolicy={doCollectPolicy}
						/>
					</TabPanel>

					<TabPanel className={!isMobile ? classes.tabPanel : ''} value={tabType} index={3}>
						<FullScanComponent
							tabType={tabType}
							userPolicyInfos={userPolicyInfos}
							fnDoSearch={() => {
								getUsersWithCollectInfo().then(collectInfo => {
									setUserPolicyInfoObj(collectInfo.obj);
									setUserPolicyInfos(collectInfo.list);
								});
							}}
							fnRestartFullScan={info => {
								restartFullScan(info);
							}}
						/>
					</TabPanel>
				</Paper>
			</FuseScrollbars>
		</div>
	);
}

export default PrivateContent;
