import FusePageSimple from '@fuse/core/FusePageSimple';
import React from 'react';
import ReleaseTable from './ReleaseTable';

import ContentHeader from '../../component/ContentHeader';

function ReleasePage() {
	const pageLayout = React.useRef(null);
	const [selectedFid, setSelectedFid] = React.useState(-1);

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={<ReleaseTable selected={selectedFid} fnSetSelectedFid={fid => setSelectedFid(fid)} />}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default ReleasePage;
