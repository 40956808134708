import { makeStyles } from '@material-ui/core/styles';
import {
	Icon,
	TextField,
	Card,
	CardActions,
	CardContent,
	IconButton,
	Button,
	Tooltip,
	InputAdornment,
	Input,
	Select,
	MenuItem,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	Typography
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as commonTypes from 'app/main/constants/CommonTypes';
import commonUtils from 'app/utils/CommonUtils';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
	getSharedFolderList,
	setReadableFolderPermissions,
	selectedSharedFolderID,
	renameSharedFolder,
	setFolderMaxSize,
	updateSharedFolder
} from 'app/store/docbase/admin/folderSlice';
import { setShowBackdrop } from 'app/store/docbase/admin/settingSlice';
import { naturalizeFiles } from 'app/store/docbase/admin/systemSlice';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';

import { openDialog } from 'app/store/fuse/dialogSlice';
import PermDialog from '../component/PermDialog';
import OpenDialogForm from '../component/OpenDialogForm';
import PermIcon from '../icon/PermIcon';

const useStyles = makeStyles(theme => ({
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	cardInfo: {
		padding: '40px 30px 20px',
		'& .MuiCardActions-root': {
			padding: 0
		},
		'& .MuiCardContent-root': {
			padding: 0
		}
	},
	cardDetailInfo: {
		'margin-left': '10px',
		height: '48px',
		'line-height': '48px'
	},
	paperPerm: { marginTop: 30, padding: '20px 30px 0' },
	paperPermHeader: {
		padding: 0,
		display: 'flex',
		width: '100%',
		'&>div:first-child': {
			width: '100%',
			maxWidth: 534,
			marginRight: 10
		},
		'&>div:first-child .MuiAutocomplete-inputRoot': {
			height: 40
		},
		'&>div:last-child>button': {
			width: 140
		}
	},
	paperPermContent: { padding: 0, marginTop: 20 },
	infoFiled: {
		color: '#000000',
		'&>div': {
			display: 'flex',
			height: 45,
			paddingRight: 30,
			borderTop: '1px solid rgb(141 149 160 / 18%)'
		},
		'&>div:last-child': { borderBottom: '1px solid rgb(141 149 160 / 18%)' }
	},
	filedTitle: {
		background: '#f5f7fa',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		cursor: 'default',
		width: '100%',
		maxWidth: 277,
		minWidth: 277
	},
	filedInput: {
		width: '100%',
		padding: '0 0 0 30px',
		display: 'flex',
		alignItems: 'center',
		'&.folder-name': { paddingRight: 10 }
	},
	customInput: {
		width: '100%',
		border: '1px solid rgba(0, 0, 0, 0.23)',
		borderRadius: 4,
		'&>input': {
			padding: '10.5px 14px'
		},
		'&.Mui-focused': {
			border: '2px solid rgba(0, 0, 0, 1)'
		},
		'&:hover': {
			border: '1px solid rgba(0, 0, 0, 1)'
		}
	},
	manage_type: {
		display: 'inline-flex',
		flex: '0 0 auto'
	},
	manage_priority: {
		width: '24px',
		height: '24px',
		'& .MuiSelect-root': {
			'padding-right': '0',
			'padding-bottom': '4px'
		},
		'& svg': {
			display: 'none'
		}
	},
	select: {
		width: '100%'
	},
	hint_span: {
		'padding-left': '10px',
		'font-size': '1rem',
		color: '#9f9f9f'
	},
	inputRight: {
		'& input': {
			'text-align': 'right'
		}
	},
	buttonGroup: {
		marginTop: 30,
		'&>div>button': { marginRight: 10 }
	},
	tbody: {
		'&>tr:nth-of-type(odd)': {
			background: '#f5f7fa'
		},
		'&>.MuiTableRow-root.MuiTableRow-hover:hover': {
			background: 'rgb(0 207 226 / 20%)'
		}
	},
	h_29: { height: '29px' },
	datePicker: {
		'& .MuiOutlinedInput-notchedOutline': { display: 'none' },
		'& input': {
			border: '1px solid rgba(0, 0, 0, 0.23)',
			borderRadius: 6,
			width: 185,
			textAlign: 'center',
			padding: '9.5px 0'
		},
		'& .MuiOutlinedInput-root.Mui-focused input': {
			boxShadow: '0 0 0 2px #000 inset'
		},
		'& .MuiButtonBase-root': {
			background: '#eceff4',
			borderRadius: 6,
			border: '1px solid #d9dee5',
			padding: 7
		},
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: 0
		}
	}
}));

function DetailSidebarContent(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const permList = React.useRef();
	const { t } = useTranslation('docbase');

	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const { sharedPerms, selectedSharedID, sharedsObj, sharedCapacity } = useSelector(({ docbase }) => docbase.admin.folder);

	const today = new Date();
	const [showPermDialog, setShowPermDialog] = React.useState(false);
	const [permsObj, setPermsObj] = React.useState({});
	const [folderName, setFolderName] = React.useState('');
	const [expireAt, setExpireAt] = React.useState(null);
	const [maxSize, setMaxSize] = React.useState(sharedCapacity);
	const [capacityEx, setCapacityEx] = React.useState(sharedCapacity);
	const [isOrgShared, setIsOrgShared] = React.useState(false);
	const [folderSizeUnit, setFolderSizeUnit] = React.useState(true);

	React.useEffect(() => {
		if (innerHeight >= 754) {
			permList.current.style.height = `${innerHeight - 140}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	React.useEffect(() => {
		if (selectedSharedID && selectedSharedID > 0 && sharedsObj[selectedSharedID]) {
			setFolderName(sharedsObj[selectedSharedID].name);
			setIsOrgShared(sharedsObj[selectedSharedID].folder_type === commonTypes.FOLDER_TYPE_ORGSHARED);
			if (sharedsObj[selectedSharedID].expire_at && sharedsObj[selectedSharedID].expire_at !== '') {
				setExpireAt(commonUtils.getDateStringToDate(sharedsObj[selectedSharedID].expire_at));
			} else {
				setExpireAt(null);
			}

			if (sharedCapacity && sharedCapacity >= 1024) {
				setMaxSize(Math.floor(sharedCapacity / 1024));
				setFolderSizeUnit(false);
			} else {
				setMaxSize(sharedCapacity || 0);
				setFolderSizeUnit(true);
			}
			setCapacityEx(sharedCapacity);
		} else {
			setFolderName('');
			setMaxSize(0);
			setIsOrgShared(false);
			setFolderSizeUnit(true);
		}
	}, [selectedSharedID, sharedsObj]);

	React.useEffect(() => {
		const obj = {};
		sharedPerms.forEach(perm => {
			obj[perm.obj_id] = true;
		});
		setPermsObj(obj);
	}, [sharedPerms]);

	React.useEffect(() => {}, [dispatch]);

	function changePerm(fid, targetObj, filePerm, trashPerm, manageType, managePriority) {
		dispatch(setShowBackdrop({ show: true }));
		const perms = [];
		sharedPerms.forEach(sp => {
			if (sp.obj_type === targetObj.obj_type && sp.obj_id === targetObj.obj_id) {
				perms.push({
					...sp,
					file_permission: filePerm,
					trash_permission: trashPerm,
					manage_type: manageType,
					manage_priority: managePriority
				});
			} else {
				perms.push(sp);
			}
		});
		setReadableFolderPermissions(fid, perms)
			.then(data => {
				dispatch(showMessage({ message: t('PERM_MESSAGE_1') }));
				dispatch(selectedSharedFolderID({ fid: selectedSharedID }));
			})
			.catch(err => {
				if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
					dispatch(showErrorMessage({ message: t('PERM_ERROR_MESSAGE_1') }));
				}
			})
			.finally(() => {
				dispatch(setShowBackdrop({ show: false }));
			});
	}

	function releasePerm(fid, targetObj) {
		dispatch(setShowBackdrop({ show: true }));
		const perms = [];
		sharedPerms.forEach(sp => {
			if (sp.obj_type !== targetObj.obj_type || (sp.obj_type === targetObj.obj_type && sp.obj_id !== targetObj.obj_id)) {
				perms.push(sp);
			}
		});
		setReadableFolderPermissions(fid, perms)
			.then(data => {
				dispatch(showMessage({ message: t('PERM_MESSAGE_2') }));
				dispatch(selectedSharedFolderID({ fid: selectedSharedID }));
			})
			.catch(err => {
				if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
					dispatch(showErrorMessage({ message: t('PERM_ERROR_MESSAGE_2') }));
				}
			})
			.finally(() => {
				dispatch(setShowBackdrop({ show: false }));
			});
	}

	return (
		<div className="" ref={permList}>
			<PermDialog
				open={showPermDialog}
				type="1"
				permsObj={permsObj}
				fid={selectedSharedID}
				fnHandleClose={() => setShowPermDialog(false)}
				fnSetPermComplete={() => {
					dispatch(showMessage({ message: t('PERM_MESSAGE_1') }));
					dispatch(selectedSharedFolderID({ fid: selectedSharedID }));
					setShowPermDialog(false);
				}}
			/>
			<Card className={classes.cardInfo}>
				<CardActions className="" disableSpacing>
					<IconButton
						className="md:hidden"
						aria-controls="detail-back"
						onClick={e => {
							dispatch(selectedSharedFolderID({ fid: 0 }));
							props.fnToggleRightSidebar();
						}}
					>
						<Icon>arrow_back</Icon>
					</IconButton>
					<Typography className={clsx(classes.cardDetailInfo, 'noto-bold')} variant="h6">
						<span>{t('DETAIL_INFO')}</span>
						<span>{selectedSharedID <= 0 ? '' : `[${sharedsObj[selectedSharedID].name}]`}</span>
					</Typography>
				</CardActions>
				<CardContent className={classes.infoFiled}>
					<div className="">
						<div className={classes.filedTitle}>
							<p className={clsx('text-left')}>{t('FOLDER_NAME')}</p>
						</div>
						<div className={clsx(classes.filedInput, 'folder-name')}>
							<TextField
								className=""
								variant="outlined"
								type="text"
								name="name"
								fullWidth
								value={folderName}
								onChange={e => setFolderName(e.target.value)}
							/>
						</div>
						{isOrgShared ? (
							<></>
						) : (
							<div className={clsx('align-middle items-center flex')}>
								<Button
									variant="contained"
									color="secondary"
									className=""
									type="button"
									fullWidth
									onClick={() => {
										if (selectedSharedID <= 0) {
											dispatch(showErrorMessage({ message: t('ORG_ERROR_MESSAGE_2') }));
										} else {
											dispatch(
												openDialog({
													children: (
														<OpenDialogForm
															title={t('NAME_CHANGE')}
															content={t('SHARED_CHANGE_MESSAGE')}
															fnHandleOK={() => {
																renameSharedFolder(selectedSharedID, folderName).then(data => {
																	dispatch(getSharedFolderList());
																});
															}}
														/>
													)
												})
											);
										}
									}}
								>
									{t('CHANGE')}
								</Button>
							</div>
						)}
					</div>

					{isOrgShared ? (
						<></>
					) : (
						<>
							<div className="">
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('EXPIRE_AT')}</p>
								</div>
								<div className={clsx(classes.filedInput, 'folder-name')}>
									<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
										<KeyboardDatePicker
											className={classes.datePicker}
											fullWidth
											disableToolbar
											invalidDateMessage=""
											autoOk
											size="small"
											variant="inline"
											inputVariant="outlined"
											format="yyyy/MM/dd"
											id="start_date"
											maxDate={new Date('2999-12-31')}
											value={expireAt}
											onChange={d => {
												// eslint-disable-next-line no-restricted-globals
												if (!isNaN(d.getTime())) {
													if (expireAt === null || expireAt.getTime() !== d.getTime()) setExpireAt(d);
												}
											}}
											KeyboardButtonProps={{
												'aria-label': 'change date start'
											}}
										/>
									</MuiPickersUtilsProvider>
								</div>
								<div className={clsx('align-middle items-center flex')}>
									<Button
										variant="contained"
										color="secondary"
										className=""
										type="button"
										fullWidth
										style={{ width: 60, minWidth: 60, marginRight: 10 }}
										onClick={() => {
											if (selectedSharedID <= 0) {
												dispatch(showErrorMessage({ message: t('ORG_ERROR_MESSAGE_2') }));
											} else {
												dispatch(
													openDialog({
														children: (
															<OpenDialogForm
																title={t('EXPIRE_AT_CHANGE')}
																content={t('EXPIRE_AT_CHANGE_MESSAGE_1')}
																fnHandleOK={() => {
																	updateSharedFolder(
																		selectedSharedID,
																		commonUtils.getDateString(expireAt)
																	).then(data => {
																		dispatch(getSharedFolderList());
																		dispatch(
																			showMessage({
																				message: t('EXPIRE_AT_CHANGE_MESSAGE_2')
																			})
																		);
																	});
																}}
															/>
														)
													})
												);
											}
										}}
									>
										{t('CHANGE')}
									</Button>
									<Button
										variant="contained"
										color="secondary"
										className=""
										type="button"
										fullWidth
										style={{ width: 60, minWidth: 60 }}
										onClick={() => {
											if (selectedSharedID <= 0) {
												dispatch(showErrorMessage({ message: t('ORG_ERROR_MESSAGE_2') }));
											} else {
												dispatch(
													openDialog({
														children: (
															<OpenDialogForm
																title={t('EXPIRE_AT_RELEASE')}
																content={t('EXPIRE_AT_RELEASE_MESSAGE_1')}
																fnHandleOK={() => {
																	updateSharedFolder(selectedSharedID, '').then(data => {
																		dispatch(getSharedFolderList());
																		dispatch(
																			showMessage({
																				message: t('EXPIRE_AT_RELEASE_MESSAGE_2')
																			})
																		);
																	});
																}}
															/>
														)
													})
												);
											}
										}}
									>
										{t('RELEASE')}
									</Button>
								</div>
							</div>
							<div className="">
								<div className={classes.filedTitle}>
									<p className={clsx('text-left')}>{t('MAX_CAPACITY')}</p>
									<span className={classes.hint_span}>{t('FOLDER_SIZE_HELP_TEXT')}</span>
								</div>
								<div className={clsx(classes.filedInput, '')}>
									<Input
										type="number"
										name="capacity"
										disableUnderline
										className={classes.customInput}
										endAdornment={
											<InputAdornment position="end" className="">
												<Select
													id="folder_capacity"
													disableUnderline
													variant="standard"
													className={clsx(classes.selectTextAlignRight, '')}
													value={folderSizeUnit}
													onChange={e => {
														let tmpSize = 0;
														if (!folderSizeUnit) {
															tmpSize = maxSize < 1024 ? maxSize : Math.floor(maxSize / 1024);
														} else {
															tmpSize = maxSize * 1024;
														}

														setFolderMaxSize(sharedsObj[selectedSharedID].real_path, tmpSize)
															.then(() => {
																setFolderSizeUnit(!folderSizeUnit);
																dispatch(
																	showMessage({ message: t('CHANGE_CAPACITY_MESSAGE_3') })
																);
															})
															.catch(err => {
																dispatch(
																	showErrorMessage({
																		message: t('CHANGE_CAPACITY_ERROR_MESSAGE_1')
																	})
																);
															});
													}}
												>
													<MenuItem value="false">Gbyte</MenuItem>
													<MenuItem value="true">Mbyte</MenuItem>
												</Select>
											</InputAdornment>
										}
										value={maxSize}
										onChange={e => setMaxSize(e.target.value)}
										onBlur={e => {
											e.preventDefault();
											const size = folderSizeUnit ? maxSize : maxSize * 1024;
											if (size < 0) {
												dispatch(showErrorMessage({ message: t('NUMBER_ERROR_MESSAGE') }));
												if (capacityEx && capacityEx >= 1024) {
													setMaxSize(Math.floor(capacityEx / 1024));
													setFolderSizeUnit(false);
												} else {
													setMaxSize(capacityEx || 0);
													setFolderSizeUnit(true);
												}

												return false;
											}

											if (capacityEx !== size) {
												setFolderMaxSize(sharedsObj[selectedSharedID].real_path, size)
													.then(() => {
														setCapacityEx(size);
														dispatch(showMessage({ message: t('CHANGE_CAPACITY_MESSAGE_3') }));
													})
													.catch(err => {
														dispatch(
															showErrorMessage({ message: t('CHANGE_CAPACITY_ERROR_MESSAGE_1') })
														);
													});
											}
											return true;
										}}
									/>
								</div>
							</div>
						</>
					)}
				</CardContent>

				{isOrgShared ? (
					<></>
				) : (
					<CardActions className={clsx(classes.buttonGroup, 'block')}>
						<div className="w-full flex">
							<div
								className={clsx(classes.h_29, 'inline-block align-middle ml-5 w-100')}
								style={{ display: 'none' }}
							>
								<Button
									variant="contained"
									className="default"
									type="button"
									fullWidth
									size="small"
									onClick={() => {
										if (selectedSharedID <= 0) {
											dispatch(showErrorMessage({ message: t('ORG_ERROR_MESSAGE_2') }));
										} else {
											naturalizeFiles(sharedsObj[selectedSharedID].real_path).then(() => {
												dispatch(
													showMessage({
														message: t('SEARCH_INDEXING_MESSAGE_1')
													})
												);
											});
										}
									}}
								>
									{t('SEARCH_INDEXING')}
								</Button>
							</div>
						</div>
					</CardActions>
				)}
			</Card>

			<Card className={classes.paperPerm}>
				<CardContent className={classes.paperPermHeader}>
					<div>
						<Button
							variant="contained"
							color="secondary"
							className=""
							type="button"
							fullWidth
							// onClick={setPerm}
							onClick={() => {
								if (selectedSharedID <= 0) {
									dispatch(showErrorMessage({ message: t('PERM_ERROR_MESSAGE_3') }));
								} else {
									setShowPermDialog(true);
								}
							}}
						>
							{t('PERM_FOLDER_TITLE')}
						</Button>
					</div>
				</CardContent>

				<CardContent className={classes.paperPermContent}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<TableHead>
							<TableRow className={classes.th}>
								<TableCell padding="none" align="left" className="left title">
									{t('USER')}
								</TableCell>
								<TableCell padding="none" align="center" className="perm-col-2">
									{t('MANAGE_PRIORITY')}
								</TableCell>
								<TableCell padding="none" align="center" className="perm-col-1">
									{t('PERM_READ_WRITE')}
								</TableCell>
								<TableCell padding="none" align="center" className="perm-col-2">
									{t('PERM_TRASH')}
								</TableCell>
								<TableCell padding="none" align="center" className="perm-col-1">
									{t('PERM_DELETE')}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody className={classes.tbody}>
							{sharedPerms && sharedPerms.length > 0 ? (
								sharedPerms.map((perm, idx) => {
									return (
										<TableRow
											className="cursor-pointer"
											hover
											role="checkbox"
											tabIndex={-1}
											// key={d.user_id}
											// onClick={event => handleCheck(event, d.user_id)}
										>
											<TableCell className="left" align="left" component="td" scope="row">
												<div>
													<PermIcon type="person" className="small" />
													<span className="folder-name">{perm.name}</span>
												</div>
											</TableCell>
											<TableCell className="cell-approve" align="center" component="td" scope="row">
												{perm.manage_type !== commonTypes.MANAGE_TYPE_APPROVE_TAKEOUT ? (
													<IconButton
														onClick={e => {
															if (perm.obj_type === 1) {
																let priority = 1;
																sharedPerms.forEach(p => {
																	if (
																		p.manage_priority < 100 &&
																		p.manage_priority >= priority
																	) {
																		priority = p.manage_priority + 1;
																	}
																});
																changePerm(
																	selectedSharedID,
																	perm,
																	perm.file_permission,
																	perm.trash_permission,
																	perm.manage_type !== commonTypes.MANAGE_TYPE_APPROVE_TAKEOUT
																		? commonTypes.MANAGE_TYPE_APPROVE_TAKEOUT
																		: commonTypes.MANAGE_TYPE_NONE,
																	priority
																);
															}
														}}
													>
														<span>{t('MANAGE_TYPE_NONE')}</span>
													</IconButton>
												) : (
													<div className={classes.manage_type}>
														<IconButton>
															<Tooltip arrow title={t('MANAGE_PRIORITY')}>
																<TextField
																	select
																	className={classes.manage_priority}
																	name="manage_type"
																	value={perm.manage_priority}
																	onChange={e => {
																		if (perm.obj_type === 1) {
																			changePerm(
																				selectedSharedID,
																				perm,
																				perm.file_permission,
																				perm.trash_permission,
																				e.target.value === -1
																					? commonTypes.MANAGE_TYPE_NONE
																					: perm.manage_type,
																				e.target.value
																			);
																		}
																	}}
																	fullWidth
																>
																	<MenuItem value={-1}>
																		<Icon>perm_identity</Icon>
																	</MenuItem>
																	<MenuItem className="justify-center" value={0}>
																		0
																	</MenuItem>
																	<MenuItem className="justify-center" value={1}>
																		1
																	</MenuItem>
																	<MenuItem className="justify-center" value={2}>
																		2
																	</MenuItem>
																	<MenuItem className="justify-center" value={3}>
																		3
																	</MenuItem>
																	<MenuItem className="justify-center" value={4}>
																		4
																	</MenuItem>
																	<MenuItem className="justify-center" value={5}>
																		5
																	</MenuItem>
																	<MenuItem className="justify-center" value={6}>
																		6
																	</MenuItem>
																	<MenuItem className="justify-center" value={7}>
																		7
																	</MenuItem>
																	<MenuItem className="justify-center" value={8}>
																		8
																	</MenuItem>
																	<MenuItem className="justify-center" value={9}>
																		9
																	</MenuItem>
																</TextField>
															</Tooltip>
														</IconButton>
													</div>
												)}
											</TableCell>
											<TableCell className="" align="center" component="td" scope="row">
												<Tooltip
													arrow
													title={
														perm.file_permission === commonTypes.FILE_PERM_READ
															? t('PERM_READ')
															: t('PERM_READ_WRITE')
													}
												>
													<IconButton
														onClick={e =>
															changePerm(
																selectedSharedID,
																perm,
																perm.file_permission === commonTypes.FILE_PERM_READ
																	? commonTypes.FILE_PERM_READ_WRITE
																	: commonTypes.FILE_PERM_READ,
																perm.trash_permission
															)
														}
													>
														{perm.file_permission === commonTypes.FILE_PERM_READ ? (
															<PermIcon type="readonly" className="" />
														) : (
															<PermIcon type="write" className="" />
														)}
													</IconButton>
												</Tooltip>
											</TableCell>
											<TableCell className="" component="td" scope="row" align="center">
												<Tooltip
													arrow
													title={
														perm.trash_permission === commonTypes.TRASH_PERM_NONE
															? t('PERM_TRASH_IMPOSSIBLE')
															: t('PERM_TRASH_POSSIBLE')
													}
												>
													<IconButton
														onClick={e =>
															changePerm(
																selectedSharedID,
																perm,
																perm.file_permission,
																perm.trash_permission === commonTypes.TRASH_PERM_NONE
																	? commonTypes.TRASH_PERM_PURGE
																	: commonTypes.TRASH_PERM_NONE
															)
														}
													>
														{perm.trash_permission === commonTypes.TRASH_PERM_NONE ? (
															<PermIcon type="trash-delete" className="" />
														) : (
															<PermIcon type="trash" className="" />
														)}
													</IconButton>
												</Tooltip>
											</TableCell>
											<TableCell className="" component="td" scope="row" align="center">
												<Tooltip title={t('PERM_DELETE')} arrow>
													<IconButton
														aria-label="releasePerm"
														onClick={e => releasePerm(selectedSharedID, perm)}
													>
														<PermIcon type="delete" className="" />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									);
								})
							) : (
								<></>
							)}
						</TableBody>
					</Table>
				</CardContent>
			</Card>
		</div>
	);
}

export default DetailSidebarContent;
