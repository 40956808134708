import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, TablePagination, IconButton, Tooltip, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
	onClickDoc,
	onClickDocEx,
	searchSecureDocs,
	getSecureDocs,
	releaseSecureDoc,
	changeRowsPerPage
} from 'app/store/docbase/admin/settingSlice';
import { getUserList } from 'app/store/docbase/admin/accountSlice';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import CustomIcon from 'app/main/icon/CustomIcon';
import commonUtils from 'app/utils/CommonUtils';
import SecureDocsTableHead from './SecureDocsTableHead';
import OpenDialogForm from '../component/OpenDialogForm';

const useStyles = makeStyles(theme => ({
	root: { marginRight: 30, borderRadius: 6 },
	deleteIcon: {
		color: '#ff6565'
	},
	contentWrapper: {
		height: `${window.innerHeight - 276}px`,
		padding: '0 20px'
	}
}));

function SecureDocsTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
	const { t } = useTranslation('docbase');

	const { selectedDocObj, docs, rowsPerPage, currentPage, totalRows } = useSelector(
		({ docbase }) => docbase.admin.setting.secureDocObj
	);

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 276}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		dispatch(searchSecureDocs({ page: 1 })).then(() => {
			dispatch(getUserList());
			props.setLoading(false);
		});
	}, [dispatch]);

	useEffect(() => {
		//	정보 변경 후 갱신을 위하여
		docs.forEach(doc => {
			if (doc.doc_id === selectedDocObj.doc_id) {
				dispatch(onClickDocEx({ docObj: doc }));
			}
		});
	}, [docs]);

	function handleRowClick(docObj) {
		dispatch(onClickDoc({ docObj }));
		props.fnToggleRightSidebar();
	}

	if (props.loading) {
		return <FuseLoading />;
	}

	function handleChangePage(event, value) {
		props.setLoading(true);
		dispatch(getSecureDocs({ page: value + 1 })).then(() => props.setLoading(false));
	}
	function handleChangeRowsPerPage(event) {
		props.setLoading(true);
		dispatch(changeRowsPerPage({ value: event.target.value }));
		dispatch(searchSecureDocs({ page: 1 })).then(() => props.setLoading(false));
	}
	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className={clsx('items-center w-full flex table-search-wrapper')}>
					<div className="">
						{/* <div className="input-item">
							<Input
								placeholder="결과 내 검색"
								className="search-input"
								disableUnderline
								fullWidth
								// value={searchText}
								inputProps={{
									'aria-label': 'Search'
								}}
								// onChange={ev => )}
							/>
						</div> */}
					</div>
					<div className="total-cnt-info">
						{/* <span className="pipe">|</span> */}
						<span>
							{t('TOTAL')} <span className="count">{totalRows}</span>
							{t('CNT')}
						</span>
					</div>
				</div>
				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<SecureDocsTableHead />

						<TableBody>
							{docs.map(d => {
								return (
									<TableRow
										className="cursor-pointer"
										hover
										role="checkbox"
										tabIndex={-1}
										key={d.fid}
										selected={selectedDocObj.doc_id === d.doc_id}
										onClick={event => handleRowClick(d)}
									>
										{/* <TableCell className="left" align="left" component="td" scope="row">
											{d.doc_id}
										</TableCell> */}
										<TableCell className="left" align="left" component="td" scope="row">
											{d.level < 10000 ? d.level : 'NONE'}
										</TableCell>
										<TableCell className="left" component="td" scope="row">
											<div>
												<p className="log-name">{commonUtils.splitLastDelimiterString(d.disp_path)}</p>
												<p className="log-path">{commonUtils.splitPreDelimiter(d.disp_path)}</p>
											</div>
										</TableCell>
										<TableCell className="left" component="td" scope="row" align="right">
											<Tooltip title={t('RELEASE')} arrow>
												<IconButton
													onClick={e => {
														dispatch(
															openDialog({
																children: (
																	<OpenDialogForm
																		title={t('SECUREDOC_RELEASE_TITLE')}
																		content={t('SECUREDOC_RELEASE_CONTENT')}
																		fnHandleOK={() => {
																			releaseSecureDoc(d.doc_id)
																				.then(data => {
																					dispatch(
																						showMessage({
																							message: t('SECUREDOC_MESSAGE_3')
																						})
																					);
																					dispatch(
																						searchSecureDocs({ page: currentPage })
																					).then(() => {
																						props.setLoading(false);
																						dispatch(closeDialog());
																					});
																				})
																				.catch(err => {
																					dispatch(
																						showErrorMessage({
																							message:
																								t('SECUREDOC_ERROR_MESSAGE_2')
																						})
																					);
																				});
																		}}
																	/>
																)
															})
														);
													}}
												>
													<CustomIcon type="delete" className="" fill="#575757" />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>

				<TablePagination
					className="flex-shrink-0 border-t-1 mx-20"
					component="div"
					count={totalRows}
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={[100, 250, 500]}
					page={currentPage - 1}
					labelRowsPerPage={t('ROW_PER_PAGE_LABEL')}
					labelDisplayedRows={({ from, to, count }) => `${count !== -1 ? count : to} 중 ${from}-${to}`}
					backIconButtonProps={{
						'aria-label': t('PAGE_PREV_BTN_LABEL')
					}}
					nextIconButtonProps={{
						'aria-label': t('PAGE_NEXT_BTN_LABEL')
					}}
					onChangePage={(event, value) => {
						handleChangePage(event, value);
						contentTable.current.scrollTop = 0;
					}}
					onChangeRowsPerPage={event => {
						handleChangeRowsPerPage(event);
						contentTable.current.scrollTop = 0;
					}}
				/>
			</Paper>
		</div>
	);
}

export default SecureDocsTable;
