import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import FuseUtils from '@fuse/utils';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Table, TableBody, TableCell, TableRow, IconButton, Button, Tooltip, Paper, Input } from '@material-ui/core';
import Formsy from 'formsy-react';
import { TextFieldFormsy } from '@fuse/core/formsy';
import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as commonTypes from 'app/main/constants/CommonTypes';
import {
	getSharedFolderList,
	createSharedFolder,
	removeSharedFolder,
	selectedSharedFolderID,
	removeOrgSharedFolder,
	getAllOrgFolderList,
	setSharedSearchText
} from 'app/store/docbase/admin/folderSlice';
import { getUserList } from 'app/store/docbase/admin/accountSlice';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import CommonUtils from 'app/utils/CommonUtils';
import CustomIcon from 'app/main/icon/CustomIcon';
import SharedsTableHead from './SharedsTableHead';
import OpenDialogForm from '../component/OpenDialogForm';

const useStyles = makeStyles(theme => ({
	root: { marginRight: 30, borderRadius: 6 },
	deleteIcon: {
		color: '#ff6565'
	},
	contentWrapper: {
		height: `${window.innerHeight - 228}px`,
		padding: '0 20px'
	},
	icon: {
		width: 20,
		height: 20
	},
	insertForm: {
		display: 'flex',
		'&>div': { display: 'flex', alignItems: 'center' },
		'&>div:first-child': { width: '100%' },
		'&>div:first-child input.MuiOutlinedInput-input': { padding: '5.7px 10px' },
		'&>div.button-group': {
			paddingRight: 20
		},
		'&>div.button-group>button': {
			width: 106,
			height: 28,
			marginLeft: 10
		}
	}
}));

function SharedsTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const formRef = React.useRef(null);
	const { t } = useTranslation('docbase');

	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const { orgsObj, sharedFolders, sharedsObj, selectedSharedID } = useSelector(({ docbase }) => docbase.admin.folder);

	const searchText = useSelector(({ docbase }) => docbase.admin.folder.searchTextShared);

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(sharedFolders);
	const [showInsertForm, setShowInsertForm] = useState(false);
	const [formValid, setFormValid] = React.useState({});

	React.useEffect(() => {
		CommonUtils.infoLog('detail selectedID start');

		CommonUtils.infoLog('detail selectedID end');
	}, [selectedSharedID]);

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 228}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		dispatch(getAllOrgFolderList()).then(() => {
			dispatch(getSharedFolderList()).then(() => {
				dispatch(getUserList()).then(() => {});
				setLoading(false);
			});
		});
		return () => {
			dispatch(selectedSharedFolderID({ fid: -1 }));
		};
	}, [dispatch]);

	useEffect(() => {
		filterSearch(searchText);
	}, [sharedFolders, searchText]);

	const filterSearch = useCallback(
		debounce(val => {
			setData(FuseUtils.filterFoldersByString(sharedFolders, val));
		}, 500),
		[sharedFolders]
	);

	function handleRowClick(fid) {
		if (selectedSharedID !== fid) {
			dispatch(selectedSharedFolderID({ fid }));
			props.fnToggleRightSidebar();
		}
	}

	function doRemoveSharedFolder(deleteFid) {
		dispatch(selectedSharedFolderID({ fid: -1 }));
		if (sharedsObj[deleteFid].folder_type === commonTypes.FOLDER_TYPE_ORGSHARED) {
			removeOrgSharedFolder(deleteFid, dispatch)
				.then(respData => {
					dispatch(showMessage({ message: t('SHARED_MESSAGE_1') }));
					dispatch(getSharedFolderList()).then(() => setLoading(false));
				})
				.catch(err => {
					dispatch(showErrorMessage({ message: t('SHARED_ERROR_MESSAGE_1') }));
				});
		} else {
			removeSharedFolder(deleteFid, dispatch)
				.then(respData => {
					dispatch(showMessage({ message: t('SHARED_MESSAGE_1') }));
					dispatch(getSharedFolderList()).then(() => setLoading(false));
				})
				.catch(err => {
					dispatch(showErrorMessage({ message: t('SHARED_ERROR_MESSAGE_1') }));
				});
		}
	}

	function hideInsertForm() {
		setShowInsertForm(false);
		setFormValid({ name: '' });
	}

	function handleSubmit(values) {
		const validationErrors = {};
		if (!values.name || values.name.trim().length <= 0) {
			validationErrors.name = t('FOLDER_ERROR_MESSAGE_1');
		} else if (values.name.trim().length > 30) {
			validationErrors.name = t('FOLDER_ERROR_MESSAGE_3');
		} else if (/[\\/:*?\\"<>|]/.test(values.name.trim())) {
			validationErrors.name = t('PATTERN_ERROR_MESSAGE');
		} else {
			validationErrors.name = '';
		}

		setFormValid(validationErrors);
		if (validationErrors.name !== '') {
			return false;
		}

		createSharedFolder(values.name, dispatch)
			.then(respData => {
				dispatch(showMessage({ message: t('SHARED_ERROR_MESSAGE_2') }));
				dispatch(getSharedFolderList()).then(() => setLoading(false));
				formRef.current.reset();
				setFormValid({ name: '' });
			})
			.catch(err => {
				if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
					setFormValid({ name: t('FOLDER_ERROR_MESSAGE_2') });
					dispatch(showErrorMessage({ message: t('FOLDER_ERROR_MESSAGE_2') }));
				}
			});
		return true;
	}

	if (loading) {
		return <FuseLoading />;
	}

	// if (data.length === 0) {
	// 	return (
	// 		<FuseAnimate delay={100}>
	// 			<div className="flex flex-1 items-center justify-center h-full">
	// 				<Typography color="textSecondary" variant="h5">
	// 					There are no users!
	// 				</Typography>
	// 			</div>
	// 		</FuseAnimate>
	// 	);
	// }
	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className="table-search-wrapper">
					<div className="">
						<div className="input-item">
							<Input
								placeholder={t('SEARCH')}
								className="search-input"
								disableUnderline
								fullWidth
								value={searchText}
								inputProps={{
									'aria-label': 'Search'
								}}
								onChange={ev => dispatch(setSharedSearchText(ev))}
							/>
						</div>
					</div>
					<div className="total-cnt-info">
						<span className="pipe">|</span>
						<span>
							{t('TOTAL')} <span className="count">{data.length}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="btn-group">
						<div>
							<Button
								variant="contained"
								color="secondary"
								className=""
								type="button"
								onClick={e => setShowInsertForm(true)}
							>
								{t('NEW_REGISTRATION')}
							</Button>
						</div>
					</div>
				</div>

				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<SharedsTableHead />

						<TableBody>
							{data.map(d => {
								const parentName = orgsObj[d.parent_fid]
									? CommonUtils.splitLastDelimiterString(orgsObj[d.parent_fid].disp_path)
									: '';
								return (
									<TableRow
										className="cursor-pointer"
										hover
										role="checkbox"
										tabIndex={-1}
										key={d.fid}
										selected={selectedSharedID === d.fid}
										onClick={event => handleRowClick(d.fid)}
									>
										<TableCell className="cursor-pointer left" component="td" scope="row">
											<Tooltip
												arrow
												title={d.folder_type === commonTypes.FOLDER_TYPE_ORGSHARED ? d.disp_path : d.name}
											>
												<span>
													{d.folder_type === commonTypes.FOLDER_TYPE_ORGSHARED
														? d.disp_path.substring(d.disp_path.lastIndexOf(`/${parentName}/`) + 1)
														: d.name}
												</span>
											</Tooltip>
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											<Tooltip title={t('DELETE')} arrow>
												<IconButton
													onClick={e => {
														e.stopPropagation();
														if (d.folder_type === commonTypes.FOLDER_TYPE_ORGSHARED) {
															dispatch(showMessage({ message: t('FOLDER_ERROR_MESSAGE_5') }));
														} else {
															dispatch(
																openDialog({
																	children: (
																		<OpenDialogForm
																			title={t('DELETE_FOLDER_TITLE')}
																			content={`'${d.name}'${t('DELETE_CONTENT')}`}
																			fnHandleOK={() => {
																				doRemoveSharedFolder(d.fid);
																			}}
																		/>
																	)
																})
															);
														}
													}}
												>
													<CustomIcon type="delete" className="" fill="#575757" />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								);
							})}
							{showInsertForm ? (
								<TableRow className="cursor-pointer">
									<TableCell className="left" component="td" scope="row">
										<Formsy
											name="registerForm"
											className="flex flex-col justify-center w-full"
											onSubmit={values => handleSubmit(values)}
											validationErrors={formValid}
											ref={formRef}
										>
											<div className={classes.insertForm}>
												<div>
													<TextFieldFormsy
														className=""
														placeholder={t('FOLDER_NAME')}
														type="text"
														name="name"
														variant="outlined"
														validations={{
															idCheck: (values, value) => {
																if (value) {
																	if (value.trim().length > 30) {
																		setFormValid({ name: t('FOLDER_ERROR_MESSAGE_3') });
																	} else if (/[\\/:*?\\"<>|]/.test(value.trim())) {
																		setFormValid({ name: t('PATTERN_ERROR_MESSAGE') });
																	} else {
																		setFormValid({ name: '' });
																	}
																} else {
																	setFormValid({ name: '' });
																}
																return true;
															}
														}}
														size="small"
														autoFocus
														fullWidth
													/>
												</div>
												<div className="button-group">
													<Button
														variant="contained"
														color="secondary"
														aria-label="Register"
														type="submit"
													>
														{t('CREATE')}
													</Button>
													<Button
														variant="contained"
														className="default"
														onClick={e => hideInsertForm()}
													>
														{t('CLOSE')}
													</Button>
												</div>
											</div>
										</Formsy>
									</TableCell>
									<TableCell className="" component="td" scope="row" align="right" />
								</TableRow>
							) : (
								''
							)}
						</TableBody>
					</Table>
				</FuseScrollbars>
			</Paper>
		</div>
	);
}

export default SharedsTable;
