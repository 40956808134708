import FuseScrollbars from '@fuse/core/FuseScrollbars';
import {
	Paper,
	Button,
	Typography,
	MenuItem,
	Backdrop,
	CircularProgress,
	Icon,
	IconButton,
	Tooltip,
	Select,
	Input,
	InputAdornment,
	AppBar,
	Tabs,
	Box,
	Tab
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useRef } from 'react';
import * as commonTypes from 'app/main/constants/CommonTypes';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getSysConfigure, setSysConfigure, factoryReset, updateSystem, getSeedKey } from 'app/store/docbase/admin/settingSlice';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import SwitchNum01 from 'app/main/component/SwitchNum01';
import OpenDialogForm from '../component/OpenDialogForm';

const useStyles = makeStyles(theme => ({
	root: {},
	mainPaper: {
		borderRadius: 6,
		padding: 40,
		'&>.setting': {
			flexWrap: 'wrap'
		}
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	settingTitle: {
		display: 'block',
		width: '100%',
		cursor: 'default',
		verticalAlign: 'middle',
		fontSize: 16,
		marginTop: 10,
		marginBottom: 5,
		'&:first-child': { marginTop: 0 }
	},
	settingGroup: {
		color: '#353b48',
		fontSize: 14,
		marginRight: 30,
		'&>.row': {
			display: 'flex',
			width: 690,
			justifyContent: 'center',
			borderTop: '1px solid #e3e3e3',
			'&:last-child': {
				borderBottom: '1px solid #e3e3e3'
			},
			'&>div': {
				display: 'flex',
				alignItems: 'center',
				padding: '0 30px'
			}
		},
		'&>.row>.title': {
			width: 277,
			height: 70,
			background: '#f5f7fa',
			'& span': { cursor: 'default', display: 'block' }
		},
		'&>.row>.content': { width: 413 },
		'&>.row.tall>div': {
			height: 'auto'
		},
		'&>.row.tall>.content': { display: 'block', padding: '20px 30px' }
	},
	hintSpan: {
		display: 'block',
		marginTop: 10,
		fontSize: 12,
		color: '#9e9e9e'
	},
	inputForm: {
		width: '100%',
		maxWidth: 265
	},
	customInput: {
		width: '100%',
		border: '1px solid rgba(0, 0, 0, 0.23)',
		borderRadius: 4,
		'&>input': {
			padding: '10.5px 14px'
		},
		'&.Mui-focused': {
			border: '2px solid rgba(0, 0, 0, 1)'
		},
		'&:hover': {
			border: '1px solid rgba(0, 0, 0, 1)'
		}
	},
	productImageUpload: {
		transitionProperty: 'box-shadow',
		transitionDuration: theme.transitions.duration.short,
		transitionTimingFunction: theme.transitions.easing.easeInOut,
		background: theme.palette.secondary.main
	},
	contentWrapper: {
		height: `${window.innerHeight - 200}px`,
		borderRadius: 6
	},
	etcBox: {
		display: 'flex',
		marginTop: 20,
		'&>div': {
			marginRight: 10
		},
		'&>div:last-child': { marginRight: 0 }
	},
	selectTextAlignRight: {
		'padding-right': '25px',
		'& .MuiSelect-select': { 'text-align': 'right' }
	},
	tabPanel: {
		overflow: 'auto',
		'&>.MuiBox-root': { padding: '30px 0 0 0' }
	}
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={2} className="h-full" style={{ 'margin-top': '-4px' }}>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}
let setTimer;
let changeConf = {};
function SettingsContent() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const updateFileInput = useRef(null);
	const { t } = useTranslation('docbase');

	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const { sysConfigure, alienConObj, seedKey } = useSelector(({ docbase }) => docbase.admin.setting);
	const productInfo = useSelector(({ auth }) => auth.initial.productInfo);

	const [openBackdrop, setOpenBackdrop] = React.useState(false);

	const [sysConf, setSysConf] = React.useState(sysConfigure);
	const [capacityObj, setCapacityObj] = React.useState({
		collectObj: { capacity: 0, unit: false },
		privateObj: { capacity: 0, unit: false },
		orgObj: { capacity: 0, unit: false },
		sharedObj: { capacity: 0, unit: false }
	});
	const [restrictFileSizeObj, setRestrictFileSizeObj] = React.useState({ size: 0, unit: false });
	const [weblinkRestrictObj, setWeblinkRestrictObj] = React.useState({
		fileSize: 0,
		fileUnit: false,
		totalSize: 0,
		totalUnit: false
	});

	const [tabType, setTabType] = React.useState(0);

	React.useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 200}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	React.useEffect(() => {
		setSysConf(sysConfigure);
		const obj = {
			collectObj: { capacity: 0, unit: false },
			privateObj: { capacity: 0, unit: false },
			orgObj: { capacity: 0, unit: false },
			sharedObj: { capacity: 0, unit: false }
		};
		if (sysConfigure.private_folder_size && sysConfigure.private_folder_size >= 1024) {
			obj.privateObj.capacity = Math.floor(sysConfigure.private_folder_size / 1024);
			obj.privateObj.unit = false;
		} else {
			obj.privateObj.capacity = sysConfigure.private_folder_size || 0;
			obj.privateObj.unit = true;
		}
		if (sysConfigure.collect_folder_size && sysConfigure.collect_folder_size >= 1024) {
			obj.collectObj.capacity = Math.floor(sysConfigure.collect_folder_size / 1024);
			obj.collectObj.unit = false;
		} else {
			obj.collectObj.capacity = sysConfigure.collect_folder_size || 0;
			obj.collectObj.unit = true;
		}
		if (sysConfigure.organization_folder_size && sysConfigure.organization_folder_size >= 1024) {
			obj.orgObj.capacity = Math.floor(sysConfigure.organization_folder_size / 1024);
			obj.orgObj.unit = false;
		} else {
			obj.orgObj.capacity = sysConfigure.organization_folder_size || 0;
			obj.orgObj.unit = true;
		}
		if (sysConfigure.shared_folder_size && sysConfigure.shared_folder_size >= 1024) {
			obj.sharedObj.capacity = Math.floor(sysConfigure.shared_folder_size / 1024);
			obj.sharedObj.unit = false;
		} else {
			obj.sharedObj.capacity = sysConfigure.shared_folder_size || 0;
			obj.sharedObj.unit = true;
		}
		setCapacityObj(obj);

		if (sysConfigure.restrict_file_size && sysConfigure.restrict_file_size >= 1024 * 1024 * 1024) {
			setRestrictFileSizeObj({ size: Math.floor(sysConfigure.restrict_file_size / 1024 / 1024 / 1024), unit: false });
		} else {
			setRestrictFileSizeObj({
				size: sysConfigure.restrict_file_size ? Math.floor(sysConfigure.restrict_file_size / 1024 / 1024) : 0,
				unit: true
			});
		}

		const onGByteWeblinkFileSize = sysConfigure.weblink_restrict_file_size > 1024 * 1024 * 1024;
		const onGByteWeblinkTotalSize = sysConfigure.weblink_restrict_total_size > 1024 * 1024 * 1024;
		setWeblinkRestrictObj({
			fileSize: onGByteWeblinkFileSize
				? Math.floor(sysConfigure.weblink_restrict_file_size / 1024 / 1024 / 1024)
				: Math.floor(sysConfigure.weblink_restrict_file_size / 1024 / 1024),
			fileUnit: !onGByteWeblinkFileSize,
			totalSize: onGByteWeblinkTotalSize
				? Math.floor(sysConfigure.weblink_restrict_total_size / 1024 / 1024 / 1024)
				: Math.floor(sysConfigure.weblink_restrict_total_size / 1024 / 1024),
			totalUnit: !onGByteWeblinkTotalSize
		});
	}, [sysConfigure]);

	React.useEffect(() => {}, [alienConObj]);

	React.useEffect(() => {
		dispatch(getSysConfigure());
	}, [dispatch]);

	//	select 변경시
	function changeSettings(e) {
		if (e.target.value !== String(sysConfigure[e.target.name])) {
			setSysConf({ ...sysConf, [e.target.name]: e.target.value });
			requestHandler(e.target.name, e.target.value);
		}
	}
	//	input 커서이동시
	function onBlurSettings(e) {
		//	입력한 값이 기존값이 다를때만 변경 요청
		if (e.target.value !== String(sysConfigure[e.target.name])) {
			requestHandler(e.target.name, e.target.value);
		}
	}
	function changeSwitch(e) {
		setSysConf({ ...sysConf, [e.target.name]: !sysConf[e.target.name] });
		requestHandler(e.target.name, !sysConf[e.target.name]);
	}

	const mountDriveLatters = [
		'E',
		'F',
		'G',
		'H',
		'I',
		'J',
		'K',
		'L',
		'M',
		'N',
		'O',
		'P',
		'Q',
		'R',
		'S',
		'T',
		'U',
		'V',
		'W',
		'X',
		'Y',
		'Z'
	];

	function requestHandler(name, value) {
		changeConf[name] = value;
		if (name === 'sync_solution_consumer_folder_accessible') {
			changeConf.sync_solution_integrated = true;
		}

		clearTimeout(setTimer);
		setTimer = setTimeout(() => {
			if (name === 'default_volume_label') {
				changeConf.lock_volume_label = sysConf.lock_volume_label;
			}
			if (name === 'lock_volume_label') {
				changeConf.default_volume_label = sysConf.default_volume_label;
			}

			dispatch(setSysConfigure({ setConf: changeConf })).then(() => {
				changeConf = {};
				dispatch(getSysConfigure());
			});
		}, 500);
	}
	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<FuseScrollbars className={clsx(classes.contentWrapper, 'overflow-x-auto')} ref={contentTable}>
				<Backdrop className={classes.backdrop} open={openBackdrop}>
					<CircularProgress color="inherit" />
				</Backdrop>
				<Paper className={classes.mainPaper}>
					<AppBar position="static" className={classes.tabsBar}>
						<Tabs
							value={tabType}
							indicatorColor=""
							onChange={(e, newValue) => {
								if (tabType !== newValue) {
									setTabType(newValue);
								}
							}}
						>
							<Tab label={t('DEFAULT_SETTING')} {...a11yProps(0)} />
							<Tab label={t('SETUP_SETTING')} {...a11yProps(1)} />
							<Tab label={t('FOLDER_SIZE_SETTING')} {...a11yProps(2)} />
							<Tab label={t('WEBLINK_SETTING')} {...a11yProps(3)} />
							<Tab label={t('SETTING_PII_DETECT')} {...a11yProps(4)} />
							<Tab label={t('RESTRICT_TITLE')} {...a11yProps(5)} />
							<Tab label={t('SETTING_CENTRALIZED_DOC')} {...a11yProps(6)} />
						</Tabs>
					</AppBar>

					<TabPanel className={classes.tabPanel} value={tabType} index={0}>
						<div className={classes.settingTitle}>{t('DEFAULT_SETTING')}</div>
						<div className="flex setting">
							<div className={classes.settingGroup}>
								<div className="row tall">
									<div className="title">
										<span>{t('SETTING_STRONG_PASSWORD')}</span>
									</div>
									<div className="content">
										<SwitchNum01
											name="strong_password"
											checked={sysConf.strong_password}
											onChange={e => changeSwitch(e)}
										/>
										<span className={clsx(classes.hintSpan)}>{t('SETTING_STRONG_PASSWORD_HINT')}</span>
									</div>
								</div>
								{sysConf.menu_support_type === 2 ? (
									<></>
								) : (
									<div className="row">
										<div className="title">
											<span>{t('SETTING_TWO_FACTOR_AUTH')}</span>
										</div>
										<div className="content">
											<SwitchNum01
												name="two_factor_authentication"
												checked={sysConf.two_factor_authentication}
												onChange={e => changeSwitch(e)}
											/>
										</div>
									</div>
								)}
								{/* <div className="row"> setConfigure에 빠져있음
									<div className="title">
										<span>{t('SETTING_DISABLE_TWO_FACTOR_AUTH')}</span>
									</div>
									<div className="content">
										<SwitchNum01
											name="disable_two_factor_authentication"
											checked={sysConfigure.disable_two_factor_authentication}
											onChange={e => changeSwitch(e)}
										/>
									</div>
								</div> */}
								<div className="row tall">
									<div className="title">
										<span>{t('SETTING_DEFAULT_VOLUME_LABEL')}</span>
									</div>
									<div className="content ">
										<div className={classes.inputForm}>
											<Input
												type="text"
												name="default_volume_label"
												disableUnderline
												className={classes.customInput}
												value={sysConf.default_volume_label}
												onChange={e => setSysConf({ ...sysConf, [e.target.name]: e.target.value })}
												onBlur={e => onBlurSettings(e)}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="title">
										<span>{t('SETTING_DUP_DETECT_ENABLE')}</span>
									</div>
									<div className="content">
										<SwitchNum01
											name="dup_detect_enable"
											checked={sysConf.dup_detect_enable}
											onChange={e => changeSwitch(e)}
										/>
									</div>
								</div>

								{sysConf.menu_support_type === 2 ? (
									<></>
								) : (
									<>
										<div className="row">
											<div className="title">
												<span>{t('SETTING_BACKUP_KEEPDAY')}</span>
											</div>
											<div className="content">
												<Input
													type="text"
													name="keep_days_backup_data"
													disableUnderline
													className={classes.customInput}
													value={sysConf.keep_days_backup_data}
													onChange={e => setSysConf({ ...sysConf, [e.target.name]: e.target.value })}
													onBlur={e => onBlurSettings(e)}
													endAdornment={
														<InputAdornment position="end" style={{ paddingRight: '5px' }}>
															{t('DAY')}
														</InputAdornment>
													}
												/>
											</div>
										</div>
										<div className="row">
											<div className="title">
												<span>{t('PRESERVE_LAST')}</span>
											</div>
											<div className="content">
												<SwitchNum01
													name="preserve_last_revision"
													checked={sysConf.preserve_last_revision}
													onChange={e => changeSwitch(e)}
												/>
											</div>
										</div>
										<div className="row tall">
											<div className="title">
												<span>{t('PRESERVE_LAST_WITHOUT_ORIGINAL')}</span>
											</div>
											<div className="content">
												<SwitchNum01
													name="preserve_last_revision_without_original"
													checked={sysConf.preserve_last_revision_without_original}
													onChange={e => changeSwitch(e)}
												/>
												<span className={clsx(classes.hintSpan)}>
													{t('PRESERVE_LAST_WITHOUT_ORIGINAL_HELP')}
												</span>
											</div>
										</div>
										<div className="row">
											<div className="title">
												<span>{t('MAX_DAYS_PRESERVE_LAST_WITHOUT_ORIGINAL')}</span>
											</div>
											<div className="content">
												<Input
													type="text"
													name="max_days_preserve_last_revision_without_original"
													disableUnderline
													className={classes.customInput}
													value={sysConf.max_days_preserve_last_revision_without_original}
													onChange={e => setSysConf({ ...sysConf, [e.target.name]: e.target.value })}
													onBlur={e => onBlurSettings(e)}
													endAdornment={
														<InputAdornment position="end" style={{ paddingRight: '5px' }}>
															{t('DAY')}
														</InputAdornment>
													}
												/>
											</div>
										</div>
									</>
								)}
							</div>
							<div className={classes.settingGroup}>
								{sysConf.menu_support_type === 2 ? (
									<></>
								) : (
									<>
										<div className="row">
											<div className="title">
												<span>{t('DESKTOP_WEB_SUPPORT')}</span>
											</div>
											<div className="content">
												<SwitchNum01
													name="desktop_web_support"
													checked={sysConf.desktop_web_support}
													onChange={e => changeSwitch(e)}
												/>
											</div>
										</div>
										<div className="row">
											<div className="title">
												<span>{t('MOBILE_WEB_SUPPORT')}</span>
											</div>
											<div className="content">
												<SwitchNum01
													name="mobile_web_support"
													checked={sysConf.mobile_web_support}
													onChange={e => changeSwitch(e)}
												/>
											</div>
										</div>
									</>
								)}
								<div className="row tall">
									<div className="title">
										<span>{t('ALLOW_ADMIN_ACCESS_USERS_FOLDERS')}</span>
									</div>
									<div className="content ">
										<SwitchNum01
											name="allow_admin_access_users_folders"
											checked={sysConf.allow_admin_access_users_folders}
											onChange={e => changeSwitch(e)}
										/>
										<span className={clsx(classes.hintSpan)}>
											{t('ALLOW_ADMIN_ACCESS_USERS_FOLDERS_HINT')}
										</span>
									</div>
								</div>
								<div className="row">
									<div className="title">
										<span>{t('PRIVATE_FOLDER_SUPPORT')}</span>
									</div>
									<div className="content">
										<SwitchNum01
											name="private_folder_support"
											checked={sysConf.private_folder_support}
											onChange={e => changeSwitch(e)}
										/>
									</div>
								</div>
								<div className="row">
									<div className="title">
										<span>{t('SETTING_SECURE_DOCUMENT_USE')}</span>
									</div>
									<div className="content">
										<div className={classes.inputForm}>
											<Input
												type="text"
												disableUnderline
												className={classes.customInput}
												name="support_secure_document"
												value={sysConf.support_secure_document ? t('USE') : t('UNUSED')}
												readOnly
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="title">
										<span>{t('SETTING_RANSOMWARE_DETECT_USE')}</span>
									</div>
									<div className="content">
										<div className={classes.inputForm}>
											<Input
												type="text"
												disableUnderline
												className={classes.customInput}
												name="support_ransomware_detection"
												value={sysConf.support_ransomware_detection ? t('USE') : t('UNUSED')}
												readOnly
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="title">
										<span>{t('SETTING_MIN_SMB_VERSION')}</span>
									</div>
									<div className="content">
										<div className={classes.inputForm}>
											<Input
												type="text"
												disableUnderline
												className={classes.customInput}
												name="min_smb_protocol"
												value={
													sysConf.min_smb_protocol === 'SMB3'
														? 'SMB 3.0'
														: sysConf.min_smb_protocol === 'SMB2'
														? 'SMB 2.0'
														: 'SMB 1.0'
												}
												readOnly
											/>
										</div>
									</div>
								</div>
								<div className="row tall">
									<div className="title">
										<span>{t('SETTING_SAMBA_PORT_USE')}</span>
									</div>
									<div className="content">
										<SwitchNum01
											name="support_default_smb_port"
											checked={sysConf.support_default_smb_port}
											onChange={e => changeSwitch(e)}
										/>
										<span className={clsx(classes.hintSpan)}>{t('SETTING_SAMBA_PORT_USE_HINT')}</span>
									</div>
								</div>
								<div className="row tall">
									<div className="title">
										<span>{t('SETTING_SMB_ENCRYPTION')}</span>
									</div>
									<div className="content ">
										<SwitchNum01
											name="smb_encryption"
											checked={sysConf.smb_encryption}
											onChange={e => changeSwitch(e)}
										/>
										<span className={clsx(classes.hintSpan)}>{t('SETTING_SMB_ENCRYPTION_HELP_TEXT')}</span>
									</div>
								</div>
								{/* <Grid className="p-10" item xs={6} sm={2}>
												<TextField
													select
													name="no_shell_menu"
													value={sysConfigure.no_shell_menu}
													label={t('SETTING_NO_SHELL_MENU')}
													fullWidth
													onChange={e => changeSettings(e)}
												>
													<MenuItem value="true">{t('USE')}</MenuItem>
													<MenuItem value="false">{t('UNUSED')}</MenuItem>
												</TextField>
											</Grid> */}
							</div>
						</div>
						{sysConf.menu_support_type === 2 ? (
							<></>
						) : (
							<div className={classes.etcBox}>
								<div>
									<Button
										variant="contained"
										color="secondary"
										className=""
										type="button"
										fullWidth
										onClick={e => {
											dispatch(
												openDialog({
													children: (
														<OpenDialogForm
															title={t('FACTORY_RESET')}
															content={t('FACTORY_RESET_CONFIRM')}
															fnHandleOK={() => {
																setOpenBackdrop(true);
																factoryReset()
																	.then(data => {
																		dispatch(
																			showMessage({
																				message: t('FACTORY_RESET_MESAGE')
																			})
																		);
																		setOpenBackdrop(false);
																		window.location.href = '/';
																	})
																	.catch(err =>
																		dispatch(
																			showErrorMessage({
																				message: t('FACTORY_RESET_FAILED')
																			})
																		)
																	);
															}}
														/>
													)
												})
											);
										}}
									>
										{t('FACTORY_RESET')}
									</Button>
								</div>
								<div>
									<Button
										variant="contained"
										color="secondary"
										className=""
										type="button"
										fullWidth
										onClick={e => {
											updateFileInput.current.click();
										}}
									>
										<Icon fontSize="default" color="white">
											publish
										</Icon>
										{t('SETTING_UPDATE')}
									</Button>
									<input
										className="hidden"
										id="button-file"
										type="file"
										ref={updateFileInput}
										onChange={e => {
											setOpenBackdrop(true);
											updateSystem(e.target.files[0])
												.then(data =>
													dispatch(
														showMessage({
															message: t('SETTING_UPDATE_MESSAGE_1')
														})
													)
												)
												.catch(err => {
													if (err.error === commonTypes.SERVER_ERROR_INVALID_UPDATE_IMAGE) {
														dispatch(
															showErrorMessage({
																message: t('SETTING_UPDATE_ERROR_MESSAGE_1')
															})
														);
													} else if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
														dispatch(
															showErrorMessage({
																message: t('SETTING_UPDATE_ERROR_MESSAGE_2')
															})
														);
													} else {
														dispatch(
															showErrorMessage({
																message: t('SETTING_UPDATE_ERROR_MESSAGE_3')
															})
														);
													}
												})
												.finally(() => {
													setOpenBackdrop(false);
												});
											updateFileInput.current.value = '';
										}}
									/>
								</div>
								<div>
									{seedKey === '' ? (
										<Button
											variant="contained"
											color="secondary"
											className=""
											type="button"
											fullWidth
											onClick={e => {
												dispatch(getSeedKey());
											}}
										>
											{t('SEEDKEY')}
										</Button>
									) : (
										<Typography
											className="font-medium relative "
											variant="subtitle1"
											color="textSecondary"
											style={{ 'line-height': '5rem' }}
										>
											{seedKey}
											<Tooltip className="inline-block" arrow title={t('COPY')}>
												<IconButton
													className=""
													onClick={e => {
														const copyTextField = document.createElement('input');
														copyTextField.value = seedKey;
														document.body.appendChild(copyTextField);
														copyTextField.select();
														document.execCommand('copy');
														copyTextField.remove();

														dispatch(showMessage({ message: t('SEEDKEY_COPY') }));
													}}
												>
													<Icon>file_copy</Icon>
												</IconButton>
											</Tooltip>
										</Typography>
									)}
								</div>

								{/* <Grid item xs={6} sm={6}>
									<Typography className="font-medium mb-10" variant="h6" color="textSecondary">
										{t('SYSTEM_SHUTDOWN')}
									</Typography>
									<Grid container>
										<Grid item xs={6}>
											<Card>
												<CardContent>
													<Button
														className="w-full"
														variant="contained"
														color="secondary"
														aria-label="emergency-mode"
														onClick={e => {
															dispatch(
																openDialog({
																	children: (
																		<OpenDialogForm
																			title={t('SHUTDOWN_MESSAGE_1')}
																			fnHandleOK={() => {
																				shutdownServer().then(data => {
																					dispatch(
																						showMessage({
																							message: t('SHUTDOWN_MESSAGE_2')
																						})
																					);
																				});
																			}}
																		/>
																	)
																})
															);
														}}
													>
														{t('SHUTDOWN')}
													</Button>
												</CardContent>
											</Card>
										</Grid>
									</Grid>
								</Grid> */}
							</div>
						)}
					</TabPanel>

					{sysConf.menu_support_type === 2 ? (
						<></>
					) : (
						<TabPanel className={classes.tabPanel} value={tabType} index={1}>
							<div className={classes.settingTitle}>{t('SETUP_SETTING')}</div>
							<div className="flex setting">
								<div className={classes.settingGroup}>
									<div className="row">
										<div className="title">
											<span>{t('CONNECT_SERVER_ADDRESS')}</span>
										</div>
										<div className="content">
											<div className={classes.inputForm}>
												<Input
													type="text"
													disableUnderline
													name="connect_server_address"
													className={classes.customInput}
													value={sysConf.connect_server_address}
													onChange={e => {
														setSysConf({ ...sysConf, [e.target.name]: e.target.value });
													}}
													onBlur={e => onBlurSettings(e)}
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="title">
											<span>{t('MAP_MOUNT_DRIVE')}</span>
										</div>
										<div className="content">
											<Select
												variant="outlined"
												className={clsx(classes.select, 'w-full')}
												value={sysConf.map_mount_drive}
												name="map_mount_drive"
												onChange={e => {
													changeSettings(e);
													if (sysConf.force_map_mount_drive !== '') {
														requestHandler('force_map_mount_drive', e.target.value);
													}
												}}
											>
												<MenuItem value="">{t('UNSELECTED')}</MenuItem>
												{mountDriveLatters.map(latter => (
													<MenuItem value={latter}>{latter}</MenuItem>
												))}
											</Select>
										</div>
									</div>
									<div className="row tall">
										<div className="title">
											<span>{t('FORCE_MAP_MOUNT_DRIVE')}</span>
										</div>
										<div className="content">
											<SwitchNum01
												name="force_map_mount_drive"
												checked={sysConf.force_map_mount_drive !== ''}
												onChange={e => {
													requestHandler(
														e.target.name,
														sysConf.force_map_mount_drive !== '' ? '' : sysConf.map_mount_drive
													);
												}}
											/>
											<span className={classes.hintSpan}>{t('FORCE_MAP_MOUNT_DRIVE_HINT')}</span>
										</div>
									</div>
									<div className="row tall">
										<div className="title">
											<span>{t('SILENT_SETUP')}</span>
										</div>
										<div className="content ">
											<SwitchNum01
												name="silent_setup"
												checked={sysConf.silent_setup}
												onChange={e => changeSwitch(e)}
											/>
											<span className={classes.hintSpan}>{t('SILENT_SETUP_HINT')}</span>
										</div>
									</div>
									<div className="row">
										<div className="title">
											<span>{t('DISABLE_CONNECT_BUTTON_WEBADMIN')}</span>
										</div>
										<div className="content ">
											<SwitchNum01
												name="disable_connect_button_webadmin"
												checked={sysConf.disable_connect_button_webadmin}
												onChange={e => changeSwitch(e)}
											/>
										</div>
									</div>
									<div className="row">
										<div className="title">
											<span>{t('AUTO_START')}</span>
										</div>
										<div className="content ">
											<SwitchNum01
												name="auto_start"
												checked={sysConf.auto_start}
												onChange={e => changeSwitch(e)}
											/>
										</div>
									</div>
									{sysConf.menu_support_type === 2 ? (
										<></>
									) : (
										<div className="row">
											<div className="title">
												<span>{t('DISABLE_SAVE_PASSWORD')}</span>
											</div>
											<div className="content ">
												<SwitchNum01
													name="disable_save_password"
													checked={sysConf.disable_save_password}
													onChange={e => changeSwitch(e)}
												/>
											</div>
										</div>
									)}
								</div>
								<div className={classes.settingGroup}>
									<div className="row">
										<div className="title">
											<span>{t('NO_SHELL_MENU')}</span>
										</div>
										<div className="content ">
											<SwitchNum01
												name="no_shell_menu"
												checked={sysConf.no_shell_menu}
												onChange={e => changeSwitch(e)}
											/>
										</div>
									</div>
									<div className="row tall">
										<div className="title">
											<span>{t('NO_TRAY_ICON_MENU')}</span>
										</div>
										<div className="content ">
											<SwitchNum01
												name="no_tray_icon_menu"
												checked={sysConf.no_tray_icon_menu}
												onChange={e => changeSwitch(e)}
											/>
											<span className={classes.hintSpan}>{t('NO_TRAY_ICON_MENU_HINT')}</span>
										</div>
									</div>
									<div className="row tall">
										<div className="title">
											<span>{t('FORCE_SETUP_PERFORM')}</span>
										</div>
										<div className="content ">
											<SwitchNum01
												name="force_setup_perform"
												checked={sysConf.force_setup_perform}
												onChange={e => changeSwitch(e)}
											/>
											<span className={classes.hintSpan}>{t('FORCE_SETUP_PERFORM_HINT')}</span>
										</div>
									</div>
									<div className="row">
										<div className="title">
											<span>{t('SETTING_LOCK_VOLUME_LABEL')}</span>
										</div>
										<div className="content ">
											<SwitchNum01
												name="lock_volume_label"
												checked={sysConf.lock_volume_label}
												onChange={e => changeSwitch(e)}
											/>
										</div>
									</div>
									<div className="row tall">
										<div className="title">
											<span>{t('SETTING_DEFAULT_VOLUME_LABEL')}</span>
										</div>
										<div className="content ">
											<div className="content ">
												<div className={classes.inputForm}>
													<Input
														type="text"
														name="default_volume_label"
														disableUnderline
														className={classes.customInput}
														value={sysConf.default_volume_label}
														onChange={e =>
															setSysConf({ ...sysConf, [e.target.name]: e.target.value })
														}
														onBlur={e => onBlurSettings(e)}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="title">
											<span>{t('INTEGRATED_WITH_AD')}</span>
										</div>
										<div className="content">
											<div className={classes.inputForm}>
												<Input
													type="text"
													disableUnderline
													className={classes.customInput}
													name="integrated_with_AD"
													value={sysConf.integrated_with_AD ? t('USE') : t('UNUSED')}
													readOnly
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</TabPanel>
					)}

					{/* <div className="mt-20">
							<Typography className="font-medium mb-10" variant="h6" color="textSecondary">
								{t('EXTERNAL_SETTING')}
							</Typography>
							<Grid container>
								<Grid item xs={12}>
									<Card>
										<CardContent>
											<Grid container>
												<Grid className="p-10" item xs={6} sm={2}>
													<TextField
														select
														name="alien_connection_enabled"
														value={alienConnectionEnabled}
														label={t('EXTERNAL_USED')}
														fullWidth
														onChange={e => setAlienConnectionEnable(e.target.value)}
													>
														<MenuItem value="true">{t('USE')}</MenuItem>
														<MenuItem value="false">{t('UNUSED')}</MenuItem>
													</TextField>
												</Grid>
												<Grid className="p-10" item xs={6} sm={2}>
													<TextField
														className="mb-24"
														type="text"
														label={t('EXTERNAL_SERVERNAME')}
														name="alien_connection_name"
														value={alienConnectionName}
														disabled={alienConnectionEnabled === 'false'}
														onChange={e => {
															const engNum = /^[a-zA-Z0-9_-]*$/;
															if (engNum.test(e.target.value)) {
																setAlienConnectionName(e.target.value);
															} else {
																dispatch(
																	showErrorMessage({ message: t('EXTERNAL_ERROR_MESSAGE_1') })
																);
															}
														}}
														fullWidth
													/>
												</Grid>
												<Grid className="p-10" item xs={6} sm={2}>
													<Button
														variant="contained"
														color="primary"
														className="mt-16"
														aria-label="ignore-add"
														size="small"
														type="button"
														style={{ 'margin-left': '10px' }}
														onClick={e => {
															if (
																String(alienConObj.enabled) !== alienConnectionEnabled ||
																alienConObj.name !== alienConnectionName
															) {
																if (alienConnectionEnabled === 'true') {
																	//	사용인데 서버명이 없을경우
																	if (alienConnectionName.trim().length <= 0) {
																		dispatch(
																			showErrorMessage({
																				message: t('EXTERNAL_ERROR_MESSAGE_2')
																			})
																		);
																		return false;
																	}
																} else if (alienConObj.name !== alienConnectionName) {
																	//	미사용으로 서버명변경시
																	dispatch(
																		showMessage({
																			message: t('EXTERNAL_ERROR_MESSAGE_3')
																		})
																	);
																	setAlienConnectionName(alienConObj.name);
																	return false;
																}
																setOpenBackdrop(true);
																setAlienConnection(alienConnectionEnabled, alienConnectionName)
																	.then(data => {
																		dispatch(getAlienConnection());
																		dispatch(showMessage({ message: t('EXTERNAL_MESSAGE') }));
																	})
																	.catch(err => {
																		let msg = t('EXTERNAL_ERROR_MESSAGE_4');
																		if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
																			setAlienConnectionName(alienConObj.name);
																			msg = t('EXTERNAL_ERROR_MESSAGE_5');
																		}
																		dispatch(
																			showErrorMessage({
																				message: msg
																			})
																		);
																	})
																	.finally(() => {
																		setOpenBackdrop(false);
																	});
																return false;
															}
															dispatch(showMessage({ message: t('EXTERNAL_ERROR_MESSAGE_6') }));
															return false;
														}}
													>
														{t('SETTING')}
													</Button>
												</Grid>
											</Grid>
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</div> */}

					<TabPanel className={classes.tabPanel} value={tabType} index={2}>
						<div className={classes.settingTitle}>{t('FOLDER_SIZE_SETTING')}</div>
						<div className="flex setting">
							<div className={classes.settingGroup}>
								<div className="row tall">
									<div className="title">
										<span>{t('PRIVATEFOLDER_SIZE')}</span>
									</div>
									<div className="content">
										<div className={classes.inputForm}>
											<Input
												type="number"
												name="private_folder_size"
												disableUnderline
												className={classes.customInput}
												value={capacityObj.privateObj.capacity}
												onChange={e => {
													setCapacityObj({
														...capacityObj,
														privateObj: {
															...capacityObj.privateObj,
															capacity: Number(e.target.value)
														}
													});
												}}
												onBlur={e => {
													e.preventDefault();
													const size = capacityObj.privateObj.unit
														? capacityObj.privateObj.capacity
														: capacityObj.privateObj.capacity * 1024;
													if (sysConfigure[e.target.name] !== size) {
														requestHandler([e.target.name], size);
													}
												}}
												endAdornment={
													<InputAdornment position="end" className="">
														<Select
															id="folder_capacity"
															disableUnderline
															variant="standard"
															className={clsx(classes.selectTextAlignRight, '')}
															value={capacityObj.privateObj.unit}
															onChange={e => {
																const obj = { ...capacityObj };
																if (capacityObj.privateObj.unit) {
																	obj.privateObj.capacity =
																		capacityObj.privateObj.capacity < 1024
																			? 0
																			: Math.floor(capacityObj.privateObj.capacity / 1024);
																} else {
																	obj.privateObj.capacity =
																		capacityObj.privateObj.capacity * 1024;
																}
																obj.privateObj.unit = !capacityObj.privateObj.unit;
																setCapacityObj(obj);
															}}
														>
															<MenuItem value="false">Gbyte</MenuItem>
															<MenuItem value="true">Mbyte</MenuItem>
														</Select>
													</InputAdornment>
												}
											/>
										</div>
										<span className={classes.hintSpan}>{t('FOLDER_SIZE_HELP_TEXT')}</span>
									</div>
								</div>
								<div className="row tall">
									<div className="title">
										<span>{t('COLLECTFOLDER_SIZE')}</span>
									</div>
									<div className="content">
										<div className={classes.inputForm}>
											<Input
												type="number"
												name="collect_folder_size"
												disableUnderline
												className={classes.customInput}
												value={capacityObj.collectObj.capacity}
												onChange={e => {
													setCapacityObj({
														...capacityObj,
														collectObj: {
															...capacityObj.collectObj,
															capacity: Number(e.target.value)
														}
													});
												}}
												onBlur={e => {
													e.preventDefault();
													const size = capacityObj.collectObj.unit
														? capacityObj.collectObj.capacity
														: capacityObj.collectObj.capacity * 1024;
													if (sysConfigure[e.target.name] !== size) {
														requestHandler([e.target.name], size);
													}
												}}
												endAdornment={
													<InputAdornment position="end" className="">
														<Select
															id="folder_capacity"
															disableUnderline
															variant="standard"
															className={clsx(classes.selectTextAlignRight, '')}
															value={capacityObj.collectObj.unit}
															onChange={e => {
																const obj = { ...capacityObj };
																if (capacityObj.collectObj.unit) {
																	obj.collectObj.capacity =
																		capacityObj.collectObj.capacity < 1024
																			? 0
																			: Math.floor(capacityObj.collectObj.capacity / 1024);
																} else {
																	obj.collectObj.capacity =
																		capacityObj.collectObj.capacity * 1024;
																}
																obj.collectObj.unit = !capacityObj.collectObj.unit;
																setCapacityObj(obj);
															}}
														>
															<MenuItem value="false">Gbyte</MenuItem>
															<MenuItem value="true">Mbyte</MenuItem>
														</Select>
													</InputAdornment>
												}
											/>
										</div>
										<span className={classes.hintSpan}>{t('FOLDER_SIZE_HELP_TEXT')}</span>
									</div>
								</div>
								<div className="row tall">
									<div className="title">
										<span>{t('ORGFOLDER_SIZE')}</span>
									</div>
									<div className="content">
										<div className={classes.inputForm}>
											<Input
												type="number"
												name="organization_folder_size"
												disableUnderline
												className={classes.customInput}
												value={capacityObj.orgObj.capacity}
												onChange={e => {
													setCapacityObj({
														...capacityObj,
														orgObj: {
															...capacityObj.orgObj,
															capacity: Number(e.target.value)
														}
													});
												}}
												onBlur={e => {
													e.preventDefault();
													const size = capacityObj.orgObj.unit
														? capacityObj.orgObj.capacity
														: capacityObj.orgObj.capacity * 1024;
													if (sysConfigure[e.target.name] !== size) {
														requestHandler([e.target.name], size);
													}
												}}
												endAdornment={
													<InputAdornment position="end" className="">
														<Select
															id="folder_capacity"
															disableUnderline
															variant="standard"
															className={clsx(classes.selectTextAlignRight, '')}
															value={capacityObj.orgObj.unit}
															onChange={e => {
																const obj = { ...capacityObj };
																if (capacityObj.orgObj.unit) {
																	obj.orgObj.capacity =
																		capacityObj.orgObj.capacity < 1024
																			? 0
																			: Math.floor(capacityObj.orgObj.capacity / 1024);
																} else {
																	obj.orgObj.capacity = capacityObj.orgObj.capacity * 1024;
																}
																obj.orgObj.unit = !capacityObj.orgObj.unit;
																setCapacityObj(obj);
															}}
														>
															<MenuItem value="false">Gbyte</MenuItem>
															<MenuItem value="true">Mbyte</MenuItem>
														</Select>
													</InputAdornment>
												}
											/>
										</div>
										<span className={classes.hintSpan}>{t('FOLDER_SIZE_HELP_TEXT')}</span>
									</div>
								</div>
								<div className="row tall">
									<div className="title">
										<span>{t('SHAREDFOLDER_SIZE')}</span>
									</div>
									<div className="content">
										<div className={classes.inputForm}>
											<Input
												type="number"
												name="shared_folder_size"
												disableUnderline
												className={classes.customInput}
												value={capacityObj.sharedObj.capacity}
												onChange={e => {
													setCapacityObj({
														...capacityObj,
														sharedObj: {
															...capacityObj.sharedObj,
															capacity: Number(e.target.value)
														}
													});
												}}
												onBlur={e => {
													e.preventDefault();
													const size = capacityObj.sharedObj.unit
														? capacityObj.sharedObj.capacity
														: capacityObj.sharedObj.capacity * 1024;
													if (sysConfigure[e.target.name] !== size) {
														requestHandler([e.target.name], size);
													}
												}}
												endAdornment={
													<InputAdornment position="end" className="">
														<Select
															id="folder_capacity"
															disableUnderline
															variant="standard"
															className={clsx(classes.selectTextAlignRight, '')}
															value={capacityObj.sharedObj.unit}
															onChange={e => {
																const obj = { ...capacityObj };
																if (capacityObj.sharedObj.unit) {
																	obj.sharedObj.capacity =
																		capacityObj.sharedObj.capacity < 1024
																			? 0
																			: Math.floor(capacityObj.sharedObj.capacity / 1024);
																} else {
																	obj.sharedObj.capacity =
																		capacityObj.sharedObj.capacity * 1024;
																}
																obj.sharedObj.unit = !capacityObj.sharedObj.unit;
																setCapacityObj(obj);
															}}
														>
															<MenuItem value="false">Gbyte</MenuItem>
															<MenuItem value="true">Mbyte</MenuItem>
														</Select>
													</InputAdornment>
												}
											/>
										</div>
										<span className={classes.hintSpan}>{t('FOLDER_SIZE_HELP_TEXT')}</span>
									</div>
								</div>
							</div>
						</div>
					</TabPanel>

					<TabPanel className={classes.tabPanel} value={tabType} index={3}>
						<div className={classes.settingTitle}>{t('WEBLINK_SETTING')}</div>
						<div className="flex setting">
							<div className={classes.settingGroup}>
								<div className="row tall">
									<div className="title">
										<span>{t('WEBLINK_USED')}</span>
									</div>
									<div className="content">
										<SwitchNum01
											name="weblink_service_support"
											checked={sysConf.weblink_service_support}
											onChange={e => changeSwitch(e)}
										/>
										<span className={classes.hintSpan}>{t('WEBLINK_COMMENT_1')}</span>
									</div>
								</div>
								<div className="row">
									<div className="title">
										<span>{t('WEBLINK_SERVICE_ADDR')}</span>
									</div>
									<div className="content">
										<div className={classes.inputForm}>
											<Input
												type="text"
												disableUnderline
												className={classes.customInput}
												fullWidth
												name="weblink_service_address"
												value={sysConf.weblink_service_address}
												onChange={e => setSysConf({ ...sysConf, [e.target.name]: e.target.value })}
												onBlur={e => onBlurSettings(e)}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="title">
										<span>{t('WEBLINK_SERVICE_PORT')}</span>
									</div>
									<div className="content">
										<div className={classes.inputForm}>
											<Input
												type="text"
												disableUnderline
												className={classes.customInput}
												fullWidth
												name="weblink_service_port"
												value={sysConf.weblink_service_port}
												onChange={e => setSysConf({ ...sysConf, [e.target.name]: e.target.value })}
												onBlur={e => onBlurSettings(e)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className={classes.settingGroup}>
								<div className="row">
									<div className="title">
										<span>{t('WEBLINK_RESTRICT_EXTS')}</span>
									</div>
									<div className="content">
										<div className={classes.inputForm}>
											<Input
												type="text"
												disableUnderline
												className={classes.customInput}
												fullWidth
												name="weblink_restrict_exts"
												value={sysConf.weblink_restrict_exts}
												onChange={e => setSysConf({ ...sysConf, [e.target.name]: e.target.value })}
												onBlur={e => onBlurSettings(e)}
											/>
										</div>
									</div>
								</div>
								<div className="row tall">
									<div className="title">
										<span>{t('WEBLINK_RESTRICT_FILE_SIZE')}</span>
									</div>
									<div className="content">
										<div className={classes.inputForm}>
											<Input
												type="number"
												name="weblink_restrict_file_size"
												disableUnderline
												className={classes.customInput}
												value={weblinkRestrictObj.fileSize}
												onChange={e => {
													setWeblinkRestrictObj({
														...weblinkRestrictObj,
														fileSize: Number(e.target.value)
													});
												}}
												onBlur={e => {
													e.preventDefault();
													const size = weblinkRestrictObj.fileUnit
														? weblinkRestrictObj.fileSize * 1024 * 1024
														: weblinkRestrictObj.fileSize * 1024 * 1024 * 1024;
													if (sysConfigure[e.target.name] !== size) {
														requestHandler([e.target.name], size);
													}
												}}
												endAdornment={
													<InputAdornment position="end" className="">
														<Select
															id="weblink_restrict_file_size"
															disableUnderline
															variant="standard"
															className={clsx(classes.selectTextAlignRight, '')}
															value={weblinkRestrictObj.fileUnit}
															onChange={e => {
																const obj = { ...weblinkRestrictObj };
																if (weblinkRestrictObj.fileUnit) {
																	obj.fileSize =
																		weblinkRestrictObj.fileSize < 1024 * 1024
																			? 0
																			: Math.floor(
																					weblinkRestrictObj.fileSize / (1024 * 1024)
																			  );
																} else {
																	obj.fileSize =
																		weblinkRestrictObj.fileSize * 1024 * 1024 * 1024;
																}
																obj.fileUnit = !weblinkRestrictObj.fileUnit;
																setWeblinkRestrictObj(obj);
															}}
														>
															<MenuItem value="false">Gbyte</MenuItem>
															<MenuItem value="true">Mbyte</MenuItem>
														</Select>
													</InputAdornment>
												}
											/>
										</div>
										<span className={classes.hintSpan}>{t('FOLDER_SIZE_HELP_TEXT')}</span>
									</div>
								</div>
								<div className="row tall">
									<div className="title">
										<span>{t('WEBLINK_RESTRICT_TOTAL_SIZE')}</span>
									</div>
									<div className="content">
										<div className={classes.inputForm}>
											<Input
												type="number"
												name="weblink_restrict_total_size"
												disableUnderline
												className={classes.customInput}
												value={weblinkRestrictObj.totalSize}
												onChange={e => {
													setWeblinkRestrictObj({
														...weblinkRestrictObj,
														totalSize: Number(e.target.value)
													});
												}}
												onBlur={e => {
													e.preventDefault();
													const size = weblinkRestrictObj.totalUnit
														? weblinkRestrictObj.totalSize * 1024 * 1024
														: weblinkRestrictObj.totalSize * 1024 * 1024 * 1024;
													if (sysConfigure[e.target.name] !== size) {
														requestHandler([e.target.name], size);
													}
												}}
												endAdornment={
													<InputAdornment position="end" className="">
														<Select
															id="weblink_restrict_total_size"
															disableUnderline
															variant="standard"
															className={clsx(classes.selectTextAlignRight, '')}
															value={weblinkRestrictObj.totalUnit}
															onChange={e => {
																const obj = { ...weblinkRestrictObj };
																if (weblinkRestrictObj.totalUnit) {
																	obj.totalSize =
																		weblinkRestrictObj.totalSize < 1024 * 1024
																			? 0
																			: Math.floor(
																					weblinkRestrictObj.totalSize / (1024 * 1024)
																			  );
																} else {
																	obj.totalSize =
																		weblinkRestrictObj.totalSize * 1024 * 1024 * 1024;
																}
																obj.totalUnit = !weblinkRestrictObj.totalUnit;
																setWeblinkRestrictObj(obj);
															}}
														>
															<MenuItem value="false">Gbyte</MenuItem>
															<MenuItem value="true">Mbyte</MenuItem>
														</Select>
													</InputAdornment>
												}
											/>
										</div>
										<span className={classes.hintSpan}>{t('FOLDER_SIZE_HELP_TEXT')}</span>
									</div>
								</div>
							</div>
						</div>
					</TabPanel>

					<TabPanel className={classes.tabPanel} value={tabType} index={4}>
						<div className={classes.settingTitle}>{t('SETTING_PII_DETECT')}</div>
						<div className="flex setting">
							<div className={classes.settingGroup}>
								<div className="row">
									<div className="title">
										<span>{t('SETTING_PII_DETECT_ENABLE')}</span>
									</div>
									<div className="content">
										<SwitchNum01
											name="pii_detect_enable"
											checked={sysConf.pii_detect_enable}
											onChange={e => changeSwitch(e)}
										/>
									</div>
								</div>
								<div className="row">
									<div className="title">
										<span>{t('SETTING_PII_DETECT_OCR')}</span>
									</div>
									<div className="content">
										<SwitchNum01
											name="async_ocr"
											checked={sysConf.async_ocr}
											onChange={e => changeSwitch(e)}
										/>
									</div>
								</div>
							</div>
						</div>
					</TabPanel>

					{sysConf.menu_support_type === 2 ? (
						<></>
					) : (
						<TabPanel className={classes.tabPanel} value={tabType} index={5}>
							<div className={classes.settingTitle}>{t('RESTRICT_TITLE')}</div>
							<div className="flex setting">
								<div className={classes.settingGroup}>
									<div className="row tall">
										<div className="title">
											<span>{t('RESTRICT_SIZE_TITLE')}</span>
										</div>
										<div className="content">
											<div className="content">
												<div className={classes.inputForm}>
													<Input
														type="number"
														disableUnderline
														className={classes.customInput}
														name="restrict_file_size"
														value={restrictFileSizeObj.size}
														onChange={e =>
															setRestrictFileSizeObj({
																...restrictFileSizeObj,
																size: Number(e.target.value)
															})
														}
														onBlur={e => {
															e.preventDefault();
															const size = restrictFileSizeObj.unit
																? restrictFileSizeObj.size * 1024 * 1024
																: restrictFileSizeObj.size * 1024 * 1024 * 1024;
															if (sysConfigure[e.target.name] !== size) {
																requestHandler([e.target.name], size);
															}
														}}
														endAdornment={
															<InputAdornment position="end" className="">
																<Select
																	id="folder_capacity"
																	disableUnderline
																	variant="standard"
																	className={clsx(classes.selectTextAlignRight, '')}
																	value={restrictFileSizeObj.unit}
																	onChange={e => {
																		let size = 0;
																		if (restrictFileSizeObj.unit) {
																			size =
																				restrictFileSizeObj.size < 1024
																					? 0
																					: Math.floor(restrictFileSizeObj.size / 1024);
																		} else {
																			size = restrictFileSizeObj.size * 1024;
																		}
																		setRestrictFileSizeObj({
																			unit: !restrictFileSizeObj.unit,
																			size
																		});
																	}}
																>
																	<MenuItem value="false">Gbyte</MenuItem>
																	<MenuItem value="true">Mbyte</MenuItem>
																</Select>
															</InputAdornment>
														}
													/>
												</div>
												<span className={classes.hintSpan}>{t('FOLDER_SIZE_HELP_TEXT')}</span>
											</div>
										</div>
									</div>
									<div className="row tall">
										<div className="title">
											<span>{t('RESTRICT_EXT_TITLE')}</span>
										</div>
										<div className="content">
											<div className={classes.inputForm}>
												<Input
													type="text"
													disableUnderline
													className={classes.customInput}
													fullWidth
													name="restrict_file_exts"
													value={sysConf.restrict_file_exts}
													onChange={e => setSysConf({ ...sysConf, [e.target.name]: e.target.value })}
													onBlur={e => onBlurSettings(e)}
												/>
											</div>
											<span className={clsx(classes.hintSpan, 'text-left')}>
												{t('RESTRICT_EXT_HELP_TEXT')}
											</span>
										</div>
									</div>
									<div className="row tall">
										<div className="title">
											<span>{t('RESTRICT_EXT_REMOVE')}</span>
										</div>
										<div className="content">
											<SwitchNum01
												name="restrict_file_exts_remove_at_batch"
												checked={!sysConf.restrict_file_exts_remove_at_batch}
												onChange={e => changeSwitch(e)}
											/>
											<span className={classes.hintSpan}>{t('RESTRICT_EXT_REMOVE_HELP_TEXT')}</span>
										</div>
									</div>
								</div>
							</div>
						</TabPanel>
					)}

					{sysConf.menu_support_type !== 2 &&
					(productInfo.supportDataleakControl ||
						productInfo.supportSavingControl ||
						productInfo.supportAccessControl) ? (
						<TabPanel className={classes.tabPanel} value={tabType} index={6}>
							<div className={classes.settingTitle}>{t('SETTING_CENTRALIZED_DOC')}</div>
							<div className="flex setting">
								<div className={classes.settingGroup}>
									<div className="row">
										<div className="title">
											<span>{t('DATALEAK_CONTRL')}</span>
										</div>
										<div className="content">
											<SwitchNum01
												name="dataleak_control"
												checked={sysConf.dataleak_control}
												onChange={e => {
													if (!productInfo.supportDataleakControl) {
														dispatch(showMessage({ message: t('DATALEAK_MESSAGE_1') }));
														return false;
													}
													changeSwitch(e);
													return true;
												}}
											/>
										</div>
									</div>
									<div className="row">
										<div className="title">
											<span>{t('ACCESS_CONTROL_USED')}</span>
										</div>
										<div className="content">
											<SwitchNum01
												name="access_control"
												checked={sysConf.access_control}
												onChange={e => {
													if (!productInfo.supportAccessControl) {
														dispatch(showMessage({ message: t('DATALEAK_MESSAGE_2') }));
														return false;
													}
													changeSwitch(e);
													return true;
												}}
											/>
										</div>
									</div>
								</div>
								<div className={classes.settingGroup}>
									<div className="row">
										<div className="title">
											<span>{t('SAVING_CONTROL_USED')}</span>
										</div>
										<div className="content">
											<SwitchNum01
												name="saving_control"
												checked={sysConf.saving_control}
												onChange={e => {
													if (!productInfo.supportSavingControl) {
														dispatch(showMessage({ message: t('DATALEAK_MESSAGE_3') }));
														return false;
													}
													changeSwitch(e);
													return true;
												}}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className={classes.settingTitle}>{t('SYNC_SOLUTION_TITLE')}</div>
							<div className="flex setting">
								<div className={classes.settingGroup}>
									<div className="row tall">
										<div className="title">
											<span>{t('SYNC_SOLUTION_INTEGRATED')}</span>
										</div>
										<div className="content">
											<SwitchNum01
												name="sync_solution_integrated"
												checked={sysConf.sync_solution_integrated}
												onChange={e => changeSwitch(e)}
											/>
											<span className={classes.hintSpan}>{t('SYNC_SOLUTION_INTEGRATED_HINT_1')}</span>
											<span className={classes.hintSpan}>{t('SYNC_SOLUTION_INTEGRATED_HINT_2')}</span>
										</div>
									</div>

									{sysConf.sync_solution_integrated ? (
										<>
											<div className="row tall">
												<div className="title">
													<span>{t('SYNC_SOLUTION_NAME')}</span>
												</div>
												<div className="content">
													<div className={classes.inputForm}>
														<Input
															type="text"
															name="sync_solution"
															className={clsx(classes.inputRight, 'no-border')}
															value={sysConf.sync_solution}
															readOnly
														/>
													</div>
													<span className={clsx(classes.hintSpan, 'text-left')}>
														{t('RESTRICT_EXT_HELP_TEXT')}
													</span>
												</div>
											</div>
											<div className="row tall">
												<div className="title">
													<span>{t('SYNC_SOLUTION_CONSUMER_FOLDER_ACCESSIBLE')}</span>
												</div>
												<div className="content">
													<SwitchNum01
														name="sync_solution_consumer_folder_accessible"
														checked={sysConf.sync_solution_consumer_folder_accessible}
														onChange={e => changeSwitch(e)}
													/>
												</div>
											</div>
										</>
									) : (
										<></>
									)}
								</div>
							</div>
						</TabPanel>
					) : (
						<></>
					)}
				</Paper>
			</FuseScrollbars>
		</div>
	);
}

export default SettingsContent;
