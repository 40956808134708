import {
	TableCell,
	TableHead,
	TableRow,
	Icon,
	Paper,
	Input,
	TableSortLabel,
	InputAdornment,
	IconButton
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	tr: {
		'& .sincesec': {
			width: 95
		},
		'& .user-id': {
			width: 200
		},
		'& .release-icon': {
			width: 50
		}
	}
}));

function HoldFilesTableHead(props) {
	const { t } = useTranslation('docbase');
	const classes = useStyles();

	const headerCols = [
		{
			id: 'document_path',
			align: 'left',
			className: 'left',
			disablePadding: false,
			label: t('DOCUMENT'),
			isSearch: false,
			sort: true
		},
		{
			id: 'button',
			align: 'center',
			className: 'release-icon',
			disablePadding: true,
			label: '',
			isSearch: false,
			sort: false
		},
		{
			id: 'user',
			align: 'left',
			className: 'user-id search-cell',
			disablePadding: false,
			label: t('USER'),
			isSearch: true,
			sort: true
		},
		{
			id: 'since_sece',
			align: 'center',
			className: 'sincesec',
			disablePadding: false,
			label: t('SINCE_SECE'),
			isSearch: false,
			sort: false
		},
		{
			id: 'start_time',
			align: 'center',
			className: 'table_col_date',
			disablePadding: false,
			label: t('START_TIME'),
			isSearch: false,
			sort: true
		}
	];

	const [searchObj, setSearchObj] = React.useState({
		user: { show: false, value: '' }
	});

	const createSortHandler = property => event => {
		props.onRequestSort(event, property);
	};

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};

	return (
		<TableHead>
			<TableRow className={classes.tr}>
				{headerCols.map(row => {
					return (
						<TableCell
							className={clsx(row.className, searchObj[row.id] && searchObj[row.id].show ? 'show' : '')}
							key={row.id}
							align={row.align}
							padding={row.disablePadding ? 'none' : 'default'}
							sortDirection={props.order.id === row.id ? props.order.direction : false}
						>
							{searchObj[row.id] && searchObj[row.id].show ? (
								<Paper className="flex items-center px-8 py-4 rounded-8 shadow mx-5">
									<Icon color="action">search</Icon>

									<Input
										placeholder={row.label}
										className="flex flex-1 mx-8 w-0"
										disableUnderline
										value={searchObj[row.id].value}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													className="p-5"
													aria-label="toggle password visibility"
													onClick={e =>
														setSearchObj({ ...searchObj, [row.id]: { show: false, value: '' } })
													}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													<Icon color="action">close</Icon>
												</IconButton>
											</InputAdornment>
										}
										inputProps={{
											'aria-label': 'Search'
										}}
										onChange={e => {
											setSearchObj({ ...searchObj, [row.id]: { show: true, value: e.target.value } });
										}}
										onKeyDown={e => {
											if (e.key === 'Enter') {
												e.preventDefault();
												props.fnSearch(searchObj.user.value);
											}
										}}
									/>
								</Paper>
							) : row.sort ? (
								<TableSortLabel
									active={props.order.id === row.id}
									direction={props.order.direction}
									onClick={createSortHandler(row.id)}
								>
									<span className="align-middle">{row.label}</span>
									{row.isSearch ? (
										<Icon
											className="align-middle ml-3 cursor-pointer"
											onClick={e => {
												setSearchObj({ ...searchObj, [row.id]: { show: true, value: '' } });
											}}
										>
											search
										</Icon>
									) : (
										<></>
									)}
								</TableSortLabel>
							) : (
								<>
									<span className="align-middle">{row.label}</span>
									{row.isSearch ? (
										<Icon
											className="align-middle ml-3 cursor-pointer"
											onClick={e => {
												setSearchObj({ ...searchObj, [row.id]: { show: true, value: '' } });
											}}
										>
											search
										</Icon>
									) : (
										<></>
									)}
								</>
							)}
						</TableCell>
					);
				}, this)}
			</TableRow>
		</TableHead>
	);
}

export default HoldFilesTableHead;
